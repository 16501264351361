import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    content: {
        textAlign: 'center'
    },
    image: {
        height: 'auto',
        width: '50%',
        objectFit: 'cover'
    }
}))

export default function DialogImagePreview({ open, setOpen, fileName }) {

    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}

        >
            <DialogTitle style={{ textAlign: 'center' }} >Prévisualisation de l'image</DialogTitle>
            <DialogContent className={classes.content}>
                <img className={classes.image} src={`${process.env.REACT_APP_BASE_API}/download/image/${fileName}`} alt="" />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    Fermer
                </Button>

            </DialogActions>
        </Dialog>
    );
}