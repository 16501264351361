import { Button, CircularProgress } from '@material-ui/core'
import React, { useCallback, useState, useContext } from 'react'
import { OrderContext } from '../../contexts/OrderContext';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { usePost } from '../../modules/request';


function OrderControlPaymentButton({ order }) {

    const [loadingPayment, setloadingPayment] = useState(false);
    const post = usePost()
    const [, dispatchSnackbar] = useContext(SnackbarContext);
    const [, dispatch] = useContext(OrderContext);
    const handelClickValidPayment = useCallback(

        () => {
            (async () => {
                setloadingPayment(true)
                try {
                    await post('/order/validePayment', { orderId: order.id })
                    dispatch({
                        type: 'UPDATE_ORDER',
                        payload: {
                            id: order.id,
                            key: 'paymentProcessed',
                            value: true
                        }
                    })
                    dispatchSnackbar({
                        type: 'SET_SNACKBAR',
                        payload: {
                            open: true,
                            content: 'Le paiment est validé',
                            type: 'success',
                            duration: 6000
                        }
                    })
                } catch (e) {
                    dispatchSnackbar({
                        type: 'SET_SNACKBAR',
                        payload: {
                            open: true,
                            content: e.response.data.message,
                            type: 'error',
                            duration: 6000
                        }
                    })
                }
                setloadingPayment(false)
            })()
        },
        [],
    )

    return (
        <>
            {order.pi ?
                <Button color='primary' variant="outlined" startIcon={loadingPayment ? <CircularProgress size={10} /> : null} disabled={order.paymentProcessed} onClick={handelClickValidPayment}>{order.paymentProcessed ? 'Paiement effectué' : 'Engager le Paiement'}</Button>
                :
                <Button color='primary' variant="outlined" disabled >Client en compte</Button>

            }
        </>


    )
}

export default OrderControlPaymentButton
