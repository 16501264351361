import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import CartLine from '../CartLine';


export default function DialogShowFormsInOrder({ baskets, open, handleClose, isUser = false }) {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const getItems = useCallback(
    () => {
      if (!!baskets?.length) {

        return baskets?.map(item => <Grid item><CartLine control={false} key={item._id} item={item} isUser={isUser} /></Grid>)
      }
    },
    [baskets],
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isDownSm}
      maxWidth={'xl'}
      scroll='body'
    >

      <DialogActions>
        <Button onClick={handleClose} variant='contained' color="primary">
          fermer
        </Button>

      </DialogActions>
      <DialogContent >
        <DialogContentText >
          <Grid container direction='column' spacing={2}>
            {getItems()}
          </Grid>
        </DialogContentText>
      </DialogContent>

    </Dialog>
  );
}
