import { Box, Typography, makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { PropertiesContext } from '../../contexts/PropertiesContext';


const useStyles = makeStyles((theme) => ({
    container: {
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius
    }
}))

function DisplayCgv() {

    const [properties] = useContext(PropertiesContext);

    const classes = useStyles()

    return (
        properties?.cgv &&
        <Box className={classes.container}>
            <Typography display="block" align="center" variant="button" style={{ fontWeight: 'bold' }}>Condition Générales de vente</Typography>
            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: properties.cgv }} />
        </Box>
    )
}

export default DisplayCgv