import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CatalogImagePreview from '../../Control/CatalogImagePreview';
import { useContext } from 'react';
import { CatalogContext } from '../../../contexts/CatalogContext';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-around',
        cursor: 'pointer',
        marginBottom: '1rem',
        '&:hover': {
            boxShadow: theme.shadows[8]
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        height: 'max-content',
        alignSelf: 'center'
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    previewImage: {
        '& img': {
            height: '100px'
        },
        '& svg': {
            height: '100px'
        },

    },
    selected: {
        borderLeft: `5px solid ${theme.palette.primary.main}`
    }
}));
function CatalogCard({ data }) {

    const [{ selectedCatalog }, dispatch] = useContext(CatalogContext)

    const isSelected = useCallback(
        () => {
            return selectedCatalog === data._id
        },
        [data._id, selectedCatalog],
    )

    const handelClick = useCallback(
        () => {
            dispatch({
                type: 'SET_SELECTED_CATALOG',
                payload: data._id
            })
            dispatch({
                type: 'SET_STEP',
                payload: 1
            })
        },
        [data._id, dispatch],
    )

    const classes = useStyles();

    return (
        <Card className={isSelected() ? [classes.root, classes.selected] : [classes.root]} onClick={handelClick} elevation={isSelected() ? 1 : 2}>
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography variant="subtitle1">
                        {data.name}
                    </Typography>

                </CardContent>
            </div>
            <div className={classes.previewImage}>
                <CatalogImagePreview image={data.file} />
            </div>
        </Card>
    )
}

export default CatalogCard
