import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ConfigurationManuShadeSelector from './ConfigurationManuShadeSelector';
import ConfigurationMenuThicknessSelector from './ConfigurationMenuThicknessSelector';
import ConfiguratorMenuMaterialSector from './ConfiguratorMenuMaterialSector';
import ConfiguratorMenuNumberPiece from './ConfiguratorMenuNumberPiece';

const useStyles = makeStyles((theme) => ({
}))


function ConfiguratorMenu({
    readOnly,
    withoutNbPiece,
    withoutThinckness,
    materialShrink,
    shadeShrink,
    thicknessShrink,
    thicknessReadOnly,
    overwriteValue,
    stepThickness,
    overwriteonChangeMaterial,
    overwriteonChangeShade,
    overwriteonChangeThickness
}) {
    const classes = useStyles();
    return (
        <>
            <ConfiguratorMenuMaterialSector readOnly={readOnly} overwriteValue={overwriteValue} overwriteonChangeMaterial={overwriteonChangeMaterial} materialShrink={materialShrink} stepThickness={stepThickness} />

            <ConfigurationManuShadeSelector readOnly={readOnly} overwriteValue={overwriteValue} overwriteonChangeShade={overwriteonChangeShade} shadeShrink={shadeShrink} stepThickness={stepThickness} />
            {!withoutThinckness && <ConfigurationMenuThicknessSelector readOnly={readOnly || thicknessReadOnly} thicknessShrink={thicknessShrink} overwriteValue={overwriteValue} overwriteonChangeThickness={overwriteonChangeThickness} stepThickness={stepThickness} />}
            {!withoutNbPiece && <ConfiguratorMenuNumberPiece readOnly={readOnly} />}
        </>
    )
}

export default ConfiguratorMenu
