import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import React, { useContext, useCallback, useEffect } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import { MaterialsContext } from '../../../contexts/MaterialsContext';

function ConfigurationMenuThicknessSelector({ readOnly, thicknessShrink, overwriteValue = null, overwriteonChangeThickness = null, stepThickness = null }) {
    const [materials] = useContext(MaterialsContext);
    const configurator = useContext(ConfiguratorContext)[0];
    const dispachConfig = useContext(ConfiguratorContext)[1];

    const selectStyle = {
        height: overwriteValue ? '25px' : 'auto'
    }

    const currentDatas = overwriteValue || configurator

    const getOptions = useCallback(
        () => {
            return materials.find(material => material._id === currentDatas.materialId)
                ?.shades.find(shade => shade._id === currentDatas.shadeId)
                ?.thicknesses.map(thickness => <MenuItem key={thickness._id} value={thickness._id}>{thickness.value}</MenuItem>)
        },
        [currentDatas.materialId, currentDatas.shadeId, materials],
    )

    useEffect(() => {
        if (stepThickness && currentDatas.shadeId) {
            const autoThicknessId = materials.find(material => material._id === currentDatas.materialId)
                ?.shades.find(shade => shade._id === currentDatas.shadeId)
                ?.thicknesses.find(thickness => thickness.value === stepThickness)?._id;

            onchange({ target: { value: autoThicknessId } })
        }
    }, [currentDatas.materialId, currentDatas.shadeId, stepThickness])

    const onchange = (e) => {

        if (overwriteonChangeThickness) {
            overwriteonChangeThickness(e);
            return;
        }
        dispachConfig({
            type: 'SET_VALUE',
            payload: {
                key: 'thicknessId',
                data: e.target.value
            }
        })
    }

    return (
        <FormControl fullWidth >
            <InputLabel id="configurator-select-thickness-label" shrink={thicknessShrink}>Epaisseur (mm)</InputLabel>
            <Select
                labelId="configurator-select-thickness-label"
                id="configurator-thickness-material"
                value={currentDatas.thicknessId}
                style={selectStyle}
                onChange={onchange}
                fullWidth
                disabled={!currentDatas.shadeId || readOnly}
                inputProps={{ readOnly: readOnly || false }}
            >
                {getOptions()}
            </Select>
        </FormControl>
    )
}

export default ConfigurationMenuThicknessSelector
