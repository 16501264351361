import { Button, Grid, makeStyles, TextField } from '@material-ui/core'
import uniqid from 'uniqid'
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { getMText, parsedxf, dxftoPolylines } from '../../modules/dxf';
import { polylinesToModels } from '../../modules/makerjs';
import CatalogCustomzationLine from './CatalogCustomzationLine';
import AddIcon from '@material-ui/icons/Add';
import makerjs from 'makerjs'

const useStyles = makeStyles((theme) => ({

    svg: {
        display: 'flex',
        marginTop: theme.spacing(4),
        justifyContent: 'center',
        '& svg': {
            height: '400px',
            width: '400px'
        }
    },

}));

const entitydefaultName = {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'D',
    5: 'E',
    6: 'F',
    7: 'G',
    8: 'H',
    9: 'I',
    10: 'J',
    11: 'K',
    12: 'J',
    13: 'M',
}


function CatalogDxfProcessing({ files, currentFile, nbFiles, handelClickNextFile, entityInformations, setentityInformations }) {

    const [mTexts, setmTexts] = useState([]);
    const [svg, setsvg] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        (async () => {
            const dxfContent = await files[currentFile].text()
            if (dxfContent) {
                const parsedDxf = parsedxf(dxfContent);
                const entities = getMText(parsedDxf)
                const tab = []
                tab.push({ _id: uniqid(), name: 'L', min: 100, max: 1000, maxW: false, maxH: false })
                entities.forEach(entity => tab.push({ _id: uniqid(), name: entity.string, min: 100, max: 1000, maxW: false, maxH: false }))
                setentityInformations({
                    name: '',
                    entities: tab
                })
                setmTexts(entities)
            }
        })()


    }, [currentFile, files, setentityInformations]);

    const getInputLine = useCallback(
        () => {
            if (entityInformations.entities) {
                return entityInformations.entities.map(entity => <CatalogCustomzationLine key={entity.id} entity={{ string: entity.name, id: entity._id }} withControl={true} setentityInformations={setentityInformations} entityInformations={entityInformations} />)
            }
        },
        [entityInformations, setentityInformations],
    )

    const handelChangeName = useCallback(
        (e) => {
            setentityInformations({ ...entityInformations, name: e.target.value })
        },
        [entityInformations, setentityInformations],
    )

    const getSVGPreview = useCallback(
        () => {
            (async () => {
                const dxfContent = await files[currentFile].text()
                if (dxfContent) {
                    const polylines = dxftoPolylines(dxfContent)
                    const { modelstab: models } = polylinesToModels(polylines.polylines)
                    const svg = makerjs.exporter.toSVG(models)
                    setsvg(svg)
                }
            })()
        },
        [currentFile, files],
    )

    const getNextEntitydefaultName = useCallback(
        () => {
            let finded = false
            for (const entityName in entitydefaultName) {
                finded = entityInformations.entities.some(entity => entity.name === entitydefaultName[entityName])
                if (!finded) {
                    return entitydefaultName[entityName]
                }
            }

        }, [entityInformations.entities])


    const handelclickAddEnity = useCallback(
        () => {

            setentityInformations({ ...entityInformations, entities: [...entityInformations.entities, { _id: 'A' + uniqid(), name: getNextEntitydefaultName(), min: 100, max: 1000, maxH: false, maxW: false }] })
        },
        [entityInformations, getNextEntitydefaultName, setentityInformations],
    )
    return (
        <div>
            <Grid container>
                <Grid item>
                    {getSVGPreview()}
                </Grid>
            </Grid>
            <Grid container justify='center'>
                <Grid item>
                    <TextField onChange={handelChangeName} value={entityInformations.name} id="standard-basic" label="Nom de la pièce" />
                </Grid>
            </Grid>
            <div className={classes.svg} dangerouslySetInnerHTML={{ __html: svg }} />
            {getInputLine()}
            <Grid container justify='flex-end' spacing={2}>
                <Grid item>
                    <Button onClick={handelclickAddEnity} variant="contained" color="secondary" startIcon={<AddIcon />}>
                        Ajouter une ligne
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default CatalogDxfProcessing
