import { Button } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    buttonGreen: {
        backgroundColor: '#00ad0a',
        color: 'white'
    }
}));

function GreenButton({ children, onClick, disabled, startIcon, type }) {
    const classes = useStyles();
    return (
        <Button
            variant="contained"
            color="green"
            onClick={onClick}
            className={classes.buttonGreen}
            disabled={disabled || false}
            startIcon={startIcon || null}
            type={type || 'button'}
        >{children}</Button>
    )
}

export default GreenButton
