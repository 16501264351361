import { Button, Grid, TextField, Divider } from '@material-ui/core'
import React, { useCallback, useState, useContext, useEffect } from 'react'
import configRec from '../../../assets/svg/configRec.svg'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'
import { PropertiesContext } from '../../../contexts/PropertiesContext'
import ConfiguratorMainFormRecBorder from './ConfiguratorMainFormRecBorder'

function ConfiguratorMainFormRec({ displayAsColumn, isSubForm = false, closeDialog }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [properties] = useContext(PropertiesContext);
    const [errorA, seterrorA] = useState(false);
    const [errorAText, seterrorAText] = useState('');
    const [errorB, seterrorB] = useState(false);
    const [errorBText, seterrorBText] = useState('');
    const [height, setheight] = useState(configurator.mainForm.height || '');
    const [width, setwidth] = useState(configurator.mainForm.width || '');
    const [defaultValue, setdefaultValue] = useState(null);

    const [borderConfigurations, setborderConfigurations] = useState({
        tl: {
            isRadiusEnabled: false,
            isChanfreinEnabled: false,
            isInner: false,
            isOutside: false,
            radius: 0,
            error: false,
            errorText: ''
        },
        tr: {
            isRadiusEnabled: false,
            isChanfreinEnabled: false,
            isInner: false,
            isOutside: false,
            radius: 0,
            error: false,
            errorText: ''
        },
        bl: {
            isRadiusEnabled: false,
            isChanfreinEnabled: false,
            isInner: false,
            isOutside: false,
            radius: 0,
            error: false,
            errorText: ''
        },
        br: {
            isRadiusEnabled: false,
            isChanfreinEnabled: false,
            isInner: false,
            isOutside: false,
            radius: 0,
            error: false,
            errorText: ''
        }
    });

    useEffect(() => {
        if (configurator?.selectedForm) {
            const selectedForm = configurator.forms.find(form => configurator.selectedForm === form.id);
            if (selectedForm && selectedForm.borderConfigurations) {
                setborderConfigurations(selectedForm.borderConfigurations)
            }
        } else if (configurator.mainForm.borderConfigurations) {
            setborderConfigurations(configurator.mainForm.borderConfigurations)
        }

    }, [])

    const setborderConfiguration = useCallback((data, border) => {
        setborderConfigurations({ ...borderConfigurations, [border]: data })
    }, [borderConfigurations])

    const handelChangeWidth = useCallback(
        (e) => {
            if (e.target.value.match(/^[0-9]*$/)) {
                seterrorB(false)
                seterrorBText('')
                if (Number(e.target.value) < properties.machineMinWidth || Number(e.target.value) > properties.machineMaxWidth) {
                    seterrorBText(`valeur comprisent entre ${properties.machineMinWidth} et ${properties.machineMaxWidth}`)
                    seterrorB(true)
                }
                setwidth(Number(e.target.value))
            }
        },
        [properties.machineMaxWidth, properties.machineMinWidth],
    )

    const handelChangeHeight = useCallback(
        (e) => {
            if (e.target.value.match(/^[0-9]*$/)) {
                seterrorA(false)
                seterrorAText('')
                if (Number(e.target.value) < properties.machineMinLength || Number(e.target.value) > properties.machineMaxLength) {
                    seterrorAText(`valeur comprisent entre ${properties.machineMinLength} et ${properties.machineMaxLength}`)
                    seterrorA(true)
                }
                setheight(Number(e.target.value))
            }
        },
        [properties.machineMaxLength, properties.machineMinLength],
    )

    const handelClickvalidate =
        () => {
            if (!errorA && !errorB && width && height) {
                if (!isSubForm) {
                    dispatch({
                        type: 'SET_VALUE',
                        payload: {
                            key: 'mainForm',
                            data: {
                                ...configurator.mainForm,
                                configured: true,
                                height,
                                width,
                                borderConfigurations: {
                                    tl: {
                                        isRadiusEnabled: borderConfigurations.tl.isRadiusEnabled,
                                        isChanfreinEnabled: borderConfigurations.tl.isChanfreinEnabled,
                                        radius: borderConfigurations.tl.radius,
                                        isInner: borderConfigurations.tl.isInner,
                                        isOutside: borderConfigurations.tl.isOutside
                                    },
                                    tr: {
                                        isRadiusEnabled: borderConfigurations.tr.isRadiusEnabled,
                                        isChanfreinEnabled: borderConfigurations.tr.isChanfreinEnabled,
                                        radius: borderConfigurations.tr.radius,
                                        isInner: borderConfigurations.tr.isInner,
                                        isOutside: borderConfigurations.tr.isOutside
                                    },
                                    bl: {
                                        isRadiusEnabled: borderConfigurations.bl.isRadiusEnabled,
                                        isChanfreinEnabled: borderConfigurations.bl.isChanfreinEnabled,
                                        radius: borderConfigurations.bl.radius,
                                        isInner: borderConfigurations.bl.isInner,
                                        isOutside: borderConfigurations.bl.isOutside
                                    },
                                    br: {
                                        isRadiusEnabled: borderConfigurations.br.isRadiusEnabled,
                                        isChanfreinEnabled: borderConfigurations.br.isChanfreinEnabled,
                                        radius: borderConfigurations.br.radius,
                                        isInner: borderConfigurations.br.isInner,
                                        isOutside: borderConfigurations.br.isOutside
                                    }
                                }
                            }
                        }
                    })
                } else {
                    dispatch({
                        type: 'SET_FORM_REC_BORDER_CONFIGURATION',
                        payload: {
                            borderConfigurations:
                            {
                                tl: {
                                    isRadiusEnabled: borderConfigurations.tl.isRadiusEnabled,
                                    isChanfreinEnabled: borderConfigurations.tl.isChanfreinEnabled,
                                    radius: borderConfigurations.tl.radius,
                                    isInner: borderConfigurations.tl.isInner,
                                    isOutside: borderConfigurations.tl.isOutside
                                },
                                tr: {
                                    isRadiusEnabled: borderConfigurations.tr.isRadiusEnabled,
                                    isChanfreinEnabled: borderConfigurations.tr.isChanfreinEnabled,
                                    radius: borderConfigurations.tr.radius,
                                    isInner: borderConfigurations.tr.isInner,
                                    isOutside: borderConfigurations.tr.isOutside
                                },
                                bl: {
                                    isRadiusEnabled: borderConfigurations.bl.isRadiusEnabled,
                                    isChanfreinEnabled: borderConfigurations.bl.isChanfreinEnabled,
                                    radius: borderConfigurations.bl.radius,
                                    isInner: borderConfigurations.bl.isInner,
                                    isOutside: borderConfigurations.bl.isOutside
                                },
                                br: {
                                    isRadiusEnabled: borderConfigurations.br.isRadiusEnabled,
                                    isChanfreinEnabled: borderConfigurations.br.isChanfreinEnabled,
                                    radius: borderConfigurations.br.radius,
                                    isInner: borderConfigurations.br.isInner,
                                    isOutside: borderConfigurations.br.isOutside
                                }
                            }
                        }
                    })
                    if (closeDialog) {
                        closeDialog();
                    }
                }
            }
        }

    return (
        <>
            <Grid container justify='flex-end'>
                <Grid item>
                    {closeDialog && <Button variant="outlined" color={'primary'} style={{ marginRight: '1rem' }} onClick={() => closeDialog()}>Fermer</Button>}
                    <Button disabled={errorA || errorB || !width || !height} color={'primary'} variant="contained" onClick={handelClickvalidate} >Valider</Button>
                </Grid>
            </Grid>
            {!isSubForm && <Grid container justify='center'>
                <Grid item>
                    <TextField style={{ marginRight: '0.5rem' }} label='A' error={errorA} helperText={errorAText || 'Largeur du rectangle (mm)'} value={height} onChange={handelChangeHeight} />
                </Grid>
                <Grid item>
                    <TextField style={{ marginRight: '0.5rem' }} label='B' error={errorB} helperText={errorBText || 'Longueur du rectangle (mm)'} value={width} onChange={handelChangeWidth} />
                </Grid>
            </Grid>}
            {
                displayAsColumn ?
                    <>
                        <Grid container>
                            <img alt='configuration rectangle' style={{ display: 'block', margin: '2rem auto', height: '300px' }} src={configRec} />
                        </Grid>
                        {Number(height) > 0 && Number(width) > 0 && <>

                            <ConfiguratorMainFormRecBorder label='1' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.tl} setborderConfiguration={(data) => setborderConfiguration(data, 'tl')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                            <Divider />
                            <ConfiguratorMainFormRecBorder label='2' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.tr} setborderConfiguration={(data) => setborderConfiguration(data, 'tr')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                            <Divider />
                            <ConfiguratorMainFormRecBorder label='3' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.bl} setborderConfiguration={(data) => setborderConfiguration(data, 'bl')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                            <Divider />
                            <ConfiguratorMainFormRecBorder label='4' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.br} setborderConfiguration={(data) => setborderConfiguration(data, 'br')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                        </>}
                    </>
                    :
                    <Grid container alignItems='center'>
                        <Grid item xs={12} md={4} container direction='column' spacing={4}>
                            {Number(height) > 0 && Number(width) > 0 && <>
                                <Grid item>
                                    <ConfiguratorMainFormRecBorder label='1' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.tl} setborderConfiguration={(data) => setborderConfiguration(data, 'tl')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                                </Grid>
                                <Divider />
                                <Grid item>
                                    <ConfiguratorMainFormRecBorder label='3' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.bl} setborderConfiguration={(data) => setborderConfiguration(data, 'bl')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                                </Grid>
                            </>}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <img alt='configuration rectangle' style={{ display: 'block', margin: '2rem auto', height: '300px' }} src={configRec} />
                        </Grid>
                        <Grid item xs={12} md={4} container direction='column' spacing={4}>
                            {Number(height) > 0 && Number(width) > 0 && <>
                                <Grid item>
                                    <ConfiguratorMainFormRecBorder label='2' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.tr} setborderConfiguration={(data) => setborderConfiguration(data, 'tr')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                                </Grid>
                                <Divider />
                                <Grid item>

                                    <ConfiguratorMainFormRecBorder label='4' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.br} setborderConfiguration={(data) => setborderConfiguration(data, 'br')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                                </Grid>
                            </>}
                        </Grid>
                    </Grid>
            }
        </>
    )
}

export default ConfiguratorMainFormRec
