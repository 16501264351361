import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import React, { useContext, useCallback, useState, useMemo } from 'react'
import { ConfiguratorContext, useConfigurator } from '../../../contexts/ConfiguratorContext'
import { makeStyles } from '@material-ui/core/styles';
import makerjs from 'makerjs'
import { usePost } from '../../../modules/request';
import { useAddItemBasket, useEditBasketItem } from '../../../contexts/BasketContext';
import { grey } from '@material-ui/core/colors';
import { calculPriceWithVat, dataURLtoBlob, displayFormatedPrice } from '../../../modules/utils';
import { PropertiesContext } from '../../../contexts/PropertiesContext';
import PanoramaWideAngleIcon from '@material-ui/icons/PanoramaWideAngle';
import HeightIcon from '@material-ui/icons/Height';
import AddIcon from '@material-ui/icons/Add';
import { useMakeCalcule } from '../../../modules/calcule';
import { CatalogContext } from '../../../contexts/CatalogContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ConfiguratorMenuNumberPiece from './ConfiguratorMenuNumberPiece';
import { Alert, AlertTitle } from '@material-ui/lab';
import ModalShowValidationsError from '../../Modal/ModalShowValidationsError';
import { modelToSvg } from '../../../modules/makerjs';
import useValidationForm from '../../Hooks/useValidationForm';
import ConfirmationModalWrapper from '../../utils/ConfirmationModalWrapper';

const useStyles = makeStyles((theme) => ({
    priceContainer: {
        textAlign: 'center'
    },
    typoTotal: {
        fontWeight: 'bold'
    },
    buttonContainer: {
        marginTop: theme.spacing(2)
    },
    alertValidation: {
        width: '100%',
    },
    validationsFormButton: {
        display: 'block',
        marginLeft: 'auto',
    }
}))

function ConfiguratorDisplayPrice({ textModels, refStage }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [{ catalogs, catalogConfiguration, selectedCatalog }, dispatchCatalog] = useContext(CatalogContext);
    const [property] = useContext(PropertiesContext)

    const post = usePost()
    const { addItemBasket } = useAddItemBasket()
    const { editItemBasket } = useEditBasketItem()
    const { validationOnErrorText, hasOnlyMultipleChains } = useValidationForm()
    const { haveGravedForms } = useConfigurator();

    const [basketLoading, setbasketLoading] = useState(false);
    const [isModalValidationsErrorOpen, setIsModalValidationsErrorOpen] = useState(false);
    const [svg, setSvg] = useState('');

    const classes = useStyles()

    const makeCalcule = useMakeCalcule();

    const history = useHistory();


    const handelAddBasket = useCallback(
        () => {
            (async () => {
                setbasketLoading(true)

                try {
                    let ratio = (refStage.current.clientWidth * 0.80) / configurator.mainForm.width
                    dispatch({
                        type: 'SET_VALUE',
                        payload: {
                            key: 'ratio',
                            data: ratio
                        }
                    })
                    dispatch({
                        type: 'SET_VALUE',
                        payload: {
                            key: 'selectedForm',
                            data: null
                        }
                    })
                    await new Promise(res => setTimeout(() => res(), 500))
                    let catalogName = null;
                    if (configurator.isFromCatalog) {
                        catalogName = catalogs.find(catalog => catalog._id === selectedCatalog)?.name;
                    }
                    const uri = refStage.current.toDataURL();

                    const dt = new Date();
                    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
                    let fileName = null;
                    if (catalogName) {
                        fileName = `PP-${catalogName.replace(/ /g, "-")}-${padL(+dt.getHours())}${padL(dt.getMinutes())}${padL(dt.getSeconds())}-${dt.getFullYear()}${padL(dt.getMonth() + 1)}${padL(dt.getDate())}-${Math.floor(Math.random() * (1000 - 1)) + 1}`;
                    } else {
                        fileName = `P${padL(+dt.getHours())}${padL(dt.getMinutes())}${padL(dt.getSeconds())}-${dt.getFullYear()}${padL(dt.getMonth() + 1)}${padL(dt.getDate())}-${Math.floor(Math.random() * (1000 - 1)) + 1}`;
                    }


                    const imageBlob = dataURLtoBlob(uri);
                    const imagePart = [
                        imageBlob
                    ]
                    const image = new File(imagePart, fileName + '.dxf.png', {});

                    let formData = new FormData()

                    formData.append('file', image)
                    formData.append('fileName', fileName)
                    await post('/upload/image', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    })

                    let profilSize = null;
                    if (property.displayProfilDxf) {

                        if (configurator.profil) {

                            const captionLine = new makerjs.models.ConnectTheDots(`${50} ${20} ${50} ${20}`)
                            captionLine.layer = 'orange'
                            captionLine.caption = {
                                text: `VUE DEPLIEE`,
                                anchor: new makerjs.paths.Line([50, 20], [50, 20])
                            };
                            makerjs.model.addModel(configurator.model, captionLine);

                            configurator.model.models['profil'] = makerjs.importer.fromSVGPathData(configurator.profil);
                            profilSize = makerjs.measure.modelExtents(configurator.model.models['profil']);
                            configurator.model.models['profil'].origin = [-(profilSize.width + 100), 0];
                            configurator.model.models['profil'].caption = {
                                text: "VUE COTE (ext)",
                                anchor: new makerjs.paths.Line([-(profilSize.width + 50), 20], [-(profilSize.width + 50), 20])
                            };
                            configurator.model.models['profil'].layer = 'aqua';
                        }

                        if (configurator.profilH && profilSize) {
                            configurator.model.models['profilH'] = makerjs.model.mirror(makerjs.importer.fromSVGPathData(configurator.profilH), false, true);
                            const size = makerjs.measure.modelExtents(configurator.model.models['profilH']);
                            configurator.model.models['profilH'].origin = [-(size.width + 100), size.height + profilSize.height + 10];
                            configurator.model.models['profilH'].caption = {
                                text: "VUE BAS (ext)",
                                anchor: new makerjs.paths.Line([-(size.width + 50), size.height + profilSize.height + 10], [-(size.width + 50), size.height + profilSize.height + 10])
                            };
                            configurator.model.models['profilH'].layer = 'aqua';
                        }
                    }
                    const dxfWithOutFolding = makerjs.exporter.toDXF(configurator.model)


                    const parts = [
                        new Blob([dxfWithOutFolding], { type: 'application/dxf' }),
                    ];
                    const newFile = new File(parts, fileName + '.dxf', {});

                    formData = new FormData()
                    formData.append('file', newFile)
                    formData.append('fileName', fileName)
                    const uploadResponse = await post('/upload/dxf', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    })
                    const filenameDxf = uploadResponse.data.message

                    const basketObject = {
                        dxf: filenameDxf,
                        length: configurator.length,
                        nbChains: configurator.nbChains,
                        materialId: configurator.materialId,
                        shadeId: configurator.shadeId,
                        thicknessId: configurator.thicknessId,
                        maxWidth: configurator.realWidth,
                        maxHeight: configurator.realHeight,
                        quantity: configurator.nbPiece,
                        nbFolding: configurator.nbFolding,
                        weight: configurator.realWeight,
                        y3d: configurator.y3d,
                        x3d: configurator.x3d,
                        z3d: configurator.z3d,
                        multipleChains: configurator.validations?.multipleChains?.nbChains || 1
                    }

                    if (configurator.isFromCatalog) {
                        basketObject.isCatalogItem = true;
                        basketObject.catalogConfiguration = {
                            catalogId: selectedCatalog,
                            catalogEntitiesConfiguration: catalogConfiguration
                        }
                    }
                    const { undoStack, redoStack, editId, ...rest } = configurator;
                    basketObject.configurator = rest;


                    if (configurator.editId) {
                        basketObject._id = configurator.editId
                        await editItemBasket(basketObject)
                    } else {
                        await addItemBasket(basketObject)
                    }

                    window.parent.postMessage({ type: 'redirect' }, "*")
                    dispatch({
                        type: 'RESET'
                    });

                    setbasketLoading(false)
                    if (!process.env.REACT_APP_IFRAME_MODE) {
                        history.push('/cart')
                    }

                } catch (e) {

                }
            })()
        },
        [addItemBasket, editItemBasket, configurator.length, configurator.mainForm.width, configurator.materialId, configurator.model, configurator.nbChains, configurator.nbFolding, configurator.nbPiece, configurator.realHeight, configurator.realWeight, configurator.realWidth, configurator.shadeId, configurator.thicknessId, configurator.y3d, configurator.z3d, configurator.x3d, dispatch, post, refStage],
    )

    const handelManualCalulcule = async () => {
        await makeCalcule(textModels);
    }


    const isButtonDisabled = () => !!(configurator.priceLoading || !configurator.materialId || !configurator.shadeId || !configurator.thicknessId || !configurator.nbPiece || configurator.validations?.hasError)

    const handleShowDetails = () => {
        setIsModalValidationsErrorOpen(true);
        const svg = modelToSvg(configurator.model);
        setSvg(svg);
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container alignContent='center' style={{ height: '40px' }}>
                        <HeightIcon color='primary' />
                        <Typography style={{ fontWeight: 'bold', marginRight: '1rem', marginLeft: '0.5rem' }} variant="body2" gutterBottom display="inline" >Dimensions : </Typography>
                        <Typography variant="body2" display="block" gutterBottom>{configurator.realHeight && configurator.realWidth ? `${configurator.realHeight} x ${configurator.realWidth} mm` : ` -- x -- mm`}</Typography>

                    </Grid>
                    <Grid container alignContent='center' style={{ height: '40px' }}>
                        <PanoramaWideAngleIcon color='primary' />
                        <Typography style={{ fontWeight: 'bold', marginRight: '1rem', marginLeft: '0.5rem' }} variant="body2" gutterBottom display="inline" >Poids : </Typography>
                        <Typography variant="body2" display="block" gutterBottom>{`${configurator.realWeight || '--'} kg`}</Typography>

                    </Grid>
                    {
                        !process.env.REACT_APP_IFRAME_MODE &&
                        <>
                            <Grid container alignItems="center" alignContent='center' style={{ height: '40px' }}>
                                <Grid item>
                                    <AddIcon color='primary' />
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontWeight: 'bold', marginRight: '1rem', marginLeft: '0.5rem' }} variant="body2" gutterBottom display="inline" >Quantité : </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <ConfiguratorMenuNumberPiece />
                                </Grid>

                            </Grid>
                        </>
                    }
                    <Grid container alignItems='center' style={{ marginTop: '1rem' }}>
                        <Grid item xs={2} className={classes.priceContainer} >
                            <Typography style={{ fontWeight: 'bold' }} variant="button" display="inline" gutterBottom>{process.env.REACT_APP_IFRAME_MODE ? 'PRIX' : 'PU'}</Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.priceContainer} >
                            <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom display="inline" >{configurator.priceLoading ? <CircularProgress size={20} /> : configurator.unitPrice ? displayFormatedPrice(configurator.unitPrice) : ' - - '}</Typography>
                            <Typography variant="button" display="inline" gutterBottom> € HT</Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.priceContainer}>
                            <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom display="inline" >{configurator.priceLoading ? <CircularProgress size={20} /> : configurator.unitPrice ? displayFormatedPrice(calculPriceWithVat(configurator.unitPrice, property.vatRate)) : ' - - '}</Typography>
                            <Typography variant="button" display="inline" gutterBottom> € TTC</Typography>
                        </Grid>

                    </Grid>


                    {!process.env.REACT_APP_IFRAME_MODE && <Grid container alignItems='center' style={{ marginTop: '1rem' }}>
                        <Grid item xs={2} className={classes.priceContainer} >
                            <Typography style={{ fontWeight: 'bold' }} variant="button" display="inline" gutterBottom>TOTAL</Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.priceContainer} >
                            <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom display="inline" >{configurator.priceLoading ? <CircularProgress size={20} /> : configurator.price ? displayFormatedPrice(configurator.price) : ' - - '}</Typography>
                            <Typography variant="button" display="inline" gutterBottom> € HT</Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.priceContainer}>
                            <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom display="inline" >{configurator.priceLoading ? <CircularProgress size={20} /> : configurator.price ? displayFormatedPrice(calculPriceWithVat(configurator.price, property.vatRate)) : ' - - '}</Typography>
                            <Typography variant="button" display="inline" gutterBottom> € TTC</Typography>
                        </Grid>
                    </Grid>}


                </Grid>
                {
                    configurator.validations?.hasError &&
                    <Grid container style={{ marginTop: '1rem' }}>
                        <Alert className={classes.alertValidation} elevation={0} severity="error" >
                            <AlertTitle>Dessin non valide : </AlertTitle>
                            <Typography variant="body2">{validationOnErrorText}</Typography>
                            {!hasOnlyMultipleChains && <Button className={classes.validationsFormButton} onClick={handleShowDetails} size="small"><small>{`Voir détails`}</small></Button>}

                        </Alert>
                    </Grid>
                }
                <Grid container justify="flex-end" className={classes.buttonContainer}>
                    {!configurator.isManualCalcule ?
                        <Grid item>
                            {
                                haveGravedForms() ?
                                    <ConfirmationModalWrapper disabled={isButtonDisabled()} onConfirm={handelAddBasket} confirmationTexte={['Vous avez ajouté de(s) contour(s) en gravure (countours vert).', 'Souhaitez vous continuer ?']}>
                                        <Button
                                            id="edit-product"
                                            disabled={isButtonDisabled()}

                                            variant="contained"
                                            color='secondary'
                                        >
                                            {configurator.editId ? 'Mettre à jour' : 'Ajouter au panier'}
                                            {basketLoading ? <CircularProgress color={grey[50]} size={20} /> : null}
                                        </Button>
                                    </ConfirmationModalWrapper>
                                    :
                                    <Button
                                        id="edit-product"
                                        disabled={isButtonDisabled()}
                                        onClick={handelAddBasket}
                                        variant="contained"
                                        color='secondary'
                                    >
                                        {configurator.editId ? 'Mettre à jour' : 'Ajouter au panier'}
                                        {basketLoading ? <CircularProgress color={grey[50]} size={20} /> : null}
                                    </Button>
                            }
                        </Grid>
                        :
                        <Grid item>
                            <Button id="calcule-manual" disabled={isButtonDisabled()} onClick={handelManualCalulcule} variant="contained" color='secondary' >Calculer le prix {basketLoading ? <CircularProgress color={grey[50]} size={20} /> : null} </Button>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {isModalValidationsErrorOpen && <ModalShowValidationsError open={isModalValidationsErrorOpen} setOpen={() => setIsModalValidationsErrorOpen(false)} svg={svg} />}
        </>
    )
}

export default ConfiguratorDisplayPrice
