import { Grid, Typography } from '@material-ui/core'
import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { UserLastNameSchemas, UserFirstNameSchemas, UserEmailSchemas, UserPasswordSchemas, UserZipCodeSchemas, UserPhoneSchemas, UserHouseNumberSchemas, UserStreetSchemas, UserCitySchemas } from '../../modules/validation'
import TextFieldValidation from '../TextFieldValidation'
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3)
    },
    section: {
        marginTop: theme.spacing(2),

    },
    typo: {
        marginBottom: '2rem'
    }

}))

function UserFrom({ userInformations, setuserInformations }) {
    const classes = useStyles();
    const passwordRules = <ul>
        <li>8 à 15 caractères</li>
        <li>au moins une lettre minuscule</li>
        <li>au moins une lettre majuscule</li>
        <li>au moins un chiffre</li>
        <li>au moins un de ces caractères spéciaux: $ @ % * + - _ !</li>
    </ul>

    const handleChange = useCallback(
        (e, key) => {
            setuserInformations({ ...userInformations, [key]: e.target.value })
        },
        [setuserInformations, userInformations],
    )

    return (
        <>
            <div className={classes.section}>
                <Typography className={classes.typo} variant="overline" align="center" display="block" gutterBottom>
                    Informations personnelles
                </Typography>

                <Grid className={classes.root} container justify='center' spacing={6}>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Nom *" yupValidation={UserLastNameSchemas} onChange={(e) => handleChange(e, 'lastName')} type="text" value={userInformations.lastName} />
                    </Grid>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Prénom *" yupValidation={UserFirstNameSchemas} onChange={(e) => handleChange(e, 'firstName')} type="text" value={userInformations.firstName} />
                    </Grid>
                </Grid>
                <Grid className={classes.root} container justify='center' spacing={6}>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Adresse e-mail *" yupValidation={UserEmailSchemas} onChange={(e) => handleChange(e, 'email')} type="text" value={userInformations.email} />
                    </Grid>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth autoComplete={false} type='password' label={<div style={{ display: 'flex', alignItems: 'center' }}>Mot de passe * <Tooltip placement="top" title={passwordRules}><LiveHelpIcon style={{ marginLeft: '5px', fontSize: 12 }} /></Tooltip></div>} yupValidation={UserPasswordSchemas} onChange={(e) => handleChange(e, 'password')} value={userInformations.password} />
                    </Grid>
                </Grid>
                <Grid className={classes.root} container justify='center' spacing={6}>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Numero de voie *" yupValidation={UserHouseNumberSchemas} onChange={(e) => handleChange(e, 'houseNumber')} type="text" value={userInformations.houseNumber} />
                    </Grid>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Rue *" yupValidation={UserStreetSchemas} onChange={(e) => handleChange(e, 'street')} type="text" value={userInformations.street} />
                    </Grid>

                </Grid>
                <Grid className={classes.root} container justify='center' spacing={6}>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Ville *" yupValidation={UserCitySchemas} onChange={(e) => handleChange(e, 'city')} type="text" value={userInformations.city} />
                    </Grid>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Code postal *" yupValidation={UserZipCodeSchemas} onChange={(e) => handleChange(e, 'zipCode')} type="text" value={userInformations.zipCode} />
                    </Grid>
                </Grid>
                <Grid className={classes.root} container justify='center' spacing={6}>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Téléphone *" yupValidation={UserPhoneSchemas} onChange={(e) => handleChange(e, 'phone')} type="text" value={userInformations.phone} />
                    </Grid>
                    <Grid item xs={10} md={4} container>
                    </Grid>
                </Grid>
            </div>

        </>
    )
}

export default UserFrom
