import React, { useCallback, useContext, useState, useEffect } from 'react'
import { AppBar, Box, Button, Drawer, Grid, IconButton, List, ListItem, ListItemText, makeStyles, Menu, MenuItem, Toolbar, useMediaQuery, useTheme } from '@material-ui/core'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import { useHistory } from 'react-router-dom';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import logo from '../assets/images/logo.png'
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import { BasketContext } from '../contexts/BasketContext';
import { useGetBasket } from '../contexts/BasketContext';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DashboardIcon from '@material-ui/icons/Dashboard';

const useStyles = makeStyles((theme) => ({

  bar: {
    background: 'white',
    //boxShadow: "rgb(17 17 26 / 10%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 8px 24px, rgb(17 17 26 / 10%) 0px 16px 48px",
    //borderBottom: `5px solid ${theme.palette.primary.main}`
  },
  logo: {

    [theme.breakpoints.down("sm")]: {
      height: '20px',
      cursor: 'pointer'
    },
    [theme.breakpoints.up("md")]: {
      height: '65px',
      cursor: 'pointer'
    },
    [theme.breakpoints.up("lg")]: {
      height: '65px',
      cursor: 'pointer'
    },
  }

}))

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

function NavBar() {

  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated()
  const auth = useAuthUser()
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [basket, dispatch] = useContext(BasketContext)


  const { getBasket } = useGetBasket()

  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const isMenuOpen = Boolean(anchorEl);

  const handelClickLoginLogout = useCallback(
    () => {
      if (isAuthenticated()) {
        history.push('/logout')
      } else {
        history.push('/login')
      }
    },
    [history, isAuthenticated],
  )

  const handelClickCart = useCallback(
    () => {
      history.push('/cart')
    },
    [history],
  )

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getBasket()

  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handelClickMenuItem = useCallback(
    (target) => {
      setOpen(false)
      history.push('/control/' + target)
    },
    [history],
  )

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => history.push('/profil')}><AccountCircle color="secondary" />Mon compte</MenuItem>
      {auth()?.role === 0 && <MenuItem onClick={() => history.push('/control')}><DashboardIcon color="secondary" /> Panneau de controle</MenuItem>}
      <MenuItem onClick={handelClickLoginLogout}><ExitToAppIcon color="secondary" /> Déconnexion</MenuItem>
    </Menu>
  );

  return (
    <AppBar position="relative" className={classes.bar} >
      <Toolbar >
        {
          isAuthenticated() && auth()?.role === 0
            ?
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
            >
              <MenuIcon color="primary" />
            </IconButton>
            : null
        }

        {!isDownSm && <img onClick={() => history.push('/')} style={{ height: '65px', cursor: 'pointer' }} src={logo} alt="logidriel" />}
        <Grid container spacing={1} alignItems="center" justify="flex-end">
          {
            !isAuthenticated() && <Grid item>
              <Button onClick={handelClickLoginLogout} size="small" color='primary' variant="outlined">Se connecter</Button>
            </Grid>
          }


          <Grid item>
            <IconButton style={{ marginLeft: 'auto', height: '35px' }} aria-label="cart" onClick={handelClickCart} >
              <StyledBadge badgeContent={basket.length} color="primary">
                <ShoppingBasketIcon />
              </StyledBadge>
            </IconButton>
          </Grid>
          {
            isAuthenticated() ?
              <>
                <Grid item>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="primary"
                  >
                    <AccountCircle />
                  </IconButton>
                  {renderMenu}
                </Grid>

              </>
              : null

          }
        </Grid>
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
        >
          <div>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <List>
            <ListItem button>
              <ListItemText primary="Gestion Commandes" onClick={() => handelClickMenuItem('orders')} />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Personnalisation" onClick={() => handelClickMenuItem('param')} />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Clients" onClick={() => handelClickMenuItem('users')} />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Catalogue de formes" onClick={() => handelClickMenuItem('catalog')} />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Catalogue SVG" onClick={() => handelClickMenuItem('svg')} />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Contact Client" onClick={() => handelClickMenuItem('clientContact')} />
            </ListItem>
          </List>
        </Drawer>
      </Toolbar>

    </AppBar>
  )
}

export default NavBar