import { Button, Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core'
import React from 'react'


const useStyles = makeStyles((theme) => ({
    root: {
        height: '90vh', // Changé de 80vh à 90vh
        '& .MuiDialog-container': {
            height: '100vh', // Vous pouvez ajuster cela aussi si nécessaire
        },
        '& .MuiPaper-root': {
            height: '100%', // Assurez-vous que cela prend toute la hauteur de la modal
            maxHeight: '100%', // Assurez-vous que cela prend toute la hauteur de la modal
        }
    },
    svgContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            width: '100%',
            height: '100%',
        }
    }
}));


function ModalShowValidationsError({ open, setOpen, svg = '' }) {

    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
            maxWidth="lg"
            fullWidth

        >
            <DialogContent>


                <div className={classes.svgContainer} dangerouslySetInnerHTML={{ __html: svg }} ></div>



            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalShowValidationsError