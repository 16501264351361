import { TableContainer, TableHead } from '@material-ui/core';
import { Grid, makeStyles, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import React, { useCallback, useContext, useMemo } from 'react'
import { BasketContext } from '../contexts/BasketContext';
import CartLine from './CartLine';
import DisplayCgv from './common/DisplayCgv';

const useStyle = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),

    },
    cardContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    priceContainer: {
        marginBottom: theme.spacing(4)
    },
    pricePaper: {
        padding: theme.spacing(2)
    },
    totalCell: {
        fontWeight: 'bold',
        borderBottom: 'none'
    },
    noBorderBottom: {
        borderBottom: 'none'

    }
}))

function OrderData({ data, me, getTotalPrice, vatRate }) {

    const [baskets] = useContext(BasketContext);
    const classes = useStyle()
    const getItems = useCallback(
        () => {

            return baskets?.map(item => <CartLine control={false} key={item._id} item={item} />)
        },
        [baskets],
    )

    const calculateVAT = (price, rate) => {
        return (price / 100) * rate;
    };

    const formatPrice = (price) => {
        return Number(price).toFixed(2).replace('.', ',');
    };

    const totalHT = useMemo(() => getTotalPrice() + data.deliveryPrice, [getTotalPrice, data.deliveryPrice]);
    const tax = useMemo(() => calculateVAT(totalHT, vatRate), [totalHT, vatRate]);
    const totalTTC = useMemo(() => totalHT * (1 + (vatRate / 100)), [totalHT, vatRate]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>

                    <Grid container spacing={2} justify='center' className={classes.cardContainer}>
                        <TableContainer style={{ maxWidth: '100%' }}>
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left"><strong>Aperçu</strong></TableCell>
                                        <TableCell align="left"><strong>Informations</strong></TableCell>
                                        <TableCell align="left"><strong>Mesures</strong></TableCell>
                                        <TableCell align="left"><strong>Quantité</strong></TableCell>
                                        <TableCell align="left"><strong>Prix</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getItems()}
                                    <TableRow>
                                        <TableCell className={classes.noBorderBottom} colSpan={4} />
                                        <TableCell className={classes.totalCell}>Sous Total HT</TableCell>
                                        <TableCell className={classes.noBorderBottom}>{formatPrice(getTotalPrice())} €</TableCell>
                                    </TableRow>
                                    {data.deliveryMethode === 'delivery' && (
                                        <TableRow>
                                            <TableCell className={classes.noBorderBottom} colSpan={4} />
                                            <TableCell className={classes.totalCell}>Livraison par transporteur</TableCell>
                                            <TableCell className={classes.noBorderBottom}>{formatPrice(data.deliveryPrice)} €</TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell className={classes.noBorderBottom} colSpan={4} />
                                        <TableCell className={classes.totalCell}>Total HT</TableCell>
                                        <TableCell className={classes.noBorderBottom}>{formatPrice(totalHT)} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.noBorderBottom} colSpan={4} />
                                        <TableCell className={classes.totalCell}>TVA {formatPrice(vatRate)}%</TableCell>
                                        <TableCell className={classes.noBorderBottom}>{formatPrice(tax)} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.noBorderBottom} colSpan={4} />
                                        <TableCell className={classes.totalCell}>Total TTC</TableCell>
                                        <TableCell className={classes.totalCell}>{formatPrice(totalTTC)} €</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item xs={8}>
                            <DisplayCgv />
                        </Grid>
                    </Grid>

                    {/* {
                        data.deliveryMethode === 'delivery' ?
                            <Grid container justify="center">
                                <Grid item xs={12} sm={4}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Nom</TableCell>
                                                <TableCell align="right">{me.lastName}</TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Prénom</TableCell>
                                                <TableCell align="right">{me.firstName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Numero de voie</TableCell>
                                                <TableCell align="right">{me.pro?.deliveryHouseNumber || me.houseNumber}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">rue</TableCell>
                                                <TableCell align="right">{me.pro?.deliveryStreet || me.street}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Code postal</TableCell>
                                                <TableCell align="right">{me.pro?.deliveryZipCode || me.zipCode}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Ville</TableCell>
                                                <TableCell align="right">{me.pro?.deliveryCity || me.city}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Téléphone</TableCell>
                                                <TableCell align="right">{me.phone}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            :
                            <Grid container justify='center'>
                                <Grid item>
                                    <Typography align="center" variant="button" display="block" gutterBottom color="primary">
                                        adresse du retrait
                                    </Typography>
                                    <Typography align="center" variant="body1" gutterBottom>
                                        {`${process.env.REACT_APP_PICK_UP_HOUSE_NUMBER} ${process.env.REACT_APP_PICK_UP_STREET}`}
                                    </Typography>
                                    <Typography align="center" variant="body1" gutterBottom>
                                        {`${process.env.REACT_APP_PICK_UP_ZIP_CODE} ${process.env.REACT_APP_PICK_UP_CITY}`}
                                    </Typography>

                                </Grid>

                            </Grid>
                    } */}



                </Paper>
            </Grid>
        </Grid>
    )
}

export default OrderData
