import { Divider, Fab, Grid, makeStyles, Paper, SvgIcon, Typography } from '@material-ui/core'
import React, { useCallback, useContext, useState } from 'react'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DropImg from '../../DropImg';
import ModalProcessImageVecto from '../../Dialog/ModalProcessImageVecto';
import ProcessUploadedSvg from '../../ProcessUploadedSvg'
import ModalProcessUploadedDxf from '../../Dialog/ModalProcessUploadedDxf';
import DialogSelectCatalogSvg from '../../Dialog/DialogSelectCatalogSvg';


const useStyles = makeStyles((theme) => ({
    drawingWindo: {
        [theme.breakpoints.down("xl")]: {
            padding: "1rem 0.5rem",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "1rem 2rem",
        }
    },
    drawerConfig: {
        paddingLeft: '6px',
        backgroundImage: "radial-gradient( circle 343px at 46.3% 47.5%, rgba(242,242,242,1) 0%, rgba(241,241,241,1) 72.9% )"
    }
}))

function ConfiguratorDrawingPannelSideBar({ paperRef, setisAccordionExpended }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [startImg, setstartImg] = useState(false);
    const [startSvg, setstartSvg] = useState(false);
    const [startDxf, setstartDxf] = useState(false);
    const [startCatalogSvg, setstartCatalogSvg] = useState(false);
    const classes = useStyles();

    const handleVecto = useCallback(({ lines, targetHeight, targetWidth, originalHeight, originalWidth, ratio, model }) => {
        dispatch({
            type: 'SET_VALUES',
            payload: {
                data: {
                    formToAdd: 'CUSTOM',
                    formToAddDataTemp: {
                        paths: lines,
                        originalHeight: originalHeight,
                        originalWidth: originalWidth,
                        height: targetHeight,
                        width: targetWidth,
                        ratio,
                        model
                    }
                }
            }
        })
        setstartImg(false)
        paperRef.current.style.cursor = 'crosshair'
    }, [dispatch, paperRef])

    const handelClickAddForm = useCallback(
        (e, form) => {
            e.stopPropagation()
            if (form === 'IMG') {
                setstartImg(true);
            }
            if (form === 'SVG') {
                setstartSvg(true);
            }
            if (form === 'DXF') {
                setstartDxf(true);
            }
            if (form === 'CATALOG_SVG') {
                setstartCatalogSvg(true);
            }
            else {

                dispatch({
                    type: 'SET_VALUE',
                    payload: {
                        key: 'formToAdd',
                        data: form
                    }
                })
                paperRef.current.style.cursor = 'crosshair'
            }
            if (setisAccordionExpended) {
                setisAccordionExpended(false)
            }
        },
        [dispatch, paperRef, setisAccordionExpended],
    )

    const handleClickChangeUnion = useCallback((type) => {
        const selectedForm = configurator.forms.find(form => form.id === configurator.selectedForm);

        dispatch({
            type: 'SET_VALUE_FORM',
            payload: {
                form: configurator.selectedForm,
                data: {
                    unionType: type,
                    graved: type === 'add' ? false : selectedForm.graved,
                    comment: type === 'del' ? false : selectedForm.comment
                }
            }

        })
    }, [configurator.forms, configurator.selectedForm, dispatch])

    const getUnionMenu = useCallback(() => {

        const selectedForm = configurator.forms.find(form => form.id === configurator.selectedForm);

        return selectedForm && <Paper elevation={0} className={classes.drawingWindo}><Typography variant="button" style={{ fontWeight: 'bold', lineHeight: '15px' }} display="block" align='center' gutterBottom>
            Opération boolean
        </Typography><Grid container alignItems='center' justify='space-between'>

                <Grid item>
                    <Fab variant='extended' onClick={() => handleClickChangeUnion('add')} color={selectedForm.unionType === 'add' ? 'secondary' : 'primary'} >
                        +
                    </Fab>
                </Grid>
                <Grid item>
                    <Fab variant='extended' onClick={() => handleClickChangeUnion('del')} color={selectedForm.unionType === 'add' ? 'primary' : 'secondary'}>
                        -
                    </Fab>
                </Grid>
            </Grid></Paper>
    }, [classes.drawingWindo, configurator.forms, configurator.selectedForm, handleClickChangeUnion])

    return (
        <>
            <Grid className={classes.drawerConfig} onClick={(e) => e.stopPropagation()} container direction='column' justify='space-between' alignItems='center'>
                <Grid item style={{ width: '100%', height: '75%' }}>
                    <Paper elevation={0} className={classes.drawingWindo}>
                        <Grid container justify='center' style={{ flexDirection: 'column', alignItems: 'center' }} >
                            <Typography variant="button" display="block" align='center' style={{ fontWeight: 'bold', lineHeight: '15px' }} gutterBottom>
                                Ajouter une découpe
                            </Typography>
                            <Fab
                                onClick={(e) => handelClickAddForm(e, 'CIRCLE')}
                                variant="extended"
                                color={configurator.formToAdd === 'CIRCLE' ? 'secondary' : 'primary'}
                                style={{ width: '100%', marginTop: '0.5rem' }}>
                                <RadioButtonUncheckedIcon style={{ position: 'absolute', left: '10px' }} color="white" />
                                <span>Cercle</span>
                            </Fab>
                            <Fab
                                onClick={(e) => handelClickAddForm(e, 'RECTANGLE')}
                                variant="extended"
                                color={configurator.formToAdd === 'RECTANGLE' ? 'secondary' : 'primary'}
                                style={{ width: '100%', marginTop: '0.5rem' }}>
                                <CheckBoxOutlineBlankIcon color="white" style={{ position: 'absolute', left: '10px' }} />
                                Rectangle
                            </Fab>
                            <Fab onClick={(e) => handelClickAddForm(e, 'TRIANGLE')} variant="extended" color={configurator.formToAdd === 'TRIANGLE' ? 'secondary' : 'primary'} style={{ width: '100%', marginTop: '0.5rem' }}>
                                <ChangeHistoryIcon color="white" style={{ position: 'absolute', left: '10px' }} />
                                Triangle
                            </Fab>
                            <Fab onClick={(e) => handelClickAddForm(e, 'OVAL')} variant="extended" color={configurator.formToAdd === 'OVAL' ? 'secondary' : 'primary'} style={{ width: '100%', marginTop: '0.5rem' }}>
                                <SvgIcon className={classes.extendedIcon} style={{ top: 'calc(50% - 5px)', position: 'absolute', left: '10px' }} >
                                    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 1H15C17.2091 1 19 2.79086 19 5C19 7.20914 17.2091 9 15 9H5C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1Z" stroke="white" stroke-width="2" />
                                    </svg>
                                </SvgIcon>
                                Oblong
                            </Fab>
                            <Fab onClick={(e) => handelClickAddForm(e, 'POLYGONE')} variant="extended" color={configurator.formToAdd === 'POLYGONE' ? 'secondary' : 'primary'} style={{ width: '100%', marginTop: '0.5rem' }}>
                                <SvgIcon className={classes.extendedIcon} style={{ position: 'absolute', left: '10px' }} >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.6943 3.6943L10 1.08239L16.3057 3.6943L18.9176 10L16.3057 16.3057L10 18.9176L3.6943 16.3057L1.08239 10L3.6943 3.6943Z" stroke="white" stroke-width="2" />
                                    </svg>
                                </SvgIcon>
                                Polygone
                            </Fab>
                            <Fab onClick={(e) => handelClickAddForm(e, 'TEXT')} variant="extended" color={configurator.formToAdd === 'TEXT' ? 'secondary' : 'primary'} style={{ width: '100%', marginTop: '0.5rem' }}>
                                <TextFieldsIcon style={{ position: 'absolute', left: '10px' }} />
                                Texte
                            </Fab>
                            {(process.env.REACT_APP_IFRAME_MODE || !process.env.REACT_APP_NO_SVG || !process.env.REACT_APP_NO_DXF || !process.env.REACT_APP_NO_MOTIF) && <Typography variant="button" display="block" align='center' style={{ marginTop: '0.5rem', fontWeight: 'bold', lineHeight: '15px' }} gutterBottom>
                                Formes personnalisées
                            </Typography>}
                            {process.env.REACT_APP_IFRAME_MODE && <Fab onClick={(e) => handelClickAddForm(e, 'IMG')} variant="extended" color={'primary'} style={{ width: '100%', marginTop: '0.5rem' }}>
                                Image
                            </Fab>}
                            {!process.env.REACT_APP_NO_SVG && <Fab onClick={(e) => handelClickAddForm(e, 'SVG')} variant="extended" color={'primary'} style={{ width: '100%', marginTop: '0.5rem' }}>
                                SVG
                            </Fab>}
                            {!process.env.REACT_APP_NO_DXF && <Fab onClick={(e) => handelClickAddForm(e, 'DXF')} variant="extended" color={'primary'} style={{ width: '100%', marginTop: '0.5rem' }}>
                                DXF
                            </Fab>}
                            {!process.env.REACT_APP_NO_MOTIF && <Fab onClick={(e) => handelClickAddForm(e, 'CATALOG_SVG')} variant="extended" color={'primary'} style={{ width: '100%', marginTop: '0.5rem' }}>
                                MOTIFS
                            </Fab>}
                            {
                                /* property.foldingEnabled ?
                                    <>
                                        <Fab onClick={(e) => handelClickAddFoldingLine(e, 'r')} variant="extended" color={configurator.formToAdd === 'FOLDINGLINE' ? 'secondary' : 'primary'} style={{ marginTop: '1rem', transform: configurator.formToAdd === 'FOLDINGLINE' ? 'translateX(-20px)' : 'translateX(0px)' }}>
                                            <RemoveIcon color="white" />
                                            Pli montant
                                        </Fab>
                                        <Fab onClick={(e) => handelClickAddFoldingLine(e, 'v')} variant="extended" color={configurator.formToAdd === 'FOLDINGLINE' ? 'secondary' : 'primary'} style={{ marginTop: '1rem', transform: configurator.formToAdd === 'FOLDINGLINE' ? 'translateX(-20px)' : 'translateX(0px)' }}>
                                            <RemoveIcon color="white" />
                                            Pli descendant
                                        </Fab>
                                    </>
 
                                    : null */
                            }



                        </Grid>
                    </Paper>
                </Grid>
                <Divider />
                <Grid item style={{ width: '100%' }}>

                    {getUnionMenu()}

                </Grid>
            </Grid>

            <DropImg startIMG={startImg} paperRef={paperRef} setstartIMG={setstartImg} onValidate={handleVecto} modal={<ModalProcessImageVecto />} authorizedTypes={['jpg', 'jpeg', 'png']} />
            <DropImg startIMG={startSvg} paperRef={paperRef} setstartIMG={setstartSvg} onValidate={handleVecto} modal={<ProcessUploadedSvg />} authorizedTypes={['svg']} />
            <DropImg startIMG={startDxf} paperRef={paperRef} setstartIMG={setstartDxf} onValidate={handleVecto} modal={<ModalProcessUploadedDxf />} authorizedTypes={['dxf']} />
            <DialogSelectCatalogSvg open={startCatalogSvg} setOpen={setstartCatalogSvg} handleCancel={() => setstartCatalogSvg(false)} paperRef={paperRef} />

        </>
    )
}

export default ConfiguratorDrawingPannelSideBar