import React, { useCallback, useContext, useRef, useState, useEffect } from 'react'
import { ConfiguratorContext } from '../contexts/ConfiguratorContext'
import { useIsAuthenticated } from 'react-auth-kit';
import { useHistory } from 'react-router-dom';
import { getFileExtension } from '../modules/utils';


function DropImg({ startIMG, setstartIMG, onValidate, modal, authorizedTypes, paperRef }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const hiddenInputRef = useRef(null)


    const [files, setfiles] = useState([]);
    const [currentFile, setcurrentFile] = useState(0);
    const [openModal, setopenModal] = useState(false);

    const isAuth = useIsAuthenticated()
    const history = useHistory()


    const handleClose = useCallback(
        () => {
            setcurrentFile(currentFile + 1)
            setopenModal(false)
            dispatch({
                type: 'SET_VALUES',
                payload: {
                    data: {
                        formToAdd: null,
                    }
                }
            })
            if (paperRef?.current?.style?.cursor) paperRef.current.style.cursor = 'auto';
        },
        [currentFile, dispatch, paperRef],
    )

    useEffect(() => {
        if (startIMG) {

            hiddenInputRef.current.click()
            setstartIMG(false)

        }
    }, [history, isAuth, setstartIMG, startIMG])

    useEffect(() => {
        (async () => {
            if (currentFile >= files.length && files.length !== 0) {

                setfiles([]);
                setcurrentFile(0);

            } else {
                try {
                    const cfile = getcurrentFile();
                    if (cfile) {
                        if (authorizedTypes && authorizedTypes.length > 0) {
                            if (authorizedTypes.includes(getFileExtension(cfile.name).toLowerCase())) {
                                setopenModal(true);
                            } else {
                                setcurrentFile(currentFile + 1)
                            }
                        }
                    }
                } catch (e) {
                    console.log('error image', e.message)
                }
            }

        })()

    }, [files, currentFile]);

    const getcurrentFile = useCallback(() => {
        return files[currentFile];
    }, [currentFile, files])

    const handelSelectFile = (e) => {
        setfiles(e.target.files)
    }

    const handleOnValidate = useCallback(({ lines, targetHeight, targetWidth, originalHeight, originalWidth, ratio, model, svg, file }) => {
        setopenModal(false)
        onValidate({ lines, targetHeight, targetWidth, originalHeight, originalWidth, ratio, model, svg, file })
    }, [onValidate])


    return (
        <>
            {!openModal && <input ref={hiddenInputRef} onChange={handelSelectFile} id='imageUpload' type='file' hidden accept={`.${authorizedTypes.join(', .')}`} />}
            {openModal && React.cloneElement(modal, { onValidate: handleOnValidate, file: getcurrentFile(), open: openModal, handleClose: handleClose })}
        </>
    )
}

export default DropImg
