import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckOutForm from './CheckOutForm';
import Alert from '@material-ui/lab/Alert';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY_STRIPE);



const useStyle = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),

    }
}))

function OrderCheckOut({ setpaymentResult, setActiveStep, paymentResult, me, data }) {
    const classes = useStyle()
    return (
        <Paper className={classes.paper} elevation={0}>
            {
                !me.idExtern ?
                    <>
                        <Grid container justify='center' >
                            <Grid item xs={12} sm={5}>
                                <Elements stripe={stripePromise}>
                                    <CheckOutForm setpaymentResult={setpaymentResult} setActiveStep={setActiveStep} me={me} datas={data} />
                                </Elements>
                            </Grid>
                        </Grid>
                        {
                            paymentResult.success === false ?
                                <Grid container justify='center'>
                                    <Grid item xs={12} sm={5}>
                                        <Alert severity="error">{paymentResult.message}</Alert>
                                    </Grid>
                                </Grid>
                                : null
                        }
                    </>
                    :
                    <Grid container justify='center' >
                        <Grid item xs={12} sm={5}>
                            <Typography variant="button" align="center" color="primary" display="block" gutterBottom>
                                paiement par facturation
                            </Typography>
                            <Typography variant="body2" align="center" gutterBottom>
                                Vous êtes client en compte chez nous. Vous recevrez une facture dès validation technique de votre commande.
                            </Typography>
                        </Grid>
                    </Grid>
            }
        </Paper>
    )
}

export default OrderCheckOut
