import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core'
import React, { useCallback } from 'react'

function ConfiguratorMainFormRecBorder({ borderConfiguration, setborderConfiguration, label, defaultValue, setdefaultValue, labelChanfreins = [] }) {


    const setConfiguration = useCallback((config) => {
        setborderConfiguration({ ...borderConfiguration, ...config })
    }, [borderConfiguration, setborderConfiguration])

    const handleClickBorderInner = useCallback((e) => {
        const checked = e.target.checked;
        if (checked && borderConfiguration.isOutside) {
            setConfiguration({ 'isInner': checked, isOutside: false })
        } else {
            setConfiguration({ 'isInner': checked })
        }
    }, [borderConfiguration.isOutside, setConfiguration])

    const handleClickBorderOutside = useCallback((e) => {
        const checked = e.target.checked;
        if (checked && borderConfiguration.isInner) {
            setConfiguration({ 'isOutside': checked, isInner: false })
        } else {

            setConfiguration({ 'isOutside': checked })
        }
    }, [borderConfiguration.isInner, setConfiguration])

    const handleClickRadiusEnable = useCallback((e) => {
        const checked = e.target.checked;
        if (checked && borderConfiguration.isChanfreinEnabled) {
            setConfiguration({ 'isRadiusEnabled': checked, isChanfreinEnabled: false, radius: defaultValue || 0 })
        } else {

            setConfiguration({ 'isRadiusEnabled': checked, radius: defaultValue || 0 })
        }
    }, [borderConfiguration.isChanfreinEnabled, defaultValue, setConfiguration])

    const handleClickChanfreinEnable = useCallback((e) => {
        const checked = e.target.checked;
        if (checked && borderConfiguration.isRadiusEnabled) {
            setConfiguration({ 'isChanfreinEnabled': checked, isRadiusEnabled: false, radius: { a: defaultValue || 0, b: defaultValue || 0 } })
        } else {

            setConfiguration({ 'isChanfreinEnabled': checked, radius: { a: defaultValue || 0, b: defaultValue || 0 } })
        }
    }, [borderConfiguration.isRadiusEnabled, defaultValue, setConfiguration])

    const handelChangeRadius = (e) => {
        const value = e.target.value;
        if (!isNaN(+value)) {
            setConfiguration({ 'radius': +value })
        }
    }

    const handelChangeChanfrein = (value, chanfrein) => {
        if (!isNaN(+value)) {
            setConfiguration({ 'radius': { ...borderConfiguration.radius, [chanfrein]: +value } })
        }
    }

    const handleBlur = (value) => {
        if (!isNaN(+value) && !defaultValue) {

            setdefaultValue(+value)

        }
    }

    return (
        <Grid container alignItems='center'>

            <Grid item xs={8} md={6}>
                <Grid container direction='column'>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={borderConfiguration.isRadiusEnabled}
                                    onChange={handleClickRadiusEnable}
                                    name="checkedIsRadiusEnabled"
                                    color="primary"
                                />
                            }
                            labelPlacement="end"
                            label={"Arrondi angle " + label}
                        />

                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={borderConfiguration.isChanfreinEnabled}
                                    onChange={handleClickChanfreinEnable}
                                    name="checkedIsChanfreinEnabled"
                                    color="primary"
                                />
                            }
                            labelPlacement="end"
                            label={"Chanfrein angle " + label}
                        />

                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={4} ms={6} style={{ textAlign: 'center', opacity: borderConfiguration.isRadiusEnabled ? 1 : 0 }}>
                <Grid container direction='column'>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={borderConfiguration.isOutside}
                                    onChange={handleClickBorderOutside}
                                    name="checkedBorderOutside"
                                    color="primary"
                                />
                            }
                            label="Extérieure"

                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={borderConfiguration.isInner}
                                    onChange={handleClickBorderInner}
                                    name="checkedBorderInner"
                                    color="primary"
                                />
                            }
                            label="Intérieure"
                        />
                    </Grid>
                </Grid>
            </Grid >
            {borderConfiguration.isRadiusEnabled && (borderConfiguration.isOutside || borderConfiguration.isInner) && <Grid item xs={12} >
                <TextField label='valeur' style={{ marginLeft: 'auto' }} onBlur={(e) => handleBlur(e.target.value)} error={borderConfiguration.error} helperText={borderConfiguration.errorText || 'Rayon de l\'arondi (mm)'} value={borderConfiguration.radius} onChange={handelChangeRadius} />
            </Grid>}
            {
                borderConfiguration.isChanfreinEnabled &&
                <>
                    <Grid item xs={6} >
                        <TextField label={labelChanfreins[0] || 'chanfrein 1'} style={{ marginLeft: 'auto' }} onBlur={(e) => handleBlur(e.target.value)} error={borderConfiguration.error} helperText={borderConfiguration.errorText || 'Valeur (mm)'} value={borderConfiguration.radius['a']} onChange={(e) => handelChangeChanfrein(e.target.value, 'a')} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label={labelChanfreins[1] || 'chanfrein 2'} style={{ marginLeft: 'auto' }} onBlur={(e) => handleBlur(e.target.value)} error={borderConfiguration.error} helperText={borderConfiguration.errorText || 'Valeur (mm)'} value={borderConfiguration.radius['b']} onChange={(e) => handelChangeChanfrein(e.target.value, 'b')} />

                    </Grid>
                </>
            }



        </Grid>
    )
}

export default ConfiguratorMainFormRecBorder