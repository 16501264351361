import axios from 'axios'
import { useAuthHeader } from 'react-auth-kit'

function useGet() {
    const token = useAuthHeader()()
    return function (url, options) {
        let config = null
        if (token) {
            config = {
                ...options,
                withCredentials: true,
                headers: {
                    authorization: token
                },
            }
        }
        return axios.get(process.env.REACT_APP_BASE_API + url, config)
    }

}

function usePost(data) {
    const token = useAuthHeader()()
    return function (url, data, options) {

        let config = null
        if (token) {
            config = {
                ...options,
                withCredentials: true,
                headers: {
                    authorization: token
                }
            }
        }
        return axios.post(process.env.REACT_APP_BASE_API + url, data, config)
    }

}


function usePut(data) {
    const token = useAuthHeader()()
    return function (url, data, options) {

        let config = null
        if (token) {
            config = {
                ...options,
                withCredentials: true,
                headers: {
                    authorization: token
                }
            }
        }
        return axios.put(process.env.REACT_APP_BASE_API + url, data, config)
    }

}


function useDelete(data) {
    const token = useAuthHeader()()
    return function (url, options) {

        let config = null
        if (token) {
            config = {
                ...options,
                withCredentials: true,
                headers: {
                    authorization: token
                }
            }
        }
        return axios.delete(process.env.REACT_APP_BASE_API + url, config)
    }

}

const defaultValideImageTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg'
]

function valideFileType(file, type = 'image', valideTypes = []) {
    if (Array.isArray(valideTypes) && valideTypes.length > 0) {
        return valideTypes.includes(file.type)
    } else {
        if (type === 'image') {
            return defaultValideImageTypes.includes(file.type)
        }
    }
    return false
}

function valideFileSize(file, maxSize = 2) {
    const octet = 1048576;
    return file.size <= (maxSize * octet)
}

function useUploadImage() {
    return async function (file, maxSize = 2) {

        if (file) {
            if (valideFileType(file, 'image')) {
                if (valideFileSize(file, maxSize)) {
                    let formData = new FormData()
                    formData.append('file', file)
                    try {
                        const res = await axios.post(process.env.REACT_APP_BASE_API + '/upload/image', formData, {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        })
                        return res.data
                    } catch (e) {
                        return { success: false, message: 'fail on upload' }
                    }
                } else {
                    return { success: false, message: `fichier trop gros : max ${maxSize} Mo` }
                }
            }
            else {
                return { success: false, message: 'Mauvais type de fichier' }
            }
        } else {
            return { success: false, message: 'Aucun fichier trouver' }
        }
    }
}
export { useGet, usePost, usePut, useDelete, useUploadImage }

