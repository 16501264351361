import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ConfiguratorMainForm from '../EndUser/Configurator/ConfiguratorMainForm';
import ConfiguratorMainFormRec from '../EndUser/Configurator/ConfiguratorMainFormRec';

export default function DialogConfigMainForm({ open, setOpen, fontsList }) {

  const dialogContentStyle = {
    height: '90vh'
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      onClose={() => setOpen(false)}
      style={{ height: '80vh' }}

    >
      <DialogContent style={dialogContentStyle}>
        <ConfiguratorMainForm
          fontsList={fontsList}
          closeDialog={() => setOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
}