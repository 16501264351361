import React, { useEffect, useContext, useCallback, useState, useRef } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ConfiguratorDrawingPannel from './ConfiguratorDrawingPannel';
import ConfiguratorControlPannel from './ConfiguratorControlPannel';
import DialogConfigurationMenu from '../../Dialog/DialogConfigurationMenu';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0

    },
    mainGrid: {
        height: '100%',
        maxHeight: `calc(100vh${process.env.REACT_APP_IFRAME_MODE ? '' : ' - 69px'})`,
    },

    controlPannelContainer: {

        [theme.breakpoints.down('lg')]: {
            height: 'auto',
        },

        height: `calc(100vh${process.env.REACT_APP_IFRAME_MODE ? '' : ' - 69px'})`,
        overflow: 'auto',
        position: 'relative',
        backgroundImage: "radial-gradient( circle 343px at 46.3% 47.5%, rgba(242,242,242,1) 0%, rgba(241,241,241,1) 72.9% )",
        padding: '0 6px',
        boxSizing: 'border-box'
    }
}))

// eslint-disable-next-line react-hooks/exhaustive-deps
const fontsList = [
    {
        key: "BlackOpsOne",
        name: "Black Ops One (Pochoir)"
    },
    {
        key: "EmblemaOne-Regular",
        name: "EmblemaOne Regular (Pochoir)"
    },
    {
        key: "old_school_united_stencil",
        name: "Old school united stencil (Pochoir)"
    },
    {
        key: "StardosStencil-Bold",
        name: "StardosStencil Bold (Pochoir)"
    },
    {
        key: "Behind-the-Painter",
        name: "Behind the Painter (Attachée)"
    },
    {
        key: "Cathrina-Belisha",
        name: "Cathrina Belisha (Attachée)"
    },
    {
        key: "MontserratSubrayada-Bold",
        name: "MontserratSubrayada Bold (Attachée)"
    },
    {
        key: "arial-black",
        name: "Arial Black (Découpée)"
    },
    {
        key: "Forte",
        name: "Forte (Découpée)"
    },

    {
        key: "NewRocker-Regular",
        name: "NewRocker Regular (Découpée)"
    },
    {
        key: "arial",
        name: "Arial (Gravée)"
    },
    {
        key: "comic",
        name: "Comic (Gravée)"
    },
    {
        key: "times",
        name: "Times (Gravée)"
    }
];


function Configurator() {

    const [configurator] = useContext(ConfiguratorContext);
    const [openDialog, setopenDialog] = useState(true);
    const [textModels, settextModels] = useState([]);
    const refStage = useRef(null);
    const classes = useStyles();


    const haveCompletedConfiguration = useCallback(
        () => {
            return configurator.materialId && configurator.shadeId && configurator.thicknessId && configurator.nbPiece
        },
        [configurator.materialId, configurator.nbPiece, configurator.shadeId, configurator.thicknessId],
    )

    useEffect(() => {
        if (haveCompletedConfiguration()) {
            setopenDialog(false)
        }
    }, [configurator.materialId, configurator.shadeId, configurator.thicknessId, haveCompletedConfiguration])

    return (
        <main className={classes.root}>
            <Grid container className={classes.mainGrid}>
                <Grid xs={12} lg={3} className={classes.controlPannelContainer}>
                    <ConfiguratorControlPannel textModels={textModels} settextModels={settextModels} fontsList={fontsList} refStage={refStage} />
                </Grid>
                <Grid xs={12} lg={9} style={{ display: 'flex' }} className={classes.drawer}>
                    <ConfiguratorDrawingPannel textModels={textModels} settextModels={settextModels} fontsList={fontsList} refStage={refStage} />
                </Grid>
            </Grid>
            <DialogConfigurationMenu open={openDialog} setopenDialog={setopenDialog} />
        </main>
    )
}

export default Configurator
