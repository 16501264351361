import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";


//const STLLoader = TreeSTLLoader(THREE);

const loader = new STLLoader();

function StlViewer({ pathToStl, color }) {
    const mountRef = useRef(null);
    const meshRef = useRef(null);
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0,
        depth: 0
    });
    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(
            750,
            window.innerWidth / window.innerHeight,
            10,
            100000
        );

        loader.load(pathToStl, (geometry) => {
            const material = new THREE.MeshPhongMaterial({
                color: color || 0x5555555
            });
            const mesh = new THREE.Mesh(geometry, material);

            meshRef.current = mesh;

            mesh.geometry.computeVertexNormals(true);
            mesh.geometry.center();

            scene.add(mesh);
            mesh.rotation.x = -1.2;

            // 1. Calculez la taille de la pièce
            const boundingBox = new THREE.Box3().setFromObject(mesh);
            const size = boundingBox.getSize(new THREE.Vector3());

            setDimensions({
                width: Math.ceil(size.x),
                height: Math.ceil(size.y),
                depth: Math.ceil(size.z)
            });

            // 2. Ajustez la position de la caméra en fonction de la plus grande dimension de la pièce.
            const maxSize = Math.max(size.x, size.y, size.z);
            const cameraDistance = maxSize * 2; // vous pouvez ajuster ce multiplicateur selon vos besoins
            camera.position.z = cameraDistance;

            // 3. Ajustez les distances de zoom pour le contrôle d'orbite.
            controls.minDistance = maxSize * 0.5; // zoom rapproché au plus près à 50% de la taille de la pièce
            controls.maxDistance = maxSize * 4;   // zoom éloigné au plus loin à 400% de la taille de la pièce

            camera.lookAt(scene.position);  // Assurez-vous que la caméra regarde le centre de la scène
        });

        const renderer = new THREE.WebGLRenderer({ antialias: true });

        const controls = new OrbitControls(camera, renderer.domElement);

        controls.maxDistance = 700;
        controls.minDistance = 100;

        /**
         * Light setup
         */

        const dirLight = new THREE.DirectionalLight(0xffffff, 1);
        dirLight.position.set(1, 0, 0);
        scene.add(dirLight);

        const dirLight2 = new THREE.DirectionalLight(0xffffff, 1); // Intensité moindre pour un éclairage secondaire
        dirLight2.position.set(-1, 0, 0);
        scene.add(dirLight2);

        const dirLightTop = new THREE.DirectionalLight(0xffffff, 1);
        dirLightTop.position.set(0, 5, 0); // Depuis le dessus
        scene.add(dirLightTop);

        const dirLightBottom = new THREE.DirectionalLight(0xffffff, 1);
        dirLightBottom.position.set(0, -5, 0); // Depuis le dessous
        scene.add(dirLightBottom);

        scene.background = new THREE.Color(0xffffff);
        mountRef.current.appendChild(renderer.domElement);

        const width = mountRef.current.clientWidth;
        const height = mountRef.current.clientHeight;

        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        renderer.setSize(width, height);

        function onWindowResize() {
            const width = mountRef.current.clientWidth;
            const height = mountRef.current.clientHeight;

            camera.aspect = width / height;
            camera.updateProjectionMatrix();

            renderer.setSize(width, height);
        }

        function animate() {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        }

        window.addEventListener("resize", onWindowResize, false);

        camera.position.z = 500;

        animate();

        // Cleanup on component unmount
        return () => {
            window.removeEventListener("resize", onWindowResize, false);
        };
    }, [pathToStl]);

    useEffect(() => {
        if (meshRef.current) {
            meshRef.current.material.color.set(color || 0x5555555);
        }
    }, [color]);

    return <div ref={mountRef} className='stl-container' />;
}

export default StlViewer;
