import { Fab, Grid, makeStyles, SvgIcon, Typography } from '@material-ui/core'
import React, { useContext, useCallback, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DropImg from '../../DropImg';
import ModalProcessImageVecto from '../../Dialog/ModalProcessImageVecto';
import ProcessUploadedSvg from '../../ProcessUploadedSvg';
import ModalProcessUploadedDxf from '../../Dialog/ModalProcessUploadedDxf';
import DialogSelectCatalogSvg from '../../Dialog/DialogSelectCatalogSvg';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    title: {
        fontWeight: 'bold'
    },
    container: {
        marginBottom: theme.spacing(2)
    }
}));

function ConfiguratorMenuForm() {
    const classes = useStyles();
    const [configurator, dispatch] = useContext(ConfiguratorContext);
    const [startImg, setstartImg] = useState(false);
    const [startSvg, setstartSvg] = useState(false);
    const [startDxf, setstartDxf] = useState(false);
    const [startCatalogSvg, setstartCatalogSvg] = useState(false);

    const handleVecto = useCallback(({ lines, targetHeight, targetWidth, originalHeight, originalWidth, ratio, model }) => {

        dispatch({
            type: 'SET_VALUES',
            payload: {
                data: {
                    form: 4,
                    mainForm: {
                        paths: lines,
                        configured: true,
                        originalHeight: originalHeight,
                        originalWidth: originalWidth,
                        height: targetHeight,
                        width: targetWidth,
                        ratio,
                        model
                    }
                }
            }
        })
        setstartImg(false)
    }, [dispatch])


    const handelChange = useCallback(
        (e, value) => {
            if (value === 'IMG') {
                setstartImg(true);
            } else if (value === 'SVG') {
                setstartSvg(true);
            } else if (value === 'DXF') {
                setstartDxf(true);
            } else if (value === 'CATALOG_SVG') {
                setstartCatalogSvg(true);
            } else {
                dispatch({
                    type: 'SET_VALUE',
                    payload: {
                        key: 'form',
                        data: value
                    }
                })
                dispatch({
                    type: 'RESET_SOFT',
                    payload: {}
                })
            }
        },
        [dispatch],
    )

    return (
        <>
            <Typography className={classes.title} variant="button" align='center' display='block' gutterBottom>
                Formes Génériques
            </Typography>
            <Grid container spacing={3} >

                <Grid item xs={12} md={6}>
                    <Fab style={{ width: '100%' }} onClick={(e) => handelChange(e, 0)} color={configurator.form === 0 ? "secondary" : "primary"} variant="extended">
                        <CheckBoxOutlineBlankIcon className={classes.extendedIcon} />
                        Rectangle
                    </Fab>

                </Grid>
                <Grid item xs={12} md={6}>
                    <Fab style={{ width: '100%' }} onClick={(e) => handelChange(e, 1)} color={configurator.form === 1 ? "secondary" : "primary"} variant="extended">
                        <PanoramaFishEyeIcon className={classes.extendedIcon} />
                        Cercle
                    </Fab>
                </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.container}>
                <Grid item xs={12} md={6}>
                    <Fab style={{ width: '100%' }} onClick={(e) => handelChange(e, 2)} color={configurator.form === 2 ? "secondary" : "primary"} variant="extended">
                        <ChangeHistoryIcon className={classes.extendedIcon} />
                        Triangle
                    </Fab>

                </Grid>
                <Grid item xs={12} md={6}>
                    <Fab style={{ width: '100%' }} onClick={(e) => handelChange(e, 3)} color={configurator.form === 3 ? "secondary" : "primary"} variant="extended">
                        <SvgIcon className={classes.extendedIcon} >
                            <svg width="16" height="18" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="1" x2="1" y1="2" y2="20" stroke="white" stroke-width="2" />
                                <line x1="1" y1="19" x2="16" y2="19" stroke="white" stroke-width="2" />
                            </svg>
                        </SvgIcon>
                        <span style={{ whiteSpace: 'nowrap' }}>Forme en L</span>
                    </Fab>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Fab style={{ width: '100%' }} onClick={(e) => handelChange(e, 5)} color={configurator.form === 5 ? "secondary" : "primary"} variant="extended">
                        <TextFieldsIcon className={classes.extendedIcon} />
                        Texte
                    </Fab>

                </Grid>
            </Grid>
            {(process.env.REACT_APP_IFRAME_MODE || !process.env.REACT_APP_NO_SVG || !process.env.REACT_APP_MAIN_FORM_NO_DXF || !process.env.REACT_APP_NO_MOTIF) &&
                <Typography className={classes.title} variant="button" align='center' display='block' gutterBottom>
                    Importer une forme depuis
                </Typography>
            }

            <Grid container spacing={3}>
                {process.env.REACT_APP_IFRAME_MODE && <Grid item xs={12} md={6}>
                    <Fab style={{ width: '100%' }} onClick={(e) => handelChange(e, 'IMG')} color={configurator.form === 4 ? "secondary" : "primary"} variant="extended">
                        <span style={{ whiteSpace: 'nowrap' }}>Image</span>
                    </Fab>
                </Grid>}
                {!process.env.REACT_APP_NO_SVG && <Grid item xs={12} md={6}>
                    <Fab style={{ width: '100%' }} onClick={(e) => handelChange(e, 'SVG')} color={configurator.form === 4 ? "secondary" : "primary"} variant="extended">
                        <span style={{ whiteSpace: 'nowrap' }}>SVG</span>
                    </Fab>
                </Grid>}
                {!process.env.REACT_APP_MAIN_FORM_NO_DXF && <Grid item xs={12} md={6}>
                    <Fab style={{ width: '100%' }} onClick={(e) => handelChange(e, 'DXF')} color={configurator.form === 4 ? "secondary" : "primary"} variant="extended">
                        <span style={{ whiteSpace: 'nowrap' }}>DXF</span>
                    </Fab>
                </Grid>}
                {!process.env.REACT_APP_NO_MOTIF && <Grid item xs={12} md={6}>
                    <Fab style={{ width: '100%' }} onClick={(e) => handelChange(e, 'CATALOG_SVG')} variant="extended" color={configurator.form === 4 ? "secondary" : "primary"} >
                        MOTIFS
                    </Fab>
                </Grid>}
            </Grid>
            <DropImg startIMG={startImg} setstartIMG={setstartImg} onValidate={handleVecto} modal={<ModalProcessImageVecto />} authorizedTypes={['jpg', 'jpeg', 'png']} />
            <DropImg startIMG={startSvg} setstartIMG={setstartSvg} onValidate={handleVecto} modal={<ProcessUploadedSvg />} authorizedTypes={['svg']} />
            <DropImg startIMG={startDxf} setstartIMG={setstartDxf} onValidate={handleVecto} modal={<ModalProcessUploadedDxf />} authorizedTypes={['dxf']} />
            <DialogSelectCatalogSvg open={startCatalogSvg} setOpen={setstartCatalogSvg} handleCancel={() => setstartCatalogSvg(false)} isMain />
        </>
    )
}

export default ConfiguratorMenuForm
