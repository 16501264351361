import { Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useCallback } from 'react'

function CatalogdrawingPannelEntitiesListValue({ data, setData, entity }) {

    const handelChange = useCallback(
        (e) => {
            const value = e.target.value
            let error = ''
            if (e.target.value > 0) {

                if (entity.asMax && value > entity.max) error = `Doit être inférrieure à ${entity.max}`
                if (entity.asMin && value < entity.min) error = `Doit être suppérieure à ${entity.min}`


                if (entity.asMin && entity.asMax && value < entity.min && value > entity.max) {
                    error = `Doit être compris entre ${entity.min} et ${entity.max}`
                }

                setData({ ...data, value: Number(e.target.value), error })
            }
            else if (e.target.value === '') {
                error = ` `
                setData({ ...data, value: '', error })
            }
        },
        [data, entity.asMax, entity.asMin, entity.max, entity.min, setData],
    )

    const getSentence = useCallback(
        () => {
            let str = ''
            if (entity.asMin) str += "min " + entity.min
            if (str) str += ' - '
            if (entity.asMax) str += "max " + entity.max
            return str
        },
        [entity.asMax, entity.asMin, entity.max],
    )

    return (
        <div>
            {data
                ?
                <div>
                    <Grid container alignItems='flex-end' wrap='nowrap'>
                        <Grid item >
                            <TextField error={data.error} helperText={data.error} value={data.value} onChange={handelChange} id="outlined-basic" />
                        </Grid>
                        <Grid item >
                            <Typography variant="caption" display="block" gutterBottom>
                                {entity.type !== 'angle' ? 'mm' : 'degré'}
                            </Typography>
                        </Grid>
                    </Grid>


                    {!data.error ? <Typography variant="caption" display="block" gutterBottom>{getSentence()}</Typography> : null}
                </div>
                :
                null}
        </div>
    )
}

export default CatalogdrawingPannelEntitiesListValue
