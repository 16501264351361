import React, { useContext, useEffect, useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/styles';
import StlViewer from '../Viewer/stl/StlViewer';
import { Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import axios from 'axios';
import { ConfiguratorContext } from '../../contexts/ConfiguratorContext';
import { useGet, usePost } from '../../modules/request';
import { dxftoSVG } from '../../modules/dxf';
import HeightIcon from '@material-ui/icons/Height';
import AddIcon from '@material-ui/icons/Add';
import PanoramaWideAngleIcon from '@material-ui/icons/PanoramaWideAngle';
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./../../../src/modules/worker";
import { calculPriceWithVat, displayFormatedPrice } from '../../modules/utils';
import { PropertiesContext } from '../../contexts/PropertiesContext';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { useAddItemBasket } from '../../contexts/BasketContext';
import ConfiguratorMenu from '../EndUser/Configurator/ConfiguratorMenu';
import ConfiguratorMenuNumberPiece from '../EndUser/Configurator/ConfiguratorMenuNumberPiece';
import { MaterialsContext } from '../../contexts/MaterialsContext';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '80vh',
        backgroundImage: `radial-gradient( circle 343px at 46.3% 47.5%, rgba(242,242,242,1) 0%, rgba(241,241,241,1) 72.9% )`
    },
    container: {
        height: '100%'
    },
    pricePaper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    viewerPaper: {
        padding: theme.spacing(2),
        height: '100%',
        position: 'relative'
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        gap: theme.spacing(1)
    },
    loader: {
        position: 'absolute',
        top: '50%',
        right: '50%',
    },
    thicknessAlert: {
        marginTop: theme.spacing(2)
    }
}))

export default function DialogStlViewer({ open, handleClose, stepFile, next, curentFileIndex, stepFiles }) {

    const classes = useStyles()

    const [stepLoading, setStepLoading] = useState(false)
    const [loading, setloading] = useState(false);
    const [basketLoading, setBasketLoading] = useState(false)
    const [price, setprice] = useState(null);
    const [unitPrice, setUnitPrice] = useState(null);
    const [svg, setsvg] = useState(null);
    const [length, setlength] = useState(0);
    const [nbChains, setnbChains] = useState(0);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [depth, setDepth] = useState(0);
    const [maxWidth, setMaxWidth] = useState(0)
    const [maxHeight, setMaxHeight] = useState(0)
    const [weight, setweight] = useState(0);
    const [dxf, setdxf] = useState('');
    const [file, setfile] = useState(null);
    const [fileSvg, setfileSvg] = useState(null);
    const [stlFile, setStlFile] = useState(null);
    const [nbFolding, setNbFolding] = useState(0);
    const [realSurface, setRealSurface] = useState(0);
    const [exactThicknessFound, setExactThicknessFound] = useState(null)
    const [detectedThickness, setDetectedThickness] = useState(0)
    const [thicknessValue, setThicknessValue] = useState(0)
    const [stepErrorIsAssemblage, setStepErrorIsAssemblage] = useState(false);

    const [configurator, dispatchConfigurator] = useContext(ConfiguratorContext)
    const [materials] = useContext(MaterialsContext)
    const [property] = useContext(PropertiesContext)
    const get = useGet();
    const post = usePost()

    const { addItemBasket } = useAddItemBasket()

    useEffect(() => {
        (async () => {
            if (stepFile) {
                setStepErrorIsAssemblage(false);
                try {
                    setStepLoading(true)
                    const formData = new FormData();
                    formData.append('stepFile', stepFile);
                    const response = await axios.post(`${process.env.REACT_APP_BASE_API}/calculate/tostl`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob'  // Important for receiving files
                    });

                    const blob = new Blob([response.data], { type: 'model/stl' });
                    const blobURL = window.URL.createObjectURL(blob);
                    setStlFile(blobURL);

                    try {
                        const formData = new FormData();
                        formData.append('stepFile', stepFile);
                        const response = await axios.post(`${process.env.REACT_APP_BASE_API}/calculate/step`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        })
                        const dxf = response.data.message.dxf;
                        const thicknessValue = response.data.message.thickness;
                        setDetectedThickness(thicknessValue)
                        const thickness = getThicknessId(thicknessValue)
                        if (thickness) {
                            if (thickness.exact) {
                                setExactThicknessFound(true)
                            } else {
                                setExactThicknessFound(false)
                            }
                            setThicknessValue(thickness.value)
                            dispatchConfigurator({
                                type: 'SET_VALUE',
                                payload: {
                                    key: 'thicknessId',
                                    data: thickness.id
                                }
                            })
                        } else {

                        }
                        setWidth(response.data.message.x)
                        setHeight(response.data.message.y)
                        setDepth(response.data.message.z)
                        const nbFolding = response.data.message.number_of_bends;
                        setNbFolding(nbFolding)
                        const worker = new Worker()
                        worker.postMessage({ type: 'DXF_CHECK', dxf })
                        worker.onmessage = async (event) => {
                            const { length, nbChains, maxWidth, maxHeight, realSurface } = event.data;

                            setlength(length)
                            setnbChains(nbChains)
                            setRealSurface(realSurface)
                            let url = `/calculate?length=${length}&materialId=${configurator.materialId}&shadeId=${configurator.shadeId}&thicknessId=${thickness.id}&nbChains=${nbChains}&nbFolding=${nbFolding}&maxWidth=${maxWidth}&maxHeight=${maxHeight}&quantity=${configurator.nbPiece}&realSurface=${realSurface}`;
                            if (window.sessionId) {
                                url += '&sessionId=' + window.sessionId;
                            }
                            const result = await get(url);
                            setMaxWidth(maxWidth)
                            setMaxHeight(maxHeight);
                            setprice(result.data.message.price)
                            setUnitPrice(result.data.message.unitPrice)
                            setweight(result.data.message.realWeight || result.data.message.weight)

                            dispatchConfigurator({
                                type: 'SET_VALUES',
                                payload: {
                                    data: { realSurface }
                                }
                            })

                            const svg = dxftoSVG(dxf);
                            setsvg(svg);
                            worker.postMessage({ type: 'DXF_IMPORT', dxfStr: dxf })
                            worker.onmessage = async (event) => {
                                let { svg } = event.data;
                                svg = svg.replace("fill:none", `fill:${currentShade?.color}`);
                                svg = svg.replace("stroke-width:0.25mm", "stroke-width:0mm");
                                svg = svg.replace("nonzero", "evenodd");
                                setsvg(svg);
                                setdxf(dxf)
                                const dt = new Date();
                                const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
                                const fileName = `PP${padL(+dt.getHours())}${padL(dt.getMinutes())}${padL(dt.getSeconds())}-${dt.getFullYear()}${padL(dt.getMonth() + 1)}${padL(dt.getDate())}-${Math.floor(Math.random() * (1000 - 1)) + 1}`;

                                const parts = [
                                    new Blob([dxf], { type: 'application/dxf' }),
                                ];
                                const newFile = new File(parts, fileName + '.dxf', {});


                                setfileSvg(svg);

                                setfile(newFile)
                            }

                        }
                    } catch (error) {
                        console.error("Error calculate STEP", error);
                    } finally {
                        setloading(false);
                    }
                } catch (error) {
                    if (error.response && error.response.data) {
                        // La réponse est déjà un blob, donc nous pouvons la lire directement
                        const reader = new FileReader();
                        reader.onload = function (event) {
                            try {
                                // Tente de parser le contenu comme JSON
                                const errorObj = JSON.parse(event.target.result);
                                console.log("Error message:", errorObj.code);
                                if (errorObj?.code === 99) {
                                    setStepErrorIsAssemblage(true);
                                }
                            } catch (e) {
                                console.error("Erreur lors de la lecture du message d'erreur", e);
                            }
                        };
                        reader.onerror = function (event) {
                            console.error("Erreur lors de la lecture du Blob", reader.error);
                        };
                        reader.readAsText(error.response.data);
                    } else {
                        console.error("Error converting STEP to STL", error);
                    }
                } finally {
                    setStepLoading(false);
                }


            }
        })()
    }, [stepFile])

    const resetValues = () => {
        setprice(null);
        setUnitPrice(null);
        setweight(null);
    }

    useEffect(() => {
        (async () => {

            if (!configurator.nbPiece || !configurator.thicknessId) {
                resetValues();
                return;
            }

            let url = `/calculate?length=${length}&materialId=${configurator.materialId}&shadeId=${configurator.shadeId}&thicknessId=${configurator.thicknessId}&nbChains=${nbChains}&nbFolding=${nbFolding}&maxWidth=${maxWidth}&maxHeight=${maxHeight}&quantity=${configurator.nbPiece}&realSurface=${realSurface}`;
            if (window.sessionId) {
                url += '&sessionId=' + window.sessionId;
            }
            const result = await get(url);
            setprice(result.data.message.price)
            setUnitPrice(result.data.message.unitPrice)
            setweight(result.data.message.realWeight || result.data.message.weight)
        })()
    }, [configurator.nbPiece, configurator.thicknessId])

    const isButtonDisabled = () => basketLoading || configurator.nbPiece <= 0 || !configurator.materialId || !configurator.shadeId || !configurator.thicknessId;

    const handelAddBasket = async () => {

        async function convertSvgToPngAndPrepareFormData(svgString, fileName) {
            // Créer un élément "dummy" dans le DOM pour dessiner le SVG
            const svgContainer = document.createElement("div");
            svgContainer.innerHTML = svgString;
            const svgElement = svgContainer.querySelector("svg");

            // Créer un canevas HTML5 pour dessiner l'image
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = svgElement.width.baseVal.value;
            canvas.height = svgElement.height.baseVal.value;

            // Dessiner le SVG sur le canevas
            const svgUrl = `data:image/svg+xml;base64,${btoa(svgString)}`;
            const svgImage = new Image();
            svgImage.src = svgUrl;

            await new Promise((resolve, reject) => {
                svgImage.onload = function () {
                    context.drawImage(svgImage, 0, 0);
                    resolve();
                };
                svgImage.onerror = reject;
            });

            // Convertir le canevas en une image PNG
            const pngUrl = canvas.toDataURL("image/png");

            // Créer un objet FormData pour envoyer l'image via Axios
            const formData = new FormData();
            const blob = await dataURItoBlob(pngUrl);
            formData.append("file", blob, fileName);

            return formData;

            function dataURItoBlob(dataURI) {
                const byteString = atob(dataURI.split(",")[1]);
                const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
                const arrayBuffer = new ArrayBuffer(byteString.length);
                const intArray = new Uint8Array(arrayBuffer);

                for (let i = 0; i < byteString.length; i++) {
                    intArray[i] = byteString.charCodeAt(i);
                }

                const blob = new Blob([intArray], { type: mimeString });
                return blob;
            }
        }


        setBasketLoading(true)
        try {
            let formData = new FormData()
            formData.append('file', file)
            let uploadResponse = await post('/upload/dxf', formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
            let filename = uploadResponse.data.message
            formData = await convertSvgToPngAndPrepareFormData(fileSvg, filename + '.png');
            await post('/upload/image', formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })

            formData = new FormData()
            formData.append('file', stepFile, filename + '.step')
            uploadResponse = await post('/upload/image', formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })


            await addItemBasket({
                dxf: filename,
                length,
                nbChains,
                materialId: configurator.materialId,
                shadeId: configurator.shadeId,
                thicknessId: configurator.thicknessId,
                maxWidth,
                maxHeight,
                weight,
                quantity: configurator.nbPiece,
                nbFolding: nbFolding,
                isStepItem: true,
                configurator,
            })

        } catch (e) {
            console.error('error')
        }
        setBasketLoading(false)


        if (curentFileIndex + 1 >= stepFiles?.length) {
            handleClose()
        } else {
            next()
        }
    }

    const isIgnoreButtonDisabled = () => curentFileIndex + 1 >= stepFiles?.length

    const currentShade = useMemo(() => {
        if (configurator?.materialId && configurator?.shadeId) {

            const material = materials.find(material => material._id === configurator.materialId)
            return material.shades.find((shade) => shade._id === configurator.shadeId)
        }
    }, [configurator.materialId, configurator.shadeId])

    const getThicknessId = (thicknessValue) => {
        if (configurator?.materialId && configurator?.shadeId) {

            const material = materials.find(material => material._id === configurator.materialId)
            const shade = material.shades.find((shade) => shade._id === configurator.shadeId)
            const thickness = shade.thicknesses.find(thickness => thickness.value === thicknessValue);
            if (thickness) {
                return {
                    id: thickness._id,
                    value: thickness.value,
                    exact: true
                }
            }
            shade.thicknesses.sort((a, b) => b.value - a.value);
            const nearThickness = shade.thicknesses.find(thickness => thickness.value < thicknessValue)
            if (nearThickness) {
                return {
                    id: nearThickness._id,
                    value: nearThickness.value,
                    exact: false
                }
            }

        }
        return null;
    }

    return (
        <Dialog
            open={open}
            maxWidth={'lg'}
            fullWidth

        >
            <DialogContent className={classes.root}>
                <Grid container className={classes.container} spacing={2}>

                    <Grid item xs={8} className={classes.container}>
                        <Paper elevation={0} className={classes.viewerPaper}>
                            {stepErrorIsAssemblage === true &&
                                <Alert className={classes.thicknessAlert} severity="error">
                                    <AlertTitle>{`Le fichier contient un assemblage de pièces, merci d'envoyer une pièce par fichier`}</AlertTitle>
                                </Alert>}
                            {stepLoading ? <CircularProgress className={classes.loader} /> : <StlViewer pathToStl={stlFile} color={currentShade?.color} />}

                        </Paper>
                    </Grid>
                    <Grid item xs={4} className={classes.container}>
                        <Paper elevation={0} className={classes.pricePaper}>

                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container alignContent='center' style={{ height: '40px' }}>
                                        <HeightIcon color='primary' />
                                        <Typography style={{ fontWeight: 'bold', marginRight: '1rem', marginLeft: '0.5rem' }} variant="body2" gutterBottom display="inline" >Dimensions : </Typography>
                                        <Typography variant="body2" display="block" gutterBottom>{width && height && depth ? `${height} x ${width} x ${depth} mm` : ` -- x -- mm`}</Typography>

                                    </Grid>
                                    <Grid container alignContent='center' style={{ height: '40px' }}>
                                        <PanoramaWideAngleIcon color='primary' />
                                        <Typography style={{ fontWeight: 'bold', marginRight: '1rem', marginLeft: '0.5rem' }} variant="body2" gutterBottom display="inline" >Poids : </Typography>
                                        <Typography variant="body2" display="block" gutterBottom>{`${weight || '--'} kg`}</Typography>

                                    </Grid>
                                    {!process.env.REACT_APP_IFRAME_MODE &&
                                        <>
                                            <Grid container alignItems="center" alignContent='center' style={{ height: '40px' }}>
                                                <Grid item>
                                                    <AddIcon color='primary' />
                                                </Grid>
                                                <Grid item>
                                                    <Typography style={{ fontWeight: 'bold', marginRight: '1rem', marginLeft: '0.5rem' }} variant="body2" gutterBottom display="inline" >Quantité : </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ConfiguratorMenuNumberPiece />
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    {!process.env.REACT_APP_IFRAME_MODE && <Grid container alignItems='center' style={{ marginTop: '1rem' }}>
                                        <Grid item xs={2} className={classes.priceContainer} >
                                            <Typography style={{ fontWeight: 'bold' }} variant="button" display="inline" gutterBottom>PU</Typography>
                                        </Grid>
                                        <Grid item xs={5} className={classes.priceContainer} >
                                            <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom display="inline" >{loading ? <CircularProgress size={20} /> : unitPrice ? displayFormatedPrice(unitPrice) : ' - - '}</Typography>
                                            <Typography variant="button" display="inline" gutterBottom> € HT</Typography>
                                        </Grid>
                                        <Grid item xs={5} className={classes.priceContainer}>
                                            <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom display="inline" >{loading ? <CircularProgress size={20} /> : unitPrice ? displayFormatedPrice(calculPriceWithVat(unitPrice, property.vatRate)) : ' - - '}</Typography>
                                            <Typography variant="button" display="inline" gutterBottom> € TTC</Typography>
                                        </Grid>

                                    </Grid>}

                                    <Grid container alignItems='center' style={{ marginTop: '1rem' }}>
                                        <Grid item xs={2} className={classes.priceContainer} >
                                            <Typography style={{ fontWeight: 'bold' }} variant="button" display="inline" gutterBottom>{process.env.REACT_APP_IFRAME_MODE ? 'PRIX' : 'TOTAL'}</Typography>
                                        </Grid>
                                        <Grid item xs={5} className={classes.priceContainer} >
                                            <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom display="inline" >{loading ? <CircularProgress size={20} /> : price ? displayFormatedPrice(price) : ' - - '}</Typography>
                                            <Typography variant="button" display="inline" gutterBottom> € HT</Typography>
                                        </Grid>
                                        <Grid item xs={5} className={classes.priceContainer}>
                                            <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom display="inline" >{loading ? <CircularProgress size={20} /> : price ? displayFormatedPrice(calculPriceWithVat(price, property.vatRate)) : ' - - '}</Typography>
                                            <Typography variant="button" display="inline" gutterBottom> € TTC</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper elevation={0} className={classes.pricePaper}>
                            <ConfiguratorMenu withoutNbPiece thicknessShrink thicknessReadOnly stepThickness={thicknessValue} />
                            {exactThicknessFound === false &&
                                <Alert className={classes.thicknessAlert} severity="warning">
                                    <AlertTitle>{`Épaisseur de ${detectedThickness} détectée dans le fichier STEP. Elle n'est pas disponible, l'épaisseur la plus proche a été sélectionnée.`}</AlertTitle>
                                </Alert>}
                        </Paper>
                        <>

                            <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
                                <Grid xs={8} item container justifyContent="flex-end" >
                                    <Button fullWidth disabled={isButtonDisabled()} onClick={handelAddBasket} variant="contained" color='secondary' startIcon={<AddShoppingCartIcon />}>Ajouter au panier {basketLoading ? <CircularProgress color="primary" size={20} /> : null} </Button>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Grid xs={8} item container justifyContent="flex-end" >
                                    <Grid item xs={6}>
                                        <Button fullWidth onClick={handleClose} variant="outlined" color="primary">Fermer</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth disabled={isIgnoreButtonDisabled()} variant="outlined" color="primary" onClick={() => next()}>Ignorer</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog>
    );
}