import React, {useEffect} from 'react'
import { useIsAuthenticated } from 'react-auth-kit';
import { useHistory } from 'react-router';
import {useAuthUser} from 'react-auth-kit'

function IsAuthAdmin({children}) {

    const isAuthenticated = useIsAuthenticated()()
    const history = useHistory()
    const auth = useAuthUser()
    useEffect(() => {
        if(!isAuthenticated && auth()?.role !== 0) {
            history.push('/login')
        }
    }, []);

    return (
        <>{children}</>
    )
}

export default IsAuthAdmin
