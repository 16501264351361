import { Checkbox, FormControlLabel, Grid, Paper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useContext, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'


const useStyles = makeStyles((theme) => ({
    paper: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem'
    },
    line: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1),
            textAlign: 'center'
        }
    }
}))

function ConfiguratorConfigTriangle({ setcurentDraggingObjectPosition, selectedForm }) {
    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [x, setx] = useState(null);
    const [y, sety] = useState(null);
    const [l1, setl1] = useState(null);
    const [l2, setl2] = useState(null);
    const [r, setr] = useState(null);

    const classes = useStyles();

    const handelChangeL1 = useCallback(
        (e) => {
            setl1(e.target.value)
        },
        [],
    )

    const handleBlurL1 = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(l1);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_VALUE_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            l1: evaluateValue
                        }
                    }

                })
                setl1(evaluateValue)
            }
        } catch (e) {
            setl1(null)
        }
    }, [configurator.selectedForm, dispatch, l1])



    const handelChangeL2 = useCallback(
        (e) => {
            setl2(e.target.value)
        },
        [],
    )

    const handleBlurL2 = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(l2);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_VALUE_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            l2: evaluateValue
                        }
                    }

                })
                setl2(evaluateValue)
            }
        } catch (e) {
            setl2(null)
        }
    }, [configurator.selectedForm, dispatch, l2])

    const handelChangeD = useCallback(
        (e) => {


            dispatch({
                type: 'SET_ANGLE_FORM',
                payload: {
                    form: configurator.selectedForm,
                    data: {
                        d: e.target.value
                    }
                }

            })
        },
        [configurator.selectedForm, dispatch],
    )

    const handelChangeX = (e) => setx(e.target.value);

    const handleBlurX = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(x);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            x: evaluateValue
                        }
                    }

                })
                setcurentDraggingObjectPosition((v) => ({ ...v, x: evaluateValue }))
                setx(evaluateValue)
            }
        } catch (e) {
            setx(null)
        }
    }, [configurator.selectedForm, dispatch, setcurentDraggingObjectPosition, x])


    const handelChangeY = (e) => sety(e.target.value);

    const handleBlurY = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(y);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            y: evaluateValue
                        }
                    }

                })
                setcurentDraggingObjectPosition((v) => ({ ...v, y: evaluateValue }))
                sety(evaluateValue)
            }
        } catch (e) {
            sety(null)
        }
    }, [configurator.selectedForm, dispatch, setcurentDraggingObjectPosition, y])


    const handelChangeR = (e) => setr(e.target.value);

    const handleBlurR = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(r);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_VALUE_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            rotation: evaluateValue
                        }
                    }

                })

                setr(evaluateValue)
            }
        } catch (e) {
            setr(null)
        }
    }, [configurator.selectedForm, dispatch, r])

    const handleChangeGraved = useCallback((e) => {
        dispatch({
            type: 'SET_VALUE_FORM',
            payload: {
                form: configurator.selectedForm,
                data: {
                    graved: e.target.checked,
                    unionType: e.target.checked ? 'del' : selectedForm.unionType

                }
            }
        })
    }, [configurator.selectedForm, dispatch, selectedForm.unionType])

    const getValueL1 = useCallback(
        () => {
            return l1 === null ? selectedForm.l1 : l1
        },
        [l1, selectedForm.l1],
    )

    const getValueL2 = useCallback(
        () => {
            return l2 === null ? selectedForm.l2 : l2
        },
        [l2, selectedForm.l2],
    )

    const getValueD = useCallback(
        () => {
            return configurator.forms.find(form => form.id === configurator.selectedForm).d
        },
        [configurator.forms, configurator.selectedForm],
    )


    const getValueX = useCallback(
        () => {
            return x === null ? selectedForm.realx : x
        },
        [selectedForm.realx, x],
    )

    const getValueY = useCallback(
        () => {
            return y === null ? selectedForm.realy : y
        },
        [selectedForm.realy, y],
    )

    const getValueR = useCallback(
        () => {
            return r === null ? selectedForm.rotation : r
        },
        [r, selectedForm.rotation],
    )

    return (

        <Grid container justify="center" alignItems='center'>

            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField label="L1 (mm)" value={getValueL1()} onBlur={handleBlurL1} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurL1()} onChange={handelChangeL1} />
                </Paper>
            </Grid>

            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField label="L2 (mm)" value={getValueL2()} onBlur={handleBlurL2} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurL2()} onChange={handelChangeL2} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={2} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField label="Angle du sommet (°)" value={getValueD()} onChange={handelChangeD} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField label="X (mm)" value={getValueX()} onBlur={handleBlurX} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurX()} margin="dense" onChange={handelChangeX} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField label="Y (mm)" value={getValueY()} onBlur={handleBlurY} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurY()} margin="dense" onChange={handelChangeY} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-triangle-rotation" label="Rotation (Deg)" value={getValueR()} onBlur={handleBlurR} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurR()} onChange={handelChangeR} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedForm.graved}
                                onChange={handleChangeGraved}
                                name="graved"
                                color="primary"
                            />
                        }
                        label="Graver"
                    />
                </Paper>
            </Grid>
        </Grid>

    )
}

export default ConfiguratorConfigTriangle
