import { Button, CircularProgress, Divider, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography, useTheme } from '@material-ui/core'
import React, { useContext, useCallback, useState, useEffect } from 'react'
import { MaterialsContext } from '../contexts/MaterialsContext'
import { useHistory } from 'react-router'
import { BasketContext } from '../contexts/BasketContext'
import CartLine from './CartLine'
import emptyBox from '../assets/images/empty-box.png'
import GreenButton from './Button/GreenButton'
import { useGetBasket } from '../contexts/BasketContext';
import { usePost } from '../modules/request'
import { grey } from '@material-ui/core/colors'
import { calculPriceWithVat, displayFormatedPrice } from '../modules/utils'
import { PropertiesContext } from '../contexts/PropertiesContext'


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    paper: {

    },
    emptybasket: {
        padding: theme.spacing(4)
    },
    emptyBasketImg: {
        height: '100px',
        margin: '0 auto',
        display: 'block'
    },
    paperTotal: {
        padding: theme.spacing(2),
        marginLeft: 'auto',
        marginTop: theme.spacing(3),
    },
    price: {
        fontWeight: 'bold',
        marginLeft: theme.spacing(2),
        fontSize: '1rem',
        textAlign: 'left'
    },
    divider: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    }
}))


function Cart() {

    const classes = useStyles()
    const [baskets, dispatch] = useContext(BasketContext)
    const history = useHistory()
    const [loading, setloading] = useState(false);
    const [materials] = useContext(MaterialsContext)
    const [property] = useContext(PropertiesContext)
    const theme = useTheme()
    const { getBasket } = useGetBasket()

    const getItems = () => {
        if (baskets && baskets.length > 0 && materials.length > 0) {
            return baskets.map(item => <CartLine key={item._id} item={item} />)
        }
    }

    const getTotalPrice = useCallback(
        () => {
            let total = 0
            baskets.forEach(item => total += item.price)
            return total
        },
        [baskets],
    )

    const handelClickValidateBasket = useCallback(
        () => {
            history.push('/cart/validate')
        },
        [history],
    )

    useEffect(() => {

        getBasket()

    }, []);

    return (
        <Grid className={classes.root} item xs={12} md={8} container direction='column' justify='center' alignItems='center'>

            <Grid container className={classes.paper} direction='column'>

                <Grid container direction='column' spacing={2} >
                    <Grid item xs={12} >

                        {baskets.length > 0
                            ?
                            <Paper elevation={0}>
                                <TableContainer style={{ maxWidth: '100%' }}>
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left"><strong>Aperçu</strong></TableCell>
                                                <TableCell width="175px" align="left"><strong>Informations</strong></TableCell>
                                                <TableCell align="left"><strong>Mesures</strong></TableCell>
                                                <TableCell align="left"><strong>Quantité</strong></TableCell>
                                                <TableCell align="left"><strong>Prix</strong></TableCell>
                                                <TableCell align="left"><strong>Actions</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {getItems()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            :
                            <Paper elevation={0} style={{ marginTop: theme.spacing(8) }}>
                                <Grid className={classes.emptybasket} container direction='column' alignItems="center">
                                    <Grid item>
                                        <Typography align="center" variant="h6" gutterBottom>
                                            Votre panier est vide
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <img className={classes.emptyBasketImg} src={emptyBox} alt='Panier vide' />
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={() => history.push('/')} color="primary" variant="outlined">Configurer une piece</Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        }
                    </Grid>
                </Grid>

                {
                    baskets.length > 0 ?
                        <Paper className={classes.paperTotal} elevation={0}>
                            <Grid container spacing={2} alignItems='center'>
                                <Divider className={classes.divider} orientation="vertical" flexItem />
                                <Grid item xs={11}>
                                    <Grid container direction='column'>
                                        <Grid item xs={12}>
                                            <Grid container justify='space-between'>
                                                <Grid item>
                                                    <Typography variant="button" gutterBottom display='inline'>
                                                        Total HT :
                                                    </Typography>

                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="button" className={classes.price} gutterBottom display='inline'>
                                                        {`${displayFormatedPrice(getTotalPrice(), property.vatRate)}`} €
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify='space-between'>
                                                <Grid item>
                                                    <Typography variant="button" gutterBottom display='inline'>
                                                        Total TTC :
                                                    </Typography>

                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="button" className={classes.price} gutterBottom display='inline'>
                                                        {`${displayFormatedPrice(calculPriceWithVat(getTotalPrice(), property.vatRate))}`} €
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </Grid>

                                <Grid item container justifyContent="flex-end">
                                    <Button variant="contained" color="primary" onClick={handelClickValidateBasket}>Valider le panier {loading ? <CircularProgress size={20} /> : null}</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        : null
                }

            </Grid>

        </Grid >

    )
}

export default Cart
