import React, { useCallback, useContext, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./../../src/modules/worker";
import { makeStyles } from '@material-ui/styles';
import { BallTriangle } from 'react-loader-spinner';

import GreenButton from './Button/GreenButton';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography, Grid } from '@material-ui/core';
import { PropertiesContext } from '../contexts/PropertiesContext';


const useStyles = makeStyles((theme) => ({
    optionsTitle: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    }
}))



function ProcessUploadedSvg({ open, handleClose, file, onValidate, isCatalogSvg }) {

    const [lines, setlines] = useState([]);
    const [originalHeight, setoriginalHeight] = useState(null);
    const [originalWidth, setoriginalWidth] = useState(null);
    const [targetHeight, settargetHeight] = useState(null);
    const [targetWidth, settargetWidth] = useState(null);
    const [startProcess, setstartProcess] = useState(false);
    const [errorH, seterrorH] = useState(false);
    const [errorHText, seterrorHText] = useState('');
    const [errorW, seterrorW] = useState(false);
    const [errorWText, seterrorWText] = useState('');
    const [properties] = useContext(PropertiesContext);
    const [ratio, setratio] = useState(0);
    const [model, setmodel] = useState({});
    const classes = useStyles();

    useEffect(() => {
        if (!lines.length) {

            let reader = new FileReader();

            // Closure to capture the file information.
            reader.onload = (function () {
                return function (e) {

                    setstartProcess(true);
                    const worker = new Worker()
                    worker.postMessage({ type: 'SVG_IMPORT', svgStr: e.target.result, originalHeight, originalWidth })
                    worker.onmessage = (event) => {
                        const { height, width, lines, model } = event.data;
                        setlines(lines);
                        setmodel(model);
                        const roundedHeight = Math.round(height);
                        const roundedWidth = Math.round(width);
                        if ((!originalHeight || originalHeight === 0) && (!originalWidth || originalWidth === 0)) {
                            setoriginalHeight(roundedHeight);
                            setoriginalWidth(roundedWidth);
                            let ratio = height / width;
                            setratio(ratio)
                        }
                        setstartProcess(false);
                        worker.terminate()
                    }
                };
            })(file);

            // Read in the image file as a data URL.
            reader.readAsText(file);
        }
    }, [file, lines.length, originalHeight, originalWidth]);

    const handleChangeHeight = useCallback((e) => {

        if (e.target.value.match(/^[0-9]*$/)) {
            seterrorH(false)
            seterrorHText('')
            if (Number(e.target.value) < properties.machineMinLength || Number(e.target.value) > properties.machineMaxLength) {
                seterrorHText(`valeur comprisent entre ${properties.machineMinLength} et ${properties.machineMaxLength}`)
                seterrorH(true)
            }
            settargetHeight(+e.target.value)
        }

    }, [properties.machineMaxLength, properties.machineMinLength])

    const handleBlurHeight = useCallback(() => {
        const value = Math.ceil(targetHeight / ratio);
        seterrorW(false)
        seterrorWText('')
        if (Number(value) < properties.machineMinWidth || Number(value) > properties.machineMaxWidth) {
            seterrorWText(`valeur comprisent entre ${properties.machineMinWidth} et ${properties.machineMaxWidth}`)
            seterrorW(true)
        }
        settargetWidth(value)

    }, [properties.machineMaxWidth, properties.machineMinWidth, ratio, targetHeight])

    const handleChangeWidth = useCallback((e) => {
        if (e.target.value.match(/^[0-9]*$/)) {
            seterrorW(false)
            seterrorWText('')
            if (Number(e.target.value) < properties.machineMinWidth || Number(e.target.value) > properties.machineMaxWidth) {
                seterrorWText(`valeur comprisent entre ${properties.machineMinWidth} et ${properties.machineMaxWidth}`)
                seterrorW(true)
            }
            settargetWidth(+e.target.value)
        }

    }, [properties.machineMaxWidth, properties.machineMinWidth])

    const handleBlurWidth = useCallback(() => {


        seterrorH(false)
        const value = Math.ceil(targetWidth * ratio);
        seterrorHText('')
        if (Number(value) < properties.machineMinLength || Number(value) > properties.machineMaxLength) {
            seterrorHText(`valeur comprisent entre ${properties.machineMinLength} et ${properties.machineMaxLength}`)
            seterrorH(true)
        }
        settargetHeight(+value)
    }, [properties.machineMaxLength, properties.machineMinLength, ratio, targetWidth])


    return (
        <Dialog maxWidth={"xl"} open={open} onClose={handleClose} >
            <DialogTitle style={{ textAlign: 'center' }} id="add-to-catalog-dialog">Importation {isCatalogSvg ? 'Motif' : 'SVG'}</DialogTitle>
            <DialogContent style={{ paddingTop: '1rem' }}>
                {startProcess ?

                    <Grid container justify='center'>
                        <BallTriangle height={200} width={200} strokeWidth="1" ariaLabel="rotating-square" visible={true} color="#3A7297" />
                    </Grid>
                    :
                    <Grid container justify='space-around'>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    {originalHeight && originalWidth && <> <Grid container >
                                        <Grid item xs={6}>
                                            <Typography className={classes.optionsTitle} variant="button" gutterBottom display='block'>
                                                Dimensions originales
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.optionsTitle} variant="button" gutterBottom display='block'>
                                                Dimensions souhaitées
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                        <Grid container alignItems='center' >
                                            <Grid item xs={6}>
                                                <Typography variant="body1" gutterBottom display='block'>
                                                    Largeur  <small>(mm)</small> : {originalHeight}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container alignItems='center'>
                                                    <Grid item xs={7}>

                                                        <Typography variant="body1" gutterBottom display='inline'>
                                                            Largeur <small>(mm)</small>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>

                                                        <TextField value={targetHeight} onBlur={handleBlurHeight} onChange={handleChangeHeight} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                        <Grid container alignItems='center' >
                                            <Grid item xs={6}>
                                                <Typography variant="body1" gutterBottom display='block'>
                                                    Longueur<small>(mm)</small> : {originalWidth}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container alignItems='center'>
                                                    <Grid item xs={7}>

                                                        <Typography variant="body1" gutterBottom display='inline'>
                                                            Longueur <small>(mm)</small>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>

                                                        <TextField value={targetWidth} onBlur={handleBlurWidth} onChange={handleChangeWidth} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </>}
                                </Grid>

                            </Grid>
                            <Divider style={{ margin: '1rem 0' }} />



                        </Grid>


                    </Grid>
                }
                <Typography variant="caption" gutterBottom display='block'>
                    {errorHText || errorWText}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Grid container justify='space-between'>
                    <Grid item>
                        <Button onClick={handleClose} color="primary" variant='contained'>
                            Fermer
                        </Button>
                    </Grid>
                    {!startProcess && <Grid item>
                        <GreenButton onClick={() => onValidate({ lines, targetHeight: targetHeight || originalHeight, targetWidth: targetWidth || originalWidth, originalHeight, originalWidth, ratio, model })} disabled={!targetHeight || !targetWidth || errorH || errorW} color="primary" variant='contained'>Valider</GreenButton>
                    </Grid>}

                </Grid>
            </DialogActions>

        </Dialog >
    )
}

export default ProcessUploadedSvg