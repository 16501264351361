import React, { useCallback, useContext, useRef, useState, useEffect, useMemo } from 'react'
import { ConfiguratorContext } from '../contexts/ConfiguratorContext'
import makerjs from 'makerjs';
import { getlayers } from '../modules/dxf'
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./../../src/modules/worker";
import DialogShowDXFDropResult from './Dialog/DialogShowDXFDropResult';
import { useIsAuthenticated } from 'react-auth-kit';
import { useHistory } from 'react-router-dom';
import { useGet, usePost } from '../modules/request';
import { useAddItemBasket } from '../contexts/BasketContext';
import DialogShowDXFLayers from './Dialog/DialogShowDXFLayers';
import { MaterialsContext } from '../contexts/MaterialsContext';
import { ajustDimensionsRect2 } from '../modules/svg';
import { dxfToModel } from '../modules/makerjs';
import { PropertiesContext } from '../contexts/PropertiesContext';
import { SnackbarContext } from '../contexts/SnackbarContext';


function DropDXF({ setActiveStep, onClick, startDXF, setstartDXF, setdxfLoading }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext);
    const [properties] = useContext(PropertiesContext);
    const [materials] = useContext(MaterialsContext);
    const hiddenInputRef = useRef(null);
    const [price, setprice] = useState(null);
    const [unitPrice, setUnitPrice] = useState(null);
    const [svg, setsvg] = useState(null);
    const [open, setopen] = useState(false);
    const [length, setlength] = useState(0);
    const [nbChains, setnbChains] = useState(0);
    const [maxWidth, setmaxWidth] = useState(0);
    const [maxHeight, setmaxHeight] = useState(0);
    const [realSurface, setRealSurface] = useState(0);
    const [weight, setweight] = useState(0);
    const [dxf, setdxf] = useState('');
    const [file, setfile] = useState(null)
    const [fileSvg, setfileSvg] = useState(null)
    const [files, setfiles] = useState([]);
    const [currentFile, setcurrentFile] = useState(0);
    const [postBasketLoading, setpostBasketLoading] = useState(false)
    const [collision, setcollision] = useState(false);
    const [layers, setlayers] = useState([]);
    const [openDialogShowDXFLayers, setopenDialogShowDXFLayers] = useState(false);
    const [selectedLayers, setselectedLayers] = useState([]);
    const [gravedLayers, setgravedLayers] = useState([]);
    const [loading, setloading] = useState(false);
    const [model, setModel] = useState({
        models: {},
        paths: {},
    });

    const isAuth = useIsAuthenticated()
    const history = useHistory()
    const post = usePost()
    const get = useGet()
    const { addItemBasket } = useAddItemBasket();
    const [, dispatchSnackbar] = useContext(SnackbarContext);

    const reset = useCallback(() => {
        dispatch({
            type: 'RESET'
        });
        setActiveStep(0);
        setprice(null);
        setlength(0);
        setopen(false);
        setnbChains(0);
        setmaxHeight(0);
        setmaxWidth(0);
        setdxf('');
        setfile(null);
        setcollision(false);
        setcurrentFile(0);
        setstartDXF(false);
        setgravedLayers([]);
        setselectedLayers([]);
        setfiles([]);
    }, [dispatch, setActiveStep, setstartDXF])

    const currentShade = useMemo(() => {
        if (configurator?.materialId && configurator?.shadeId) {

            const material = materials.find(material => material._id === configurator.materialId)
            return material.shades.find((shade) => shade._id === configurator.shadeId)
        }
    }, [configurator.materialId, configurator.shadeId])


    const handleClose = useCallback(
        () => {
            window.parent.postMessage({ type: 'cancel-dxf-redirect' }, "*");
            reset();
            setopen(false)
        },
        [reset],
    )

    useEffect(() => {
        if (startDXF && !open) {

            hiddenInputRef.current.click()
            setstartDXF(false)

        }
    }, [history, isAuth, setstartDXF, startDXF])

    const handelClickNext = useCallback(
        () => {
            dispatch({
                type: 'SET_VALUE',
                payload: {
                    key: 'nbPiece',
                    data: 1
                }
            })
            setcurrentFile(currentFile + 1)
            setprice(null)
            setlength(0)
            setopen(false)
            setnbChains(0)
            setmaxHeight(0)
            setmaxWidth(0)
            setdxf('')
            setfile(null)
            setcollision(false)
            setgravedLayers([]);
            setselectedLayers([]);
        },
        [currentFile],
    )

    const isDXFFile = (file) => {
        //const validDXFMimeTypes = ['application/dxf', 'image/vnd.dxf', 'application/x-dxf', 'image/x-dxf'];
        return file.name?.includes('.dxf') || false;

    };


    useEffect(() => {
        (async () => {
            if (currentFile >= files.length && files.length !== 0) {
                reset();
                window.parent.postMessage({ type: 'redirect' }, "*");
            } else {
                try {
                    const currentProcessingFile = files[currentFile];
                    setselectedLayers([]);
                    setgravedLayers([]);
                    if (!isDXFFile(currentProcessingFile)) {
                        alert(`Le fichier ${currentProcessingFile.name} n'est pas conforme`);
                        setcurrentFile((prev) => prev + 1);
                        return;
                    }

                    var rawDXF = await files[currentFile].text();
                    const layers = getlayers(rawDXF);
                    const model = dxfToModel(rawDXF)

                    if (!Object.keys(model.models).length && !Object.keys(model.paths).length) {
                        alert(`Le fichier ${currentProcessingFile.name} n'est pas conforme`);
                        setcurrentFile((prev) => prev + 1);
                        return;
                    }

                    setModel(model);
                    if (layers.length > 1) {
                        setdxf(rawDXF)
                        setlayers(layers);
                        setopenDialogShowDXFLayers(true)
                    } else {
                        handleClickValidateDxf(model)
                    }

                } catch (e) {
                    console.error('error dxf', e.message);
                }
            }
        })();
    }, [files, currentFile]);



    const handleClickValidateDxf = useCallback((model) => {
        (async () => {
            setloading(true);
            setdxfLoading(true);

            const worker = new Worker();

            worker.postMessage({ type: 'MODEL_CHECK', model, formValidations: properties.formValidations })
            worker.onmessage = async (event) => {
                const { validations } = event.data;
                let hasError = false;


                Object.keys(validations).forEach(key => {
                    if (validations[key].error) {
                        validations.hasError = true;
                        if (validations[key].datas && Array.isArray(validations[key].datas)) {
                            validations[key].datas.forEach((element) => {
                                element.links.forEach(link => {
                                    const route = link.walkedPath.route;
                                    let modelToFind = makerjs.travel(model, route).result

                                    modelToFind.layer = 'red';
                                })
                            })
                        }
                    }
                })

                dispatch({
                    type: 'SET_VALUES',
                    payload: {
                        data: { validations }
                    }
                })

                if (!hasError) {

                    worker.postMessage({ type: 'MODEL_MEASURE', model })

                    worker.onmessage = async (event) => {
                        const { length, nbChains, maxWidth, maxHeight, realSurface } = event.data;
                        setlength(length)
                        setnbChains(nbChains)
                        setmaxWidth(maxWidth)
                        setmaxHeight(maxHeight)
                        setRealSurface(realSurface)
                        let url = `/calculate?length=${length}&materialId=${configurator.materialId}&shadeId=${configurator.shadeId}&thicknessId=${configurator.thicknessId}&nbChains=${nbChains}&nbFolding=0&maxWidth=${maxWidth}&maxHeight=${maxHeight}&quantity=${configurator.nbPiece}&realSurface=${realSurface}`;
                        if (window.sessionId) {
                            url += '&sessionId=' + window.sessionId;
                        }
                        const result = await get(url);
                        setprice(result.data.message.price)
                        setUnitPrice(result.data.message.unitPrice)
                        setweight(result.data.message.realWeight || result.data.message.weight)

                        dispatch({
                            type: 'SET_VALUES',
                            payload: {
                                data: { realSurface }
                            }
                        })
                    }

                }

                const options = {
                    layerOptions: {
                        'green': {
                            stroke: 'green',
                            fill: 'transparent'
                        },

                    },
                    fillRule: 'evenodd',
                    //fill: currentShade?.color
                };

                if (!hasError) {
                    options.fill = currentShade?.color;
                }

                let svg = ajustDimensionsRect2(makerjs.exporter.toSVG(
                    model,
                    options
                ))

                svg = svg.replace("stroke-width:0.25mm", "stroke-width:0.25mm");
                svg = svg.replace("nonzero", "evenodd");


                function reorderSvg(svgString, color) {
                    // Assurez-vous que la couleur est une chaîne non vide
                    if (typeof color !== 'string' || color.trim() === '') {
                        return svgString; // Retourne la chaîne originale si la couleur n'est pas valide
                    }

                    // Expression régulière pour trouver tous les paths avec le stroke spécifié
                    const pathRegex = new RegExp(`<path[^>]*stroke="${color}"[^>]*\/>`, 'g');

                    // Extraire tous les éléments correspondants
                    const paths = svgString.match(pathRegex);
                    if (!paths || paths.length === 0) {
                        return svgString; // Retourne la chaîne originale si aucun match n'est trouvé
                    }

                    // Retirer tous les éléments originaux
                    paths.forEach(path => {
                        svgString = svgString.replace(path, '');
                    });

                    // Réinsérer tous les éléments à la fin du groupe <g>
                    const allPaths = paths.join('');
                    svgString = svgString.replace(/<\/g>/, `${allPaths}</g>`);

                    return svgString;
                }

                svg = reorderSvg(svg, "green");
                svg = reorderSvg(svg, "red");
                setsvg(svg);
                setopenDialogShowDXFLayers(false)
                setopen(true)
                const dxf = makerjs.exporter.toDXF(model);
                setdxf(dxf)
                const dt = new Date();
                const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
                const fileName = `P${padL(+dt.getHours())}${padL(dt.getMinutes())}${padL(dt.getSeconds())}-${dt.getFullYear()}${padL(dt.getMonth() + 1)}${padL(dt.getDate())}-${Math.floor(Math.random() * (1000 - 1)) + 1}`;

                const parts = [
                    new Blob([dxf], { type: 'application/dxf' }),
                ];
                const newFile = new File(parts, fileName + '.dxf', {});


                setfileSvg(svg);
                setdxfLoading(false)
                setloading(false);
                setfile(newFile)

            }

        })()
    }, [configurator.materialId, configurator.nbPiece, configurator.shadeId, configurator.thicknessId, get, gravedLayers, selectedLayers])

    const handelSelectFile = (e) => {
        // const validDXFFiles = Array.from(e.target.files).filter(isDXFFile);
        // if (validDXFFiles.length !== e.target.files.length) {
        //     alert('Le format du fichier n\'est pas conforme');
        // }
        setfiles(e.target.files);
    };


    const handelAddToBasket = () => {

        async function convertSvgToPngAndPrepareFormData(svgString, fileName) {
            // Créer un élément "dummy" dans le DOM pour dessiner le SVG
            const svgContainer = document.createElement("div");
            svgContainer.innerHTML = svgString;
            // Créer un canevas HTML5 pour dessiner l'image
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = 500;
            canvas.height = 500
            // Dessiner le SVG sur le canevas
            const svgUrl = `data:image/svg+xml;base64,${btoa(svgString)}`;
            const svgImage = new Image();
            svgImage.src = svgUrl;
            await new Promise((resolve, reject) => {
                svgImage.onload = function () {
                    context.drawImage(svgImage, 0, 0);
                    resolve();
                };
                svgImage.onerror = reject;
            });
            // Convertir le canevas en une image PNG
            const pngUrl = canvas.toDataURL("image/png");

            // Créer un objet FormData pour envoyer l'image via Axios
            const formData = new FormData();
            const blob = await dataURItoBlob(pngUrl);
            formData.append("file", blob, fileName);

            return formData;

            function dataURItoBlob(dataURI) {
                const byteString = atob(dataURI.split(",")[1]);
                const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
                const arrayBuffer = new ArrayBuffer(byteString.length);
                const intArray = new Uint8Array(arrayBuffer);

                for (let i = 0; i < byteString.length; i++) {
                    intArray[i] = byteString.charCodeAt(i);
                }

                const blob = new Blob([intArray], { type: mimeString });
                return blob;
            }
        }


        (async () => {
            setpostBasketLoading(true)
            try {
                let formData = new FormData()
                formData.append('file', file)
                let uploadResponse = await post('/upload/dxf', formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                const filename = uploadResponse.data.message

                formData = await convertSvgToPngAndPrepareFormData(svg, filename + '.png');

                await post('/upload/image', formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })

                await addItemBasket({
                    dxf: filename,
                    length,
                    nbChains,
                    materialId: configurator.materialId,
                    shadeId: configurator.shadeId,
                    thicknessId: configurator.thicknessId,
                    maxWidth,
                    maxHeight,
                    weight,
                    isFromDxf: true,
                    quantity: configurator.nbPiece,
                    nbFolding: 0,
                    multipleChains: configurator.validations?.multipleChains?.nbChains || 1,
                    configurator,
                })

                setopen(false)

            } catch (e) {
                console.error('error')
            }
            setpostBasketLoading(false)
            if (currentFile <= files.length) {
                dispatch({
                    type: 'SET_VALUE',
                    payload: {
                        key: 'nbPiece',
                        data: 1
                    }
                })
                setcurrentFile(currentFile + 1)
            }
        })()
    };

    const handleCloseDialogShowDXFLayers = () => {
        setopenDialogShowDXFLayers(false);
        reset();

    }

    useEffect(() => {
        if (!!svg?.length && configurator.thicknessId) {
            const regex = /fill:[^"]*/g;
            setsvg(svg.replace(regex, `fill:${currentShade?.color || '#7b7b7b'}`));
        }
    }, [configurator.thicknessId])

    useEffect(() => {
        (async () => {
            let url = `/calculate?length=${length}&materialId=${configurator.materialId}&shadeId=${configurator.shadeId}&thicknessId=${configurator.thicknessId}&nbChains=${nbChains}&nbFolding=0&maxWidth=${maxWidth}&maxHeight=${maxHeight}&quantity=${configurator.nbPiece}&realSurface=${realSurface}`;
            if (window.sessionId) {
                url += '&sessionId=' + window.sessionId;
            }
            const result = await get(url);
            setprice(result.data.message.price)
            setUnitPrice(result.data.message.unitPrice)
            setweight(result.data.message.realWeight || result.data.message.weight)
        })()
    }, [configurator.nbPiece, configurator.thicknessId])

    return (
        <>
            {!open && <input ref={hiddenInputRef} onChange={handelSelectFile} id='fileid' type='file' multiple hidden accept=".dxf" />}
            {open && <DialogShowDXFDropResult lastFile={currentFile === files.length - 1} handelClickNext={handelClickNext} qte={configurator.nbPiece} postBasketLoading={postBasketLoading} collision={collision} price={price} svg={svg} open={open} handleClose={handleClose} handelAddToBasket={handelAddToBasket} weight={weight} maxHeight={maxHeight} maxWidth={maxWidth} unitPrice={unitPrice} />}
            {openDialogShowDXFLayers && <DialogShowDXFLayers loading={loading} model={model} setModel={setModel} handleClose={handleCloseDialogShowDXFLayers} handleClickValidateDxf={handleClickValidateDxf} open={openDialogShowDXFLayers} dxf={dxf} layers={layers} selectedLayers={selectedLayers} setselectedLayers={setselectedLayers} gravedLayers={gravedLayers} setgravedLayers={setgravedLayers} currentShade={currentShade} />}
        </>
    )
}

export default DropDXF
