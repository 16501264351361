import React, { useContext, useCallback, useState } from 'react';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import { PropertiesContext } from '../contexts/PropertiesContext'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableCellEditable from './TableCellEditable';
import EditIcon from '@material-ui/icons/Edit';
import { rateSchema, DeliveryTimeSchemas, transportMaxWidthSchema, nbOperatorSchema, transportMaxLengthSchemas, foldingRateSchemas, foldingMaxWidthSchemas, foldingMaxLengthSchemas, minPlanFoldingSchemas, maxWeightPerOperatorSchema, maxLengthPerOperatorSchema, maxLengthSchema, minPlanCuttingSchema, machineMaxLengthSchemas, machineMaxWidthSchemas, vatRateSchema, MultipleChainsAllowMaxNumberSchemas } from '../modules/validation';
import { Button, Switch, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ModalAddTransportPlan from './ModalAddTransportPlan';
import RemoveIcon from '@material-ui/icons/Remove';
import ModalAddTransportDelay from './ModalAddTransportDelay';
import { setDeepProperty } from '../modules/utils';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    tableCellCategory: {
        background: 'aliceblue'

    },
    tableCellProperty: {
        background: 'antiquewhite'
    },
    tableCellValue: {
        background: 'cornsilk'
    },
    nonClickable: {
        pointerEvents: 'none'
    },
}));

function PropertyHandler() {
    const [properties, dispatch] = useContext(PropertiesContext);
    const [rate, setrate] = useState('')
    const [costPerKm] = useState('')

    const [minPlanCutting, setminPlanCutting] = useState('');
    const [machineMaxWidth, setmachineMaxWidth] = useState('');
    const [machineMaxLength, setmachineMaxLength] = useState('');
    const [foldingRate, setfoldingRate] = useState('');
    const [minPlanFolding, setminPlanFolding] = useState('');
    const [maxWeightPerOperator, setmaxWeightPerOperator] = useState('');
    const [maxLengthPerOperator, setmaxLengthPerOperator] = useState('');
    const [foldingMaxWidth, setfoldingMaxWidth] = useState('');
    const [foldingMaxLength, setfoldingMaxLength] = useState('');
    const [transportMaxWidth, settransportMaxWidth] = useState('');
    const [transportMaxLength, settransportMaxLength] = useState('');
    const [isNewTransportPlan, setisNewTransportPlan] = useState(false);
    const [isNewTransportDelay, setisNewTransportDelay] = useState(false);
    const [newTransportPlan, setnewTransportPlan] = useState({ from: '', price: '' });
    const [nbOperator, setnbOperator] = useState('');
    const [machineMinWidth, setmachineMinWidth] = useState('');
    const [machineMinLength, setmachineMinLength] = useState('');
    const [startingPlanFolding, setstartingPlanFolding] = useState('');
    const [, setdeliveryTime] = useState('');
    const [startingPlanCutting, setstartingPlanCutting] = useState('');
    const [startingPlanCuttingGlobal, setstartingPlanCuttingGlobal] = useState('');
    const [multipleChainsAllowMaxNumber, setMultipleChainsAllowMaxNumber] = useState('');
    const [vatRate, setvatRate] = useState('');

    const classes = useStyles();


    const handelEditProperty = async (key, value) => {
        try {
            let dataToUpdate;

            if (!properties || !properties._id) {
                const data = {
                    rate,
                    costPerKm,
                    minPlanCutting,
                    machineMaxWidth,
                    machineMaxLength,
                    foldingRate,
                    minPlanFolding,
                    maxWeightPerOperator,
                    maxLengthPerOperator,
                    foldingMaxWidth,
                    foldingMaxLength,
                    transportMaxWidth,
                    transportMaxLength,
                    vatRate,
                    startingPlanFolding
                }
                dataToUpdate = data;
            } else {
                dataToUpdate = { ...properties };
            }

            // Utilisez setDeepProperty pour définir la valeur
            setDeepProperty(dataToUpdate, key, value);

            const endpoint = properties && properties._id
                ? `/propertie/${properties._id}`
                : '/propertie';
            const method = properties && properties._id ? 'put' : 'post';

            const res = await axios[method](process.env.REACT_APP_BASE_API + endpoint, dataToUpdate);

            dispatch({
                type: 'SET_PROPERTY',
                payload: res.data.message
            });

        } catch (e) {
            // Gestion des erreurs
        }
    };

    const handelEditTransportPlan = useCallback(
        (id, key) => {
            (async () => {
                const newData = { ...properties, transportPlans: properties.transportPlans.map(item => item._id !== id ? item : { ...item, [key]: newTransportPlan[key] }) }
                const res = await axios.put(process.env.REACT_APP_BASE_API + '/propertie/' + properties._id, newData)
                dispatch({
                    type: 'SET_PROPERTY',
                    payload: res.data.message
                })
            })()

        },
        [dispatch, newTransportPlan, properties],
    )

    const handelclickAddTransportPlan = useCallback(
        () => {
            setisNewTransportPlan(true)
        },
        [],
    )

    const handelclickAddTransportDelay = useCallback(() => {
        setisNewTransportDelay(true)
    }, [])

    const handelclickDeleteTransportPlan = useCallback(
        (id) => {
            (async () => {
                const newproperties = { ...properties, deliveryTimes: properties.deliveryTimes.filter(deliveryTime => deliveryTime._id !== id) }
                try {
                    await axios.put(process.env.REACT_APP_BASE_API + '/propertie/' + properties._id, newproperties)
                    dispatch({
                        type: 'SET_PROPERTY',
                        payload: newproperties
                    })
                } catch (e) {

                }

            })()
        },
        [dispatch, properties],
    )


    const getTransportPlansTableBody = useCallback(
        () => {
            return properties?.transportPlans?.map((transportPlan, index) => <TableRow>


                <TableCell>{`Forfait ${index + 1} jusqu'à (kg)`}</TableCell>
                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={newTransportPlan.to} yupValidation={rateSchema} onCickEdited={() => handelEditTransportPlan(transportPlan._id, 'to')} onChange={(e) => setnewTransportPlan({ ...newTransportPlan, to: e.target.value })} icon={<EditIcon style={{ fontSize: 20 }} />} >{transportPlan.to}</TableCellEditable>
                <TableCell>{`Prix`}</TableCell>
                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={newTransportPlan.price} yupValidation={rateSchema} onCickEdited={() => handelEditTransportPlan(transportPlan._id, 'price')} onChange={(e) => setnewTransportPlan({ ...newTransportPlan, price: e.target.value })} icon={<EditIcon style={{ fontSize: 20 }} />}>{transportPlan.price}</TableCellEditable>

                <TableCell style={{ padding: 0 }}><Button style={{ width: '100%' }} onClick={() => handelclickDeleteTransportPlan(transportPlan._id)}><RemoveIcon color="secondary" /></Button></TableCell>
            </TableRow>)
        },
        [classes.tableCellValue, handelEditTransportPlan, handelclickDeleteTransportPlan, newTransportPlan, properties?.transportPlans],
    )

    const getTransportDelaysTableBody = useCallback(() => {
        return properties?.deliveryTimes?.map((deliveryTime, index) => <TableRow>
            <TableCell>{`Delai ${index + 1}`}</TableCell>
            <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={deliveryTime.time} yupValidation={DeliveryTimeSchemas} onCickEdited={() => handelEditProperty('deliveryTime', deliveryTime)} onChange={(e) => setdeliveryTime(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />}>{deliveryTime.time || ''}</TableCellEditable>
            <TableCell>{`Reduction`}</TableCell>
            <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={deliveryTime.reduction} yupValidation={DeliveryTimeSchemas} onCickEdited={() => handelEditProperty('deliveryTime', deliveryTime)} onChange={(e) => setdeliveryTime(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />}>{deliveryTime.reduction || ''}</TableCellEditable>
            <TableCell style={{ padding: 0 }}><Button style={{ width: '100%' }} onClick={() => handelclickDeleteTransportPlan(deliveryTime._id)}><RemoveIcon color="secondary" /></Button></TableCell>

        </TableRow>)

    }, [classes.tableCellValue, handelclickDeleteTransportPlan, properties?.deliveryTimes])

    return (

        <TableContainer component={Paper} className={classes.root} elevation={0}>
            <Table aria-label="tableau des propriétés">

                <TableBody>
                    <TableRow>
                        <TableCell align="left" className={classes.tableCellCategory}>Découpage</TableCell>
                        <Table>
                            <TableBody>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Taux de TVA <small>(%)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={vatRate} yupValidation={vatRateSchema} onCickEdited={() => handelEditProperty('vatRate', vatRate)} onChange={(e) => setvatRate(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} />}>{properties?.vatRate || ''}</TableCellEditable>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Taux horaire découpe laser <small>(€/h)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={rate} yupValidation={rateSchema} onCickEdited={() => handelEditProperty('rate', rate)} onChange={(e) => setrate(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} />}>{properties?.rate || ''}</TableCellEditable>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Forfait minimum <small>(€)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={minPlanCutting} yupValidation={minPlanCuttingSchema} onCickEdited={() => handelEditProperty('minPlanCutting', minPlanCutting)} onChange={(e) => setminPlanCutting(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} >{(properties.minPlanCutting || properties.minPlanCutting === 0) ? properties.minPlanCutting : ''}</TableCellEditable>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Forfait de mise en route par pièce<small>(€)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={startingPlanCutting} yupValidation={minPlanCuttingSchema} onCickEdited={() => handelEditProperty('startingPlanCutting', startingPlanCutting)} onChange={(e) => setstartingPlanCutting(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} >{(properties.startingPlanCutting || properties.startingPlanCutting === 0) ? properties.startingPlanCutting : ''}</TableCellEditable>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Forfait de mise en route global <small>(€)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={startingPlanCuttingGlobal} yupValidation={minPlanCuttingSchema} onCickEdited={() => handelEditProperty('startingPlanCuttingGlobal', startingPlanCuttingGlobal)} onChange={(e) => setstartingPlanCuttingGlobal(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} >{(properties.startingPlanCuttingGlobal || properties.startingPlanCuttingGlobal === 0) ? properties.startingPlanCuttingGlobal : ''}</TableCellEditable>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellCategory}>Capacités machine</TableCell>
                                    <Table>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell align="left" className={classes.tableCellProperty}>Longueur maximale <small>(mm)</small></TableCell>
                                                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={machineMaxWidth} yupValidation={machineMaxWidthSchemas} onCickEdited={() => handelEditProperty('machineMaxWidth', machineMaxWidth)} onChange={(e) => setmachineMaxWidth(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />}>{properties?.machineMaxWidth || ''}</TableCellEditable>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className={classes.tableCellProperty}>Largeur maximale <small>(mm)</small></TableCell>
                                                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={machineMaxLength} yupValidation={machineMaxLengthSchemas} onCickEdited={() => handelEditProperty('machineMaxLength', machineMaxLength)} onChange={(e) => setmachineMaxLength(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />}>{properties?.machineMaxLength || ''}</TableCellEditable>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className={classes.tableCellProperty}>Longueur minimale <small>(mm)</small></TableCell>
                                                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={machineMinWidth} yupValidation={machineMaxWidthSchemas} onCickEdited={() => handelEditProperty('machineMinWidth', machineMinWidth)} onChange={(e) => setmachineMinWidth(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />}>{properties?.machineMinWidth || ''}</TableCellEditable>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className={classes.tableCellProperty}>Largeur minimale <small>(mm)</small></TableCell>
                                                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={machineMinLength} yupValidation={machineMaxLengthSchemas} onCickEdited={() => handelEditProperty('machineMinLength', machineMinLength)} onChange={(e) => setmachineMinLength(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />}>{properties?.machineMinLength || ''}</TableCellEditable>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableRow>
                    <TableRow >
                        <TableCell align="left" className={classes.tableCellCategory}>
                            Pliage
                            <Switch
                                style={{ position: 'absolute' }}
                                checked={properties.foldingEnabled ? true : false}
                                onChange={(e) => handelEditProperty('foldingEnabled', e.target.checked)}
                                color="primary"
                                name="transportEnabled"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </TableCell>
                        <Table>
                            <TableBody style={{ opacity: !properties.foldingEnabled ? '35%' : null }} className={!properties.foldingEnabled ? classes.nonClickable : ''}>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCellProperty}>Taux horaire pliage <small>(€/h)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={foldingRate} yupValidation={foldingRateSchemas} onCickEdited={() => handelEditProperty('foldingRate', foldingRate)} onChange={(e) => setfoldingRate(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />}>{properties.foldingRate || ''}</TableCellEditable>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCellProperty}>Forfait minimum <small>(€)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={minPlanFolding} yupValidation={minPlanFoldingSchemas} onCickEdited={() => handelEditProperty('minPlanFolding', minPlanFolding)} onChange={(e) => setminPlanFolding(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />}>{(properties.minPlanFolding || properties.minPlanFolding === 0) ? properties.minPlanFolding : ''}</TableCellEditable>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Forfait de mise en route <small>(€)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={startingPlanFolding} yupValidation={minPlanFoldingSchemas} onCickEdited={() => handelEditProperty('startingPlanFolding', startingPlanFolding)} onChange={(e) => setstartingPlanFolding(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} >{(properties.startingPlanFolding || properties.startingPlanFolding === 0) ? properties.startingPlanFolding : ''}</TableCellEditable>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Nombre d'opérateurs</TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={nbOperator} yupValidation={nbOperatorSchema} onCickEdited={() => handelEditProperty('nbOperator', nbOperator)} onChange={(e) => setnbOperator(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} >{properties?.nbOperator || ''}</TableCellEditable>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Poids maximum par opérateur <small>(kg)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={maxWeightPerOperator} yupValidation={maxWeightPerOperatorSchema} onCickEdited={() => handelEditProperty('maxWeightPerOperator', maxWeightPerOperator)} onChange={(e) => setmaxWeightPerOperator(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />}>{properties?.maxWeightPerOperator || ''}</TableCellEditable>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Longueur maximale par opérateur <small>(mm)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={maxLengthPerOperator} yupValidation={maxLengthPerOperatorSchema} onCickEdited={() => handelEditProperty('maxLengthPerOperator', maxLengthPerOperator)} onChange={(e) => setmaxLengthPerOperator(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} >{properties?.maxLengthPerOperator || ''}</TableCellEditable>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellCategory}>Capacités machine</TableCell>
                                    <Table>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell align="left" className={classes.tableCellProperty}>Profondeur maximale <small>(mm)</small></TableCell>
                                                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={foldingMaxWidth} yupValidation={foldingMaxWidthSchemas} onCickEdited={() => handelEditProperty('foldingMaxWidth', foldingMaxWidth)} onChange={(e) => setfoldingMaxWidth(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} >{properties?.foldingMaxWidth || ''}</TableCellEditable>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className={classes.tableCellProperty}>Longueur maximale <small>(mm)</small></TableCell>
                                                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={foldingMaxLength} yupValidation={foldingMaxLengthSchemas} onCickEdited={() => handelEditProperty('foldingMaxLength', foldingMaxLength)} onChange={(e) => setfoldingMaxLength(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} >{properties?.foldingMaxLength || ''}</TableCellEditable>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Afficher les vues de profils dans les DXF</TableCell>
                                    <TableCell>
                                        <Switch
                                            style={{ position: 'absolute' }}
                                            checked={properties.displayProfilDxf ? true : false}
                                            onChange={(e) => handelEditProperty('displayProfilDxf', e.target.checked)}
                                            color="primary"
                                            name="transportEnabled"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableRow>
                    <TableRow >
                        <TableCell align="left" className={classes.tableCellCategory}>Transport <Switch
                            style={{ position: 'absolute' }}
                            checked={properties.transportEnabled ? true : false}
                            onChange={(e) => handelEditProperty('transportEnabled', e.target.checked)}
                            color="primary"
                            name="transportEnabled"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        /></TableCell>
                        <Table>
                            <TableBody style={{ opacity: !properties.transportEnabled ? '35%' : null }} className={!properties.transportEnabled ? classes.nonClickable : ''}>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Largeur maximale transporteur <small>(mm)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={transportMaxWidth} yupValidation={transportMaxWidthSchema} onCickEdited={() => handelEditProperty('transportMaxWidth', transportMaxWidth)} onChange={(e) => settransportMaxWidth(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} align="left">{properties?.transportMaxWidth || ''}</TableCellEditable>

                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Longueur maximale transporteur <small>(mm)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={transportMaxLength} yupValidation={transportMaxLengthSchemas} onCickEdited={() => handelEditProperty('transportMaxLength', transportMaxLength)} onChange={(e) => settransportMaxLength(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} align="left">{properties?.transportMaxLength || ''}</TableCellEditable>

                                </TableRow>
                                <TableRow >
                                    <TableCell>Forfaits Transport <small>(€)</small><Button startIcon={<AddIcon onClick={handelclickAddTransportPlan} color='secondary' />}></Button></TableCell>
                                    <Table>
                                        <TableBody>
                                            {getTransportPlansTableBody()}

                                        </TableBody>
                                    </Table>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Délai de livraison <small>(jour(s))</small><Button startIcon={<AddIcon onClick={handelclickAddTransportDelay} color='secondary' />}></Button></TableCell>
                                    <TableBody>
                                        {getTransportDelaysTableBody()}
                                    </TableBody>
                                </TableRow>
                            </TableBody>
                        </Table>

                    </TableRow>
                    <TableRow >
                        <TableCell align="left" className={classes.tableCellCategory}>Retrait sur place autorisé <Switch
                            style={{ position: 'absolute' }}
                            checked={properties.pickUpAllowed ? true : false}
                            onChange={(e) => handelEditProperty('pickUpAllowed', e.target.checked)}
                            color="primary"
                            name="transportEnabled"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        /></TableCell>

                    </TableRow>
                    <TableRow >
                        <TableCell align="left" className={classes.tableCellCategory}>Vérification des géométries
                            <Switch
                                style={{ position: 'absolute' }}
                                checked={properties.formValidations.validationEnabled ? true : false}
                                onChange={(e) => handelEditProperty('formValidations.validationEnabled', e.target.checked)}
                                color="primary"

                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </TableCell>
                        <Table>
                            <TableBody style={{ opacity: !properties.formValidations.validationEnabled ? '35%' : null }} className={!properties.formValidations.validationEnabled ? classes.nonClickable : ''}>

                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Autoriser les contours multiples</TableCell>
                                    <TableCell>
                                        <Switch
                                            style={{ position: 'absolute' }}
                                            checked={properties.formValidations.isMultipleChainsAllow ? true : false}
                                            onChange={(e) => handelEditProperty('formValidations.isMultipleChainsAllow', e.target.checked)}
                                            color="primary"

                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ opacity: !properties.formValidations.isMultipleChainsAllow ? '35%' : null }} align="left" className={classes.tableCellProperty}>Nombre maximal autorisé</TableCell>

                                    <TableCellEditable style={{ opacity: !properties.formValidations.isMultipleChainsAllow ? '35%' : null }} className={!properties.formValidations.isMultipleChainsAllow ? classes.nonClickable : classes.tableCellValue} justify="start" align="left" value={multipleChainsAllowMaxNumber} yupValidation={MultipleChainsAllowMaxNumberSchemas} onCickEdited={() => handelEditProperty('formValidations.multipleChainsAllowMaxNumber', multipleChainsAllowMaxNumber)} onChange={(e) => setMultipleChainsAllowMaxNumber(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} align="left">{properties?.formValidations?.multipleChainsAllowMaxNumber || ''}</TableCellEditable>

                                </TableRow>

                            </TableBody>
                        </Table>

                    </TableRow>
                </TableBody>
            </Table>
            <ModalAddTransportPlan open={isNewTransportPlan} setdiagOpen={setisNewTransportPlan} />
            <ModalAddTransportDelay open={isNewTransportDelay} setdiagOpen={setisNewTransportDelay} />
        </TableContainer>
    )
}

export default PropertyHandler
