import { Chip, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      ...theme.typography.button,
      padding: theme.spacing(1),
    },
    chip: {
        margin: '0 auto',
        display: 'flex',
        width: 'max-content',
        marginBottom: '1rem',
        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
    }
  }));


function ChipTitle({children}) {
    const classes = useStyles();
    return (
        <Chip           
        className={classes.chip}
        label={<Typography align='center'  variant="button" gutterBottom>{children}</Typography>}
    />
    )
}

export default ChipTitle
