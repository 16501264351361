import React, { useEffect, useContext, useState } from 'react'
import axios from 'axios'
import MaterialHandler from './MaterialHandler';
import { MaterialsContext } from '../contexts/MaterialsContext'
import { AppBar, Grid, Tab, Tabs } from '@material-ui/core';
import PropertyHandler from './PropertyHandler';
import Other from './Control/Params/Other';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    if (value === index) {

        return (
            <Grid
                role="tabpanel"
                container
                justifyContent="center"
                style={{ marginTop: '2rem' }}
                {...other}
            >
                {value === index && children}

            </Grid>
        );
    }
    return null
}

function ParamHandler() {

    const [materials, dispach] = useContext(MaterialsContext);

    const [selectedTab, setSelectedTab] = useState(0)

    useEffect(() => {
        (async () => {
            const materials = await axios.get(process.env.REACT_APP_BASE_API + '/material')
            dispach({
                type: 'SET_MATERIALS',
                payload: materials.data.message
            })
        })()

    }, [dispach]);

    return (
        <>
            <Grid container justify="space-around" style={{ height: '100%', backgroundColor: '#f1f1f1' }}>
                <AppBar position="static">
                    <Tabs value={selectedTab} centered onChange={(e, v) => setSelectedTab(v)}>
                        <Tab label="Gestion des Materiaux" />
                        <Tab label="Propriétés Générales" />
                        <Tab label="Autres" />
                    </Tabs>
                </AppBar>
                <TabPanel value={selectedTab} index={0}>
                    <Grid item xs={12} sm={8}>
                        <MaterialHandler />
                    </Grid>
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <Grid item xs={12} sm={8}>
                        <PropertyHandler />
                    </Grid>
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    <Grid item xs={12} sm={8}>
                        <Other />
                    </Grid>
                </TabPanel>
            </Grid >
        </>

    )
}

export default ParamHandler
