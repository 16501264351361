import React, { useCallback } from 'react'

import { FormControlLabel, Grid, Typography, Switch } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';
import { UserZipCodeSchemas, ProTVANameSchemas, ProSiretSchemas, ProSocialNameSchemas, UserPhoneSchemas, UserHouseNumberSchemas, UserStreetSchemas, UserCitySchemas } from '../../modules/validation'
import TextFieldValidation from '../TextFieldValidation'



const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3)
    },
    typo: {

    },
    section: {
        marginTop: theme.spacing(4),

    }

}))

function UserProForm({ setUserProInformations, userProInformations }) {

    const classes = useStyles();

    const handleChange = useCallback(
        (e, key) => {
            setUserProInformations({ ...userProInformations, [key]: e.target.value })
        },
        [setUserProInformations, userProInformations],
    )

    const handelChangeSameAdresse = useCallback(
        (e) => {
            if (e.target.checked) {
                setUserProInformations({
                    ...userProInformations,
                    deliveryHouseNumber: '',
                    deliveryStreet: '',
                    deliveryCity: '',
                    deliveryZipCode: '',
                    sameAdresse: e.target.checked
                })
            } else {
                setUserProInformations({
                    ...userProInformations,
                    sameAdresse: e.target.checked
                })
            }

        },
        [setUserProInformations, userProInformations],
    )

    return (
        <>
            <div className={classes.section}>
                <Typography className={classes.typo} variant="overline" display="block" align="center" gutterBottom>
                    Informations légales
                </Typography>

                <Grid className={classes.root} container justify='center' spacing={6}>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Raison Sociale *" yupValidation={ProSocialNameSchemas} onChange={(e) => handleChange(e, 'socialReason')} type="text" value={userProInformations.socialReason} />
                    </Grid>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Numero de SIRET *" yupValidation={ProSiretSchemas} onChange={(e) => handleChange(e, 'siret')} type="text" value={userProInformations.siret} />
                    </Grid>
                </Grid>

                <Grid className={classes.root} container justify='center' spacing={6}>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="N° de TVA *" yupValidation={ProTVANameSchemas} onChange={(e) => handleChange(e, 'tva')} type="text" value={userProInformations.tva} />
                    </Grid>
                    <Grid item xs={10} md={4} container justify="center">
                    </Grid>
                </Grid>
            </div>
            <div className={classes.section}>
                <Typography className={classes.typo} variant="overline" display="block" align="center" gutterBottom>
                    Adresse de facturation
                </Typography>
                <Grid className={classes.root} container justify='center' spacing={6}>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Numéro de voie" yupValidation={UserHouseNumberSchemas} onChange={(e) => handleChange(e, 'houseNumber')} type="text" value={userProInformations.houseNumber} />
                    </Grid>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Rue *" yupValidation={UserStreetSchemas} onChange={(e) => handleChange(e, 'street')} type="text" value={userProInformations.street} />
                    </Grid>

                </Grid>
                <Grid className={classes.root} container justify='center' spacing={6}>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Ville *" yupValidation={UserCitySchemas} onChange={(e) => handleChange(e, 'city')} type="text" value={userProInformations.city} />
                    </Grid>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth label="Code postal *" yupValidation={UserZipCodeSchemas} onChange={(e) => handleChange(e, 'zipCode')} type="text" value={userProInformations.zipCode} />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.section}>
                <Grid container>
                    <Grid item xs={10} md={4}>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography className={classes.typo} variant="overline" display="block" align="center" gutterBottom>
                            Adresse de livraison
                        </Typography>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={userProInformations.sameAdresse}
                                    onChange={handelChangeSameAdresse}
                                    name="sameAdresse"
                                    color="primary"
                                />
                            }
                            label="Même que adresse postal"
                        />
                    </Grid>

                </Grid>

                <Grid className={classes.root} container justify='center' spacing={6}>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth disabled={userProInformations.sameAdresse} label="Numero de voie" yupValidation={UserHouseNumberSchemas} onChange={(e) => handleChange(e, 'deliveryHouseNumber')} type="text" value={userProInformations.deliveryHouseNumber} />
                    </Grid>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth disabled={userProInformations.sameAdresse} label="Rue *" yupValidation={UserStreetSchemas} onChange={(e) => handleChange(e, 'deliveryStreet')} type="text" value={userProInformations.deliveryStreet} />
                    </Grid>

                </Grid>
                <Grid className={classes.root} container justify='center' spacing={6}>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth disabled={userProInformations.sameAdresse} label="Ville *" yupValidation={UserCitySchemas} onChange={(e) => handleChange(e, 'deliveryCity')} type="text" value={userProInformations.deliveryCity} />
                    </Grid>
                    <Grid item xs={10} md={4} container justify="center">
                        <TextFieldValidation fullWidth disabled={userProInformations.sameAdresse} label="Code postal *" yupValidation={UserZipCodeSchemas} onChange={(e) => handleChange(e, 'deliveryZipCode')} type="text" value={userProInformations.deliveryZipCode} />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.section}>
                <Typography className={classes.typo} variant="overline" display="block" align="center" gutterBottom>
                    informations de contact
                </Typography>
                <Grid className={classes.root} container justify='center' spacing={6}>
                    <Grid item xs={10} md={4}>
                        <TextFieldValidation fullWidth label="Téléphone *" yupValidation={UserPhoneSchemas} onChange={(e) => handleChange(e, 'phone')} type="text" value={userProInformations.phone} />
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <TextFieldValidation fullWidth label="Téléphone mobile" yupValidation={UserPhoneSchemas} onChange={(e) => handleChange(e, 'mobile')} type="text" value={userProInformations.mobile} />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default UserProForm
