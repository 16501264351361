import React, { useReducer } from 'react';
const SnackbarContext = React.createContext([[], () => {}]);

const initialState = {
    open: false,
    content: '',
    type: 'success',
    duration: 6000
}

const reducer =  (state, action) => {
  switch (action.type) {
    case "SET_SNACKBAR":
      return action.payload
    default:
      throw new Error();
  }
};


const SnackbarProvider = (props) => {
  const [snackbar, dispatch] = useReducer(reducer, initialState);



  return (
    <SnackbarContext.Provider value={[snackbar, dispatch]}>
      {props.children}
    </SnackbarContext.Provider>
  );
}

export { SnackbarContext, SnackbarProvider };