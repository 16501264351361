import { Dialog, LinearProgress, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, TextField } from '@material-ui/core'
import React, { useState, useCallback, useContext } from 'react'
import axios from 'axios'
import { PropertiesContext } from '../contexts/PropertiesContext';

function ModalAddTransportDelay({ open, setdiagOpen }) {

    const [loading, setloading] = useState(false);
    const [properties, dispatch] = useContext(PropertiesContext)
    const [time, settime] = useState('');
    const [reduction, setreduction] = useState('');

    const resetForm = useCallback(
        () => {
            settime('')
            setreduction('')
        },
        [],
    )

    const handelClickAdd = useCallback(
        () => {
            (async () => {
                setloading(true)
                const newproperties = { ...properties, deliveryTimes: [...properties.deliveryTimes, { time, reduction }] }
                try {

                    await axios.put(process.env.REACT_APP_BASE_API + '/propertie/' + properties._id, newproperties)
                    dispatch({
                        type: 'SET_PROPERTY',
                        payload: newproperties
                    })
                    setloading(false)
                    resetForm()
                    setdiagOpen(false)
                } catch (e) {

                }

            })()

        },
        [dispatch, properties, reduction, resetForm, setdiagOpen, time],
    )

    return (
        <Dialog open={open} onClose={() => setdiagOpen(false)} aria-labelledby="form-dialog-title">
            {loading ? <LinearProgress /> : null}
            <DialogTitle id="form-dialog-title" align="center">Ajouter un delai de livraison</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Veuillez remplir les informations suivantes.
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <TextField id="add-property-transport-plan" label="Délais en jours" helperText={`Délais de livraison`} value={time} onChange={(e) => settime(e.target.value)} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField id="add-property-transport-plan" label="Réduction" helperText={`Réduction en %`} value={reduction} onChange={(e) => setreduction(e.target.value)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setdiagOpen(false)}>
                    Annuler
                </Button>
                <Button variant="outlined" onClick={handelClickAdd} color="primary">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalAddTransportDelay
