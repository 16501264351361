import { Checkbox, FormControlLabel, Grid, Paper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useContext, useCallback, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'

const useStyles = makeStyles((theme) => ({
    paper: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem'
    },
    line: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1),
            textAlign: 'center'
        }
    }
}))


function ConfiguratorConfigCircle({ calculateLength, setcurentDraggingObjectPosition, selectedForm }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [x, setx] = useState(null);
    const [y, sety] = useState(null);
    const [d, setd] = useState(null);

    const classes = useStyles();

    const handelChange = useCallback(
        (e) => {
            setd(e.target.value)
        },
        [],
    )


    const handleBlur = useCallback(() => {
        try {

            // eslint-disable-next-line no-eval
            const evaluateValue = eval(d);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_VALUE',
                    payload: {
                        key: 'forms',
                        data: configurator.forms.map(item => item.id !== configurator.selectedForm ? item : { ...item, radius: evaluateValue / 2, realRadius: evaluateValue / 2 })
                    }

                })
                setd(evaluateValue)
            }
        } catch (e) {
            setd(null)
        }
    }, [configurator.forms, configurator.selectedForm, d, dispatch])

    const handelChangeX = (e) => setx(e.target.value);

    const handleBlurX = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(x);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            x: evaluateValue
                        }
                    }

                })
                setcurentDraggingObjectPosition((v) => ({ ...v, x: evaluateValue }))

            }
            setx(evaluateValue)
        } catch (e) {
            setx(null)
        }
    }, [configurator.selectedForm, dispatch, setcurentDraggingObjectPosition, x])

    const handelChangeY = (e) => sety(e.target.value);

    const handleBlurY = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(y);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            y: evaluateValue
                        }
                    }

                })
                setcurentDraggingObjectPosition((v) => ({ ...v, y: evaluateValue }))
            }
            sety(evaluateValue)
        } catch (e) {
            sety(null)
        }
    }, [configurator.selectedForm, dispatch, setcurentDraggingObjectPosition, y])

    const handleChangeGraved = useCallback((e) => {
        dispatch({
            type: 'SET_VALUE_FORM',
            payload: {
                form: configurator.selectedForm,
                data: {
                    graved: e.target.checked,
                    unionType: e.target.checked ? 'del' : selectedForm.unionType

                }
            }
        })
    }, [configurator.selectedForm, dispatch, selectedForm.unionType])

    const getValue = useCallback(
        () => {
            return d === null ? selectedForm.realRadius * 2 : d;
        },
        [d, selectedForm.realRadius],
    )

    const getValueX = useCallback(
        () => {
            return x === null ? selectedForm.realx : x
        },
        [selectedForm.realx, x],
    )

    const getValueY = useCallback(
        () => {
            return y === null ? selectedForm.realy : y
        },
        [selectedForm.realy, y],
    )

    return (
        <Grid container justify="center" alignItems='center' >
            <Grid item xs={5} md={2} ld={2} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-circle-r" label="Diamètre (mm)" value={getValue()} onBlur={handleBlur} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlur()} margin="dense" onChange={handelChange} />
                </Paper>
            </Grid>

            <Grid item xs={5} md={2} ld={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-circle-y" label="X (mm)" value={getValueX()} onBlur={handleBlurX} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurX()} margin="dense" onChange={handelChangeX} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} ld={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-circle-x" label="Y (mm)" value={getValueY()} onBlur={handleBlurY} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurY()} margin="dense" onChange={handelChangeY} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} ld={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedForm.graved}
                                onChange={handleChangeGraved}
                                name="graved"
                                color="primary"
                            />
                        }
                        label="Graver"
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}

export default ConfiguratorConfigCircle
