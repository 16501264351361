import { Grid, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useCallback } from 'react'
import { CatalogSvgContext, useGetCatalogSvg } from '../../../contexts/CatalogSvgContext';
import CatalogSvgCard from './CatalogSvgCard';

function DisplayCatalogSvg({ filters = {}, handleValidateDelete, delectable, selectable, onSelect }) {

    const [{ catalogsSvg }] = useContext(CatalogSvgContext);
    const { getCatalogSvg } = useGetCatalogSvg()
    useEffect(() => {
        if (!catalogsSvg || !catalogsSvg.length) {
            getCatalogSvg()
        }
    }, []);

    const filtredCatalogSvg = useCallback(() => {
        let filtredCatalogSvg = catalogsSvg;
        if (filters.type && filters.type.length) {
            filtredCatalogSvg = filtredCatalogSvg.filter(catalogSvg => catalogSvg.type.some(t => filters.type.includes(t)))
        }
        if (filters.family && filters.family.length) {
            filtredCatalogSvg = filtredCatalogSvg.filter(catalogSvg => catalogSvg.family.some(t => filters.family.includes(t)))
        }
        return filtredCatalogSvg;
    }, [catalogsSvg, filters.family, filters.type])

    const getCatalogSvgToDisplay = useCallback(() => {
        const catalogSvg = filtredCatalogSvg();
        if (catalogSvg && catalogSvg.length) {
            return catalogSvg.map(catalogSvg => <Grid key={catalogSvg._id} item xs={12} sm={3}><CatalogSvgCard data={catalogSvg} delectable={delectable} selectable={selectable} onSelect={onSelect} handleValidateDelete={handleValidateDelete} /></Grid>)
        } else {
            return <Grid container justify='center'>
                <Typography align="center" display='block' variant="button" gutterBottom>
                    Aucun de motifs à afficher
                </Typography>
            </Grid>
        }
    }, [delectable, filtredCatalogSvg, handleValidateDelete, onSelect, selectable])

    return (
        <Grid container spacing={2}>
            {getCatalogSvgToDisplay()}
        </Grid>
    )
}

export default DisplayCatalogSvg