const { optimize } = require('svgo');

const optimizeSvg = (svgString, opt = {}) => {
    const result = optimize(svgString, opt);
    return result.data;
}

var markerRegEx = /[MmLlSsQqLlHhVvCcSsQqTtAaZz]/g;
var digitRegEx = /-?[0-9]*\.?\d+/g;

function svgPathToCommands(str) {
    var results = [];
    var match; while ((match = markerRegEx.exec(str)) !== null) { results.push(match); };
    return results
        .map(function (match) {
            return {
                marker: str[match.index],
                index: match.index
            };
        })
        .reduceRight(function (all, cur) {
            var chunk = str.substring(cur.index, all.length ? all[all.length - 1].index : str.length);
            return all.concat([
                {
                    marker: cur.marker,
                    index: cur.index,
                    chunk: (chunk.length > 0) ? chunk.substr(1, chunk.length - 1) : chunk
                }
            ]);
        }, [])
        .reverse()
        .map(function (command) {
            var values = command.chunk.match(digitRegEx);
            return { marker: command.marker, values: values ? values.map(parseFloat) : [] };
        })
}

function commandsToSvgPath(commands) {
    return commands.map(function (command) {
        return command.marker + ' ' + command.values.join(',');
    }).join(' ').trim();
}


const formatSvgPath = (path) => {
    const datas = svgPathToCommands(path)

    const finalData = [];

    datas.forEach((data, index, array) => {
        if (data.marker === 'c' && data.values.length > 6) {
            const sliced = [];
            for (let i = 0; i < data.values.length; i += 6) {

                sliced.push(data.values.slice(i, i + 6))
            }
            for (let i = 0; i < sliced.length; i++) {
                finalData.push({ values: sliced[i], marker: data.marker });
            }
        } else if (data.marker === 'l' && data.values.length > 2) {
            const sliced = [];
            for (let i = 0; i < data.values.length; i += 2) {

                sliced.push(data.values.slice(i, i + 2))
            }
            for (let i = 0; i < sliced.length; i++) {
                finalData.push({ values: sliced[i], marker: data.marker });
            }
        }
        else {
            finalData.push(data);
        }
    })
    return commandsToSvgPath(finalData);
}

const ajustDimensionsRect = (svgString) => {
    const regexDimensions = /(<svg[^>]*width="(\d+)"[^>]*height="(\d+)"[^>]*>)/;
    const wRect = /width="100%"/;
    const hRect = /height="100%"/;

    const dimensionsMatch = svgString.match(regexDimensions);
    if (!dimensionsMatch) {
        console.error("Erreur: Les dimensions du SVG sont introuvables.");
        return svgString;
    }

    const width = dimensionsMatch[2];
    const height = dimensionsMatch[3];

    return svgString.replace(wRect, `width="${width}"`).replace(hRect, `height="${height}"`);
}


function ajustDimensionsRect2(svgString) {
    // Utilise une regex pour remplacer les valeurs de width et height par 100%
    var updatedSVG = svgString.replace(/width="\d*\.?\d*(px|em|%)?"/, 'width="100%"')
        .replace(/height="\d*\.?\d*(px|em|%)?"/, 'height="100%"');

    return updatedSVG;
}

function extractGreenStrokeGElements(svgString) {
    var results = [];
    var regex = /<g[^>]*stroke="rgb\(0,\s*255,\s*0\)"[^>]*>[\s\S]*?<\/g>/g;
    var match;

    while ((match = regex.exec(svgString)) !== null) {
        results.push(match[0]);
    }

    return results;
}

function insertGElementsBeforeEnd(svgString, gElements) {
    // Joint tous les éléments g en une seule chaîne
    var gElementsString = gElements.join('');

    // Insère les éléments g avant la balise fermante </svg>
    return svgString.replace(/<\/svg>/, gElementsString + '</svg>');
}

function mirrorSVG(svgString) {
    // Parser le SVG
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(svgString, "image/svg+xml");
    let svgElement = xmlDoc.documentElement;

    // Appliquer la transformation rotate3d
    svgElement.style.transform = "rotate3d(1, 0, 0, 180deg)";

    // Exporter le SVG modifié
    let serializer = new XMLSerializer();
    let mirroredSVGString = serializer.serializeToString(svgElement);
    return mirroredSVGString;
}


/* const getViewBox = (svg) => {
    let viewBox = svg.match(/(?<=viewBox=")[\s\S]*?(?=")/)[0]
    if (viewBox) {
        viewBox = viewBox.split(' ')
        return { left: +viewBox[0], top: +viewBox[1], right: +viewBox[2], bottom: +viewBox[3] }
    }
    return { left: 0, top: 0, right: 0, bottom: 0 }
} */


/* const getPaddingViewBox = (strSVG) => {
    const oParser = new DOMParser();
    let svg = oParser.parseFromString(strSVG, "image/svg+xml");
    svg = svg.documentElement;
    const hanchor = document.getElementById("svg-hidden");
    hanchor.style.display = 'block';
    hanchor.style.position = 'absolute';
    hanchor.style.visibility = 'hidden';
    hanchor.appendChild(svg);
    svg.removeAttribute('viewBox');

    // Set default coordinates
    const coords = {
        top: Infinity,
        left: Infinity,
        bottom: -Infinity,
        right: -Infinity
    };

    // Filter SVG to visible elements
    const filterize = newSvg => {
        const newest = [...newSvg.children].filter(x => x.tagName !== 'defs' && x.tagName !== 'style' && x.tagName !== 'title' && x.tagName !== 'desc');
        if ((newest.length === 1 && newest.childElementCount > 0) || newest[0].tagName === 'g') {
            return filterize(newest[0]);
        }

        return newest.filter(x => {
            return (x.getBoundingClientRect().top !== 0 && x.getBoundingClientRect().left !== 0 && x.getBoundingClientRect().bottom !== 0 && x.getBoundingClientRect().right !== 0)
        });

    }

    // Get coordinates of SVG elements
    const u = filterize(svg)
    u.forEach(x => {
        const { top, left, bottom, right } = x.getBoundingClientRect();
        if (top < coords.top) {
            coords.top = x.getBoundingClientRect().top;
        }
        if (left < coords.left) {
            coords.left = x.getBoundingClientRect().left;
        }
        if (right > coords.right) {
            coords.right = x.getBoundingClientRect().right;
        }
        if (bottom > coords.bottom) {
            coords.bottom = x.getBoundingClientRect().bottom;
        }
    });
    const scropedViewBox = { left: +coords.left.toFixed(2), top: +coords.top.toFixed(2), right: +(coords.right - coords.left).toFixed(2), bottom: +(coords.bottom - coords.top).toFixed(2) }
    const originalViewBox = getViewBox(strSVG)
    hanchor.removeChild(svg);
    return { left: originalViewBox.left + scropedViewBox.left, top: originalViewBox.top + scropedViewBox.top, right: originalViewBox.right - scropedViewBox.right, bottom: originalViewBox.bottom - scropedViewBox.bottom }
} */

const scropSVG = (strSVG) => {
    const WIDTH = 100;
    const HEIGHT = null;


    const oParser = new DOMParser();
    let svg = oParser.parseFromString(strSVG, "image/svg+xml");
    svg = svg.documentElement;
    const hanchor = document.getElementById("svg-hidden");
    hanchor.style.display = 'block';
    hanchor.style.position = 'absolute';
    hanchor.style.visibility = 'hidden';
    hanchor.appendChild(svg);
    svg.removeAttribute('viewBox');

    // Set default coordinates
    const coords = {
        top: Infinity,
        left: Infinity,
        bottom: -Infinity,
        right: -Infinity
    };

    // Filter SVG to visible elements
    const filterize = newSvg => {
        const newest = [...newSvg.children].filter(x => x.tagName !== 'defs' && x.tagName !== 'style' && x.tagName !== 'title' && x.tagName !== 'desc');
        if ((newest.length === 1 && newest.childElementCount > 0) || newest[0].tagName === 'g') {
            return filterize(newest[0]);
        }

        return newest.filter(x => {
            return (x.getBoundingClientRect().top !== 0 && x.getBoundingClientRect().left !== 0 && x.getBoundingClientRect().bottom !== 0 && x.getBoundingClientRect().right !== 0)
        });

    }

    // Get coordinates of SVG elements
    const u = filterize(svg)
    u.forEach(x => {
        const { top, left, bottom, right } = x.getBoundingClientRect();
        if (top < coords.top) {
            coords.top = x.getBoundingClientRect().top;
        }
        if (left < coords.left) {
            coords.left = x.getBoundingClientRect().left;
        }
        if (right > coords.right) {
            coords.right = x.getBoundingClientRect().right;
        }
        if (bottom > coords.bottom) {
            coords.bottom = x.getBoundingClientRect().bottom;
        }
    });

    // Set viewBox based on coordinates
    svg.setAttribute('viewBox', `${coords.left.toFixed(2)} ${coords.top.toFixed(2)} ${(coords.right - coords.left).toFixed(2)} ${(coords.bottom - coords.top).toFixed(2)}`);

    // Set given width OR height
    if (WIDTH) {
        svg.setAttribute('width', WIDTH);
        svg.removeAttribute('height');
    }
    if (HEIGHT) {
        svg.setAttribute('height', HEIGHT);
        svg.removeAttribute('width');
    }
    return svg.outerHTML;

}

export { formatSvgPath, optimizeSvg, scropSVG, ajustDimensionsRect, ajustDimensionsRect2, extractGreenStrokeGElements, insertGElementsBeforeEnd, mirrorSVG };