import React, { useState } from 'react';
import { Button, Typography, Dialog, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';

const ConfirmationModalWrapper = ({ children, onConfirm, confirmationMessage = 'Êtes-vous sûr ?', confirmationTexte = [], disabled = false }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefuse = (e) => {
    e.stopPropagation();
    handleClose();
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <div onClick={handleOpen}>
      {children}
      <Dialog
        open={open}
        onClose={handleClose}

      >
        <DialogContent>
          <Typography variant="h6" align="center">{confirmationMessage}</Typography>
          {confirmationTexte && confirmationTexte.map((texte) => <Typography variant="body2" align="center">{texte}</Typography>)}

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
            <Button onClick={handleRefuse} color="primary" variant="outlined" style={{ marginRight: '10px' }}>
              Retour
            </Button>
            <Button onClick={handleConfirm} variant="contained" color="primary">
              Valider
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConfirmationModalWrapper;
