import React, { useContext, useReducer } from 'react';
import { useGet, usePost, usePut } from '../modules/request';
import { SnackbarContext } from './SnackbarContext';


const UserContext = React.createContext([{}, () => { }]);

const initialState = {
  me: {},
  users: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USERS":
      return { users: action.payload, me: state.me }
    case "SET_PROPERTY":
      return {
        me: state.me, users: state.users.map(user => user._id !== action.payload.id ? user : {
          ...user,
          [action.payload.key]: action.payload.value
        })
      }
    case "SET_ME":
      return { me: action.payload, users: state.users }
    default:
      throw new Error();
  }
};


const useGetUsers = () => {
  const [state, dispatch] = useContext(UserContext);
  const get = useGet()
  return {
    getUsers: async () => {
      if (state.users.length <= 0) {
        const res = await get('/user')
        dispatch({
          type: 'SET_USERS',
          payload: res.data.message
        })
      }

    },
  };

}

const useGetMe = () => {
  const [state, dispatch] = useContext(UserContext);
  const get = useGet()
  return {
    getMe: async () => {
      const res = await get('/user/me');
      dispatch({
        type: 'SET_ME',
        payload: res.data.message
      })

    },
  };

}

const useEditUser = () => {
  const [state, dispatch] = useContext(UserContext);
  const put = usePut()
  return {
    editUser: async (id, key, value) => {
      await put(`/user/${id}`, { ...state.users.find(user => user._id === id), [key]: value, })
      dispatch({
        type: 'SET_PROPERTY',
        payload: {
          key: key,
          value: value,
          id: id
        }
      })
    }
  }
}

const useEditMe = () => {
  const [, dispatch] = useContext(UserContext);
  const [, dispatchSnackbar] = useContext(SnackbarContext)
  const put = usePut()
  return {
    editMe: async (id, data) => {
      const res = await put(`/user/${id}`, data)
      dispatch({
        type: 'SET_ME',
        payload: res.data.message
      })
      dispatchSnackbar({
        type: 'SET_SNACKBAR',
        payload: {
          open: true,
          content: 'profil mis à jour !',
          type: 'success',
          duration: 5000
        }
      })
    }
  }
}


const useResetPasswordUser = () => {
  const post = usePost()
  return {
    resetPasswordUser: async (token, password) => {
      try {
        const response = await post(`/user/forgot/password/reset/${token}`, { password })
        return response.data.success
      } catch (e) {
        return false
      }

    }
  }
}

const useChangePasswordUser = () => {
  const post = usePost()
  const [, dispatchSnackbar] = useContext(SnackbarContext)
  return {
    changePasswordUser: async (id, password) => {
      try {
        const response = await post(`/user/${id}/password`, { password })
        dispatchSnackbar({
          type: 'SET_SNACKBAR',
          payload: {
            open: true,
            content: 'Mot de passe mis à jour !',
            type: 'success',
            duration: 5000
          }
        })
        return response.data.success
      } catch (e) {
        return false
      }

    }
  }
}





function UserProvider(props) {
  const [users, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={[users, dispatch]}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider, useGetUsers, useEditUser, useResetPasswordUser, useGetMe, useEditMe, useChangePasswordUser };