import React, { useContext, useCallback, useState, useEffect } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, Typography, useTheme, useMediaQuery, Stepper, Step, StepLabel } from '@material-ui/core'
import ConfiguratorMenu from '../Configurator/ConfiguratorMenu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';
import ConfiguratorDisplayPrice from '../Configurator/ConfiguratorDisplayPrice';
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import ChipTitle from '../../display/ChipTitle';
import { useHistory } from 'react-router';
import CatalogMenuForm from './CatalogMenuForm';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import { CatalogContext, useGetCatalog } from '../../../contexts/CatalogContext'
import { MaterialsContext } from '../../../contexts/MaterialsContext';
import CatalogCard from './CatalogCard'
import DialogConfigurationMenu from '../../Dialog/DialogConfigurationMenu';
import CatalogImagePreview from '../../Control/CatalogImagePreview';
import uniqid from 'uniqid'
import makerjs from 'makerjs';
import { Alert, AlertTitle } from '@material-ui/lab';
import { median } from '../../../modules/geo';
import { getDeltaL } from '../../../modules/deltaL';

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.typography.button,
        padding: theme.spacing(1),
    },
    chip: {
        margin: '0 auto',
        display: 'flex',
        width: 'max-content',
        marginBottom: '1rem'
    },
    buttonsPaper: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1)
    }
}));

function getSteps() {
    return process.env.REACT_APP_FOLDING_DISABLE_CUT ? ['Choix de la Pièce', 'Choix des Dimensions', 'Visualisation'] : ['Choix de la Pièce', 'Choix des Dimensions', 'Ajout de découpes'];
}


function CatalogControlPannel({ textModels, settextModels, refStage }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext);
    const [isopen, setisopen] = useState(false);
    const [catalogsToDisplay, setcatalogsToDisplay] = useState([]);
    const [openDialog, setopenDialog] = useState(true);
    const [firstVisit, setfirstVisit] = useState(true);
    const classes = useStyles()
    const history = useHistory()
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    const { getCatalog } = useGetCatalog();
    const [{ catalogs, step, catalogConfiguration, selectedCatalog, hasError }, dispatchCatalog] = useContext(CatalogContext);
    const [materials] = useContext(MaterialsContext)

    const steps = getSteps();


    useEffect(() => {
        getCatalog()
    }, [])

    const getCatalogData = useCallback(
        () => {
            const catalog = catalogs.find(catalog => catalog._id === selectedCatalog)
            if (catalog) return catalog.fileConfig
            return ''
        },
        [catalogs, selectedCatalog],
    )

    const handelClickGoBack = useCallback(
        () => {
            dispatch({
                type: 'RESET'
            })
            history.push('/')
        },
        [dispatch, history],
    )

    const haveCompletedConfiguration = useCallback(
        () => {
            return configurator.materialId && configurator.shadeId && configurator.thicknessId && configurator.nbPiece
        },
        [configurator.materialId, configurator.nbPiece, configurator.shadeId, configurator.thicknessId],
    )

    useEffect(() => {
        if (haveCompletedConfiguration()) {
            setopenDialog(false)
            setfirstVisit(false)
            const selectedThickness = materials.find(material => material._id === configurator.materialId)?.shades.find(shade => shade._id === configurator.shadeId)?.thicknesses.find(thickness => thickness._id === configurator.thicknessId)
            if (selectedThickness) {
                setcatalogsToDisplay(catalogs.filter(catalog => catalog.thickness.includes(selectedThickness.value)).map(catalog => <CatalogCard key={catalog._id} data={catalog} />))
            } else {
                setcatalogsToDisplay([])
            }
        }
    }, [catalogs, configurator.materialId, configurator.shadeId, configurator.thicknessId, haveCompletedConfiguration, materials])

    const handleClickPrev = () => {
        dispatchCatalog({
            type: 'SET_STEP',
            payload: step - 1
        })
    }

    const handleClickNext = () => {
        const nextStep = step + 1;
        if (nextStep === 2) {
            let mainFormheight = 0;
            let mainFormWidth = 0;
            const subForms = [];
            const linesH = []
            let foldingLines = [];
            const labels = [];

            let lines = catalogConfiguration.filter(config => config.entity.type === 'line' && !config.entity.horizontal)
            const selectedThickness = materials.find(material => material._id === configurator.materialId)?.shades.find(shade => shade._id === configurator.shadeId)?.thicknesses.find(thickness => thickness._id === configurator.thicknessId)

            let lastline = [...lines].sort((a, b) => b.entity.linesDep.length - a.entity.linesDep.length)[0];
            let cache = {};

            let anglesByOrder = catalogConfiguration.filter(config => config.entity.type === 'angle' && !config.entity.horizontal);
            anglesByOrder = [...anglesByOrder].sort((a, b) => a.entity.linesDep.length - b.entity.linesDep.length);
            [...lines].sort((a, b) => a.entity.linesDep.length - b.entity.linesDep.length).forEach((line, index, array) => {
                let deltaL = 0;
                if (index === 0) {
                    deltaL = getDeltaL(selectedThickness.value, anglesByOrder[0].value) / 2;
                } else if (array[index + 1]) {
                    deltaL += getDeltaL(selectedThickness.value, anglesByOrder[index - 1].value) / 2;
                    deltaL += getDeltaL(selectedThickness.value, anglesByOrder[index].value) / 2;
                } else {
                    deltaL = getDeltaL(selectedThickness.value, anglesByOrder[index - 1].value) / 2;
                }

                cache[line.entityId] = line.value + deltaL;
            });



            const reorderLineH = (catalogConfiguration) => {
                const linesH = JSON.parse(JSON.stringify(catalogConfiguration)).filter(config => (config.entity.type === 'line' && config.entity.horizontal) || config.entity.type === 'longer');
                const longerLine = linesH.find(line => line.entity.type === 'longer');
                const linesBeforeL = linesH.filter(line => !line.entity.linesDepH.includes(longerLine.entityId) && line.entityId !== longerLine.entityId)
                linesBeforeL.sort((a, b) => b.entity.linesDepH.length - a.entity.linesDepH.length)
                const lineAfterL = linesH.filter(line => line.entity.linesDepH.includes(longerLine.entityId));
                lineAfterL.sort((a, b) => a.entity.linesDepH.length - b.entity.linesDepH.length)
                const allLines = [...linesBeforeL, longerLine, ...lineAfterL];
                // allLines.forEach((line, i, array) => {
                //     if (i === 0) {
                //         array[i].entity.linesDepH = [];
                //     } else {
                //         array[i].entity.linesDepH = [...array[i - 1].entity.linesDepH, array[i - 1].entityId]
                //     }
                // })
                return allLines

            }
            const reorderAngleH = (catalogConfiguration, reorderLinesH) => {
                const anglesH = JSON.parse(JSON.stringify(catalogConfiguration)).filter(config => config.entity.type === 'angle' && config.entity.horizontal);
                const longerLine = reorderLinesH.find(config => config.entity.type === 'longer');
                const anglesHBeforeL = anglesH.filter(angle => !angle.entity.linesDepH.includes(longerLine.entityId))
                const anglesHAfterL = anglesH.filter(angle => angle.entity.linesDepH.includes(longerLine.entityId))

                anglesHBeforeL.sort((a, b) => b.entity.linesDepH.length - a.entity.linesDepH.length).forEach((angle, i, array) => {

                    if (!angle.entity.linesDepH.length) {
                        longerLine.entity.linesDepH.forEach(depH => {
                            if (!angle.entity.linesDepH.includes(depH)) {
                                array[i].entity.linesDepH.push(depH)
                            }
                        })
                        //array[i].entity.linesDepH.push(longerLine.entityId);
                    } else {
                        angle.entity.linesDepH.forEach(depH => {
                            const corespondingLine = reorderLinesH.find(lineH => lineH.entity.entityId === depH)
                            corespondingLine?.entity.linesDepH(dep => {
                                if (!angle.entity.linesDepH.includes(dep)) {
                                    array[i].entity.linesDepH.push(dep)
                                }
                            })

                        })
                    }
                })
                anglesHAfterL.sort((a, b) => a.entity.linesDepH.length - b.entity.linesDepH.length);
                anglesHAfterL.forEach((angleAfterL, i, array) => {
                    longerLine.entity.linesDepH.forEach(depH => {
                        if (!angleAfterL.entity.linesDepH.includes(depH)) {
                            array[i].entity.linesDepH.push(depH)
                        }
                    })
                    for (let y = 0; y <= i; y++) {
                        array[y].entity.linesDepH.forEach(depH => {
                            if (!angleAfterL.entity.linesDepH.includes(depH)) {
                                array[i].entity.linesDepH.push(depH)
                            }
                        })
                    }

                })
                return [...anglesHBeforeL, ...anglesHAfterL];
            }

            const reorderLinesH = reorderLineH(catalogConfiguration);

            if (reorderLinesH.length > 1) {

                const reorderAnglesH = reorderAngleH(catalogConfiguration, reorderLinesH)

                reorderLinesH.forEach((line, index, array) => {
                    let deltaL = 0;
                    if (index === 0) {
                        deltaL = getDeltaL(selectedThickness.value, reorderAnglesH[0].value) / 2;
                    } else if (array[index + 1]) {
                        deltaL += getDeltaL(selectedThickness.value, reorderAnglesH[index - 1].value) / 2;
                        deltaL += getDeltaL(selectedThickness.value, reorderAnglesH[index].value) / 2;
                    } else {
                        deltaL = getDeltaL(selectedThickness.value, reorderAnglesH[index - 1].value) / 2;
                    }

                    cache[line.entityId] = line.value + deltaL;
                })
            }


            const catalogConfigurationWithDeltaL = [
                ...catalogConfiguration.map(config => cache[config.entityId] ? { ...config, value: cache[config.entityId] } : { ...config })
            ]

            const foldingLinesWithOutDeltL = [];

            const pp = reorderLineH(catalogConfiguration);
            const ppp = reorderAngleH(catalogConfiguration, pp);

            const pppp = catalogConfiguration.filter(config => !config.entity.horizontal).filter(config => config.entity.type !== 'longer');

            const OrderedCatalogConfiguration = [...pp, ...ppp, ...pppp];
            OrderedCatalogConfiguration.forEach(config => {
                switch (config.entity.type) {
                    case 'angle':
                        const entity = config.entity;

                        const foldConfig = { a: { x: 0, y: 0 }, b: { x: 0, y: 0 } };

                        if (config.entity.horizontal) {
                            let afterL = false;
                            config.entity.linesDepH.forEach(e => {
                                const entity = OrderedCatalogConfiguration.find(c => c.entity._id === e);
                                if (entity.entity.type === 'longer') {
                                    afterL = true
                                }
                                foldConfig.a.x += entity.value;
                            })
                            if (!afterL) {
                                foldConfig.a.x = -foldConfig.a.x
                            }
                            foldConfig.b.x = foldConfig.a.x;
                            const entityV = OrderedCatalogConfiguration.find(c => JSON.stringify(c.entity.linesDep) === JSON.stringify(config.entity.linesDep));

                            entity.linesDep.forEach(lineId => {
                                const dep = OrderedCatalogConfiguration.find(entity => entity.entityId === lineId)
                                foldConfig.a.y += dep.value
                            })

                            foldConfig.b.y = foldConfig.a.y + entityV.value
                            foldingLinesWithOutDeltL.push({ horizontal: true, a: { x: foldConfig.a.x, y: foldConfig.a.y }, b: { x: foldConfig.b.x, y: foldConfig.b.y }, value: config.value, name: entity.name, foldingType: config.entity.foldingType })


                        } else {

                            entity.linesDep.forEach(lineId => {
                                const dep = catalogConfiguration.find(entity => entity.entityId === lineId)
                                foldConfig.a.y += dep.value
                            })

                            foldConfig.b.x = catalogConfiguration.find(config => config.entity.type === 'longer').value;
                            foldConfig.b.y = foldConfig.a.y
                            foldingLinesWithOutDeltL.push({ a: { x: foldConfig.a.x, y: foldConfig.a.y }, b: { x: foldConfig.b.x, y: foldConfig.b.y }, value: config.value, name: entity.name, foldingType: config.entity.foldingType })

                        }

                        break;
                    default:
                        break;
                }
            });

            catalogConfigurationWithDeltaL.forEach(config => {
                switch (config.entity.type) {
                    case 'line':
                        if (config.entity.horizontal) {
                            const recData = {};
                            recData.width = config.value;
                            const entityV = catalogConfigurationWithDeltaL.find(c => JSON.stringify(c.entity.linesDep) === JSON.stringify(config.entity.linesDep));
                            recData.height = entityV.value;
                            recData.x = 0;
                            let afterL = false;
                            let l = 0;
                            config.entity.linesDepH.forEach(e => {
                                const entity = catalogConfigurationWithDeltaL.find(c => c.entity._id === e);
                                if (entity.entity.type === 'longer') {
                                    afterL = true
                                    l = entity.value
                                }
                                recData.x += entity.value;
                            })
                            if (!afterL) {

                                recData.x = -recData.x - config.value
                            } else {
                                recData.afterL = true;
                                recData.l = l;
                            }
                            recData.y = 0;
                            config.entity.linesDep.forEach(e => {
                                const entity = catalogConfigurationWithDeltaL.find(c => c.entity._id === e);
                                recData.y += entity.value;
                            })
                            recData.y += entityV.value;

                            let findedLine = false
                            linesH.forEach((line, i, array) => {
                                if (JSON.stringify(line.depV.sort()) === JSON.stringify(config.entity.linesDep.sort())) {
                                    array[i] = { ...array[i], recDatas: [...array[i].recDatas, recData] }
                                    findedLine = true;
                                }
                            });
                            if (!findedLine) {
                                linesH.push({ depV: config.entity.linesDep, recDatas: [recData] })
                            }

                            labels.push({ text: config.entity.name, x: recData.x + config.value / 2, y: recData.y - (config.value / 2), fontSize: 20 })

                        } else {
                            mainFormheight += config.value;
                            const lineEntity = config.entity;
                            let y = 0
                            lineEntity.linesDep.forEach(lineId => {
                                const dep = catalogConfigurationWithDeltaL.find(entity => entity.entityId === lineId)
                                y += dep.value
                            })
                            labels.push({ text: config.entity.name, x: config.value / 2, y: y + config.value / 2, fontSize: 20 })
                        }


                        break;
                    case 'longer':
                        mainFormWidth += config.value;
                        break;
                    case 'angle':
                        const entity = config.entity;

                        const foldConfig = { a: { x: 0, y: 0 }, b: { x: 0, y: 0 } };

                        if (config.entity.horizontal) {
                            let afterL = false;
                            config.entity.linesDepH.forEach(e => {
                                const entity = catalogConfigurationWithDeltaL.find(c => c.entity._id === e);
                                if (entity.entity.type === 'longer') {
                                    afterL = true
                                }
                                foldConfig.a.x += entity.value;
                            })
                            if (!afterL) {
                                foldConfig.a.x = -foldConfig.a.x
                            }
                            foldConfig.b.x = foldConfig.a.x;
                            const entityV = catalogConfigurationWithDeltaL.find(c => JSON.stringify(c.entity.linesDep) === JSON.stringify(config.entity.linesDep));

                            entity.linesDep.forEach(lineId => {
                                const dep = catalogConfigurationWithDeltaL.find(entity => entity.entityId === lineId)
                                foldConfig.a.y += dep.value
                            })

                            foldConfig.b.y = foldConfig.a.y + entityV.value
                            foldingLines.push({ horizontal: true, a: { x: foldConfig.a.x, y: foldConfig.a.y }, b: { x: foldConfig.b.x, y: foldConfig.b.y }, value: config.value, name: entity.name, foldingType: config.entity.foldingType })


                        } else {

                            entity.linesDep.forEach(lineId => {
                                const dep = catalogConfigurationWithDeltaL.find(entity => entity.entityId === lineId)
                                foldConfig.a.y += dep.value
                            })


                            foldConfig.b.x = catalogConfigurationWithDeltaL.find(config => config.entity.type === 'longer').value;
                            foldConfig.b.y = foldConfig.a.y
                            foldingLines.push({ a: { x: foldConfig.a.x, y: foldConfig.a.y }, b: { x: foldConfig.b.x, y: foldConfig.b.y }, value: config.value, name: entity.name, foldingType: config.entity.foldingType })

                        }

                        break;
                    default:
                        break;
                }
            });

            linesH.forEach(line => {
                let afterL = false;
                const subForm = { x: 0, y: 0, width: 0, height: 0 };
                line.recDatas.forEach(rec => {
                    subForm.width += rec.width;
                    if (!afterL && rec.afterL) {
                        subForm.width += rec.l;
                        afterL = true
                    }
                    if (rec.x < subForm.x) {
                        subForm.x = rec.x;
                    }
                    subForm.y = rec.y;
                    subForm.height = rec.height;
                })
                subForms.push(subForm);
            })




            const genModel = (datas) => {
                let model = {
                    models: {}
                }
                let last = [0, 0]
                datas.forEach(data => {
                    const line = new makerjs.paths.Line(last, [last[0] + data.length, last[1]])
                    if (data.angle) {
                        makerjs.path.rotate(line, data.angle, last);
                    }
                    last = line.end;
                    makerjs.path.addTo(line, model);
                })
                if (datas.length > 2) {
                    const med = median(datas.map(d => d.angle))
                    let m = makerjs.measure.modelExtents(model);
                    model = makerjs.model.rotate(model, med * -1, m.center);
                }
                return model
            }


            const profilViewData = [];
            let total = 0;
            let nexAngle = 0;
            foldingLinesWithOutDeltL.filter(fl => !fl.horizontal).forEach(foldingLine => {
                const line = Math.abs(foldingLine.a.y) - total;
                const obj = { length: line };
                if (nexAngle) {
                    obj.angle = nexAngle;
                } else {
                    obj.angle = 0;
                }
                profilViewData.push(obj);
                total += line

                nexAngle += foldingLine.foldingType === 'DOWN' ? (foldingLine.value - 180) : (foldingLine.value - 180) * -1;
            })
            const totalHeightWithoutDeltaL = catalogConfiguration.filter(f => !f.entity.horizontal && f.entity.type === 'line').map(f => f.value).reduce((a, b) => a + b, 0);
            profilViewData.push({ length: totalHeightWithoutDeltaL - total, angle: nexAngle })


            const profilViewDataH = [];
            const foldingLinesHWithOutDeltaL = foldingLinesWithOutDeltL.filter(fl => fl.horizontal);
            if (foldingLinesHWithOutDeltaL.length) {

                total = 0;
                nexAngle = 0;
                const lEntity = OrderedCatalogConfiguration.find((c) => c.entity.type === 'longer');
                const beforeL = OrderedCatalogConfiguration.filter(c => c.entity.horizontal && c.entity.type === 'line' && !c.entity.linesDepH.includes(lEntity.entityId)).reduce((p, c) => p.value + c.value);
                foldingLinesHWithOutDeltaL.forEach(foldingLine => {
                    const line = Math.abs((foldingLine.a.x + (beforeL.value || beforeL) - total));
                    const obj = { length: line };
                    if (nexAngle) {
                        obj.angle = nexAngle;
                    } else {
                        obj.angle = 0;
                    }
                    profilViewDataH.push(obj);
                    total += line

                    nexAngle += foldingLine.foldingType === 'DOWN' ? (foldingLine.value - 180) * -1 : foldingLine.value - 180;
                })
                if (profilViewDataH.length) {
                    const totalHeightWithoutDeltaL = OrderedCatalogConfiguration.filter(f => (f.entity.horizontal && f.entity.type === 'line') || f.entity.type === 'longer').map(f => f.value).reduce((a, b) => a + b, 0);

                    profilViewDataH.push({ length: Math.abs(totalHeightWithoutDeltaL - total), angle: nexAngle })
                }
            }

            let model = genModel(profilViewData);
            let modelH = null;
            if (profilViewDataH.length) {
                modelH = genModel(profilViewDataH);
            }
            const getBestZheigth = (model) => {
                const mesure = makerjs.measure.modelExtents(model)
                const longerLine = reorderLinesH.find(config => config.entity.type === 'longer');
                const { height, width } = mesure;
                return [height, width, longerLine.value].sort((a, b) => a - b);
            }


            let [y3d, x3d, z3d] = getBestZheigth(model);
            if (modelH) {
                let [y3dH, x3dH, z3dH] = getBestZheigth(modelH)
                if (y3d < y3dH) {
                    y3d = y3dH
                }
                if (x3d < x3dH) {
                    x3d = x3dH
                }
                if (z3d < z3dH) {
                    z3d = z3dH
                }
            }

            const data = {
                form: 0,
                nbFolding: foldingLines.length,
                isFromCatalog: true,
                profil: model ? makerjs.exporter.toSVGPathData(model) : null,
                profilH: modelH ? makerjs.exporter.toSVGPathData(modelH) : null,
                y3d: Math.ceil(y3d),
                x3d: Math.ceil(x3d),
                z3d: Math.ceil(z3d),

                mainForm: {
                    height: mainFormheight,
                    width: mainFormWidth,
                    configured: true,
                    borderConfigurations: {
                        tl: { isRadiusEnabled: false, isChanfreinEnabled: false, radius: 0, isInner: false, isOutside: true },
                        tr: { isRadiusEnabled: false, isChanfreinEnabled: false, radius: 0, isInner: false, isOutside: true },
                        bl: { isRadiusEnabled: false, isChanfreinEnabled: false, radius: 0, isInner: false, isOutside: true },
                        br: { isRadiusEnabled: false, isChanfreinEnabled: false, radius: 0, isInner: false, isOutside: true }
                    }
                }
            }


            data.forms = [
                ...subForms.map(subForm => {
                    return {
                        id: uniqid(),
                        type: 'RECTANGLE',
                        width: subForm.width,
                        height: subForm.height,
                        realx: subForm.x + (subForm.width / 2),
                        realy: subForm.y - (subForm.height / 2),
                        unionType: 'add',
                        graved: false,
                        rotation: 0,
                        notSelectable: true
                    }
                }),
                ...foldingLines.map(foldingLine => {
                    return {
                        type: 'FOLDINGLINE',
                        a: { x: foldingLine.a.x, y: foldingLine.a.y },
                        b: { x: foldingLine.b.x, y: foldingLine.b.y },
                        foldingType: foldingLine.foldingType,
                        value: foldingLine.value
                    }
                }),
                ...labels.map(label => {
                    return {
                        type: 'LABEL',
                        x: label.x,
                        y: label.y,
                        text: label.text,
                        fontSize: mainFormheight / 50
                    }
                }),
            ]
            if (configurator.editId) {
                data.forms = [
                    ...data.forms,
                    ...configurator.forms.filter(form => !form.notSelectable && !['FOLDINGLINE', 'LABEL'].includes(form.type))
                ];
            }

            dispatch({
                type: 'SET_VALUES',
                payload: {
                    data
                }
            })
        }
        dispatchCatalog({
            type: 'SET_STEP',
            payload: step + 1
        })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '3rem', boxSizing: 'border-box' }} >
            <Grid container justify="center" alignItems="center" direction="column" style={{ padding: '5px' }} >
                <Grid item md={12} style={{ width: '100%' }}>
                    <Stepper activeStep={step} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {step > 0 && <Paper elevation={0} className={classes.buttonsPaper}>
                        <Grid container justify='space-between'>

                            <Button onClick={handleClickPrev} variant='contained'>Précédent</Button>
                            {step === 1 && <Button disabled={hasError} onClick={handleClickNext} variant='contained' color='primary'>Suivant</Button>}
                        </Grid>
                        {hasError && <Alert style={{ marginTop: '1rem' }} severity="error">
                            <AlertTitle>{hasError === 'maxLength' ? 'La longueur de votre pièce dépasse les capacités de pliage.' : 'La largeur de votre pièce dépasse les capacités de pliage.'}</AlertTitle>

                        </Alert>}
                    </Paper>}
                </Grid>
                {step === 2 && configurator.price &&

                    <Grid item md={12} style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}>
                        <Paper elevation={0} style={{ padding: "2rem", maxHeight: "80%", overflow: "auto" }}>
                            <ConfiguratorDisplayPrice textModels={textModels} refStage={refStage} />
                        </Paper>
                    </Grid>}
                {
                    step === 2 && <Grid item xs={10} md={12} style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}>
                        <Paper elevation={0} style={{ padding: "2rem", maxHeight: "80%", overflow: "auto" }}>
                            <CatalogImagePreview image={getCatalogData()} />
                        </Paper>
                    </Grid>
                }
                <Grid item md={12} style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}>
                    {
                        !firstVisit && <Accordion elevation={0} expanded={!haveCompletedConfiguration() || isopen}>
                            <AccordionSummary

                                expandIcon={<ExpandMoreIcon />}
                                onClick={() => setisopen(!isopen)}
                            >
                                <Grid container>
                                    <SettingsIcon color='primary' />
                                    <Typography className={classes.heading}>{step > 0 ? 'Lire la matière' : 'Modifier la matière'}</Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction='column'>
                                    <ConfiguratorMenu readOnly={step > 0} withoutNbPiece />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    }

                </Grid>

                {
                    haveCompletedConfiguration() &&
                    <>

                        {step === 0 && <Grid container justifyContent="space-around" alignItems="center" direction="column" style={{ marginTop: '2rem' }} >
                            <Grid item md={12} style={{ width: '100%' }}>
                                {catalogsToDisplay.length <= 0 && <ChipTitle>Aucune formes disponible</ChipTitle>}

                                <Paper elevation={0} style={{ padding: "2rem" }}>
                                    {
                                        catalogsToDisplay.length > 0 ? <CatalogMenuForm catalogsToDisplay={catalogsToDisplay} /> : <Typography variant="body1" gutterBottom>Aucune forme disponible pour cette épaisseur</Typography>
                                    }

                                </Paper>
                            </Grid>
                        </Grid>
                        }

                    </>
                }

            </Grid>

            {!isDownSm && false && !process.env.REACT_APP_IFRAME_MODE && <Button variant="contained" onClick={handelClickGoBack} startIcon={<ChevronLeftIcon />} color='secondary' style={{ position: 'absolute', top: '10px', left: '10px' }}></Button>}
            <DialogConfigurationMenu open={openDialog} setopenDialog={setopenDialog} />
        </div>
    )
}

export default CatalogControlPannel
