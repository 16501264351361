import { FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';
import React, { useContext, useCallback, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import { MaterialsContext } from '../../../contexts/MaterialsContext';

const useStyles = makeStyles(() => ({
    shadeName: {
        background: '#514f4f',
        padding: '10px',
        borderRadius: '5px',
    },
}))

function ConfigurationManuShadeSelector({ readOnly, shadeShrink, overwriteValue = null, overwriteonChangeShade = null, stepThickness = null }) {
    const [materials, dispach] = useContext(MaterialsContext);
    const configurator = useContext(ConfiguratorContext)[0]
    const dispachConfig = useContext(ConfiguratorContext)[1]

    const currentDatas = overwriteValue || configurator

    const classes = useStyles()

    const getStyle = (imagename) => {
        return imagename ? { height: overwriteValue ? '25px' : '60px', fontWeight: 'bold', backgroundImage: `url('${process.env.REACT_APP_BASE_API}/download/image/${imagename}')`, backgroundSize: 'cover', color: 'white', marginBottom: overwriteValue ? '2px' : '0' } : { height: overwriteValue ? '25px' : 'auto', marginBottom: overwriteValue ? '2px' : '0' }
    }

    const formStyle = {
        marginBottom: overwriteValue ? '0' : '1rem'
    }

    const shadeNameStyle = {
        background: '#514f4f',
        padding: overwriteValue ? '5px' : '10px',
        borderRadius: overwriteValue ? '0' : '5px',
        fontSize: overwriteValue ? '12px' : 'auto'
    };

    const getOptions = useCallback(
        () => {
            if (!stepThickness) {
                return materials.find(material => material._id === currentDatas.materialId)?.shades.map(shade => <MenuItem style={getStyle(shade.image)} key={shade._id} value={shade._id}><span style={shade.image ? shadeNameStyle : null}>{shade.name}</span></MenuItem>)
            }
            return materials.find(material => material._id === currentDatas.materialId)?.shades.filter(shade => shade.thicknesses.some(thickness => thickness.value === stepThickness)).map(shade => <MenuItem style={getStyle(shade.image)} key={shade._id} value={shade._id}><span style={shade.image ? shadeNameStyle : null}>{shade.name}</span></MenuItem>)

        },
        [currentDatas.materialId, materials, stepThickness],
    )

    const getShadeImageNameFromId = useCallback((shadeId) => materials.find(material => material._id === currentDatas.materialId)?.shades.find(shade => shade._id === shadeId)?.image, [currentDatas.materialId, materials])
    const getShadeColorFromId = useCallback((shadeId) => materials.find(material => material._id === currentDatas.materialId)?.shades.find(shade => shade._id === shadeId)?.color, [currentDatas.materialId, materials])

    const onchange = (e) => {
        if (overwriteonChangeShade) {
            overwriteonChangeShade(e);
            return;
        }
        dispachConfig({
            type: 'SET_VALUES',
            payload: {
                data: {
                    shadeId: e.target.value,
                    shadeImageName: getShadeImageNameFromId(e.target.value) || null,
                    shadeColor: getShadeColorFromId(e.target.value) || null,
                    thicknessId: null,
                }
            }
        })
    }
    return (
        <FormControl fullWidth style={formStyle} >
            <InputLabel id="configurator-select-shade-label" shrink={shadeShrink} >Nuance</InputLabel>
            <Select
                labelId="configurator-select-shade-label"
                id="configurator-shade-material"
                style={getStyle(getShadeImageNameFromId(currentDatas.shadeId))}
                value={currentDatas.shadeId}
                onChange={onchange}
                label="Matériaux"
                fullWidth
                disabled={!currentDatas.materialId || readOnly}
                inputProps={{ readOnly: readOnly || false }}
            >
                {getOptions()}
            </Select>
        </FormControl>
    )
}

export default ConfigurationManuShadeSelector
