import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useDeleteCatalogSvg } from '../../../contexts/CatalogSvgContext';
import DialogValidation from '../../Dialog/DialogValidation';


const useStyles = makeStyles({
  subTitle: {
    fontWeight: 'bold'
  },
  media: {
    height: 140,
    objectFit: 'contain'
  },
});

function CatalogSvgCard({ data, handleValidateDelete, delectable, selectable, onSelect }) {

  const [isModelOpen, setisModelOpen] = useState(false);

  const { deleteCatalogSvg } = useDeleteCatalogSvg()
  const classes = useStyles();

  const handleCancel = () => {
    setisModelOpen(false);
  }


  const handleClickDelete = async () => {
    setisModelOpen(true);
  }

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  const makeTranslate = (s) => {
    if (s === 'cuting') return 'Découpe'
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  return (
    <>
      <Card className={classes.root}>
        <ConditionalWrapper condition={selectable} wrapper={children => <CardActionArea onClick={() => onSelect(data._id)}>{children}</CardActionArea>} >

          <CardMedia
            component="img"
            height="140"
            className={classes.media}
            image={`${process.env.REACT_APP_BASE_API}/download/svg/${data.file}`}
            title={`svg ${data.name}`}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {data.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <span className={classes.subTitle}>Famille</span>: {data.family.join(', ')}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <span className={classes.subTitle}>Utilisation</span>: {data.type.join(', ')}
            </Typography>
          </CardContent>
          <CardActions>
            {delectable && <Button size="small" color="primary" onClick={handleClickDelete}>
              Supprimer
            </Button>}
          </CardActions>
        </ConditionalWrapper>
      </Card>
      <DialogValidation open={isModelOpen} handleCancel={handleCancel} handleValidate={() => handleValidateDelete(data._id)} />
    </>
  )
}

export default CatalogSvgCard