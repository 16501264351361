const deltaL = {
    0.6: {
        V: {
            8: {
                165: -0.1,
                150: -0.2,
                135: -0.4,
                120: -0.6,
                105: -0.9,
                90: -1.4,
                75: -1,
                60: -0.6,
                45: -0.2,
                30: 0.3,
                15: 0.7,
                0: 1.1
            }
        }
    },
    0.8: {
        V: {
            8: {
                165: -0.1,
                150: -0.3,
                135: -0.5,
                120: -0.7,
                105: -1.1,
                90: -1.7,
                75: -1.3,
                60: -0.8,
                45: -0.4,
                30: 0,
                15: 0.4,
                0: 0.8
            }
        }
    },
    1: {
        V: {
            8: {
                165: -0.2,
                150: -0.4,
                135: -0.6,
                120: -0.9,
                105: -1.4,
                90: -2,
                75: -1.6,
                60: -1.1,
                45: -0.7,
                30: -0.3,
                15: 0.2,
                0: 0.6
            }
        }
    },
    1.2: {
        V: {
            10: {
                165: -0.2,
                150: -0.4,
                135: -0.7,
                120: -1.1,
                105: -1.6,
                90: -2.4,
                75: -1.9,
                60: -1.4,
                45: -0.8,
                30: -0.3,
                15: 0.2,
                0: 0.8
            }
        }
    },
    1.5: {
        V: {
            12: {
                165: -0.3,
                150: -0.6,
                135: -0.9,
                120: -1.4,
                105: -2.1,
                90: -3,
                75: -2.4,
                60: -1.7,
                45: -1,
                30: -0.4,
                15: 0.3,
                0: 1
            }
        }
    },
    2: {
        V: {
            16: {
                165: -0.3,
                150: -0.7,
                135: -1.2,
                120: -1.9,
                105: -2.7,
                90: -4,
                75: -3.1,
                60: -2.3,
                45: -1.4,
                30: -0.5,
                15: 0.3,
                0: 1.2
            }
        }
    },
    2.5: {
        V: {
            20: {
                165: -0.4,
                150: -0.9,
                135: -1.5,
                120: -2.3,
                105: -3.4,
                90: -5,
                75: -3.9,
                60: -2.8,
                45: -1.7,
                30: -0.6,
                15: 0.5,
                0: 1.6
            }
        }
    },
    3: {
        V: {
            25: {
                165: -0.5,
                150: -1.1,
                135: -1.8,
                120: -2.8,
                105: -4.1,
                90: -6,
                75: -4.7,
                60: -3.4,
                45: -2.1,
                30: -0.7,
                15: 0.6,
                0: 1.9
            }
        }
    },
    4: {
        V: {
            32: {
                165: -0.7,
                150: -1.5,
                135: -2.4,
                120: -3.7,
                105: -5.4,
                90: -7.9,
                75: -6.3,
                60: -4.6,
                45: -2.9,
                30: -1.2,
                15: 0.4,
                0: 2.1
            }
        }
    },
    5: {
        V: {
            40: {
                165: -0.9,
                150: -1.8,
                135: -3,
                120: -4.6,
                105: -6.8,
                90: -10,
                75: -7.8,
                60: -5.7,
                45: -3.5,
                30: -1.3,
                15: 0.8,
                0: 3
            }
        }
    },
    6: {
        V: {
            50: {
                165: -1,
                150: -2.2,
                135: -3.6,
                120: -5.5,
                105: -8.2,
                90: -12,
                75: -9.4,
                60: -6.8,
                45: -4.1,
                30: -1.4,
                15: 1.2,
                0: 3.9
            }
        }
    },
    8: {
        V: {
            63: {
                165: -1.4,
                150: -2.9,
                135: -4.9,
                120: -7.4,
                105: -11,
                90: -16,
                75: -13,
                60: -9.2,
                45: -5.8,
                30: -2.5,
                15: 0.9,
                0: 4.2
            }
        }
    },
    10: {
        V: {
            80: {
                165: -1.7,
                150: -3.7,
                135: -6.1,
                120: -9.2,
                105: -14,
                90: -20,
                75: -16,
                60: -11,
                45: -7,
                30: -2.7,
                15: 1.7,
                0: 6
            }
        }
    },
    12: {
        V: {
            100: {
                165: -2.1,
                150: -4.4,
                135: -7.3,
                120: -11,
                105: -16,
                90: -24,
                75: -19,
                60: -14,
                45: -8.2,
                30: -2.9,
                15: 2.4,
                0: 7.8
            }
        }
    },
    15: {
        V: {
            160: {
                165: -2.5,
                150: -5.3,
                135: -9,
                120: -14,
                105: -21,
                90: -32,
                75: -24,
                60: -15,
                45: -7,
                30: -1.3,
                15: 9.6,
                0: 18
            }
        }
    },
    20: {
        V: {
            160: {
                165: -3.3,
                150: -7.1,
                135: -12,
                120: -19,
                105: -28,
                90: -42,
                75: -32,
                60: -21,
                45: -10,
                30: -0.4,
                15: 11,
                0: 22
            }
        }
    }
}

const getDeltaL = (thinckess, degrees) => {
    const closestDegrees = [0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165].reduce((a, b) => {
        return Math.abs(b - degrees) < Math.abs(a - degrees) ? b : a;
    });

    const thicknessData = deltaL[thinckess]['V'];
    const keys = Object.keys(thicknessData);
    return thicknessData[keys[0]][closestDegrees];

}

module.exports = { getDeltaL };