import React, { useCallback, useContext, useEffect, useState } from 'react'
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./../../../src/modules/worker";
import makerjs from 'makerjs'
import { makeStyles } from '@material-ui/styles';
import GreenButton from '../Button/GreenButton';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography, Grid, CircularProgress } from '@material-ui/core';
import { getlayers } from '../../modules/dxf';
import DialogShowDXFLayers from './DialogShowDXFLayers';
import { PropertiesContext } from '../../contexts/PropertiesContext';
import { dxfToModel } from '../../modules/makerjs';
import { ConfiguratorContext } from '../../contexts/ConfiguratorContext';

const useStyles = makeStyles((theme) => ({
    optionsTitle: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    }
}))


function ModalProcessUploadedDxf({ open, handleClose, file, onValidate }) {

    const [svg, setsvg] = useState('');
    const [dxf, setdxf] = useState('');
    const [lines, setlines] = useState([]);
    const [originalHeight, setoriginalHeight] = useState(null);
    const [originalWidth, setoriginalWidth] = useState(null);
    const [targetHeight, settargetHeight] = useState(null);
    const [targetWidth, settargetWidth] = useState(null);
    const [startProcess, setstartProcess] = useState(false);
    const [openSelectLayerModal, setopenSelectLayerModal] = useState(false);
    const [ratio, setratio] = useState(0);
    const [model, setModel] = useState({
        models: {},
        paths: {},
    });
    const [layers, setlayers] = useState([]);
    const [gravedLayers, setgravedLayers] = useState([]);
    const [selectedLayers, setselectedLayers] = useState([]);
    const [loading, setloading] = useState(false);
    const [errorH, seterrorH] = useState(false);
    const [errorHText, seterrorHText] = useState('');
    const [errorW, seterrorW] = useState(false);
    const [errorWText, seterrorWText] = useState('');
    const [properties] = useContext(PropertiesContext);

    const classes = useStyles();

    useEffect(() => {

        // Closure to capture the file information.
        if (!dxf) {
            let reader = new FileReader();

            reader.onload = (function () {
                return function (e) {
                    const rawDxf = e.target.result;
                    const layers = getlayers(rawDxf);
                    const model = dxfToModel(rawDxf)
                    setModel(model);
                    setdxf(rawDxf);

                    if (layers.length > 1) {
                        setlayers(layers)
                        setopenSelectLayerModal(true);
                    } else {
                        handleClickValidateDxf(model)
                    }
                };
            })(file);

            // Read in the image file as a data URL.
            reader.readAsText(file);
        }
    }, [dxf, file, originalHeight, originalWidth]);



    const handleChangeHeight = useCallback((e) => {

        if (e.target.value.match(/^[0-9]*$/)) {
            seterrorH(false)
            seterrorHText('')
            if (Number(e.target.value) < properties.machineMinLength || Number(e.target.value) > properties.machineMaxLength) {
                seterrorHText(`valeur comprisent entre ${properties.machineMinLength} et ${properties.machineMaxLength}`)
                seterrorH(true)
            }
            settargetHeight(+e.target.value)
        }

    }, [properties.machineMaxLength, properties.machineMinLength])

    const handleBlurHeight = useCallback(() => {
        const value = Math.ceil(targetHeight / ratio);
        seterrorW(false)
        seterrorWText('')
        if (Number(value) < properties.machineMinWidth || Number(value) > properties.machineMaxWidth) {
            seterrorWText(`valeur comprisent entre ${properties.machineMinWidth} et ${properties.machineMaxWidth}`)
            seterrorW(true)
        }
        settargetWidth(value)

    }, [properties.machineMaxWidth, properties.machineMinWidth, ratio, targetHeight])

    const handleChangeWidth = useCallback((e) => {
        if (e.target.value.match(/^[0-9]*$/)) {
            seterrorW(false)
            seterrorWText('')
            if (Number(e.target.value) < properties.machineMinWidth || Number(e.target.value) > properties.machineMaxWidth) {
                seterrorWText(`valeur comprisent entre ${properties.machineMinWidth} et ${properties.machineMaxWidth}`)
                seterrorW(true)
            }
            settargetWidth(+e.target.value)
        }

    }, [properties.machineMaxWidth, properties.machineMinWidth])

    const handleBlurWidth = useCallback(() => {


        seterrorH(false)
        const value = Math.ceil(targetWidth * ratio);
        seterrorHText('')
        if (Number(value) < properties.machineMinLength || Number(value) > properties.machineMaxLength) {
            seterrorHText(`valeur comprisent entre ${properties.machineMinLength} et ${properties.machineMaxLength}`)
            seterrorH(true)
        }
        settargetHeight(+value)
    }, [properties.machineMaxLength, properties.machineMinLength, ratio, targetWidth])



    const handleClickValidateDxf = useCallback((model) => {

        setloading(true)
        setstartProcess(true);

        const worker = new Worker();

        worker.postMessage({ type: 'MODEL_IMPORT', model })

        worker.onmessage = async (event) => {
            const { svg, height, width, lines, model } = event.data;
            setlines(lines);
            setsvg(svg);
            const roundedHeight = Math.round(height);
            const roundedWidth = Math.round(width);
            if ((!originalHeight || originalHeight === 0) && (!originalWidth || originalWidth === 0)) {
                setoriginalHeight(roundedHeight);
                setoriginalWidth(roundedWidth);
                let ratio = height / width;
                setratio(ratio)
            }
            setModel(model);
            setloading(false)
            setopenSelectLayerModal(false);
            setstartProcess(false);
        }


        setstartProcess(true);

    }, [originalHeight, originalWidth])


    const handleCloseDialogShowDXFLayers = () => {
        setopenSelectLayerModal(false);
        handleClose()
    }

    return (
        <>
            <Dialog fullWidth maxWidth={'sm'} open={open} onClose={handleClose} >
                <DialogTitle style={{ textAlign: 'center' }}>Importation DXF</DialogTitle>
                <DialogContent style={{ paddingTop: '1rem' }}>


                    <Grid container justify='space-around'>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    {originalHeight && originalWidth ? <>
                                        <Grid container justify='space-around' spacing={4}>
                                            <Grid item xs={5}>
                                                <Typography className={classes.optionsTitle} variant="button" gutterBottom display='block'>
                                                    Dimensions originales
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography className={classes.optionsTitle} variant="button" gutterBottom display='block'>
                                                    Dimensions souhaitées
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems='baseline' justify='space-around' spacing={3} >
                                            <Grid item xs={6}>
                                                <Typography variant="body1" gutterBottom display='block'>
                                                    Largeur <small>(mm)</small> : {originalHeight}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container spacing={2} alignItems='center'>
                                                    <Grid item xs={7}>

                                                        <Typography variant="body1" gutterBottom display='inline' >
                                                            Largeur <small>(mm)</small>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>

                                                        <TextField value={targetHeight} onBlur={handleBlurHeight} onChange={handleChangeHeight} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                        <Grid container alignItems='baseline' justify='space-around' spacing={4} >
                                            <Grid item xs={6}>
                                                <Typography variant="body1" gutterBottom display='block'>
                                                    Longueur <small>(mm)</small>: {originalWidth}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container spacing={2} alignItems='center'>
                                                    <Grid item xs={7}>

                                                        <Typography variant="body1" gutterBottom display='inline'>
                                                            Longueur <small>(mm)</small>:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>

                                                        <TextField value={targetWidth} onBlur={handleBlurWidth} onChange={handleChangeWidth} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                        :
                                        <Grid container justifyContent="center">
                                            <CircularProgress disableShrink />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Divider style={{ margin: '1rem 0' }} />
                        </Grid>
                        <Typography variant="caption" gutterBottom display='block'>
                            {errorHText || errorWText}
                        </Typography>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Grid container justify='space-between'>
                        <Grid item>
                            <Button onClick={handleClose} color="primary" variant='contained'>
                                Fermer
                            </Button>
                        </Grid>
                        <Grid item>
                            <GreenButton disabled={!targetHeight || !targetWidth || errorH || errorW} onClick={() => onValidate({ lines, targetHeight: targetHeight || originalHeight, targetWidth: targetWidth || originalWidth, originalHeight, originalWidth, ratio, model })} color="primary" variant='contained'>Valider</GreenButton>
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog >
            {openSelectLayerModal && <DialogShowDXFLayers loading={loading} model={model} setModel={setModel} allowGraved={false} handleClose={handleCloseDialogShowDXFLayers} handleClickValidateDxf={handleClickValidateDxf} open={openSelectLayerModal} dxf={dxf} layers={layers} selectedLayers={selectedLayers} setselectedLayers={setselectedLayers} gravedLayers={gravedLayers} setgravedLayers={setgravedLayers} />}

        </>
    )
}

export default ModalProcessUploadedDxf