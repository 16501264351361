import { Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Paper, Select, Typography } from '@material-ui/core'
import React, { useState, useEffect, useContext } from 'react'
import { CatalogSvgContext, useDeleteCatalogSvg, usePostCatalogSvg } from '../../contexts/CatalogSvgContext';
import { CatalogSvgDatasSchemas } from '../../modules/validation';
import GreenButton from '../Button/GreenButton';
import DisplayCatalogSvg from '../EndUser/CatalogSvg/DisplayCatalogSvg';
import DropImg from '../DropImg';
import ProcessUploadedRaw from '../ProcessUploadedRaw';
import TextFieldValidation from '../TextFieldValidation';
import DialogAddCatalogSvgType from '../Dialog/DialogAddCatalogSvgType';
import DialogAddCatalogSvgFamily from '../Dialog/DialogAddCatalogSvgFamily';


const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: theme.spacing(4)
    }
}));


function CatalogSvgControl() {

    const [{ catalogsSvg }] = useContext(CatalogSvgContext);
    const [newSvgData, setnewSvgData] = useState({ name: '', type: [], family: [], file: '' });
    const [startSvg, setstartSvg] = useState(false);
    const [formError, setformError] = useState(true);
    const [typeToAdd, settypeToAdd] = useState('');
    const [types, settypes] = useState([]);
    const [familyToAdd, setfamilyToAdd] = useState('');
    const [families, setfamilies] = useState([]);
    const [openAddTypeDialog, setopenAddTypeDialog] = useState(false);
    const [openAddTypeFamily, setopenAddTypeFamily] = useState(false);
    const { postCatalogSvg } = usePostCatalogSvg()
    const { deleteCatalogSvg } = useDeleteCatalogSvg()

    const classes = useStyles();

    useEffect(() => {
        let mergedType = []
        catalogsSvg.forEach((cat) => {
            mergedType = mergedType.concat(cat.type);
        })
        settypes([...new Set(mergedType)])

        let mergedFamily = []
        catalogsSvg.forEach((cat) => {
            mergedFamily = mergedFamily.concat(cat.family);
        })
        setfamilies([...new Set(mergedFamily)])

    }, [catalogsSvg]);

    const handleChangeType = (e) => {
        setnewSvgData({ ...newSvgData, type: e.target.value })
    }

    const handleChangeFamily = (e) => {
        setnewSvgData({ ...newSvgData, family: e.target.value })
    }

    const handleChangeName = (e) => {

        setnewSvgData({ ...newSvgData, name: e.target.value })
    }

    const handleClickSelectSvg = () => {
        setstartSvg(true);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { file, ...rest } = newSvgData;
        await postCatalogSvg(rest, file);
        setnewSvgData({ name: '', type: [], family: [], file: '' });

    }

    const handleVecto = ({ file }) => {
        setnewSvgData({ ...newSvgData, file });
        setstartSvg(false);
    }

    const handleValidateDelete = async (id) => {

        await deleteCatalogSvg(id);

    }


    const handleChangeTypeToAdd = (e) => {
        settypeToAdd(e.target.value)
    }

    const handleClickAddType = (e) => {
        settypeToAdd('');
        settypes((types => [...types, typeToAdd]));
        setopenAddTypeDialog(false);
    }


    const handleChangeFamilyToAdd = (e) => {
        setfamilyToAdd(e.target.value)
    }

    const handleClickAddFamily = (e) => {
        setfamilyToAdd('');
        setfamilies((families => [...families, familyToAdd]));
        setopenAddTypeFamily(false);
    }


    useEffect(() => {
        if (newSvgData.name.length > 2 && newSvgData.type.length && newSvgData.file && newSvgData.family.length) {
            setformError(false)
        } else {
            setformError(true)
        }
    }, [newSvgData]);

    return (
        <>
            <Grid container justify="center"  >
                <Grid item xs={12} sm={11}>
                    <Paper elevation={0} >
                        <Grid container justify='center'>
                            <Grid item xs={10} className={classes.section}>
                                <form onSubmit={handleSubmit}>
                                    <Typography variant="h5" align='center' color='primary' gutterBottom>
                                        Ajouter un SVG
                                    </Typography>
                                    <Grid container justify='center' alignItems='center' spacing={2}>
                                        <Grid item sx={12} sm={3} >
                                            <Button onClick={handleClickSelectSvg} color='primary' variant='contained'>Choisir un fichier</Button>
                                        </Grid>
                                        <Grid item sx={12} sm={3} >
                                            <TextFieldValidation id="svg-add-name" label="Nom" value={newSvgData.name} onChange={handleChangeName} fullWidth yupValidation={CatalogSvgDatasSchemas} />
                                        </Grid>
                                        <Grid iitem container sx={12} sm={3} alignItems='center' >
                                            <Grid item xs={8}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="svg-add-type-label">Famille</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="svg-add-family-label"
                                                        id="svg-add-family"
                                                        multiple
                                                        value={newSvgData.family}
                                                        onChange={handleChangeFamily}
                                                    >
                                                        {families.map((family, i) => <MenuItem key={i} value={family}>{family}</MenuItem>)}

                                                    </Select>

                                                </FormControl>
                                            </Grid>
                                            <Grid item >
                                                <Button variant='contained' onClick={() => setopenAddTypeFamily(true)} >+</Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item container sx={12} sm={3} alignItems='center' >
                                            <Grid item xs={8}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="svg-add-type-label">Type d'utilisation</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="svg-add-type-label"
                                                        id="svg-add-type"
                                                        multiple
                                                        value={newSvgData.type}
                                                        onChange={handleChangeType}
                                                    >
                                                        {types.map((type, i) => <MenuItem key={i} value={type}>{type}</MenuItem>)}

                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item >
                                                <Button variant='contained' onClick={() => setopenAddTypeDialog(true)} >+</Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify='flex-end'>
                                            <Grid item >
                                                <GreenButton disabled={formError} type='submit'>Ajouter</GreenButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid item xs={12} className={classes.section}>
                                <Typography variant="h5" align='center' color='primary' gutterBottom>
                                    SVG disponibles
                                </Typography>
                                <DisplayCatalogSvg handleValidateDelete={handleValidateDelete} delectable />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <DropImg startIMG={startSvg} paperRef={null} setstartIMG={setstartSvg} onValidate={handleVecto} modal={<ProcessUploadedRaw />} authorizedTypes={['svg']} />
            <DialogAddCatalogSvgType handleClose={() => setopenAddTypeDialog(false)} open={openAddTypeDialog} typeToAdd={typeToAdd} handleChangeTypeToAdd={handleChangeTypeToAdd} handleClickAddType={handleClickAddType} />
            <DialogAddCatalogSvgFamily handleClose={() => setopenAddTypeFamily(false)} open={openAddTypeFamily} familyToAdd={familyToAdd} handleChangeFamilyToAdd={handleChangeFamilyToAdd} handleClickAddFamily={handleClickAddFamily} />
        </>

    )
}

export default CatalogSvgControl