import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ConfiguratorMainFormRec from '../EndUser/Configurator/ConfiguratorMainFormRec';

export default function DialogFormRectangle({ open, setOpen, isSubForm = false }) {

  const dialogContentStyle = {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      onClose={() => setOpen(false)}
      PaperProps={{
        style: {
          borderRadius: "15px",
        }
      }}
    >
      <DialogContent style={dialogContentStyle}>
        <ConfiguratorMainFormRec
          isSubForm={isSubForm}
          closeDialog={() => setOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
}