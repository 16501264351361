import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Typography, useTheme, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles({
    rowh: {
        fontWeight: 'bold'
    }
});

export default function DialogShowClientInformation({ client, open, handleClose }) {
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={isDownSm}
                maxWidth={'xl'}
                scroll='body'
            >
                <DialogTitle align='center'>{`Informations client`}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} variant='contained' color="primary">
                        fermer
                    </Button>

                </DialogActions>
                <DialogContent style={{
                    maxWidth: 'none'
                }}>
                    <DialogContentText >
                        <TableContainer >
                            <Grid container spacing={6}>
                                <Grid item xs={client?.isPro ? 6 : 12}>
                                    <Table className={classes.table}>
                                        <Typography variant="button" display="block" gutterBottom>
                                            Informations personnelles
                                        </Typography>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={classes.rowh} component="th" scope="row">
                                                    Nom
                                                </TableCell>
                                                <TableCell align="right">{client?.lastName || client?.informations?.lastName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.rowh} component="th" scope="row">
                                                    Prénom
                                                </TableCell>
                                                <TableCell align="right">{client?.firstName || client?.informations?.firstName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.rowh} component="th" scope="row">
                                                    E-mail
                                                </TableCell>
                                                <TableCell align="right">{client?.email || client?.informations?.email}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.rowh} component="th" scope="row">
                                                    Téléphone
                                                </TableCell>
                                                <TableCell align="right">{client?.phone || client?.informations?.phone}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.rowh} component="th" scope="row">
                                                    Numéro de voie
                                                </TableCell>
                                                <TableCell align="right">{client?.houseNumber || client?.informations?.houseNumber}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.rowh} component="th" scope="row">
                                                    Rue
                                                </TableCell>
                                                <TableCell align="right">{client?.street || client?.informations?.street}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.rowh} component="th" scope="row">
                                                    Ville
                                                </TableCell>
                                                <TableCell align="right">{client?.city || client?.informations?.city}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.rowh} component="th" scope="row">
                                                    Code postal
                                                </TableCell>
                                                <TableCell align="right">{client?.zipCode || client?.informations?.zipCode}</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </Grid>
                                {
                                    client?.isPro ?
                                        <Grid item xs={6}>
                                            <Table className={classes.table}>
                                                <Typography variant="button" display="block" gutterBottom>
                                                    Informations sur l'entreprise
                                                </Typography>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            Raison social
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.socialReason || client?.informations?.pro.socialReason}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            SIRET
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.siret || client?.informations?.pro.siret}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            N° TVA
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.tva || client?.informations?.pro.tva}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            Téléphone
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.phone || client?.informations?.pro.phone}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            Mobile
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.mobile || client?.informations?.pro.mobile}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <Typography variant="button" display="block" gutterBottom>
                                                            Adresse de facturation
                                                        </Typography>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            N° de voie
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.houseNumber || client?.informations?.pro.houseNumber}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            Rue
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.street || client?.informations?.pro.street}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            Ville
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.city || client?.informations?.pro.city}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            Code postal
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.zipCode || client?.informations?.pro.zipCode}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <Typography variant="button" display="block" gutterBottom>
                                                            Adresse de livraison
                                                        </Typography>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            N° de voie
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.deliveryHouseNumber || client?.informations?.pro.deliveryHouseNumber}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            Rue
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.deliveryStreet || client?.informations?.pro.deliveryStreet}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            Ville
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.deliveryCity || client?.informations?.pro.deliveryCity}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.rowh} component="th" scope="row">
                                                            Code postal
                                                        </TableCell>
                                                        <TableCell align="right">{client?.pro?.deliveryZipCode || client?.informations?.pro.deliveryZipCode}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        : null
                                }

                            </Grid>




                        </TableContainer>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    );
}
