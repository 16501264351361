const getFileExtension = (filename) => {
    const [_, extension] = filename.match(/[^\\]*\.(\w+)$/)
    return extension
}


const displayFormatedPrice = (price) => Number(price).toFixed(2).replace(/\./, ',')

const calculPriceWithVat = (price, vat = 20) => price * (1 + (vat / 100));

const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}


const setDeepProperty = (obj, path, value) => {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const lastObj = keys.reduce((o, key) => o[key] = o[key] || {}, obj);
    lastObj[lastKey] = value;
}

export {
    getFileExtension,
    displayFormatedPrice,
    calculPriceWithVat,
    dataURLtoBlob,
    setDeepProperty
}