import React, { useCallback, useEffect, useState } from 'react'
import { Fab, Grid, List, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import { CatalogContext } from '../../../contexts/CatalogContext';
import { PropertiesContext } from '../../../contexts/PropertiesContext';
import CatalogdrawingPannelEntitiesListValue from './CatalogdrawingPannelEntitiesListValue';
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',

    },
    fab: {
        marginRight: theme.spacing(2),
        textTransform: 'none'
    }
}));


function CatalogdrawingPannelEntitiesList() {

    const classes = useStyles();
    const [{ catalogs, selectedCatalog, catalogConfiguration }, dispatchCatalog] = useContext(CatalogContext)
    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [properties] = useContext(PropertiesContext)
    const [catalog, setcatalog] = useState(null)

    useEffect(() => {
        setcatalog(catalogs.find(catalog => catalog._id === selectedCatalog))
    }, [catalogs, selectedCatalog])

    useEffect(() => {

        if (catalog && catalog.entities) {
            dispatchCatalog({
                type: 'SET_CATALOG_CONFIGURATION',
                payload: catalog.entities.map(entity => {
                    const currentValue = catalogConfiguration.find(config => config.entityId === entity._id)?.value
                    return { entityId: entity._id, entity, value: currentValue || entity.type === 'angle' ? 90 : entity.min }
                })
            })

            let nbFolding = 0
            catalog.entities.forEach(entity => {
                if (entity.type === 'angle') {
                    nbFolding++
                }
            })

            dispatch({
                type: 'SET_VALUE',
                payload: { key: 'nbFolding', data: nbFolding }
            })
        }
    }, [catalog, catalogs, dispatch, dispatchCatalog, selectedCatalog])

    /* useEffect(() => {

        if (catalogConfiguration && catalogConfiguration.length > 0 && configurator.materialId && configurator.shadeId && configurator.thicknessId && !checkIfDataAsErrors()) {
            const lengthEntity = catalogConfiguration.find(item => item.entity.type === 'longer')
            if (lengthEntity && lengthEntity.value) {
                dispatch({
                    type: 'SET_VALUE',
                    payload: {
                        key: 'priceLoading',
                        data: true
                    }
                })
                if (idTimeout) {
                    clearTimeout(idTimeout)
                }
                setidTimeout(setTimeout(async () => {

                    const maxWidth = lengthEntity.value || 0;
                    let maxHeight = 0;
                    let nbFolding = 0;

                    catalogConfiguration.forEach(data => {

                        if (data.entity.type === 'line') {
                            maxHeight += Number(data.value)
                        } else if (data.entity.type === 'angle') {
                            nbFolding++
                        }
                    });


                    if (maxWidth > properties.machineMaxWidth && maxHeight > properties.machineMaxLength && maxWidth > properties.machineMaxLength && maxHeight > properties.machineMaxWidth) {
                        dispatchCatalog({
                            type: 'SET_CATALOG_CONFIGURATION',
                            payload: [...catalogConfiguration.map(data => ({ ...data, error: "Dimension trop grande" }))]
                        })
                        return
                    }

                    const length = (maxWidth * 2) + (maxHeight * 2)

                    const response = await get(`/calculate?length=${length}&materialId=${configurator.materialId}&shadeId=${configurator.shadeId}&thicknessId=${configurator.thicknessId}&nbChains=${4}&maxWidth=${maxWidth}&maxHeight=${maxHeight}&nbFolding=${nbFolding}&quantity=${configurator.nbPiece}`)
                    if (response.status === 200) {
                        dispatchCatalog({
                            type: 'SET_CATALOG_PRICE',
                            payload: response.data.message.price
                        })
                    }
                    dispatch({
                        type: 'SET_VALUES',
                        payload: {
                            data: {
                                priceLoading: false,
                                price: response.data.message.price
                            }
                        }
                    })

                }, 1000))
                return () => {
                    clearTimeout(idTimeout);
                }
            }
        }



    }, [catalogConfiguration, configurator.mainForm.height, configurator.mainForm.width, configurator.materialId, configurator.nbFolding, configurator.nbPiece, configurator.shadeId, configurator.thicknessId])
 */
    const handelSetData = useCallback(
        (newData) => {
            dispatchCatalog({
                type: 'SET_CATALOG_CONFIGURATION',
                payload: [...catalogConfiguration.map(data => data.entityId === newData.entityId ? newData : data)]
            })
        },
        [catalogConfiguration, dispatchCatalog],
    )
    const getData = useCallback(
        (entity) => {
            return catalogConfiguration.find(data => data.entityId === entity._id)
        },
        [catalogConfiguration],
    )

    const getListItes = useCallback(
        () => {
            if (catalog) {
                return catalog.entities.map(entity => <ListItem style={{ justifyContent: 'center' }}>

                    <Grid item >
                        <Fab className={classes.fab} disableRipple disableFocusRipple variant="extended" color="primary" aria-label="add">
                            <div>{entity.name}</div>
                        </Fab>
                    </Grid>
                    <Grid item xs={6}>
                        <CatalogdrawingPannelEntitiesListValue setData={handelSetData} data={getData(entity)} entity={entity} />
                    </Grid>

                </ListItem>)
            }
        },
        [catalog, getData, handelSetData],
    )


    return (
        <List className={classes.list} >
            {getListItes()}
        </List>
    )
}

export default CatalogdrawingPannelEntitiesList
