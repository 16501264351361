import { getOneCookie } from "./cookies"

const _getNewSessionId = () => {
    //get new session from server
}

const checkSession = () => {
    const sessionId = getOneCookie('sessionId');
    if (!sessionId) {

    }
}


Object.defineProperty(window, 'sessionId', {
    writable: true,
    value: null,
});

// Fonction pour mettre à jour window.sessionId et déclencher un événement personnalisé
function setSessionId(newValue) {
    // Création et dispatch d'un événement personnalisé
    const event = new CustomEvent('sessionIdChange', { detail: newValue });
    window.dispatchEvent(event);

    // Mise à jour de la propriété
    window.sessionId = newValue;
}

function setEditId(newValue) {
    // Création et dispatch d'un événement personnalisé
    const event = new CustomEvent('editIdChange', { detail: newValue });
    window.dispatchEvent(event);
}

export { checkSession, setSessionId, setEditId }