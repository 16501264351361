import React, { useEffect } from 'react'
import { Grid, Paper, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import CatalogdrawingPannelEntitiesList from './CatalogdrawingPannelEntitiesList';
import CatalogdrawingPannelImage from './CatalogdrawingPannelImage';
import { useContext } from 'react';
import { CatalogContext } from '../../../contexts/CatalogContext';
import { useCallback } from 'react';
import { PropertiesContext } from '../../../contexts/PropertiesContext';

const useStyles = makeStyles((theme) => ({
    displayPriceContainer: {
        alignSelf: 'flex-end',
        marginBottom: theme.spacing(2),
    },
    previewImage: {

        padding: theme.spacing(2),
        '& img': {
            maxHeight: '50vh',
            maxWidth: '100%'
        },
        '& svg': {
            height: '100%'
        },
    },
    paper: {

    },
    buttonContainer: {
        textAlign: 'center'
    },
    listContainer: {
        height: '100%',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center'
    }
}));

function CatalogDrawingPannel() {

    const classes = useStyles();
    const [{ selectedCatalog, catalogConfiguration, hasError }, catalogDispatch] = useContext(CatalogContext)
    const [properties] = useContext(PropertiesContext);
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    const checkMaxMachineCapacity = useCallback(() => {
        const formTotalWidth = catalogConfiguration.filter(c => c.entity.type === 'line' && !c.entity.horizontal).map(c => c.value).reduce((p, c) => p + c, 0);
        const formTotalLength = catalogConfiguration.filter(c => (c.entity.type === 'longer' || (c.entity.type === 'line' && c.entity.horizontal))).map(c => c.value).reduce((p, c) => p + c, 0);
        if (formTotalLength > properties.foldingMaxLength && formTotalLength > properties.foldingMaxWidth) {
            catalogDispatch({
                type: 'SET_ERROR',
                payload: 'maxLength'
            })
        } else if (formTotalWidth > properties.foldingMaxWidth && formTotalWidth > properties.foldingMaxLength) {
            catalogDispatch({
                type: 'SET_ERROR',
                payload: 'mawWidth'
            })
        } else if (hasError) {
            catalogDispatch({
                type: 'SET_ERROR',
                payload: false
            })
        }
    }, [catalogConfiguration, catalogDispatch, hasError, properties.foldingMaxLength, properties.foldingMaxWidth])

    useEffect(() => {
        checkMaxMachineCapacity();
    }, [catalogConfiguration, checkMaxMachineCapacity]);

    return (
        selectedCatalog ? isDownSm ?
            <Grid container alignItems='center' justify='space-around' style={{ overflow: 'auto' }}>
                <Grid item xs={12} sm={10}>
                    <Paper elevation={0} className={classes.previewImage}>
                        <CatalogdrawingPannelImage />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={10} style={{ maxHeight: '80vh', overflow: 'auto' }}>
                    <Paper elevation={0}>
                        <CatalogdrawingPannelEntitiesList />
                    </Paper>
                </Grid>

            </Grid>
            :

            <Grid container alignItems='center' justify='space-around'>
                <Grid item xs={5}>
                    <Paper elevation={0} className={classes.previewImage}>
                        <CatalogdrawingPannelImage />
                    </Paper>
                </Grid>

                <Grid item xs={3} container direction='column' >
                    <Paper elevation={0} className={classes.listContainer}>
                        <Grid item xs={10} container justify='center' style={{ height: '80vh', overflow: 'auto' }}>
                            <CatalogdrawingPannelEntitiesList />
                        </Grid>
                    </Paper>
                </Grid>


            </Grid>

            : null
    )
}

export default CatalogDrawingPannel
