import { useContext, useMemo } from "react";
import { ConfiguratorContext } from "../../contexts/ConfiguratorContext";
import { makeStyles } from "@material-ui/core";
import { PropertiesContext } from "../../contexts/PropertiesContext";

const useStyles = makeStyles(() => ({
    ul: {
        padding: 0
    },
}))

const useValidationForm = () => {

    const styles = useStyles();

    const [configurator] = useContext(ConfiguratorContext);
    const [properties] = useContext(PropertiesContext);

    const translation = (validations) => {

        const errors = [];

        Object.keys(validations).forEach(verb => {
            if (validations[verb].error) {

                if (verb === 'toDeepElements') {
                    errors.push('Il y a des découpes dans des découpes.');
                }
                if (verb === 'multipleChains') {
                    errors.push(`Vous avez trop de contours distincts dans votre dessin (Maximum : ${properties.formValidations.multipleChainsAllowMaxNumber})`);
                }
                if (verb === 'openChains') {
                    errors.push('Il y a des contours ouverts.');
                }
                if (verb === 'chainsToClose' || verb === 'chainToSmall') {
                    if (!errors.includes('Des découpes sont trop proches les unes des autres.')) {

                        errors.push('Des découpes sont trop proches les unes des autres.');
                    }
                }
            }
        });

        return errors;
    }


    const validationOnErrorText = useMemo(() => {
        const errors = translation(configurator.validations);

        return <ul className={styles.ul}>
            {errors.map(error => <li>{error}</li>)}
        </ul>;
    }, [configurator.validations])

    const hasOnlyMultipleChains = useMemo(() => {

        const nbErrors = Object.keys(configurator.validations).filter(key => configurator.validations[key].error).length;

        return nbErrors === 1 && configurator.validations?.multipleChains?.error;
    }, [configurator.validations])

    return {
        validationOnErrorText,
        hasOnlyMultipleChains
    };
}

export default useValidationForm