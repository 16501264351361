import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import uniqid from 'uniqid';
import { MaterialsProvider } from './contexts/MaterialsContext';
import { PropertiesProvider } from './contexts/PropertiesContext';
import { ConfiguratorProvider } from './contexts/ConfiguratorContext';
import { AuthProvider } from 'react-auth-kit';
import { BasketProvider } from './contexts/BasketContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { frFR } from '@material-ui/core/locale';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { CatalogProvider } from './contexts/CatalogContext';
import { getOneCookie } from './modules/cookies';
import { CatalogSvgProvider } from './contexts/CatalogSvgContext';
import { setEditId, setSessionId } from './modules/sessions';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#498ab5',
      main: '#3A7297',
      dark: '#22455d',
      contrastText: '#fff',
    },
    secondary: {
      light: '#164f54',
      main: '#35919D',
      dark: '#164f54',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiPaper: {
      elevation0: {
        //boxShadow: "rgb(89 179 190 / 21%) 0px 0px 20px 11px",
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px"
        //borderLeft: `5px solid #3A7297`
      }

    },

  }
}, frFR);


const root = document.getElementById('root');

window.onmessage = function (event) {
  if (event.data && event.data.type === 'need-sessionId') {
    window.parent.postMessage({ type: 'set-sessionId', data: uniqid() + uniqid() }, "*")
  }

  if (event.data && event.data.type === 'sessionId') {
    window.sessionId = event.data.data;
    setSessionId(event.data.data);
  }

  if (event.data && event.data.type === 'editId') {
    window.editId = event.data.data;
    setEditId(event.data.data);
  }
};



ReactDOM.render(
  <React.StrictMode>
    <MaterialsProvider>
      <PropertiesProvider>
        <ConfiguratorProvider>
          <CatalogProvider>
            <CatalogSvgProvider>
              <BasketProvider>
                <SnackbarProvider>
                  <Router>
                    <AuthProvider authStorageType={"cookie"}
                      cookieDomain={window.location.hostname}
                      cookieSecure={window.location.protocol === "http:"}>
                      <ThemeProvider theme={theme}>
                        <App />
                      </ThemeProvider>
                    </AuthProvider>
                  </Router>

                </SnackbarProvider>
              </BasketProvider>
            </CatalogSvgProvider>
          </CatalogProvider>
        </ConfiguratorProvider>
      </PropertiesProvider>
    </MaterialsProvider>
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
