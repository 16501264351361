import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    content: {
        textAlign: 'center'
    },
    colorPicker: {
        border: 0,
        height: '50px',
        width: '50px'

    }
}))

export default function DialogSelectColor({ open, setOpen, onSelect, value }) {

    const [selectedColor, setselectedColor] = useState(null);

    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}

        >
            <DialogTitle >Selectionner une couleur</DialogTitle>
            <DialogContent className={classes.content}>
                <input className={classes.colorPicker} onChange={(e) => setselectedColor(e.target.value)} value={selectedColor || value} type='color' />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    Annuler
                </Button>
                <Button onClick={() => onSelect(selectedColor)} color="primary" autoFocus>
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    );
}