import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ConfiguratorMenuStepper from './Configurator/ConfiguratorMenuStepper';
import { ConfiguratorContext } from '../../contexts/ConfiguratorContext';
import { CatalogContext } from '../../contexts/CatalogContext';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: '100%'

    },
    buttonConfigurator: {
        height: '125px',
        backgroundImage: 'linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%)',
        color: 'white',
        width: '100%',
        fontSize: '1.2rem'
    },
    buttonDropDXF: {
        height: '125px',
        backgroundImage: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
        color: 'white',
        width: '100%',
        fontSize: '1.2rem'
    },
    title: {
        borderBottom: '1px solide black',
        color: '#4a4852'
    }
}))

function EndUserInterfaceMain() {

    const [, dispatch] = useContext(ConfiguratorContext);
    const [, dispatchCatalog] = useContext(CatalogContext);
    const classes = useStyles();


    useEffect(() => {
        dispatch({
            type: 'RESET'
        })
        dispatchCatalog({
            type: 'RESET'
        })

    }, [])

    return (
        <main className={classes.root}>
            <Grid container justify="center">
                <Grid item sm={12} md={8} lg={6}>
                    <ConfiguratorMenuStepper />
                </Grid>
            </Grid>

        </main>
    )
}

export default EndUserInterfaceMain
