import { Button, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useCallback, useContext, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'
import { PropertiesContext } from '../../../contexts/PropertiesContext'

function ConfiguratorMainFormText({ fontsList, closeDialog }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [properties] = useContext(PropertiesContext);
    const [errorText, seterrorText] = useState(false);
    const [errorTextText, seterrorTextText] = useState('');
    const [text, settext] = useState(configurator.mainForm.text || '');

    const [errorFontSize, seterrorFontSize] = useState(false);
    const [errorFontSizeText, seterrorFontSizeText] = useState('');
    const [fontSize, setfontSize] = useState(configurator.mainForm.fontSize || '');


    const [fontFamily, setfontFamily] = useState(configurator.mainForm.fontFamily || '');

    const handelChangeText = useCallback(
        (e) => {
            if (e.target.value.match(/^[0-9a-zA-Zéèà@&$ù%! ]*$/)) {
                seterrorText(false)
                seterrorTextText('')

                if (e.target.value.length < 1) {
                    seterrorTextText(`Le texte ne peut être vide`)
                    seterrorText(true)
                }
                settext(e.target.value)
            }

        },
        [],
    )

    const handelChangeFontSize = useCallback(
        (e) => {
            if (e.target.value.match(/^[0-9]*$/)) {
                seterrorFontSize(false)
                seterrorFontSizeText('')

                const maxLimit = properties.machineMaxWidth > properties.machineMaxLength ? properties.machineMaxWidth : properties.machineMaxLength;
                const minLimit = properties.machineMinWidth > properties.machineMinLength ? properties.machineMinWidth : properties.machineMinLength;


                if ((Number(e.target.value) < properties.machineMinWidth || Number(e.target.value) > maxLimit)) {
                    seterrorFontSizeText(`valeur comprisent entre ${properties.machineMinWidth}  et ${maxLimit}`)
                    seterrorFontSize(true)
                }
                setfontSize(e.target.value)
            }

        },
        [properties.machineMaxWidth, properties.machineMinWidth],
    )

    const handelChangeFontFamily = useCallback(
        (e) => {
            setfontFamily(e.target.value)
        },
        [],
    )

    const handelClickvalidate = useCallback(
        () => {

            dispatch({
                type: 'SET_VALUE',
                payload: {
                    key: 'mainForm',
                    data: {
                        ...configurator.mainForm, text, fontSize: Number(fontSize), fontFamily, configured: true
                    }
                }
            })
            if (closeDialog) {
                closeDialog()
            }
        },
        [configurator.mainForm, dispatch, fontFamily, fontSize, text],
    )



    return (
        <>
            <Grid container justify='flex-end'>
                <Grid item >
                    {closeDialog && <Button variant="outlined" color={'primary'} style={{ marginRight: '1rem' }} onClick={() => closeDialog()}>Fermer</Button>}
                    <Button disabled={errorText} color="primary" variant="contained" onClick={handelClickvalidate} >Valider</Button>
                </Grid>
            </Grid>
            <Grid container spacing={4} justify='center'>
                <Grid item>
                    <TextField label='Longueur (mm)' error={errorFontSize} helperText={errorFontSizeText || 'Longueur'} type="texte" value={fontSize} onChange={handelChangeFontSize} />
                </Grid>
                <Grid item>
                    <TextField label='Texte' error={errorText} helperText={errorTextText || 'Texte'} type="texte" value={text} onChange={handelChangeText} />
                </Grid>
                <Grid item>
                    <InputLabel id="select-fontFamily-label">Police</InputLabel>
                    <Select
                        labelId="select-fontFamily-label"
                        fullWidth
                        id="select-fontFamily"
                        value={fontFamily}
                        onChange={handelChangeFontFamily}
                    >{
                            fontsList.map(font => <MenuItem value={font.key}>{font.name}</MenuItem>)
                        }

                    </Select>
                </Grid>
            </Grid>
        </>
    )
}

export default ConfiguratorMainFormText
