import React, { useEffect } from 'react'
import { useIsAuthenticated } from 'react-auth-kit';
import { useHistory, useLocation } from 'react-router';

function IsAuth({ children, redirectTo }) {

    const isAuthenticated = useIsAuthenticated()()
    const history = useHistory()
    const location = useLocation()
    useEffect(() => {
        if (!isAuthenticated && location.pathname !== '/signUp' && !location.pathname.includes('/signup/activate/')) {
            if (redirectTo) {
                history.push(`/login?redirectTo=${redirectTo}`)
            } else {
                history.push('/login')
            }
        }
    }, []);

    return (
        <>{isAuthenticated && children}</>
    )
}

export default IsAuth
