import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import DialogConfigurationMenu from '../../Dialog/DialogConfigurationMenu';
import { CloudUpload } from '@material-ui/icons';
import DropSTEP from '../../DropSTEP';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(100vh - 69px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonDropDXF: {
        height: '125px',
        background: theme.palette.secondary.main,
        color: 'white',
        width: '25%',
        fontSize: '1.2rem'
    },
}));


function FromStep() {
    const [configurator] = useContext(ConfiguratorContext);
    const [openDialog, setopenDialog] = useState(true);
    const [startSTEP, setStartSTEP] = useState(false);
    const [sepLoading, setStepLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const classes = useStyles();

    const haveCompletedConfiguration = useCallback(
        () => {
            return configurator.materialId && configurator.shadeId && configurator.nbPiece
        },
        [configurator.materialId, configurator.nbPiece, configurator.shadeId],
    )

    useEffect(() => {
        if (haveCompletedConfiguration()) {
            setopenDialog(false)
            setStartSTEP(true)
        }
    }, [configurator.materialId, configurator.shadeId, configurator.thicknessId])
    return (
        <div className={classes.root}>
            <Button onClick={() => setStartSTEP(true)} style={{ marginTop: '1rem' }} className={classes.buttonDropDXF} color="secondary" variant="contained" startIcon={false ? <CircularProgress /> : <CloudUpload style={{ fontSize: 40 }} />} >Utiliser vos STEP</Button>
            <DialogConfigurationMenu open={openDialog} setopenDialog={setopenDialog} withoutThinckness />

            <DropSTEP setStepLoading={setStepLoading} setActiveStep={setActiveStep} startSTEP={startSTEP} setStartSTEP={setStartSTEP} />

        </div>
    )
}

export default FromStep