import makerjs, { $ } from 'makerjs'
import { Helper } from 'dxf';
import parse from 'parse-svg-path'
import uniqid from 'uniqid'
import { createArcForLWPolyline } from './createArcForLWPolyline';
import { interpolateBSpline } from './geo';
import { applyTransforms } from './applyTransforms';


const polylinesToModels = (polylines) => {
    let modelstab = { models: {} }
    polylines.forEach((polyline, index) => {
        let points = ''
        polyline.vertices.map(item => { return [+item[0].toFixed(3), +item[1].toFixed(3)] }).forEach((vertice, index) => points += vertice.join(' ') + ' ')
        const model = new makerjs.models.ConnectTheDots(false, points);
        modelstab.models[uniqid()] = model

        /* if ((polyline.rgb[0] === 0 && polyline.rgb[1] === 0 && polyline.rgb[2] === 255) || (polyline.rgb[0] === 255 && polyline.rgb[1] === 0 && polyline.rgb[2] === 0)) {
            nbFolding = nbFolding + 1
        } else {
            polyline.vertices.map(item => { return [+item[0].toFixed(3), +item[1].toFixed(3)] }).forEach((vertice, index) => points += vertice.join(' ') + ' ')
            const model = new makerjs.models.ConnectTheDots(false, points);
            modelstab.models[uniqid()] = model
        } */
    })

    return { modelstab }
}

const svgDataPathToModel = (paths) => {
    const model = {};
    paths.forEach(path => makerjs.model.addModel(model, makerjs.importer.fromSVGPathData(path), uniqid()))
    return model;
}

const measureModel = (model) => {
    const { height, width } = makerjs.measure.modelExtents(model);
    return { height, width }
}


const dxfToModel = (dxf) => {

    const createModelFromJson = (jsonData) => {
        let model = {
            paths: {},
            models: {}
        };
        jsonData.forEach(item => {
            const id = uniqid();
            const BLACK_LAYER = 'black';
            if (item.type === 'LINE') {

                let polyline = [
                    [item.start.x, item.start.y],
                    [item.end.x, item.end.y],
                ]
                polyline = applyTransforms(polyline, item.transforms)
                model.paths[id] = new makerjs.paths.Line(polyline[0], polyline[1]);
                setLayerToModel(model.paths[id], BLACK_LAYER, item.layer);

            } else if (item.type === 'ARC') {
                model.paths[id] = new makerjs.paths.Arc([item.x, item.y], item.r, (item.startAngle / Math.PI) * 180, (item.endAngle / Math.PI) * 180);
                setLayerToModel(model.paths[id], BLACK_LAYER, item.layer);

            } else if (item.type === 'CIRCLE') {
                if (item.r >= 0.1) {
                    model.paths[id] = new makerjs.paths.Circle([item.x, item.y], item.r);
                    setLayerToModel(model.paths[id], BLACK_LAYER, item.layer);
                }

            } else if (item.type === 'POLYLINE' || item.type === 'LWPOLYLINE') {
                let polyline = []
                if (item.polyfaceMesh) {
                    // Only return the first polyline because we can't return many
                    // polyline.push(...polyfaceOutline(item)[0])
                } else if (item.polygonMesh) {
                    // Do not attempt to render polygon meshes
                } else if (item.vertices.length) {
                    if (item.closed) {
                        item.vertices = item.vertices.concat(item.vertices[0])
                    }
                    for (let i = 0, il = item.vertices.length; i < il - 1; ++i) {
                        const from = [item.vertices[i].x, item.vertices[i].y]
                        const to = [item.vertices[i + 1].x, item.vertices[i + 1].y]
                        polyline.push(from)
                        if (item.vertices[i].bulge) {
                            polyline = polyline.concat(
                                createArcForLWPolyline(from, to, item.vertices[i].bulge),
                            )
                        }
                        if (i === il - 2) {
                            polyline.push(to)
                        }
                    }
                } else {
                    console.log('Polyline entity with no vertices')
                }
                let points = '';
                polyline.map(item => { return [+item[0].toFixed(3), +item[1].toFixed(3)] }).forEach((vertice, index) => points += vertice.join(' ') + ' ')
                model.models[id] = new makerjs.models.ConnectTheDots(false, points);
                setLayerToModel(model.models[id], BLACK_LAYER, item.layer);

            } else if (item.type === 'SPLINE') {
                const options = {};
                const polyline2 = interpolateBSpline(
                    item.controlPoints,
                    item.degree,
                    item.knots,
                    options.interpolationsPerSplineSegment,
                    item.weights,
                )
                let points2 = '';
                polyline2.map(item => { return [+item[0].toFixed(3), +item[1].toFixed(3)] }).forEach((vertice, index) => points2 += vertice.join(' ') + ' ')
                model.models[id] = new makerjs.models.ConnectTheDots(false, points2);
                setLayerToModel(model.models[id], BLACK_LAYER, item.layer);

            } else {
                console.warn('item.type not handled : ', item.type)
            }

        });

        return model;
    }

    const helper = new Helper(dxf)
    const jsonData = helper.denormalise();

    return createModelFromJson(jsonData);
}

const svgToModel = async (svg) => {
    let lines = svg.match(/(?:d=")[\s\S]*?(?=")/g).map(line => line.replace(/d="/, "")).filter(line => line[0] === 'M' || line[0] === 'm');
    const model = {
        models: {}
    }

    function fixPath(path) {
        var resolvedParts = [];

        parse(path).forEach(function (command) {
            resolvedParts.push(command.join(' '));
        });

        return resolvedParts.join(' ');
    }

    lines.forEach((line, index) => {
        const formatedLine = fixPath(line)
        model.models[index] = makerjs.importer.fromSVGPathData(formatedLine);

    })
    return model;
}

const modelToSvg = (model) => {
    return makerjs.exporter.toSVG(model);
}

const findToDeepElement = (chains, model) => {

    function findAllAtDepthOrMore(json, depth, currentDepth = 1, results = [], parent) {


        if (currentDepth >= depth) {


            let ok = false;

            parent.links.forEach(link => {
                if (link.walkedPath.layer !== 'green') {
                    ok = true;
                }

            })
            if (ok) {
                results.push(json);
            }

        }

        if (json.contains && json.contains.length > 0) {
            const parent = json;
            json.contains.forEach(child => {
                findAllAtDepthOrMore(child, depth, currentDepth + 1, results, parent);
            });
        }

        return results;
    }
    const depth = 3;
    return findAllAtDepthOrMore(chains[0], depth)

}

const asMultipleChains = (chains) => {

    const multipleChains = chains.filter(chain => {

        let isMarking = false;

        chain.links.forEach(link => {
            if (link.walkedPath.layer === 'green') {
                isMarking = true;
            }

        })
        return !isMarking;
    })

    return multipleChains?.length;
}

const asChainsOpen = (chains) => {

    const openChains = chains.filter(chain => {

        let isMarking = false;

        chain.links.forEach(link => {
            if (link.walkedPath.layer === 'green') {
                isMarking = true;
            }

        })
        return !chain.endless && !isMarking;
    })

    return openChains;
}

const setLayerToModel = (model, color, name) => {
    model.layer = color;
    model.layerName = name;
}

const asChainsToClose = (chains, minDistanceForError) => {
    const elements = [];
    chains.forEach(c => {
        const points = makerjs.chain.toPoints(c, 1)
        elements.push({ chain: c, points });
    })

    const distanceMinimaleEntrePolygones = (poly1, poly2) => {
        let minDistance = Infinity;

        for (let i = 0; i < poly1.length; i++) {
            for (let j = 0; j < poly2.length; j++) {
                let point1 = poly1[i];
                let point2 = poly2[j];

                let distance = makerjs.measure.pointDistance(point1, point2);
                if (distance > 0.1 && distance < minDistance) {
                    minDistance = distance;
                }
            }
        }

        return minDistance;
    }

    for (let i = 0; i < elements.length; i++) {
        for (let j = i + 1; j < elements.length; j++) {
            let distance = distanceMinimaleEntrePolygones(elements[i].points, elements[j].points);
            if (distance < minDistanceForError && elements[i].chain?.pathLength > 0.1 && elements[j].chain?.pathLength > 0.1) {
                return [elements[i].chain, elements[j].chain]
            }
        }
    }
    return [];
}

const asChainToSmall = (chains, minDistanceForError) => {
    const elements = [];
    chains.forEach(chain => {
        const model = makerjs.chain.toNewModel(chain);
        elements.push({ model, chain });
    })

    for (const { model, chain } of elements) {

        let measure = makerjs.measure.modelExtents(model)
        if (measure && measure.width > 0.1 && measure.height > 0.1 && (measure.width < minDistanceForError || measure.height < minDistanceForError)) {
            return [chain]
        }

    }
    return [];
}

const validateModel = (model, formValidations = {}, thickness = null, isValidationDesactived = false) => {

    const validations = {
        toDeepElements: {
            error: false,
            datas: []
        },
        multipleChains: {
            error: false,
            datas: [],
            nbChains: 1,
        },
        openChains: {
            error: false,
            datas: []
        },
        chainsToClose: {
            error: false,
            datas: []
        },
        chainToSmall: {
            error: false,
            datas: []
        },
    }

    if (formValidations.validationEnabled && isValidationDesactived === false) {

        const chains = makerjs.model.findChains(model, { contain: true, pointMatchingDistance: 0.001 });

        const deepElements = findToDeepElement(chains, model);
        if (deepElements && deepElements.length) {
            validations.toDeepElements.error = true;
            validations.toDeepElements.datas = deepElements;
        }

        const multipleChaines = asMultipleChains(chains);

        const multipleChainsAllowMaxNumber = formValidations.multipleChainsAllowMaxNumber || Infinity;
        if ((!formValidations.isMultipleChainsAllow && multipleChaines > 1) || formValidations.isMultipleChainsAllow && multipleChaines > multipleChainsAllowMaxNumber) {
            validations.multipleChains.error = true;
            validations.multipleChains.datas = chains;
        }
        validations.multipleChains.nbChains = multipleChaines

        const openChains = asChainsOpen(chains);

        if (openChains.length > 0) {
            validations.openChains.error = true;
            validations.openChains.datas = openChains;
        }


        const flatChains = makerjs.model.findChains(model, { pointMatchingDistance: 0.001 });

        if (thickness) {
            const chainsToClose = asChainsToClose(flatChains, thickness / 2)

            if (chainsToClose.length > 0) {
                validations.chainsToClose.error = true;
                validations.chainsToClose.datas = chainsToClose;
            }

            const chainToSmall = asChainToSmall(flatChains, thickness / 2)
            if (chainToSmall.length > 0) {
                validations.chainToSmall.error = true;
                validations.chainToSmall.datas = chainToSmall;
            }
        }
    }


    return validations;

}

export {
    polylinesToModels,
    svgDataPathToModel,
    measureModel,
    svgToModel,
    findToDeepElement,
    asMultipleChains,
    dxfToModel,
    setLayerToModel,
    asChainsOpen,
    modelToSvg,
    validateModel
}