import { Button, Grid, TextField } from '@material-ui/core'
import React, { useCallback, useContext, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'
import configCircle from '../../../assets/svg/configCircle.svg'
import { PropertiesContext } from '../../../contexts/PropertiesContext'

function ConfiguratorMainFormCircle({ closeDialog }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [properties] = useContext(PropertiesContext);
    const [errorR, seterrorR] = useState(false);
    const [errorRText, seterrorRText] = useState('');
    const [diametre, setdiametre] = useState(configurator.mainForm.radius * 2 || '');
    const handelChangeRadius = useCallback(
        (e) => {

            if (e.target.value.match(/^[0-9]*$/)) {
                seterrorR(false)
                seterrorRText('')

                const maxLimit = properties.machineMaxWidth < properties.machineMaxLength ? properties.machineMaxWidth : properties.machineMaxLength;
                const minLimit = properties.machineMinWidth < properties.machineMinLength ? properties.machineMinWidth : properties.machineMinLength;

                if ((Number(e.target.value) < minLimit || Number(e.target.value) > maxLimit)) {
                    seterrorRText(`valeur comprisent entre ${minLimit} et ${maxLimit}`)
                    seterrorR(true)
                }
                setdiametre(e.target.value)
            }

        },
        [properties.machineMaxLength, properties.machineMaxWidth, properties.machineMinLength, properties.machineMinWidth],
    )

    const handelClickvalidate = useCallback(
        () => {
            dispatch({
                type: 'SET_VALUE',
                payload: {
                    key: 'mainForm',
                    data: {
                        ...configurator.mainForm, height: Number(diametre), width: Number(diametre), radius: Number(diametre / 2), configured: true
                    }
                }
            })

            if (closeDialog) {
                closeDialog();
            }
        },
        [configurator.mainForm, diametre, dispatch],
    )

    return (
        <>
            <Grid container justify='flex-end'>
                <Grid item >
                    {closeDialog && <Button variant="outlined" color={'primary'} style={{ marginRight: '1rem' }} onClick={() => closeDialog()}>Fermer</Button>}
                    <Button disabled={errorR} color="primary" variant="contained" onClick={handelClickvalidate} >Valider</Button>
                </Grid>
            </Grid>
            <Grid container spacing={4} justify='center'>
                <Grid item>
                    <TextField label='D' error={errorR} helperText={errorRText || 'Diamètre du cercle (mm)'} type="texte" value={diametre} onChange={handelChangeRadius} />
                </Grid>
            </Grid>
            <Grid container>
                <img alt='configuration rectangle' style={{ display: 'block', margin: '2rem auto', height: '300px' }} src={configCircle} />
            </Grid>
        </>
    )
}

export default ConfiguratorMainFormCircle
