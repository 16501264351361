import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import DialogConfigurationMenu from '../../Dialog/DialogConfigurationMenu';
import { CloudUpload } from '@material-ui/icons';
import DropSTEP from '../../DropSTEP';
import DropImg from '../../DropImg';
import ModalProcessImageVecto from '../../Dialog/ModalProcessImageVecto';
import { useHistory } from 'react-router-dom';
import ProcessUploadedSvg from '../../ProcessUploadedSvg';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(100vh - 69px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonDropDXF: {
        height: '125px',
        background: theme.palette.secondary.main,
        color: 'white',
        width: '25%',
        fontSize: '1.2rem'
    },
}));


function FromSvg() {
    const [configurator, dispatch] = useContext(ConfiguratorContext);
    const [openDialog, setopenDialog] = useState(true);
    const [startSvg, setstartSvg] = useState(false);
    const history = useHistory()

    const classes = useStyles();

    const haveCompletedConfiguration = useCallback(
        () => {
            return configurator.materialId && configurator.shadeId && configurator.thicknessId && configurator.nbPiece
        },
        [configurator.materialId, configurator.nbPiece, configurator.shadeId, configurator.thicknessId],
    )

    useEffect(() => {
        if (haveCompletedConfiguration()) {
            setopenDialog(false)
            setstartSvg(true)
        }
    }, [configurator.materialId, configurator.shadeId, configurator.thicknessId, haveCompletedConfiguration])

    const handleVecto = useCallback(({ lines, targetHeight, targetWidth, originalHeight, originalWidth, ratio, model }) => {

        dispatch({
            type: 'SET_VALUES',
            payload: {
                data: {
                    form: 4,
                    mainForm: {
                        paths: lines,
                        configured: true,
                        originalHeight: originalHeight,
                        originalWidth: originalWidth,
                        height: targetHeight,
                        width: targetWidth,
                        ratio,
                        model
                    }
                }
            }
        })
        setstartSvg(false)
        history.push('/configurator');
    }, [dispatch])

    return (
        <div className={classes.root}>
            <Button onClick={() => setstartSvg(true)} style={{ marginTop: '1rem' }} className={classes.buttonDropDXF} color="secondary" variant="contained" startIcon={false ? <CircularProgress /> : <CloudUpload style={{ fontSize: 40 }} />} >Utiliser vos SVG</Button>
            <DialogConfigurationMenu open={openDialog} setopenDialog={setopenDialog} />
            <DropImg startIMG={startSvg} setstartIMG={setstartSvg} onValidate={handleVecto} modal={<ProcessUploadedSvg />} authorizedTypes={['svg']} />
        </div>
    )
}

export default FromSvg