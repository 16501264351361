import { Checkbox, FormControlLabel, Grid, makeStyles, Paper, TextField } from '@material-ui/core'
import React, { useContext, useCallback, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'

const useStyles = makeStyles((theme) => ({
    paper: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem'
    },
    line: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1),
            textAlign: 'center'
        }
    }
}))


function ConfiguratorConfigPolygone({ setcurentDraggingObjectPosition, selectedForm }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [x, setx] = useState(null);
    const [y, sety] = useState(null);
    const [d, setd] = useState(null);
    const [r, setr] = useState(null);

    const classes = useStyles();

    const handelChange = useCallback(
        (e) => {
            setd(e.target.value)
        },
        [],
    )

    const handleBlur = useCallback(() => {
        try {

            // eslint-disable-next-line no-eval
            const evaluateValue = eval(d);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_VALUE',
                    payload: {
                        key: 'forms',
                        data: configurator.forms.map(item => item.id !== configurator.selectedForm ? item : { ...item, radius: evaluateValue / 2, realRadius: evaluateValue / 2 })
                    }

                })
                setd(evaluateValue)
            }
        } catch (e) {
            setd(null)
        }
    }, [configurator.forms, configurator.selectedForm, d, dispatch])


    const handelChangeX = (e) => setx(e.target.value);

    const handleBlurX = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(x);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            x: evaluateValue
                        }
                    }

                })
                setcurentDraggingObjectPosition((v) => ({ ...v, x: evaluateValue }))
            }
            setx(evaluateValue)
        } catch (e) {
            setx(null)
        }
    }, [configurator.selectedForm, dispatch, setcurentDraggingObjectPosition, x])


    const handelChangeY = (e) => sety(e.target.value);

    const handleBlurY = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(y);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            y: evaluateValue
                        }
                    }

                })
                setcurentDraggingObjectPosition((v) => ({ ...v, y: evaluateValue }))
            }
            sety(evaluateValue)
        } catch (e) {
            sety(null)
        }
    }, [configurator.selectedForm, dispatch, setcurentDraggingObjectPosition, y])


    const handelChangeNbSides = useCallback(
        (e) => {

            dispatch({
                type: 'SET_VALUE',
                payload: {
                    key: 'forms',
                    data: configurator.forms.map(item => item.id !== configurator.selectedForm ? item : { ...item, nbSides: e.target.value })
                }

            })
        },
        [configurator.forms, configurator.selectedForm, dispatch],
    )

    const handelChangeRotation = (e) => setr(e.target.value);

    const handleBlurR = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(r);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_ROTATION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            rotation: evaluateValue
                        }
                    }

                })
            }
            setr(evaluateValue)
        } catch (e) {
            setr(null)
        }
    }, [configurator.selectedForm, dispatch, r])

    const handleChangeGraved = useCallback((e) => {
        dispatch({
            type: 'SET_VALUE_FORM',
            payload: {
                form: configurator.selectedForm,
                data: {
                    graved: e.target.checked,
                    unionType: e.target.checked ? 'del' : selectedForm.unionType

                }
            }
        })
    }, [configurator.selectedForm, dispatch, selectedForm.unionType])

    const getValue = useCallback(
        () => {
            return d === null ? selectedForm.radius * 2 : d;
        },
        [d, selectedForm.radius],
    )

    const getValueNbSides = useCallback(
        () => {
            return selectedForm.nbSides
        },
        [selectedForm.nbSides],
    )

    const getValueX = useCallback(
        () => {
            return x === null ? selectedForm.realx : x
        },
        [selectedForm.realx, x],
    )

    const getValueY = useCallback(
        () => {
            return y === null ? selectedForm.realy : y
        },
        [selectedForm.realy, y],
    )

    const getValueRotation = useCallback(
        () => {
            return r === null ? selectedForm.rotation : r
        },
        [r, selectedForm.rotation],
    )

    return (

        <Grid container justify="center" alignItems='center' >
            <Grid item xs={5} md={2} lg={2} className={classes.line}>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField label="Diamètre (mm)" value={getValue()} onBlur={handleBlur} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlur()} margin="dense" onChange={handelChange} />
                </Paper>
            </Grid>

            <Grid item xs={5} md={2} lg={2} className={classes.line}>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField label="Nombre de cotés" value={getValueNbSides()} margin="dense" onChange={handelChangeNbSides} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField label="X (mm)" value={getValueX()} onBlur={handleBlurX} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurX()} margin="dense" onChange={handelChangeX} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField label="Y (mm)" value={getValueY()} onBlur={handleBlurY} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurY()} margin="dense" onChange={handelChangeY} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField label="Rotation (°)" value={getValueRotation()} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurR()} onBlur={handleBlurR} margin="dense" onChange={handelChangeRotation} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedForm.graved}
                                onChange={handleChangeGraved}
                                name="graved"
                                color="primary"
                            />
                        }
                        label="Graver"
                    />
                </Paper>
            </Grid>
        </Grid>


    )
}

export default ConfiguratorConfigPolygone
