import { makeStyles, Paper, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useCallback, useContext } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'
import ConfiguratorMainFormCircle from './ConfiguratorMainFormCircle'
import ConfiguratorMainFormL from './ConfiguratorMainFormL'
import ConfiguratorMainFormRec from './ConfiguratorMainFormRec'
import ConfiguratorMainFormText from './ConfiguratorMainFormText'
import ConfiguratorMainFormTriangle from './ConfiguratorMainFormTriangle'


const useStyles = makeStyles((theme) => ({
    paper: {
        //width: '75%',
        //height: '80%',
        margin: '0 auto',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        position: 'absolute',
        top: '6px',
        bottom: '6px',
        right: '6px',
        left: 0
    }
}))



function ConfiguratorMainForm({ closeDialog, fontsList }) {

    const classes = useStyles()
    const theme = useTheme()
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const [configurator, dispatch] = useContext(ConfiguratorContext)

    const getMenu = useCallback(
        () => {
            switch (configurator.form) {
                case 0:
                    return <ConfiguratorMainFormRec closeDialog={closeDialog} />
                case 1:
                    return <ConfiguratorMainFormCircle closeDialog={closeDialog} />
                case 2:
                    return <ConfiguratorMainFormTriangle closeDialog={closeDialog} />
                case 3:
                    return <ConfiguratorMainFormL closeDialog={closeDialog} />
                case 5:
                    return <ConfiguratorMainFormText fontsList={fontsList} closeDialog={closeDialog} />
                default:
                    break;
            }
        },
        [configurator.form],
    )

    return (
        <Paper className={isDownSm ? null : classes.paper} elevation={0} style={{ overflow: isDownSm ? 'inherit' : 'auto', height: '100%', padding: '1rem' }}>
            {getMenu()}
        </Paper>
    )
}

export default ConfiguratorMainForm
