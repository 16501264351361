import { Dialog, LinearProgress, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, TextField } from '@material-ui/core'
import React, {useState, useCallback, useContext} from 'react'
import axios from 'axios'
import { PropertiesContext } from '../contexts/PropertiesContext';

function ModalAddTransportPlan({open, setdiagOpen}) {

    const [loading, setloading] = useState(false);
    const [properties, dispatch] = useContext(PropertiesContext)
    const [price, setprice] = useState('');
    const [to, setto] = useState('');

    const resetForm = useCallback(
        () => {
            setprice('')
            setto('')
        },
        [],
    )

    const handelClickAdd = useCallback(
        () => {
            (async () => {
                setloading(true)
                const newproperties = {...properties, transportPlans: [...properties.transportPlans, {price, to }]}
                try {
                    
                    await axios.put(process.env.REACT_APP_BASE_API + '/propertie/' + properties._id, newproperties)
                    dispatch({
                        type: 'SET_PROPERTY',
                        payload: newproperties
                    })
                    setloading(false)
                    resetForm()
                    setdiagOpen(false)
                } catch(e) {

                }
               
            })()
            
        },
        [dispatch, price, properties, resetForm, setdiagOpen, to],
    )

    return (
        <Dialog open={open} onClose={() => setdiagOpen(false)} aria-labelledby="form-dialog-title">
            { loading ? <LinearProgress /> : null }
            <DialogTitle id="form-dialog-title" align="center">Ajouter un forfait de transport</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Veuillez remplir les informations suivantes.
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <TextField id="add-property-transport-plan" label="poid maximum"  helperText={`Poid en kg de fin de forfait`} value={to} onChange={(e) => setto(e.target.value)} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField id="add-property-transport-plan" label="prix"  helperText={`Prix du forfait`} value={price} onChange={(e) => setprice(e.target.value)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
            <Button variant="outlined" onClick={() => setdiagOpen(false)}>
                Annuler
            </Button>
            <Button variant="outlined" onClick={handelClickAdd} color="primary">
                Enregistrer
            </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalAddTransportPlan
