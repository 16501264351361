import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import DialogConfigurationMenu from '../../Dialog/DialogConfigurationMenu';
import DropDXF from '../../DropDXF';
import { CloudUpload } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(100vh - 69px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonDropDXF: {
        height: '125px',
        background: theme.palette.secondary.main,
        color: 'white',
        width: '25%',
        fontSize: '1.2rem'
    },
}));


function FromDxf() {
    const [configurator] = useContext(ConfiguratorContext);
    const [openDialog, setopenDialog] = useState(true);
    const [startDXF, setstartDXF] = useState(false);
    const [dxfLoading, setdxfLoading] = useState(false);
    const [activeStep, setactiveStep] = useState(0);

    const classes = useStyles();

    const haveCompletedConfiguration = useCallback(
        () => {
            return configurator.materialId && configurator.shadeId && configurator.thicknessId && configurator.nbPiece
        },
        [configurator.materialId, configurator.nbPiece, configurator.shadeId, configurator.thicknessId],
    )

    useEffect(() => {
        if (haveCompletedConfiguration()) {
            setopenDialog(false)
            setstartDXF(true)
        }
    }, [configurator.materialId, configurator.shadeId, configurator.thicknessId, haveCompletedConfiguration])
    return (
        <div className={classes.root}>
            <Button onClick={() => setstartDXF(true)} style={{ marginTop: '1rem' }} className={classes.buttonDropDXF} color="secondary" variant="contained" startIcon={dxfLoading ? <CircularProgress style={{ color: 'white' }} /> : <CloudUpload style={{ fontSize: 40 }} />} >Utiliser vos DXF</Button>
            <DialogConfigurationMenu open={openDialog} setopenDialog={setopenDialog} />
            <DropDXF setdxfLoading={setdxfLoading} setActiveStep={setactiveStep} startDXF={startDXF} setstartDXF={setstartDXF} />
        </div>
    )
}

export default FromDxf