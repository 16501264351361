import { ButtonGroup, CircularProgress, DialogContent, IconButton, List, ListItem, makeStyles, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { MaterialsContext, useMaterials } from '../contexts/MaterialsContext'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useAddItemBasket, useDeleteItemBasket, useEditBasketItem, useGetBasket } from '../contexts/BasketContext'
import { Alert, AlertTitle } from '@material-ui/lab';
import { calculPriceWithVat, displayFormatedPrice, getFileExtension } from '../modules/utils'
import { PropertiesContext } from '../contexts/PropertiesContext'
import { useHistory } from 'react-router-dom';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext'
import { CatalogContext } from '../contexts/CatalogContext'
import { TableCell, TableRow } from '@material-ui/core';
import { Dialog, DialogTitle } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ConfiguratorMenu from './EndUser/Configurator/ConfiguratorMenu'
import ConfirmationModalWrapper from './utils/ConfirmationModalWrapper';


const useStyles = makeStyles((theme) => ({

    root: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        width: '100%',

    },
    svg: {
        height: '150px'
    },
    image: {
        height: '75px',
        width: '75px',
        objectFit: 'contain'
    },
    price: {
        marginLeft: theme.spacing(0.5),
        fontWeight: 'bold',
        width: '50px'
    },
    priceChip: {
        width: '100%',
    },
    priceChipVat: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    previewContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '75px',
        height: '75px',
        borderRadius: '5px',
        cursor: 'pointer',
        margin: '0 auto'
    },
    table: {
        '& th, & td': {
            border: '1px solid grey',
            borderCollapse: 'collapse',
        },
    },
    columnDivider: {
        borderRight: '1px solid grey',
    },
    actionButton: {
        minWidth: '30px',
    },
    smallTextField: {
        width: '60px',
        '& input': {
            textAlign: 'center',
        }
    },
    imgPreview: {
        maxHeight: '80%',
        minWidth: '80%',
        objectFit: 'contain'
    },
}))


function CartLine({ item, control = true, isUser = false }) {
    const classes = useStyles()
    const [materials] = useContext(MaterialsContext)
    const [configurator, dispatchConfigurator] = useContext(ConfiguratorContext)
    const [, dispatchCatalog] = useContext(CatalogContext)
    const [property] = useContext(PropertiesContext);
    const { getBasket } = useGetBasket()
    const { editItemBasket } = useEditBasketItem()
    const { deleteItemBasket } = useDeleteItemBasket()
    const [imgPreview, setimgPreview] = useState(null);
    const [, setloadingDXF] = useState(true);
    const [loadingQuantity, setloadingQuantity] = useState(false)
    const [loadingDelete, setloadingDelete] = useState(false);
    const [loadingError, setloadingError] = useState(false);
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [tmpItem, setTmpItem] = useState({ ...item });

    const { addItemBasket } = useAddItemBasket()
    const { getThickness } = useMaterials();

    useEffect(() => {
        setTmpItem({ ...item })
    }, [item, item.configurator])

    useEffect(() => {
        (async () => {
            setloadingDXF(true)
            try {

                const image = item.dxf
                setimgPreview(<img className={classes.image} src={`${process.env.REACT_APP_BASE_API}/download/image/${image}.png`} alt="" />)

            } catch (e) {
                setloadingError(true)
            }
            setloadingDXF(false)
        })()

    }, []);


    const handelClickChangeQte = async (e) => {
        const quantity = e.target.value
        if (quantity > 0) {
            setloadingQuantity(true)
            const newBasketItem = {
                ...item,
                quantity: e.target.value
            }
            await editItemBasket(newBasketItem)
            await getBasket();
            setloadingQuantity(false)
        }
    }

    const handelClickDelete = useCallback(
        () => {
            (async () => {
                setloadingDelete(true);
                await deleteItemBasket(item._id);
                await getBasket();
                setloadingDelete(false);
            })()
        },
        [deleteItemBasket, getBasket, item._id],
    )

    const handleEditProduct = useCallback(() => {
        dispatchConfigurator({
            type: 'EDIT',
            payload: {
                data: item.configurator,
                quantity: item.quantity,
                id: item._id
            }
        });
        if (item.configurator?.isFromCatalog) {
            dispatchCatalog({
                type: 'SET_STEP',
                payload: 1,
            });
            dispatchCatalog({
                type: 'SET_CATALOG_CONFIGURATION',
                payload: item.catalogConfiguration.catalogEntitiesConfiguration,
            });
            dispatchCatalog({
                type: 'SET_SELECTED_CATALOG',
                payload: item.catalogConfiguration.catalogId,
            });
        }

        history.push(item.configurator?.isFromCatalog ? '/catalog' : '/configurator');

    }, [item, history]);

    const handleCloneProduct = async () => {

        const clone = {
            dxf: item.dxf,
            length: item.length,
            nbChains: item.nbChains,
            materialId: item.materialId,
            shadeId: item.shadeId,
            thicknessId: item.thicknessId,
            maxWidth: item.maxWidth,
            maxHeight: item.maxHeight,
            weight: item.weight,
            quantity: 1,
            configurator: item.configurator,
            nbFolding: item.nbFolding,
            isDuplicate: true
        }
        if (item.isFromDxf) {
            clone.isFromDxf = true;
        }
        if (item.isStepItem) {
            clone.isStepItem = true;
        }

        if (item.isCatalogItem) {
            clone.isCatalogItem = true;
            clone.catalogConfiguration = item.catalogConfiguration;
        }
        await addItemBasket(clone)
        await getBasket();

    };

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const getMaterialImageNameFromId = (materialId) => materials.find(material => material._id === materialId)?.image;


    const overwriteonChangeMaterial = (e) => {
        setTmpItem({
            ...tmpItem,
            materialId: e.target.value,
            shadeId: null,
            thicknessId: null,
            configurator: {
                ...tmpItem.configurator,
                materialId: e.target.value,
                materialImageName: getMaterialImageNameFromId(e.target.value),
                shadeId: null,
                shadeImageName: null,
                shadeColor: null,
                thicknessId: null
            }
        })
    };

    const getShadeImageNameFromId = (shadeId) => materials.find(material => material._id === tmpItem.materialId)?.shades.find(shade => shade._id === shadeId)?.image;
    const getShadeColorFromId = (shadeId) => materials.find(material => material._id === tmpItem.materialId)?.shades.find(shade => shade._id === shadeId)?.color;


    const overwriteonChangeShade = (e) => {
        setTmpItem({
            ...tmpItem,
            shadeId: e.target.value,
            thicknessId: null,
            configurator: {
                ...tmpItem.configurator,
                shadeId: e.target.value,
                shadeImageName: getShadeImageNameFromId(e.target.value) || null,
                shadeColor: getShadeColorFromId(e.target.value) || null,
                thicknessId: null,
            }
        })
    };

    const overwriteonChangeThickness = async (e) => {

        const newItem = {
            ...tmpItem,
            thicknessId: e.target.value,
            configurator: {
                ...tmpItem.configurator,
                thicknessId: e.target.value,
            }
        }
        setTmpItem(newItem)

        await editItemBasket(newItem)
        await getBasket();

    }

    const itemThickness = getThickness(item.materialId, item.shadeId, item.thicknessId)

    return (
        <>
            <TableRow>
                <TableCell>
                    {
                        loadingError ?
                            <Alert severity="error">
                                <AlertTitle>Erreur du chargement de l'apperçus</AlertTitle>

                            </Alert>
                            : <div
                                className={classes.previewContainer}
                                onClick={handleClickOpenDialog}
                            >
                                {imgPreview}
                            </div>
                    }
                </TableCell>
                <TableCell>
                    <ConfiguratorMenu
                        withoutNbPiece
                        materialShrink
                        shadeShrink
                        thicknessShrink
                        readOnly={!control}
                        overwriteValue={tmpItem}
                        overwriteonChangeMaterial={overwriteonChangeMaterial}
                        overwriteonChangeShade={overwriteonChangeShade}
                        overwriteonChangeThickness={overwriteonChangeThickness}
                        thicknessReadOnly={item.isStepItem}
                        stepThickness={(item.isStepItem && itemThickness?.value) || null}
                    />
                </TableCell>
                <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                        <Typography variant="body2" >
                            <strong>{item.maxWidth}</strong>
                        </Typography>
                        <Typography variant="body2" style={{ margin: '0px 5px' }}>
                            x
                        </Typography>
                        <Typography variant="body2" >
                            <strong>{item.maxHeight}</strong>
                        </Typography>
                        <Typography variant="body2" style={{ marginLeft: '8px' }}>
                            mm
                        </Typography>
                    </div>
                    <Typography variant="body2">
                        <strong>{item.weight}</strong> kg
                    </Typography>
                </TableCell>
                <TableCell>
                    <TextField
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        disabled={!control}
                        value={item.quantity}
                        onChange={handelClickChangeQte}
                        className={classes.smallTextField}
                    />
                </TableCell>
                <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        <Typography style={{ width: '50px' }} variant="body2" gutterBottom >
                            PU HT
                        </Typography>
                        <Typography className={classes.price} variant="body2" gutterBottom >
                            {loadingQuantity ? '--,--' : displayFormatedPrice(item.price / item.quantity)} €
                        </Typography>
                    </div>
                    <br></br>
                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        <Typography style={{ width: '50px' }} variant="body2" gutterBottom >
                            HT
                        </Typography>
                        <Typography className={classes.price} variant="body2" gutterBottom >
                            {loadingQuantity ? '--,--' : displayFormatedPrice(item.price)} €
                        </Typography>
                    </div>
                    <br></br>
                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        <Typography style={{ width: '50px' }} variant="body2" gutterBottom display='inline'>
                            TTC
                        </Typography>
                        <Typography className={classes.price} variant="button" gutterBottom display='inline'>
                            {loadingQuantity ? '--,--' : displayFormatedPrice(calculPriceWithVat(item.price, property.vatRate))} €
                        </Typography>
                    </div>
                </TableCell>

                <TableCell>
                    <ButtonGroup color="primary">
                        {
                            control &&
                            <Tooltip title="Supprimer" placement="top">
                                <IconButton className={classes.actionButton}>
                                    {loadingDelete ? <CircularProgress size={20} color='secondary' /> : <ConfirmationModalWrapper onConfirm={handelClickDelete}> <DeleteOutlineOutlinedIcon /></ConfirmationModalWrapper>}
                                </IconButton>
                            </Tooltip>
                        }

                        {(isUser || control) &&
                            <Tooltip title="Dupliquer" placement="top">
                                <IconButton className={classes.actionButton}>
                                    <FileCopyIcon onClick={handleCloneProduct} />
                                </IconButton>

                            </Tooltip>
                        }
                        {
                            control && !item.isStepItem && !item.isFromDxf &&
                            <Tooltip title="Editer" placement="top">
                                <IconButton className={classes.actionButton}>
                                    <EditOutlinedIcon onClick={handleEditProduct} />
                                </IconButton>
                            </Tooltip>
                        }

                    </ButtonGroup>
                </TableCell>

            </TableRow>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <div>
                        {
                            loadingError ?
                                <Alert severity="error">
                                    <AlertTitle>Erreur du chargement de l'apperçus</AlertTitle>
                                </Alert>
                                : <div
                                    className={classes.previewContainer}
                                    style={{ width: '600px', height: '600px', borderRadius: '5px' }}
                                >
                                    <img className={classes.imgPreview} src={`${process.env.REACT_APP_BASE_API}/download/image/${item.dxf}.png`} alt="" />
                                </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </>

    );


}

export default CartLine
