import React, { useContext, useReducer } from 'react';
import { useGet, usePost, usePut } from '../modules/request';
import { SnackbarContext } from './SnackbarContext';


const ContactModelsContext = React.createContext([[], () => { }]);

const initialState = []

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_MODELS":
      return action.payload
    case "SET_MODEL":
      return [...state, { ...action.payload.datas }]
    case "SET_PROPERTIES":
      return state.map(contactModel => contactModel._id !== action.payload.id ? contactModel : { ...contactModel, ...action.payload.properties })
    case "SET_PROPERTY":
      return state.map(contactModel => contactModel._id !== action.payload.id ? contactModel : {
        ...contactModel,
        [action.payload.key]: action.payload.value
      })
    default:
      throw new Error();
  }
};


const useGetContactModels = () => {
  const [, dispatch] = useContext(ContactModelsContext);
  const get = useGet()
  return {
    getContactModels: async () => {
      const res = await get('/contactModel')
      dispatch({
        type: 'SET_MODELS',
        payload: res.data.message
      })
    },
  };
}

const useEditContactModels = () => {
  const [, dispatch] = useContext(ContactModelsContext);
  const [, dispatchSnackbar] = useContext(SnackbarContext)
  const put = usePut()
  return {
    editContactModel: async (id, datas) => {
      try {
        const res = await put(`/contactModel/${id}`, datas)
        dispatch({
          type: 'SET_PROPERTIES',
          payload: {
            properties: datas,
            id: id
          }
        })

        dispatchSnackbar({
          type: 'SET_SNACKBAR',
          payload: {
            open: true,
            content: 'Modifications enregistrées',
            type: 'success',
            duration: 4000
          }
        })



        return res.data.message
      } catch (e) {
        dispatchSnackbar({
          type: 'SET_SNACKBAR',
          payload: {
            open: true,
            content: 'Une erreure c\'est produite durant l\'enregistrement',
            type: 'error',
            duration: 4000
          }
        })
        return false
      }
    },
  };
}


const useCreateContactModels = () => {
  const [, dispatch] = useContext(ContactModelsContext);
  const [, dispatchSnackbar] = useContext(SnackbarContext)
  const post = usePost()
  return {
    createContactModel: async (id, datas) => {
      try {
        const res = await post(`/contactModel`, datas)
        dispatch({
          type: 'SET_MODEL',
          payload: {
            datas: res.data.message
          }
        })
        dispatchSnackbar({
          type: 'SET_SNACKBAR',
          payload: {
            open: true,
            content: 'Modifications enregistrées',
            type: 'success',
            duration: 4000
          }
        })
        return res.data.message
      } catch (e) {
        dispatchSnackbar({
          type: 'SET_SNACKBAR',
          payload: {
            open: true,
            content: 'Une erreure c\'est produite durant l\'enregistrement',
            type: 'error',
            duration: 4000
          }
        })
        return false
      }
    },
  };
}


function ContactModelsProvider(props) {
  const [contactsModels, dispatch] = useReducer(reducer, initialState);

  return (
    <ContactModelsContext.Provider value={[contactsModels, dispatch]}>
      {props.children}
    </ContactModelsContext.Provider>
  );
};

export { ContactModelsContext, ContactModelsProvider, useGetContactModels, useEditContactModels, useCreateContactModels };