import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import StlViewer from './Viewer/stl/StlViewer';
import DialogStlViewer from './Dialog/DialogStlViewer';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';


function DropSTEP({
    setActiveStep,
    onClick,
    startSTEP,
    setStartSTEP,
    setStepLoading
}) {
    const [showModal, setShowModal] = useState(false);
    const [stepFiles, setStepFiles] = useState([]);
    const [curentFileIndex, setCurentFileIndex] = useState(0)
    const [, configuratorDispatch] = useContext(ConfiguratorContext);
    const hiddenInputRef = useRef(null);

    useEffect(() => {
        if (startSTEP) {
            hiddenInputRef.current.click();
            //setStartSTEP(false);
        }
    }, [startSTEP]);


    const handelSelectFile = async (e) => {
        setStepFiles(e.target.files)
        setShowModal(true)
    }

    const handleCloseDialogStlViewer = () => {
        setStepFiles([])
        configuratorDispatch({
            type: 'RESET'
        })
        setActiveStep(0)
        setShowModal(false);
        window.parent.postMessage({ type: 'redirect' }, "*")
    }

    const next = () => {
        if (!!stepFiles?.length && curentFileIndex + 1 < stepFiles.length) {
            setCurentFileIndex((c) => c + 1);
        }
    }


    return (
        <>
            <input ref={hiddenInputRef} onChange={handelSelectFile} multiple type='file' hidden accept=".step, .stp" />
            {showModal && (
                <DialogStlViewer open={showModal} handleClose={handleCloseDialogStlViewer} stepFile={stepFiles[curentFileIndex]} next={next} curentFileIndex={curentFileIndex} stepFiles={stepFiles} />
            )}
        </>
    );
}

export default DropSTEP;
