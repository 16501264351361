import bSpline from './bSpline';

const degrees_to_radians = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi / 180);
}

const getThridPointOfTriangle = (x, y, l1, r) => {


    const x1 = x + (l1 * Math.cos(degrees_to_radians(r)))

    const y2 = y + (l1 * Math.sin(degrees_to_radians(r)))

    return { x: Math.round(x1), y: Math.round(y2) }
}


const getPointsOfTriangle = (a, l1, l2, r) => {
    return {
        a,
        b: {
            x: a.x + l2,
            y: a.y
        },
        c: getThridPointOfTriangle(a.x, a.y, l1, r)

    }
}

const getLineLengthByCoord = (x1, y1, x2, y2, ratio) => {
    const l = Math.sqrt(Math.pow((x2 - x1) / ratio, 2) + Math.pow((y2 - y1) / ratio, 2))

    return l
}


const median = (arr) => {
    if (arr.length == 0) {
        return; // 0.
    }
    arr.sort((a, b) => a - b); // 1.
    const midpoint = Math.floor(arr.length / 2); // 2.
    const median = arr.length % 2 === 1 ?
        arr[midpoint] : // 3.1. If odd length, just take midpoint
        (arr[midpoint - 1] + arr[midpoint]) / 2; // 3.2. If even length, take median of midpoints
    return median;
}

const interpolateBSpline = (
    controlPoints,
    degree,
    knots,
    interpolationsPerSplineSegment,
    weights,
) => {
    const polyline = []
    const controlPointsForLib = controlPoints.map(function (p) {
        return [p.x, p.y]
    })

    const segmentTs = [knots[degree]]
    const domain = [knots[degree], knots[knots.length - 1 - degree]]

    for (let k = degree + 1; k < knots.length - degree; ++k) {
        if (segmentTs[segmentTs.length - 1] !== knots[k]) {
            segmentTs.push(knots[k])
        }
    }

    interpolationsPerSplineSegment = interpolationsPerSplineSegment || 25
    for (let i = 1; i < segmentTs.length; ++i) {
        const uMin = segmentTs[i - 1]
        const uMax = segmentTs[i]
        for (let k = 0; k <= interpolationsPerSplineSegment; ++k) {
            const u = (k / interpolationsPerSplineSegment) * (uMax - uMin) + uMin
            // Clamp t to 0, 1 to handle numerical precision issues
            let t = (u - domain[0]) / (domain[1] - domain[0])
            t = Math.max(t, 0)
            t = Math.min(t, 1)
            const p = bSpline(t, degree, controlPointsForLib, knots, weights)
            polyline.push(p)
        }
    }
    return polyline
}


export { getThridPointOfTriangle, getPointsOfTriangle, getLineLengthByCoord, median, interpolateBSpline }