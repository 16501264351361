import { Paper, TextField, Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useContext, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'

const useStyles = makeStyles(() => ({
    paper: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem'
    }
}))


function ConfiguratorConfigCustom({ setcurentDraggingObjectPosition, selectedForm }) {
    const [configurator, dispatch] = useContext(ConfiguratorContext);
    const [x, setx] = useState(null);
    const [y, sety] = useState(null);
    const [h, seth] = useState(null);
    const [l, setl] = useState(null);
    const [r, setr] = useState(null);


    const classes = useStyles();

    const handelChangeL = (e) => setl(e.target.value);

    const handelBlurL = useCallback(() => {

        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(l);
            if (evaluateValue) {
                dispatch({
                    type: 'SET_WIDTH_HEIGHT_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            width: evaluateValue,
                            height: evaluateValue * selectedForm.ratio
                        }
                    }
                })
            }
            setl(evaluateValue)
        } catch (e) {
            setl(null)
        }

    }, [configurator.selectedForm, dispatch, l, selectedForm.ratio])

    const handelChangeH = (e) => seth(e.target.value)

    const handelBlurH = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(h);
            if (evaluateValue) {
                dispatch({
                    type: 'SET_WIDTH_HEIGHT_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            width: evaluateValue / selectedForm.ratio,
                            height: evaluateValue
                        }
                    }
                })
            }
            seth(evaluateValue)
        } catch (e) {
            seth(null)
        }
    }, [configurator.selectedForm, dispatch, h, selectedForm.ratio])


    const handelChangeX = (e) => setx(e.target.value);

    const handleBlurX = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(x);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            x: evaluateValue
                        }
                    }

                })
                setcurentDraggingObjectPosition((v) => ({ ...v, x: evaluateValue }))
            }
            setx(evaluateValue)
        } catch (e) {
            setx(null)
        }
    }, [configurator.selectedForm, dispatch, setcurentDraggingObjectPosition, x])


    const handelChangeY = (e) => sety(e.target.value);

    const handleBlurY = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(y);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            y: evaluateValue
                        }
                    }

                })
                setcurentDraggingObjectPosition((v) => ({ ...v, y: evaluateValue }))
            }
            sety(evaluateValue)
        } catch (e) {
            sety(null)
        }
    }, [configurator.selectedForm, dispatch, setcurentDraggingObjectPosition, y])


    const handelChangeRotation = useCallback(
        (e) => {

            let value = null;
            if (e.target.value === '') {
                value = e.target.value;
            } else if (!isNaN(e.target.value)) {
                value = Number(e.target.value);
            }
            setr(value);
            if ((value || value === 0) && !isNaN(value)) {
                dispatch({
                    type: 'SET_ROTATION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            rotation: e.target.value
                        }
                    }

                })
            }
        },
        [configurator.selectedForm, dispatch],
    )

    const handleChangeGraved = useCallback((e) => {
        dispatch({
            type: 'SET_VALUE_FORM',
            payload: {
                form: configurator.selectedForm,
                data: {
                    graved: e.target.checked,
                    unionType: e.target.checked ? 'del' : selectedForm.unionType

                }
            }
        })
    }, [configurator.selectedForm, dispatch, selectedForm.unionType])


    const getValueL = useCallback(
        () => {
            return l === null ? selectedForm.width : l
        },
        [l, selectedForm.width],
    )

    const getValueH = useCallback(
        () => {
            return h === null ? selectedForm.height : h
        },
        [selectedForm.height, h],
    )

    const getValueX = useCallback(
        () => {
            return x === null ? selectedForm.realx : x
        },
        [selectedForm.realx, x],
    )

    const getValueY = useCallback(
        () => {
            return y === null ? selectedForm.realy : y
        },
        [selectedForm.realy, y],
    )

    const getValueRotation = useCallback(
        () => {
            return r === '' ? r : selectedForm.rotation
        },
        [r, selectedForm.rotation],
    )

    return (

        <Grid container justify="center" alignItems='center'>

            <Grid item xs={5} md={2} lg={2} style={{ marginRight: '1rem' }}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-rectangle-h" label="Largeur (mm)" value={getValueH()} margin="dense" onBlur={handelBlurH} onChange={handelChangeH} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={2} style={{ marginRight: '1rem' }}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-rectangle-l" label="Longueur (mm)" value={getValueL()} margin="dense" onBlur={handelBlurL} onChange={handelChangeL} />
                </Paper>
            </Grid>

            <Grid item xs={5} md={2} lg={1} style={{ marginRight: '1rem' }}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-circle-x" label="X (mm)" value={getValueX()} margin="dense" onChange={handelChangeX} onBlur={handleBlurX} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} style={{ marginRight: '1rem' }}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-circle-y" label="Y (mm)" value={getValueY()} margin="dense" onChange={handelChangeY} onBlur={handleBlurY} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} style={{ marginRight: '1rem' }}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-circle-x" label="Rotation (*)" value={getValueRotation()} margin="dense" onChange={handelChangeRotation} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} style={{ marginRight: '1rem' }}>
                <Paper className={classes.paper} elevation={0}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedForm.graved}
                                onChange={handleChangeGraved}
                                name="graved"
                                color="primary"
                            />
                        }
                        label="Graver"
                    />
                </Paper>
            </Grid>

        </Grid>

    )
}

export default ConfiguratorConfigCustom
