import React, { useContext, useReducer } from 'react';
import { useGet, usePost, usePut } from '../modules/request';
import { BasketContext } from './BasketContext';


const OrderContext = React.createContext([[], () => { }]);

const initialState = []

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ORDERS":
      return action.payload
    case "UPDATE_ORDER":
      return state.map(order => order._id !== action.payload.id ? order : { ...order, ...action.payload.datas })
    case "DELETE_ORDER":
      return state.filter(order => order._id !== action.payload.id); // Filter out the order with the specified ID

    default:
      throw new Error();
  }
};


const useGetOrders = () => {
  const [state, dispatch] = useContext(OrderContext);
  const get = useGet()
  return {
    getOrder: async ({ page, field, value }) => {

      const res = await get('/order')
      dispatch({
        type: 'SET_ORDERS',
        payload: res.data.message
      })
    },
  };
}

const useGetMyOrder = () => {
  const [, dispatch] = useContext(OrderContext);
  const get = useGet()
  return {
    getMyOrder: async () => {
      const res = await get('/order/my')
      dispatch({
        type: 'SET_ORDERS',
        payload: res.data.message
      })
    },
  };
}

const usePostOrders = () => {
  const [state, dispatch] = useContext(BasketContext);
  const post = usePost()
  return {
    postOrder: async (data) => {
      try {
        const res = await post('/order', data)
        dispatch({
          type: 'SET_BASKET',
          payload: []
        })
        return res.data.message
      } catch (e) {
        return false
      }
    },
  };
}

const useUpdateOrder = () => {
  const [, dispatch] = useContext(OrderContext);
  const put = usePut()
  return {
    updateOrder: async (orderId, updatedData) => {
      try {
        const res = await put(`/order/${orderId}`, updatedData)
        dispatch({
          type: 'UPDATE_ORDER',
          payload: {
            id: orderId,
            datas: {
              ...updatedData // This will spread the updated data as key-value pairs
            }
          }
        })
        return res.data.message;
      } catch (e) {
        return false;
      }
    },
  };
}

function OrderProvider(props) {
  const [order, dispatch] = useReducer(reducer, initialState);

  return (
    <OrderContext.Provider value={[order, dispatch]}>
      {props.children}
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderProvider, useGetOrders, usePostOrders, useGetMyOrder, useUpdateOrder };