import React, { useState, useEffect, useCallback, useContext, useRef } from 'react'
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Slider, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import makerjs from 'makerjs'
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./../../../src/modules/worker";
import { ConfiguratorContext } from '../../contexts/ConfiguratorContext';
import ScannerIcon from '@material-ui/icons/Scanner';
import { BallTriangle } from 'react-loader-spinner';
import { makeStyles } from '@material-ui/styles';


import GreenButton from '../Button/GreenButton';
import { PropertiesContext } from '../../contexts/PropertiesContext';

const useStyles = makeStyles((theme) => ({
    optionsTitle: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    svgContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
        overflow: 'auto',
        "& svg": {
            maxWidth: '100%',
            maxHeight: '75vh',
        }
    }
}))


function ModalProcessImageVecto({ open, handleClose, file, onValidate }) {

    const svgRef = useRef(null);
    const [svg, setsvg] = useState('');
    const [lines, setlines] = useState([]);
    const [threshold, setThreshold] = useState(180);
    const [invert, setInvert] = useState(false);
    const [originalHeight, setoriginalHeight] = useState(null);
    const [originalWidth, setoriginalWidth] = useState(null);
    const [targetHeight, settargetHeight] = useState(null);
    const [targetWidth, settargetWidth] = useState(null);
    const [startProcess, setstartProcess] = useState(false);
    const [errorH, seterrorH] = useState(false);
    const [errorHText, seterrorHText] = useState('');
    const [errorW, seterrorW] = useState(false);
    const [errorWText, seterrorWText] = useState('');
    const [properties] = useContext(PropertiesContext);
    const [ratio, setratio] = useState(0);
    const [configurator, configuratorDispatch] = useContext(ConfiguratorContext)
    const classes = useStyles();

    const process = useCallback(() => {

        setstartProcess(true)

    }, [])

    useEffect(() => {
        if (startProcess) {



            const worker = new Worker()
            worker.postMessage({ type: 'IMG_IMPORT', file: URL.createObjectURL(file), threshold, invert, targetHeight, targetWidth, originalHeight, originalWidth });
            worker.onmessage = (event) => {
                const { height, width, lines, svg } = event.data;
                setsvg(svg);
                setlines(lines);
                setoriginalHeight(height);
                setoriginalWidth(width);
                settargetHeight('');
                settargetWidth('');
                let ratio = height / width;
                setratio(ratio)

                setstartProcess(false);

                worker.terminate();
            }

        }
    }, [configuratorDispatch, file, threshold, originalHeight, originalWidth, startProcess, targetHeight, targetWidth])



    const handleClickProcess = useCallback(() => {
        process();
    }, [process])

    const handleChangeThreshold = (e, v) => {
        setThreshold(v)
    }

    const handleChangeQtres = (e) => setInvert(e.target.checked);

    const handleChangeHeight = useCallback((e) => {

        if (e.target.value.match(/^[0-9]*$/)) {
            seterrorH(false)
            seterrorHText('')
            if (Number(e.target.value) < properties.machineMinLength || Number(e.target.value) > properties.machineMaxLength) {
                seterrorHText(`valeur comprisent entre ${properties.machineMinLength} et ${properties.machineMaxLength}`)
                seterrorH(true)
            }
            settargetHeight(+e.target.value)
        }

    }, [properties.machineMaxLength, properties.machineMinLength])

    const handleBlurHeight = useCallback(() => {
        const value = Math.ceil(targetHeight / ratio);
        seterrorW(false)
        seterrorWText('')
        if (Number(value) < properties.machineMinWidth || Number(value) > properties.machineMaxWidth) {
            seterrorWText(`valeur comprisent entre ${properties.machineMinWidth} et ${properties.machineMaxWidth}`)
            seterrorW(true)
        }
        settargetWidth(value)

    }, [properties.machineMaxWidth, properties.machineMinWidth, ratio, targetHeight])

    const handleChangeWidth = useCallback((e) => {
        if (e.target.value.match(/^[0-9]*$/)) {
            seterrorW(false)
            seterrorWText('')
            if (Number(e.target.value) < properties.machineMinWidth || Number(e.target.value) > properties.machineMaxWidth) {
                seterrorWText(`valeur comprisent entre ${properties.machineMinWidth} et ${properties.machineMaxWidth}`)
                seterrorW(true)
            }
            settargetWidth(+e.target.value)
        }

    }, [properties.machineMaxWidth, properties.machineMinWidth])

    const handleBlurWidth = useCallback(() => {


        seterrorH(false)
        const value = Math.ceil(targetWidth * ratio);
        seterrorHText('')
        if (Number(value) < properties.machineMinLength || Number(value) > properties.machineMaxLength) {
            seterrorHText(`valeur comprisent entre ${properties.machineMinLength} et ${properties.machineMaxLength}`)
            seterrorH(true)
        }
        settargetHeight(+value)
    }, [properties.machineMaxLength, properties.machineMinLength, ratio, targetWidth])


    const handleClickValidate = () => {


        let model = {
            models: {}
        }
        lines.forEach((line, index) => {
            model.models[index] = makerjs.importer.fromSVGPathData(line);
        })
        const scropedSVG = makerjs.exporter.toSVG(model);
        const linesScroped = scropedSVG.match(/(?:path[ \s]d=")[\s\S]*?(?=")/g).map(line => line.replace(/path[ \s]d="/, "")).filter(line => line[0] === 'M' || line[0] === 'm');
        model = {
            models: {}
        }
        linesScroped.forEach((line, index) => {
            model.models[index] = makerjs.importer.fromSVGPathData(line);
        })
        configuratorDispatch({
            type: 'SET_VALUES',
            payload: {
                data: {
                    isValidationDesactived: true,
                }
            }
        })
        onValidate({ lines: linesScroped, targetHeight: targetHeight || originalHeight, targetWidth: targetWidth || originalWidth, originalHeight, originalWidth, ratio, model })
    }


    return (
        <Dialog fullWidth={true} fullScreen open={open} onClose={handleClose} >
            <DialogTitle style={{ textAlign: 'center' }} id="add-to-catalog-dialog">Vectorisez votre image</DialogTitle>
            <DialogContent style={{ paddingTop: '1rem' }}>
                <Grid container justify='space-around'>
                    <Grid item xs={12} md={4}>
                        <Grid container justify='center' alignItems='center' direction='column'>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid xs={6}>
                                <img style={{ maxHeight: '200px', maxWidth: '300px' }} src={URL.createObjectURL(file)} alt="" />
                            </Grid>
                        </Grid>
                        <Divider style={{ margin: '1rem 0' }} />
                        <Grid container>

                            <Grid item xs={12}>

                                {originalHeight && originalWidth && <> <Grid container >
                                    <Grid item xs={6}>
                                        <Typography className={classes.optionsTitle} variant="button" gutterBottom display='block'>
                                            Dimensions originales
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.optionsTitle} variant="button" gutterBottom display='block'>
                                            Dimensions souhaitées
                                        </Typography>
                                    </Grid>
                                </Grid>
                                    <Grid container alignItems='center' >
                                        <Grid item xs={6}>
                                            <Typography variant="body1" gutterBottom display='block'>
                                                Largeur  <small>(mm)</small> : {originalHeight}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container alignItems='center'>
                                                <Grid item xs={5}>

                                                    <Typography variant="body1" gutterBottom display='inline'>
                                                        Largeur  <small>(mm)</small> :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>

                                                    <TextField value={targetHeight} onBlur={handleBlurHeight} onChange={handleChangeHeight} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container alignItems='center' >
                                        <Grid item xs={6}>
                                            <Typography variant="body1" gutterBottom display='block'>
                                                Longueur<small>(mm)</small> : {originalWidth}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container alignItems='center'>
                                                <Grid item xs={5}>

                                                    <Typography variant="body1" gutterBottom display='inline'>
                                                        Longueur<small>(mm)</small> :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>

                                                    <TextField value={targetWidth} onBlur={handleBlurWidth} onChange={handleChangeWidth} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </>}
                                <Divider style={{ margin: '1rem 0' }} />
                                <Typography variant="button" className={classes.optionsTitle} gutterBottom display='block'>
                                    Seuil de luminosité
                                </Typography>
                                <Slider
                                    defaultValue={180}
                                    getAriaValueText={(v) => v}
                                    onChangeCommitted={handleChangeThreshold}
                                    aria-labelledby="discrete-slider"
                                    step={1}
                                    marks
                                    min={1}
                                    max={254}
                                    valueLabelDisplay="auto"
                                />
                                <Typography variant="button" className={classes.optionsTitle} gutterBottom display='block'>
                                    Inverser l'image
                                </Typography>
                                <Checkbox
                                    checked={invert}
                                    onChange={handleChangeQtres}
                                />


                            </Grid>


                        </Grid>
                        <Divider style={{ margin: '1rem 0' }} />
                        <Typography variant="caption" gutterBottom display='block'>
                            {errorHText || errorWText}
                        </Typography>
                        {
                            svg && !startProcess && (originalHeight || targetHeight) && (originalWidth || targetWidth) &&
                            <Typography variant="caption" gutterBottom display='block'>
                                {(!targetHeight || !targetWidth) && "Pour valider la vectorisation, n'oubliez pas d'indiquer les dimensions souhaitées de votre image"}
                            </Typography>
                        }

                    </Grid>
                    <Divider flexItem orientation="vertical" />
                    <Grid item xs={12} md={7}>
                        <Grid container justify='center' >
                            <Grid item xs={12} className={classes.svgContainer}>
                                {startProcess ? (
                                    <BallTriangle
                                        style={{ justifyContent: "center" }}
                                        height={300}
                                        width={300}
                                        strokeWidth="1"
                                        ariaLabel="rotating-square"
                                        visible={true}
                                        color="#3A7297"
                                    />
                                ) : (
                                    <div
                                        ref={svgRef}
                                        className={classes.svgContent}
                                        dangerouslySetInnerHTML={{ __html: svg }}
                                    ></div>
                                )}
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item style={{ marginBottom: '1rem' }}>
                        <Button startIcon={<ScannerIcon />} endIcon={startProcess && <CircularProgress />} disabled={startProcess} onClick={handleClickProcess} color="primary" variant='contained'>Vectoriser</Button>
                    </Grid>
                    {svg && !startProcess && (originalHeight || targetHeight) && (originalWidth || targetWidth) && <Grid item style={{ marginLeft: '1rem' }}>
                        <GreenButton disabled={!targetHeight || !targetWidth || errorH || errorW} onClick={handleClickValidate} color="primary" variant='contained'>Valider</GreenButton>

                    </Grid>}
                    <Grid item style={{ marginLeft: 'auto' }}>
                        <Button onClick={handleClose} color="primary" variant='contained'>
                            Annuler
                        </Button>
                    </Grid>
                </Grid>

            </DialogActions>

        </Dialog >
    )
}

export default ModalProcessImageVecto