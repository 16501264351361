import { CircularProgress, Divider, FormControl, FormControlLabel, Grid, LinearProgress, makeStyles, Paper, Radio, RadioGroup, Typography } from '@material-ui/core'
import React, { useCallback, useContext } from 'react'
import TextFieldValidation from './TextFieldValidation'
import { UserCitySchemas, UserHouseNumberSchemas, UserLastNameSchemas, UserStreetSchemas, UserZipCodeSchemas } from '../modules/validation'
import { PropertiesContext } from '../contexts/PropertiesContext'
import { useTheme } from '@material-ui/core'
import PanoramaWideAngleIcon from '@material-ui/icons/PanoramaWideAngle';
import HeightIcon from '@material-ui/icons/Height';
import { BasketContext } from '../contexts/BasketContext'

const useStyle = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(4)
    },
    radio: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    inputs: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    inputsContainer: {
        marginTop: theme.spacing(4)
    },
    input: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(4)
    }
}))

function CartValidateChoiseDelivery({ me, setme, data, setdata, loading, overSizeForTransport = false }) {

    const theme = useTheme()
    const classes = useStyle()
    const [properties] = useContext(PropertiesContext);
    const [baskets] = useContext(BasketContext);
    const handelChangeMethode = useCallback(
        (e) => {
            setdata({ ...data, deliveryMethode: e.target.value, deliveryTime: null })
        },
        [data, setdata],
    )

    const handelError = useCallback(
        (v) => {
            setdata({ ...data, error: v })
        },
        [data, setdata],
    )

    const handleChange = useCallback(
        (e, key) => {
            if (me.isPro && (key !== 'lastName' && key !== 'firstName')) {
                setme({ ...me, pro: { ...me.pro, [key]: e.target.value } })
            } else {
                setme({ ...me, [key]: e.target.value })
            }

        },
        [me, setme],
    )

    const handleChangeDeliveryTime = (e) => {
        if (e.target.value) {
            setdata({ ...data, deliveryTime: e.target.value, deliveryMethode: 'delivery' })
        }
    }
    const getDimention = useCallback(() => {
        const mostHeight = baskets.sort(function (a, b) {
            return a.maxHeight - b.maxHeight
        }).reverse()[0]
        const mostWidth = baskets.sort(function (a, b) {
            return a.maxWidth - b.maxWidth
        }).reverse()[0]
        if (mostHeight && mostWidth) {
            return mostHeight.maxHeight > mostWidth.maxWidth ? `${mostHeight.maxHeight} x ${mostHeight.maxWidth}` : `${mostWidth.maxHeight} x ${mostWidth.maxWidth}`;
        }
        return 0;
    }, [baskets])

    const getWeight = () => baskets.map(({ weight, quantity }) => ({ weight, quantity })).reduce((a, b) => a + (b.weight * b.quantity), 0)?.toFixed(2)

    const getDelays = useCallback(() => !properties?.deliveryTimes?.length
        ? <FormControlLabel value={'toConvent'} labelPlacement="top" control={<Radio />} label={`Livraison avec délai à convenir`} />
        : properties?.deliveryTimes?.map(deliveryTime => <FormControlLabel key={deliveryTime._id} value={deliveryTime._id} labelPlacement="top" control={<Radio />} label={`${deliveryTime.time} jours`} />), [properties.deliveryTimes])


    return (
        <Grid container>
            <Grid item xs={12} style={{ position: 'relative' }}>
                {loading && <div style={{ position: 'absolute', top: 0, right: 0, width: '100%' }}><LinearProgress /></div>}
                <Paper elevation={0} className={classes.paper}>

                    <Typography color='primary' variant="h5" align="center" gutterBottom > Méthode de livraison </Typography>
                    <Grid container justify='center'  >
                        <Grid item md={3} xs={12} container justify='center' alignItems='center'>
                            <HeightIcon />
                            <Typography style={{ fontWeight: 'bold', marginRight: '1rem' }} variant="body2" gutterBottom display="inline" >Dimension de la plus grand pièce dépliée</Typography>
                            <Typography variant="body2" display="block" gutterBottom>{getDimention() + ' mm'}</Typography>

                        </Grid>
                        <Grid item md={3} xs={12} container justify='center' alignItems='center'>
                            <PanoramaWideAngleIcon />
                            <Typography style={{ fontWeight: 'bold', marginRight: '1rem', marginLeft: '0.5rem' }} variant="body2" gutterBottom display="inline" >Poids total</Typography>
                            <Typography variant="body2" display="block" gutterBottom>{getWeight() + ' kg'}</Typography>

                        </Grid>
                    </Grid>
                    <FormControl style={{ width: '100%' }} component="fieldset">
                        <RadioGroup row name="delivery-method" defaultValue="top" onChange={handelChangeMethode} className={classes.radio} value={data.deliveryMethode}>

                            <Grid container justify="space-around">
                                {
                                    properties.transportEnabled && !overSizeForTransport ?
                                        <Grid item container direction='column' alignItems='center'>
                                            <Typography color='primary' variant="h6" align="center" gutterBottom > Mode de livraison </Typography>
                                            <FormControl component="fieldset">
                                                <RadioGroup style={{ flexDirection: 'row' }} name="deliveryTimes" value={data.deliveryTime} onChange={handleChangeDeliveryTime}>
                                                    {getDelays()}

                                                </RadioGroup>
                                            </FormControl>

                                        </Grid>
                                        : null
                                }


                                {
                                    properties.pickUpAllowed ?
                                        <Grid item>
                                            <FormControlLabel
                                                value="pickup"
                                                control={<Radio color="primary" />}
                                                label="Retrait sur place"
                                                labelPlacement="top"
                                            />
                                        </Grid>
                                        : null

                                }


                            </Grid>
                        </RadioGroup>
                        <Divider variant="middle" />
                        {
                            data.deliveryMethode === 'delivery' ?
                                <Grid container justify='center' className={classes.inputsContainer}>

                                    <Grid item xs={12} sm={6}>
                                        <div style={{ marginBottom: theme.spacing(3) }}>
                                            <Typography color="primary" variant="h6" align="center" gutterBottom>
                                                Frais de livraison
                                            </Typography>
                                            <Typography color="primary" variant="body1" align="center" gutterBottom>
                                                {loading ? <CircularProgress size={15} disableShrink /> : `${data.deliveryPrice} €`}
                                            </Typography>
                                        </div>


                                        <Typography color="primary" variant="h6" align="center" gutterBottom>
                                            Adresse de livraison
                                        </Typography>
                                        <Grid container className={classes.inputs} spacing={2}>
                                            <Grid item xs={12} sm={5} className={classes.input}>
                                                <TextFieldValidation fullWidth variant="outlined" onError={(v) => handelError(v)} label="Nom" yupValidation={UserLastNameSchemas} InputLabelProps={{ shrink: true }} onChange={(e) => handleChange(e, 'lastName')} type="text" value={me.lastName} />
                                            </Grid>
                                            <Grid item xs={12} sm={5} className={classes.input}>
                                                <TextFieldValidation fullWidth variant="outlined" onError={(v) => handelError(v)} label="Prénom" yupValidation={UserLastNameSchemas} InputLabelProps={{ shrink: true }} onChange={(e) => handleChange(e, 'firstName')} type="text" value={me.firstName} />
                                            </Grid>
                                            <Grid item xs={12} sm={5} className={classes.input}>
                                                <TextFieldValidation fullWidth variant="outlined" onError={(v) => handelError(v)} label="Numero de voie" yupValidation={UserHouseNumberSchemas} InputLabelProps={{ shrink: true }} onChange={(e) => handleChange(e, me.isPro ? 'deliveryHouseNumber' : 'houseNumber')} type="text" value={me?.pro?.deliveryHouseNumber || me.houseNumber} />
                                            </Grid>
                                            <Grid item xs={12} sm={5} className={classes.input}>
                                                <TextFieldValidation fullWidth variant="outlined" onError={(v) => handelError(v)} label="Rue" yupValidation={UserStreetSchemas} InputLabelProps={{ shrink: true }} onChange={(e) => handleChange(e, me.isPro ? 'deliveryStreet' : 'street')} type="text" value={me?.pro?.deliveryStreet || me.street} />
                                            </Grid>
                                            <Grid item xs={12} sm={5} className={classes.input}>
                                                <TextFieldValidation fullWidth variant="outlined" onError={(v) => handelError(v)} label="Code postal" yupValidation={UserZipCodeSchemas} InputLabelProps={{ shrink: true }} onChange={(e) => handleChange(e, me.isPro ? 'deliveryZipCode' : 'zipCode')} type="text" value={me?.pro?.deliveryZipCode || me.zipCode} />
                                            </Grid>
                                            <Grid item xs={12} sm={5} className={classes.input}>
                                                <TextFieldValidation fullWidth variant="outlined" onError={(v) => handelError(v)} label="Ville" yupValidation={UserCitySchemas} InputLabelProps={{ shrink: true }} onChange={(e) => handleChange(e, me.isPro ? 'deliveryCity' : 'city')} type="text" value={me?.pro?.deliveryCity || me.city} />
                                            </Grid>
                                            <Grid item xs={12} sm={5} className={classes.input}>
                                                <TextFieldValidation fullWidth variant="outlined" onError={(v) => handelError(v)} label="Téléphone" yupValidation={UserLastNameSchemas} InputLabelProps={{ shrink: true }} onChange={(e) => handleChange(e, 'phone')} type="text" value={me?.pro?.phone || me.phone} />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                : data.deliveryMethode === 'pickup' && properties.pickUpAllowed
                                    ?
                                    <Grid container justify='center' className={classes.inputsContainer}>

                                        <Grid item xs={6} className={classes.inputs}>
                                            <Typography variant="h6" align="center" gutterBottom color="primary">
                                                Retrait sur place
                                            </Typography>
                                            <Grid container justify='center'>
                                                <Grid item>
                                                    <Typography align="center" variant="body1" gutterBottom style={{ marginBottom: '1rem' }}>
                                                        Récupérez votre commande directement sur place.
                                                    </Typography>
                                                    <Typography align="center" variant="button" display="block" gutterBottom color="primary">
                                                        adresse du retrait
                                                    </Typography>
                                                    <Typography align="center" variant="body1" gutterBottom>
                                                        {`${process.env.REACT_APP_PICK_UP_HOUSE_NUMBER} ${process.env.REACT_APP_PICK_UP_STREET}`}
                                                    </Typography>
                                                    <Typography align="center" variant="body1" gutterBottom>
                                                        {`${process.env.REACT_APP_PICK_UP_ZIP_CODE} ${process.env.REACT_APP_PICK_UP_CITY}`}
                                                    </Typography>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    : null
                        }
                    </FormControl>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default CartValidateChoiseDelivery
