import React, { useContext, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DisplayCatalogSvg from '../EndUser/CatalogSvg/DisplayCatalogSvg';
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { CatalogSvgContext } from '../../contexts/CatalogSvgContext';
import ProcessUploadedSvg from '../ProcessUploadedSvg';
import { ConfiguratorContext } from '../../contexts/ConfiguratorContext';

const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: theme.spacing(4)
    }
}));

export default function DialogSelectCatalogSvg({ open, setOpen, handleCancel, paperRef, isMain }) {

    const [blob, setblob] = useState({});
    const [startProcess, setstartProcess] = useState(false);
    const [family, setfamily] = useState([]);
    const [type, settype] = useState([]);
    const [allowTypes, setallowTypes] = useState([]);
    const [allowFamilies, setallowFamilies] = useState([]);
    const [{ catalogsSvg }] = useContext(CatalogSvgContext);
    const [, dispatch] = useContext(ConfiguratorContext)
    const classes = useStyles();

    useEffect(() => {
        let mergedType = []
        catalogsSvg.forEach((cat) => {
            mergedType = mergedType.concat(cat.type);
        })
        setallowTypes([...new Set(mergedType)])

        let mergedFamily = []
        catalogsSvg.forEach((cat) => {
            mergedFamily = mergedFamily.concat(cat.family);
        })
        setallowFamilies([...new Set(mergedFamily)])

    }, [catalogsSvg]);

    const onSelect = async (id) => {
        const selectedCatalogSvg = catalogsSvg.find(c => c._id === id)
        if (selectedCatalogSvg) {
            let blob = await fetch(`${process.env.REACT_APP_BASE_API}/download/svg/${selectedCatalogSvg.file}`).then(r => r.blob())
            setblob(blob)
            setstartProcess(true)
        }
    }

    const handleClose = () => {
        setstartProcess(false)
    }

    const onValidateImportSvg = ({ lines, targetHeight, targetWidth, originalHeight, originalWidth, ratio, model }) => {
        if (isMain) {
            dispatch({
                type: 'SET_VALUES',
                payload: {
                    data: {
                        form: 4,
                        mainForm: {
                            paths: lines,
                            configured: true,
                            originalHeight: originalHeight,
                            originalWidth: originalWidth,
                            height: targetHeight,
                            width: targetWidth,
                            ratio,
                            model
                        }
                    }
                }
            })
        } else {

            dispatch({
                type: 'SET_VALUES',
                payload: {
                    data: {
                        formToAdd: 'CUSTOM',
                        formToAddDataTemp: {
                            paths: lines,
                            originalHeight: originalHeight,
                            originalWidth: originalWidth,
                            height: targetHeight,
                            width: targetWidth,
                            ratio,
                            model
                        }
                    }
                }
            })
        }
        if (paperRef) {
            paperRef.current.style.cursor = 'crosshair'
        }
        setstartProcess(false);
        setOpen(false);
    }

    const handleChangeType = (e) => {
        settype(e.target.value);
    }

    const handleChangeFamily = (e) => {
        setfamily(e.target.value);
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCancel}
                fullWidth
                maxWidth={'lg'}

            >
                <DialogTitle style={{ textAlign: 'center' }}>{"Cliquez pour choisir le motif que vous souhaitez ajouter à votre création"}</DialogTitle>
                <DialogContent style={{ height: "70vh" }}>
                    <Grid container className={classes.section} justify='center'>
                        <Grid xs={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel id="svg-family-label">Familles</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="svg-family-label"
                                    id="svg-family"
                                    multiple
                                    value={family}
                                    onChange={handleChangeFamily}
                                >
                                    {allowFamilies.map((family, i) => <MenuItem key={i} value={family}>{family}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={0} md={1}>

                        </Grid>
                        <Grid xs={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel id="svg-add-type-label">Types d'utilisation</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="svg-add-type-label"
                                    id="svg-add-type"
                                    multiple
                                    value={type}
                                    onChange={handleChangeType}
                                >
                                    {allowTypes.map((type, i) => <MenuItem key={i} value={type}>{type}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.section}>
                        <Grid item xs={12}>
                            <DisplayCatalogSvg filters={{ type, family }} selectable onSelect={onSelect} />
                        </Grid>
                    </Grid>
                    <DialogContentText >
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary" autoFocus>
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
            {startProcess && <ProcessUploadedSvg open={startProcess} handleClose={handleClose} onValidate={onValidateImportSvg} file={blob} isCatalogSvg />}
        </>

    );
}