import React, { useCallback, useState, useRef, useContext } from 'react';
import uniqid from 'uniqid'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogActions, DialogContent, Grid, Typography, TextField, makeStyles, Divider, Select, MenuItem, InputLabel, FormControl, FormHelperText, Slide, CircularProgress } from '@material-ui/core';
import CatalogCustomzationLine from '../Control/CatalogCustomzationLine';
import { usePostCatalog } from '../../contexts/CatalogContext';
import { MaterialsContext } from '../../contexts/MaterialsContext'
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import { SnackbarContext } from '../../contexts/SnackbarContext';

const entitydefaultName = {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'D',
    5: 'E',
    6: 'F',
    7: 'G',
    8: 'H',
    9: 'I',
    10: 'J',
    11: 'K',
    12: 'M',
    13: 'N',
    14: 'O',
    15: 'P',
    16: 'Q',
    17: 'R',
    18: 'S',
    19: 'T',
    20: 'U',
    21: 'V',
    22: 'W',
    23: 'X',
    24: 'Y',
    25: 'Z',

}

const useStyles = makeStyles((theme) => ({
    customImage: {
        maxHeight: '350px',
        maxWidth: '350px',
        margin: '0 auto',
        display: 'block',
    },
    title: {
        background: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center'
    },
    catalogName: {
        marginBottom: theme.spacing(4)
    },
    dialogActions: {
        background: '#cdcdcd'
    },
    catalogThickness: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    buttonContainer: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center'
        }
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function DialogAddToCatalogManuel({ open, handleClose, setfiles, handelclickManualAdd }) {

    const classes = useStyles();
    const [materials] = useContext(MaterialsContext)
    const { postCatalog } = usePostCatalog()
    const hiddenInputRef = useRef(null);
    const hiddenInputConfigImageRef = useRef(null)
    const [, dispatchSnackbar] = useContext(SnackbarContext)
    const [file, setfile] = useState(null)
    const [fileConfig, setfileConfig] = useState(null)
    const [nbEntities, setnbEntities] = useState(1);
    const [errors, seterrors] = useState({});
    const [loading, setloading] = useState(false);

    const id_temp = uniqid();
    const [entityInformations, setentityInformations] = useState({
        name: '',
        entities: [
            { _id: 'A' + id_temp, id_tmp: 'A' + id_temp, type: 'longer', name: 'L', min: 100, max: 1000, asMin: false, asMax: false },
        ]
    });

    const getLinesLibele = useCallback(() => entityInformations.entities.filter(entity => entity.type === 'line' || entity.type === 'longer').map(entity => ({ id: entity._id, name: entity.name, horizontal: entity.horizontal, type: entity.type })), [entityInformations.entities])

    const getEntities = useCallback(
        () => {
            return entityInformations.entities.map((entity, index) => <Grid item xs={12}><CatalogCustomzationLine key={entity.id} withControl={true} entity={{ string: entity.name, id: entity._id, type: entity.type }} setentityInformations={setentityInformations} entityInformations={entityInformations} linesLibele={getLinesLibele()} /></Grid>)

        },
        [entityInformations],
    )

    const handelclickAddEnity = useCallback(
        (type) => {
            setnbEntities(nbEntities + 1)
            const id = 'A' + uniqid();
            setentityInformations({ ...entityInformations, entities: [...entityInformations.entities, { _id: id, id_tmp: id, name: entitydefaultName[nbEntities], type, min: 100, max: 1000 }] })
        },
        [entityInformations, nbEntities],
    )

    const handelClickValidate = useCallback(
        () => {
            (async () => {
                let error = {};
                let asError = false
                if (!entityInformations.name) {
                    error = { ...error, name: 'Valeur requise' }
                    asError = true
                }
                if (!entityInformations.file) {
                    error = { ...error, file: 'Image requis' }
                    asError = true
                }
                if (!entityInformations.fileConfig) {
                    error = { ...error, fileConfig: 'Image requise' }
                    asError = true
                }
                if (!entityInformations.thickness) {
                    error = { ...error, thickness: 'Valeur requise' }
                    asError = true
                }
                if (entityInformations.entities.length < 2) {
                    dispatchSnackbar({
                        type: 'SET_SNACKBAR',
                        payload: {
                            open: true,
                            content: "Vous devez ajouter au moins une ligne",
                            type: 'error',
                            duration: 6000
                        }
                    })
                    return;
                }
                if (!asError) {
                    setloading(true)
                    const res = await postCatalog({ ...entityInformations, entities: entityInformations.entities.map(({ _id, ...rest }) => rest) }, file, fileConfig)
                    setloading(false)
                    if (res) {
                        dispatchSnackbar({
                            type: 'SET_SNACKBAR',
                            payload: {
                                open: true,
                                content: 'Forme ajoutée !',
                                type: 'success',
                                duration: 6000
                            }
                        })
                        handleClose()
                    } else {
                        dispatchSnackbar({
                            type: 'SET_SNACKBAR',
                            payload: {
                                open: true,
                                content: "Une erreure c'est produite",
                                type: 'error',
                                duration: 6000
                            }
                        })
                    }
                }
                seterrors(error)
            })()
        },
        [dispatchSnackbar, entityInformations, file, fileConfig, handleClose, postCatalog],
    )

    const handelclickAddImage = useCallback(
        () => {
            hiddenInputRef.current.click()
        },
        [],
    )
    const handelclickAddConfigImage = useCallback(
        () => {
            hiddenInputConfigImageRef.current.click()
        },
        [],
    )
    const handelSelectFile = useCallback(
        (e) => {
            const file = e.target.files[0]
            setfile(file)
            const url = URL.createObjectURL(file)
            setentityInformations({ ...entityInformations, file: url })
        },
        [entityInformations],
    )
    const handelSelectConfigFile = useCallback(
        (e) => {
            const file = e.target.files[0]
            setfileConfig(file)
            const url = URL.createObjectURL(file)
            setentityInformations({ ...entityInformations, fileConfig: url })
        },
        [entityInformations],
    )
    const handelChangeTitle = useCallback(
        (e) => {
            setentityInformations({ ...entityInformations, name: e.target.value })
        },
        [entityInformations],
    )

    const handelChangeThickness = useCallback(
        (e) => {
            setentityInformations({ ...entityInformations, thickness: e.target.value })
        },
        [entityInformations],
    )

    const getOptions = useCallback(
        () => {
            const thicknesses = [];
            materials.forEach(material => material.shades.forEach(shade => shade.thicknesses.forEach(thickness => thicknesses.push(thickness.value))));
            return [...new Set(thicknesses)].map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)
        },
        [materials],
    )



    return (
        <Dialog fullScreen TransitionComponent={Transition} open={open} onClose={handleClose}>
            <DialogTitle id="add-to-catalog-dialog" className={classes.title}>Ajout d'une forme</DialogTitle>
            <DialogContent>

                <Grid container justify='center' className={classes.catalogName} spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <TextField onChange={handelChangeTitle} fullWidth value={entityInformations.name} id="item-title" label="Nom de ma pièce" error={errors.name} helperText={errors.name} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl error={errors.thickness} fullWidth>
                            <InputLabel id="thickness-valide">Selectionnez une épaisseurs valides</InputLabel>
                            <Select
                                style={{ width: '300px' }}
                                labelId="thickness-valide"
                                value={entityInformations.thickness || []}
                                fullWidth
                                onChange={handelChangeThickness}
                                multiple
                            >
                                {getOptions()}
                            </Select>
                            <FormHelperText>{errors.thickness}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container justify='center' className={classes.catalogName}>
                    <Grid item xs={12} sm={6}>
                        <Grid container direction='column' justify='center' alignItems='center'>
                            <Grid xs={12} item style={{ marginBottom: '1rem' }}>
                                <Button onClick={handelclickAddImage} variant="contained" color="secondary" startIcon={<ImageIcon />}>
                                    Choisir une image de présentation
                                </Button>
                                {
                                    errors.file &&
                                    <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
                                        {errors.file}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {
                                    entityInformations.file ? <img class={classes.customImage} src={entityInformations.file} alt="" /> : null
                                }
                            </Grid>
                        </Grid>
                        <input ref={hiddenInputRef} onChange={handelSelectFile} id='fileid' type='file' multiple hidden />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container direction='column' justify='center' alignItems='center'>
                            <Grid xs={12} item style={{ marginBottom: '1rem' }}>
                                <Button onClick={handelclickAddConfigImage} variant="contained" color="secondary" startIcon={<ImageIcon />}>
                                    Choisir une image de configuration
                                </Button>
                                {
                                    errors.fileConfig &&
                                    <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
                                        {errors.fileConfig}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {
                                    entityInformations.fileConfig ? <img class={classes.customImage} src={entityInformations.fileConfig} alt="" /> : null
                                }
                            </Grid>
                        </Grid>
                        <input ref={hiddenInputConfigImageRef} onChange={handelSelectConfigFile} id='fileid' type='file' multiple hidden />
                    </Grid>
                </Grid>
                <Grid container justify='flex-end' spacing={2}>

                    <Grid item xs={6} className={classes.buttonContainer}>
                        <Button onClick={() => handelclickAddEnity('line')} variant="contained" color="secondary" startIcon={<AddIcon />}>
                            Ajouter une ligne
                        </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.buttonContainer}>
                        <Button onClick={() => handelclickAddEnity('angle')} variant="contained" color="secondary" startIcon={<AddIcon />}>
                            Ajouter un angle
                        </Button>
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: '2rem', marginBottom: '1rem' }} />
                <Grid container>
                    {getEntities()}
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={handleClose} color="primary" variant='contained'>
                    Fermer
                </Button>
                <Button onClick={handelClickValidate} color="primary" variant='contained' startIcon={loading && <CircularProgress style={{ color: 'white' }} size={20} />}>
                    {loading ? 'Enregistrement' : 'Valider'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogAddToCatalogManuel
