import { FormControl, TextField, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';

const useStyles = makeStyles((theme) => ({
    input: {

        '& input': {
            textAlign: 'center'
        }
    }
}))

function ConfiguratorMenuNumberPiece({ readOnly }) {
    const configurator = useContext(ConfiguratorContext)[0]
    const dispachConfig = useContext(ConfiguratorContext)[1]

    const classes = useStyles();

    const [value, setvalue] = useState(configurator.nbPiece)

    const onchange = (e) => {
        if (e.target.value >= 1 || e.target.value === '') {
            setvalue(e.target.value)
        }
    }


    useEffect(() => {
        if (value > 0) {
            dispachConfig({
                type: 'SET_VALUE',
                payload: {
                    key: 'nbPiece',
                    data: value
                }
            })

        }
    }, [value])

    const onBlur = () => {
        if (value <= 0 || value === '') {
            setvalue(configurator.nbPiece)
        }
    }

    return (
        <FormControl fullWidth >
            <TextField
                id="configurator-input-number-piece"
                value={value}
                onBlur={onBlur}
                onChange={onchange}
                className={classes.input}
                fullWidth
                disabled={!configurator.thicknessId || readOnly}
                inputProps={{ readOnly: readOnly || false }}
                type="number"
            />
        </FormControl>
    )
}

export default ConfiguratorMenuNumberPiece
