import React, {useCallback, useState, useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, LinearProgress } from '@material-ui/core';
import {MaterialsContext} from '../contexts/MaterialsContext'
import axios from 'axios'


function ModalDeleteMaterial({open, setdiagOpen, type, settypeToDel, materialId, shadeId, thicknessId, setselectMaterialTab, setselectShadesTab, selectThickness, setselectThickness}) {

    const [materials, dispatch] = useContext(MaterialsContext);
    const [loading, setloading] = useState(false)
    
    const getTitle = useCallback(
        () => {
            
            switch(type) {
                case 'M' :
                    return 'Supression de la matière ' + materials.find(material => material._id === materialId)?.name
                case 'N' :
                    return 'Supression de la nuance ' + materials.find(material => material._id === materialId).shades?.find(shade => shade._id === shadeId)?.name
                case 'E' :
                    return 'Supression de l\'épaisseur' + materials.find(material => material._id === materialId).shades.find(shade => shade._id === shadeId).thicknesses?.find(item => item._id === thicknessId)?.value
                default:
                    break;
            }
        },
        [materialId, materials, shadeId, thicknessId, type],
    )

    
    const handelClickDelete = useCallback(
        () => {
            (async () => {
                
                switch(type) {
                    case 'M':
                        try {
                            setloading(true)
                            await axios.delete(process.env.REACT_APP_BASE_API + '/material/' + materialId)
                            setselectMaterialTab(0)
                            dispatch({
                                type: 'DELETE_MATERIAL', 
                                payload: {
                                    id: materialId
                                }
                            })
                        } catch (e) {
                            console.error(e)
                        }
                        setloading(false)
                        setdiagOpen(false)
                        break;
                    case 'N' :
                        let newMaterial = materials.find(material => material._id === materialId)
                        newMaterial = {...newMaterial, shades: newMaterial.shades.filter(shade => shade._id !== shadeId)}
                        
                       
                        try {
                            setloading(true)
                            await axios.put(process.env.REACT_APP_BASE_API + '/material/' + materialId, newMaterial)
                            setselectShadesTab(0)
                            dispatch({
                                type: 'SET_MATERIAL',
                                payload : { idMaterial: materialId, material: newMaterial }
                            })
                        } catch(e) {
                            console.error(e)
                        }
                        setloading(false)
                        setdiagOpen(false)
                        break;
                    case 'E' :
                        const data = materials.map(
                            material => material._id !== materialId ? material : {...material, shades: material.shades.map(
                                shade => shade._id !== shadeId ? shade : {...shade, thicknesses: shade.thicknesses.filter(
                                    thickness => thickness._id !== selectThickness
                                )})})
                       
                        try {
                            setloading(true)
                            const res = await axios.put(process.env.REACT_APP_BASE_API + '/material/' + materialId, data.find(d => d._id  === materialId))
                            setselectThickness(0)
                            dispatch({
                                type: 'SET_MATERIAL',
                                payload : { idMaterial: materialId, material: data.find(d => d._id  === materialId) }
                            })
                        } catch(e) {
                            console.error(e)
                        }
                        setloading(false)
                        setdiagOpen(false)
                        break;
                    default:
                        break;
                }
                settypeToDel(null)
                
            })()
        },
        [dispatch, materialId, materials, selectThickness, setdiagOpen, setselectMaterialTab, setselectShadesTab, setselectThickness, settypeToDel, shadeId, type],
    )

    return (
        <Dialog open={open} onClose={() => setdiagOpen(false)} aria-labelledby="form-dialog-title">
                { loading ? <LinearProgress /> : null }
                <DialogTitle id="form-dialog-title" align="center">{getTitle()}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Etes vous sûr ? Les données seront supprimées définitivement.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant="outlined" onClick={() => setdiagOpen(false)}>
                    Annuler
                </Button>
                <Button variant="outlined" onClick={handelClickDelete} color="primary">
                    Supprimer
                </Button>
                </DialogActions>
            </Dialog>
    )
}

export default ModalDeleteMaterial
