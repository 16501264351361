import { Button, Grid, TextField } from '@material-ui/core'
import React, { useCallback, useContext, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'
import { PropertiesContext } from '../../../contexts/PropertiesContext'

function ConfiguratorMainFormCustom() {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [properties, dispatchProperties] = useContext(PropertiesContext);
    const [height, setheight] = useState(configurator.mainForm.height || '');
    const [errorHeight, seterrorHeight] = useState(false);
    const [errorHeightText, seterrorHeightText] = useState('');
    const [width, setwidth] = useState(configurator.mainForm.width || '');
    const [errorWidth, seterrorWidth] = useState(false);
    const [errorWidthText, seterrorWidthText] = useState('');

    const handelChangeHeight = useCallback(
        (e) => {

            if (e.target.value.match(/^[0-9]*$/)) {
                seterrorHeight(false)
                seterrorHeightText('')
                if ((Number(e.target.value) < properties.machineMinWidth || Number(e.target.value) > properties.machineMaxWidth) && (Number(e.target.value) < properties.machineMinLength || Number(e.target.value) > properties.machineMaxLength)) {
                    seterrorHeightText(`valeur comprisent entre ${properties.machineMinWidth < properties.machineMinLength ? properties.machineMinWidth : properties.machineMinLength}  et ${properties.machineMaxWidth < properties.machineMaxLength ? properties.machineMaxWidth : properties.machineMaxLength}`)
                    seterrorHeight(true)
                }
                setheight(Math.round(Number(e.target.value)))
                setwidth(Math.round(Number(e.target.value) / configurator.mainForm.ratio))
            }

        },
        [configurator.mainForm.ratio, properties.machineMaxLength, properties.machineMaxWidth, properties.machineMinLength, properties.machineMinWidth],
    )

    const handelChangeWidth = useCallback(
        (e) => {

            if (e.target.value.match(/^[0-9]*$/)) {
                seterrorWidth(false)
                seterrorWidthText('')
                if ((Number(e.target.value) < properties.machineMinWidth || Number(e.target.value) > properties.machineMaxWidth) && (Number(e.target.value) < properties.machineMinLength || Number(e.target.value) > properties.machineMaxLength)) {
                    seterrorWidthText(`valeur comprisent entre ${properties.machineMinWidth < properties.machineMinLength ? properties.machineMinWidth : properties.machineMinLength}  et ${properties.machineMaxWidth < properties.machineMaxLength ? properties.machineMaxWidth : properties.machineMaxLength}`)
                    seterrorWidth(true)
                }
                setwidth(Math.round(Number(e.target.value)))
                setheight(Math.round(Number(e.target.value) * configurator.mainForm.ratio))
            }

        },
        [configurator.mainForm.ratio, properties.machineMaxLength, properties.machineMaxWidth, properties.machineMinLength, properties.machineMinWidth],
    )

    const handelClickvalidate = useCallback(
        () => {
            dispatch({
                type: 'SET_VALUE',
                payload: {
                    key: 'mainForm',
                    data: {
                        ...configurator.mainForm, height: Number(height), width: Number(width), configured: true
                    }
                }
            })
        },
        [configurator.mainForm, dispatch, height, width],
    )

    return (
        <>
            <Grid container justify='flex-end'>
                <Grid item >
                    <Button disabled={errorHeight || errorWidth} color="primary" variant="contained" onClick={handelClickvalidate} >Valider</Button>
                </Grid>
            </Grid>
            <Grid container spacing={4} justify='center'>
                <Grid item>
                    <TextField label='Largeur' error={errorHeight} helperText={errorHeightText || 'Largeur (mm)'} type="texte" value={height} onChange={handelChangeHeight} />
                </Grid>
                <Grid item>
                    <TextField label='Longueur ' error={errorWidth} helperText={errorWidthText || 'Longueur (mm)'} type="texte" value={width} onChange={handelChangeWidth} />
                </Grid>
            </Grid>

        </>
    )
}

export default ConfiguratorMainFormCustom
