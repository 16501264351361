import React, {useEffect} from 'react'
import { useSignOut } from 'react-auth-kit'
import { useHistory } from 'react-router-dom';

function Logout() {
    const signOut = useSignOut()
    const history = useHistory();
    useEffect(() => {
        signOut()
        history.push('/')
    }, [])
    return (
        <div>
            Logout ...
        </div>
    )
}

export default Logout
