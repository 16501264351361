import { Button, Grid, IconButton, Paper, Typography } from '@material-ui/core'
import React, { useContext, useCallback, useState } from 'react'
import ConfiguratorMenu from './ConfiguratorMenu'
import ConfiguratorMenuForm from './ConfiguratorMenuForm'
import { makeStyles } from '@material-ui/core/styles';
import ConfiguratorDisplayPrice from './ConfiguratorDisplayPrice';
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ConfiguratorMainFormRec from './ConfiguratorMainFormRec';
import ConfiguratorMainFormCircle from './ConfiguratorMainFormCircle';
import ConfiguratorMainFormTriangle from './ConfiguratorMainFormTriangle';
import ConfiguratorMainFormL from './ConfiguratorMainFormL';
import SettingsIcon from '@material-ui/icons/Settings';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import ConfiguratorMainFormText from './ConfiguratorMainFormText';
import ConfiguratorMainFormCustom from './ConfiguratorMainFormCustom';
import DialogFormRectangle from '../../Dialog/DialogConfigFormRectangle';
import DialogConfigurationMenu from '../../Dialog/DialogConfigurationMenu';
import BuildIcon from '@material-ui/icons/Build';
import DialogConfigMainForm from '../../Dialog/DialogConfigMainForm';

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.typography.button,
        padding: theme.spacing(1),
    },
    chip: {
        margin: '0 auto',
        display: 'flex',
        width: 'max-content',
        marginBottom: '1rem'
    },
    title: {
        fontWeight: 'bold',
        fontSize: '1rem',
        color: '#3c3c3c'
    },
    container: {
        '&:first-child': {
            marginTop: theme.spacing(8)
        },
        '& > div': {
            marginBottom: theme.spacing(6)
        }
    }
}));

function ConfiguratorControlPannel({ fontsList, textModels, settextModels, refStage }) {

    const [openDialogConfigurationRectangle, setOpenDialogConfigurationRectangle] = useState(false);
    const [openDialogConfigurationMainForm, setOpenDialogConfigurationMainForm] = useState(false);

    const [configurator, dispatch] = useContext(ConfiguratorContext);

    const classes = useStyles()

    const getMenu = useCallback(
        () => {
            switch (configurator.form) {
                case 0:
                    return <ConfiguratorMainFormRec displayAsColumn />
                case 1:
                    return <ConfiguratorMainFormCircle />
                case 2:
                    return <ConfiguratorMainFormTriangle />
                case 3:
                    return <ConfiguratorMainFormL displayAsColumn />
                case 4:
                    return <ConfiguratorMainFormCustom />
                case 5:
                    return <ConfiguratorMainFormText fontsList={fontsList} />
                default:
                    break;
            }
        },
        [configurator],
    )

    const handleOpenMainDailogConfigurationRectangle = () => {
        setOpenDialogConfigurationRectangle(true);
    };

    const handleCloseDialogConfigurationRectangle = () => {
        setOpenDialogConfigurationRectangle(false);
    }

    return (
        <>

            <Grid className={classes.container} container justify="center" justifyContent="space-between" alignItems="center" direction="column" >
                {
                    configurator && configurator.mainForm.configured ?

                        <Grid item md={12} style={{ width: '100%' }}>

                            <Paper elevation={0} style={{ padding: "2rem" }}>
                                <ConfiguratorDisplayPrice textModels={textModels} settextModels={settextModels} refStage={refStage} />
                            </Paper>
                        </Grid>
                        : null
                }
                {
                    configurator && configurator.mainForm.configured &&
                    <Grid item md={12} style={{ width: '100%' }}>
                        <Paper elevation={0}>
                            <Button onClick={() => setOpenDialogConfigurationMainForm(true)} fullWidth startIcon={<SquareFootIcon color='primary' />} >Modifier la matière</Button>
                        </Paper>
                    </Grid>
                }

                {configurator && !configurator.mainForm.configured && <Grid item md={12} style={{ width: '100%' }}>

                    <Paper elevation={0} style={{ padding: "2rem" }}>

                        <ConfiguratorMenuForm />

                    </Paper>
                </Grid>}

                {configurator && configurator.mainForm.configured && !configurator.isFromCatalog && configurator.form !== 4 && <Grid item md={12} style={{ width: '100%' }}>
                    <Paper elevation={0}>
                        <Button onClick={handleOpenMainDailogConfigurationRectangle} fullWidth startIcon={<BuildIcon color='primary' />} >Modifier la Forme principale</Button>
                    </Paper>
                </Grid>}
            </Grid >
            {openDialogConfigurationRectangle && <DialogConfigMainForm
                open={openDialogConfigurationRectangle}
                setOpen={handleCloseDialogConfigurationRectangle}
                fontsList={fontsList}
            />
            }
            {
                openDialogConfigurationMainForm && <DialogConfigurationMenu
                    open={openDialogConfigurationMainForm}
                    setopenDialog={() => setOpenDialogConfigurationMainForm(false)}
                    isClosable={true}
                />
            }
        </>
    )
}

export default ConfiguratorControlPannel