import React, { useContext, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import Paper from '@material-ui/core/Paper';
import { MaterialsContext } from '../contexts/MaterialsContext'
import { Tab, Tabs, Button, Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import TableCellEditable from './TableCellEditable';
import ModalAddMaterial from './ModalAddMaterial';
import ModalDeleteMaterial from './ModalDeleteMaterial';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { ShadeDefaultPriceSchema, ThicknessPricePerKiloSchema, MaterialnameSchema, MaterialDensitySchema, ThicknessFoldingTimeSchema, ShadenameSchema, ThicknessValueSchema, ThicknessPierceTimeSchema, ThicknessCuttingSpeedSchema } from '../modules/validation'
import TableCellFileSelect from './TableCellFileSelect';
import TableCellColorSelect from './TableCellColorSelect';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DialogImagePreview from './Dialog/DialogImagePreview';

const useStyles = makeStyles((theme) => ({
    root: {},
    titleTab: {},
    enptyMaterialText: {
        display: 'flex',
        alignItems: 'center'
    },
    tablePaper: {
        padding: theme.spacing(2)
    },
    tableTitle: {
        paddingTop: theme.spacing(2)
    }
}));

function MaterialHandler() {

    const [materials, dispatch] = useContext(MaterialsContext);

    const [selectMaterialTab, setselectMaterialTab] = useState(null);
    const [selectShadesTab, setselectShadesTab] = useState(null);
    const [selectThickness, setselectThickness] = useState(null);

    const [materialName, setmaterialName] = useState('')
    const [materialDensity, setmaterialDensity] = useState('');

    const [shadeName, setshadeName] = useState('');
    const [shadeDefaultPrice, setshadeDefaultPrice] = useState('');

    const [thicknessCuttingSpeed, setthicknessCuttingSpeed] = useState('')
    const [thicknessPierceTime, setthicknessPierceTime] = useState('')
    const [thicknessValue, setthicknessValue] = useState('')
    const [thicknesspricePerKilo, setthicknesspricePerKilo] = useState('');
    const [thicknessFoldingTime, setthicknessFoldingTime] = useState('');
    const [diagOpen, setdiagOpen] = useState(false);
    const [diagDelOpen, setdiagDelOpen] = useState(false);
    const [typeToAdd, settypeToAdd] = useState(null)
    const [typeToDel, settypeToDel] = useState(null)
    const [isModalPreviewImageOpen, setisModalPreviewImageOpen] = useState(false);
    const [fileNameForImagePreview, setfileNameForImagePreview] = useState('');



    const classes = useStyles();

    const handelChangeMaterialTab = useCallback(
        (e, n) => {
            setselectMaterialTab(n)
            setselectShadesTab(null)
            setselectThickness(null)
        },
        [],
    )

    const handelChangeShadeTab = useCallback(
        (e, n) => {
            setselectShadesTab(n)
            setselectThickness(null)
        },
        [],
    )

    const materialsTabs = useCallback(
        () => {
            return materials.length > 0 ? materials.map(material => <Tab key={material._id} label={material.name} value={material._id} />) : <Typography className={classes.enptyMaterialText} variant="subtitle2" align='center'>Aucun: cliquez sur "Ajouter"</Typography>
        },
        [classes.enptyMaterialText, materials],
    )

    const shadesTabs = useCallback(
        () => {
            const material = materials.find(material => material._id === selectMaterialTab)
            return material.shades.map((shade) => <Tab key={shade._id} label={shade.name} value={shade._id} />)
        },
        [materials, selectMaterialTab],
    )

    const thicknessTab = useCallback(
        () => {
            const material = materials.find(material => material._id === selectMaterialTab)
            const shade = material.shades.find((shade) => shade._id === selectShadesTab)
            return shade.thicknesses.map(thickness => <Tab key={thickness._id} label={thickness.value} value={thickness._id} />)
        },
        [materials, selectMaterialTab, selectShadesTab],
    )

    const handelChangeMaterialName = useCallback(
        (e) => {
            setmaterialName(e.target.value)
        },
        [],
    )

    const handelChangeMaterialDensity = useCallback(
        (e) => {
            setmaterialDensity(e.target.value)
        },
        [],
    )

    const handelChangeShadelName = useCallback(
        (e) => {
            setshadeName(e.target.value)
        },
        [],
    )

    const handelChangeShadelDefaultPrice = useCallback(
        (e) => {
            setshadeDefaultPrice(e.target.value)
        },
        []
    )

    const handelChangeThicknessCuttingSpeed = useCallback(
        (e) => {
            setthicknessCuttingSpeed(e.target.value)
        },
        [],
    )

    const handelChangeThicknessPierceTime = useCallback(
        (e) => {
            setthicknessPierceTime(e.target.value)
        },
        [],
    )

    const handelChangeThicknessValue = useCallback(
        (e) => {
            setthicknessValue(e.target.value)
        },
        [],
    )

    const handelChangeThicknesspricePerKilo = useCallback(
        (e) => {
            setthicknesspricePerKilo(e.target.value)
        },
        [],
    )
    const handelChangeThicknessFoldingTime = useCallback(
        (e) => {
            setthicknessFoldingTime(e.target.value)
        },
        [],
    )
    const handelClickAddMaterial = useCallback(
        () => {
            settypeToAdd('M')
            setdiagOpen(true)

        },
        [],
    )

    const handelClickAddShades = useCallback(
        () => {
            settypeToAdd('N')
            setdiagOpen(true)
        },
        [],
    )

    const handelClickAddThickness = useCallback(
        () => {
            settypeToAdd('E')
            setdiagOpen(true)
        },
        [],
    )

    const handelClickDelMaterial = useCallback(
        () => {
            settypeToDel('M')
            setdiagDelOpen(true)
        },
        [],
    )

    const handelClickDelShade = useCallback(
        () => {
            settypeToDel('N')
            setdiagDelOpen(true)
        },
        [],
    )

    const handelClickDelThickness = useCallback(
        () => {
            settypeToDel('E')
            setdiagDelOpen(true)
        },
        [],
    )


    const handelClickEditedMatrial = useCallback(
        (key, value) => {
            (async () => {
                const updateMaterial = { ...materials.find(material => material._id === selectMaterialTab), [key]: value }
                try {
                    await axios.put(process.env.REACT_APP_BASE_API + '/material/' + selectMaterialTab, updateMaterial)
                    dispatch({
                        type: 'EDIT_MATERIAL_VALUE',
                        payload: { id: selectMaterialTab, key, value }
                    })
                } catch (e) {
                    console.err('error POST')
                }

            })()


        },
        [dispatch, materials, selectMaterialTab],
    )

    const handelClickEditedShade = useCallback(
        (materialKey, key, value) => {

            (async () => {
                const materialToUpdate = materials.map(
                    material => material._id !== materialKey ? material : {
                        ...material, shades: material.shades.map(
                            shade => shade._id !== selectShadesTab ? shade : { ...shade, [key]: value }
                        )
                    }
                ).find(material => material._id === materialKey)

                try {
                    await axios.put(process.env.REACT_APP_BASE_API + '/material/' + selectMaterialTab, materialToUpdate)
                    dispatch({
                        type: 'SET_MATERIAL',
                        payload: { idMaterial: selectMaterialTab, material: materialToUpdate }
                    })
                } catch (e) {
                    console.err('error POST')
                }

            })()
        }, [dispatch, materials, selectMaterialTab, selectShadesTab]
    )

    const handelClickEditedThickness = useCallback(
        (key, value) => {
            (async () => {
                const materialToUpdate = materials.map(
                    material => material._id !== selectMaterialTab ? material : {
                        ...material, shades: material.shades.map(
                            shade => shade._id !== selectShadesTab ? shade : {
                                ...shade, thicknesses: shade.thicknesses.map(
                                    thickness => thickness._id !== selectThickness ? thickness : { ...thickness, [key]: value }
                                )
                            }
                        )
                    }
                ).find(material => material._id === selectMaterialTab)

                try {
                    await axios.put(process.env.REACT_APP_BASE_API + '/material/' + selectMaterialTab, materialToUpdate)
                    dispatch({
                        type: 'SET_MATERIAL',
                        payload: { idMaterial: selectMaterialTab, material: materialToUpdate }
                    })
                } catch (e) {
                    console.err('error POST')
                }

            })()
        }, [dispatch, materials, selectMaterialTab, selectShadesTab, selectThickness]
    )

    const handelClickEditedShadeDefaultPrice = useCallback((value) => {
        (async () => {
            const materialToUpdate = materials.map(
                material => material._id !== selectMaterialTab ? material : {
                    ...material, shades: material.shades.map(
                        shade => shade._id !== selectShadesTab ? shade : {
                            ...shade, shadeDefaultPrice: value, thicknesses: shade.thicknesses.map(
                                thickness => ({ ...thickness, pricePerKilo: Number(value) })
                            )
                        }
                    )
                }
            ).find(material => material._id === selectMaterialTab)

            try {
                await axios.put(process.env.REACT_APP_BASE_API + '/material/' + selectMaterialTab, materialToUpdate)
                dispatch({
                    type: 'SET_MATERIAL',
                    payload: { idMaterial: selectMaterialTab, material: materialToUpdate }
                })
            } catch (e) {
                console.err('error POST')
            }

        })()


    }, [dispatch, materials, selectMaterialTab, selectShadesTab])

    const getNbShades = useCallback(
        () => {
            return materials.find(material => material._id === selectMaterialTab).shades.length
        },
        [materials, selectMaterialTab],
    )

    const getNbThickness = useCallback(
        () => {
            return materials.find(material => material._id === selectMaterialTab).shades.find(shade => shade._id === selectShadesTab).thicknesses.length
        },
        [materials, selectMaterialTab, selectShadesTab],
    )

    const openModalImagePreview = (fileName) => {
        if (fileName) {
            setfileNameForImagePreview(fileName)
            setisModalPreviewImageOpen(true)
        }
    }


    const materialsInformations = useCallback(
        () => {
            const material = materials.find(material => material._id === selectMaterialTab)
            return material ? <TableContainer component={Paper} elevation={0}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Nom</TableCell>
                            <TableCell align="center">Densité</TableCell>
                            <TableCell align="center">Image</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        <TableRow>
                            <TableCellEditable yupValidation={MaterialnameSchema} onCickEdited={() => handelClickEditedMatrial('name', materialName)} value={materialName} onChange={handelChangeMaterialName} icon={<EditIcon fontSize='small' />}>{material.name}</TableCellEditable>
                            <TableCellEditable yupValidation={MaterialDensitySchema} onCickEdited={() => handelClickEditedMatrial('density', Number(materialDensity))} value={materialDensity} onChange={handelChangeMaterialDensity} icon={<EditIcon fontSize='small' />}>{material.density}</TableCellEditable>
                            <TableCellFileSelect onCickEdited={(fileName) => handelClickEditedMatrial('image', fileName)}>{material.image && <VisibilityIcon onClick={() => openModalImagePreview(material.image)} />} </TableCellFileSelect>

                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer> : null
        },
        [handelChangeMaterialDensity, handelChangeMaterialName, handelClickEditedMatrial, materialDensity, materialName, materials, selectMaterialTab],
    )

    const shadesInformations = useCallback(
        () => {
            const material = materials.find(material => material._id === selectMaterialTab)
            const shade = material.shades.find((shade) => shade._id === selectShadesTab)
            return <TableContainer component={Paper} elevation={0}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Nom</TableCell>
                            <TableCell align="center">Prix par defaut</TableCell>
                            <TableCell align="center">Image</TableCell>
                            <TableCell align="center">Couleur</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        <TableRow>
                            <TableCellEditable yupValidation={ShadenameSchema} onCickEdited={() => handelClickEditedShade(selectMaterialTab, 'name', shadeName)} value={shadeName} onChange={handelChangeShadelName} icon={<EditIcon fontSize='small' />}>{shade.name}</TableCellEditable>
                            <TableCellEditable yupValidation={ShadeDefaultPriceSchema} onCickEdited={() => handelClickEditedShadeDefaultPrice(shadeDefaultPrice)} value={shadeDefaultPrice} onChange={handelChangeShadelDefaultPrice} icon={<EditIcon fontSize='small' />}>{shade.shadeDefaultPrice}</TableCellEditable>
                            <TableCellFileSelect onCickEdited={(fileName) => handelClickEditedShade(selectMaterialTab, 'image', fileName)}>{shade.image && <VisibilityIcon onClick={() => openModalImagePreview(shade.image)} />}</TableCellFileSelect>
                            <TableCellColorSelect onCickEdited={(color) => handelClickEditedShade(selectMaterialTab, 'color', color)} value={shade.color}></TableCellColorSelect>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        },
        [handelChangeShadelDefaultPrice, handelChangeShadelName, handelClickEditedShade, handelClickEditedShadeDefaultPrice, materials, selectMaterialTab, selectShadesTab, shadeDefaultPrice, shadeName],
    )

    const thicknessInformation = useCallback(
        () => {
            const material = materials.find(material => material._id === selectMaterialTab)
            const shade = material.shades.find((shade) => shade._id === selectShadesTab)
            const thickness = shade.thicknesses.find(thickness => thickness._id === selectThickness)

            return <TableContainer component={Paper} elevation={0}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Epaisseur <small>(mm)</small></TableCell>
                            <TableCell align="center">Vitesse de coupe <small>(mm/min)</small></TableCell>
                            <TableCell align="center">Temps de percage <small>(mm/sec)</small></TableCell>
                            <TableCell align="center">Temps de pliage <small>(s)</small></TableCell>
                            <TableCell align="center">Prix au kilo <small>(€/kg)</small></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        <TableRow>
                            <TableCellEditable onCickEdited={() => handelClickEditedThickness('value', thicknessValue)} yupValidation={ThicknessValueSchema} value={thicknessValue} onChange={handelChangeThicknessValue} icon={<EditIcon fontSize='small' />}>{thickness.value}</TableCellEditable>
                            <TableCellEditable onCickEdited={() => handelClickEditedThickness('cuttingSpeed', thicknessCuttingSpeed)} yupValidation={ThicknessCuttingSpeedSchema} value={thicknessCuttingSpeed} onChange={handelChangeThicknessCuttingSpeed} icon={<EditIcon fontSize='small' />}>{thickness.cuttingSpeed}</TableCellEditable>
                            <TableCellEditable onCickEdited={() => handelClickEditedThickness('pierceTime', thicknessPierceTime)} yupValidation={ThicknessPierceTimeSchema} value={thicknessPierceTime} onChange={handelChangeThicknessPierceTime} icon={<EditIcon fontSize='small' />}>{thickness.pierceTime}</TableCellEditable>
                            <TableCellEditable onCickEdited={() => handelClickEditedThickness('foldingTime', thicknessFoldingTime)} yupValidation={ThicknessFoldingTimeSchema} value={thicknessFoldingTime} onChange={handelChangeThicknessFoldingTime} icon={<EditIcon fontSize='small' />}>{thickness.foldingTime}</TableCellEditable>
                            <TableCellEditable onCickEdited={() => handelClickEditedThickness('pricePerKilo', thicknesspricePerKilo)} yupValidation={ThicknessPricePerKiloSchema} value={thicknesspricePerKilo} onChange={handelChangeThicknesspricePerKilo} icon={<EditIcon fontSize='small' />}>{thickness.pricePerKilo}</TableCellEditable>

                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        },
        [handelChangeThicknessCuttingSpeed, handelChangeThicknessFoldingTime, handelChangeThicknessPierceTime, handelChangeThicknessValue, handelChangeThicknesspricePerKilo, handelClickEditedThickness, materials, selectMaterialTab, selectShadesTab, selectThickness, thicknessCuttingSpeed, thicknessFoldingTime, thicknessPierceTime, thicknessValue, thicknesspricePerKilo],
    )


    return (
        <Paper className={classes.root} elevation={0}>
            <div>
                <form className={classes.root} noValidate autoComplete="off">
                    <Paper elevation={0}>
                        <Grid container direction="row" justify='space-between' className={classes.tableTitle}>
                            <Button variant="contained" onClick={handelClickAddMaterial}><AddIcon fontSize='small' /></Button>
                            <Typography variant="h6" style={{ margin: '0 1rem' }} align='center'>Matériaux</Typography>
                            <Button variant="contained" disabled={!selectMaterialTab} onClick={handelClickDelMaterial}><RemoveIcon color="secondary" fontSize='small' /></Button>

                        </Grid>


                        <Tabs
                            value={selectMaterialTab || 0}
                            onChange={handelChangeMaterialTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >


                            {
                                materialsTabs()
                            }
                        </Tabs>
                        {
                            selectMaterialTab ?
                                materialsInformations()
                                : null
                        }
                    </Paper>
                    {

                        selectMaterialTab ?
                            <Paper elevation={0}>
                                <Grid container direction="row" justify='space-between' className={classes.tableTitle}>
                                    <Button variant="contained" onClick={handelClickAddShades}><AddIcon fontSize='small' /></Button>
                                    <Typography variant="h6" align='center'>Nuances</Typography>
                                    <Button variant="contained" disabled={!selectShadesTab || getNbShades() <= 1} onClick={handelClickDelShade}><RemoveIcon color="secondary" fontSize='small' /></Button>
                                </Grid>
                                <Tabs
                                    value={selectShadesTab || 0}
                                    onChange={handelChangeShadeTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    style={{ marginTop: '1rem' }}
                                    variant="scrollable"
                                    scrollButtons="auto"

                                >
                                    {
                                        shadesTabs()
                                    }

                                </Tabs>
                                {
                                    selectShadesTab ?
                                        shadesInformations()
                                        : null
                                }
                            </Paper>
                            : null
                    }
                    {
                        selectShadesTab ?
                            <Paper elevation={0}>
                                <Grid container direction="row" justify='space-between' className={classes.tableTitle}>
                                    <Button variant="contained" onClick={handelClickAddThickness}><AddIcon fontSize='small' /></Button>
                                    <Typography variant="h6" align='center'>Epaisseurs</Typography>
                                    <Button variant="contained" disabled={!selectThickness || getNbThickness() <= 1} onClick={handelClickDelThickness}><RemoveIcon color="secondary" fontSize='small' /></Button>
                                </Grid>
                                <Tabs
                                    value={selectThickness || 0}
                                    onChange={(e, n) => setselectThickness(n)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    style={{ marginTop: '1rem' }}
                                >
                                    {
                                        thicknessTab()
                                    }
                                </Tabs>
                                {
                                    selectThickness ?
                                        thicknessInformation()
                                        : null
                                }
                            </Paper>
                            : null
                    }


                </form>
            </div>
            {diagOpen && <ModalAddMaterial open={diagOpen} setdiagOpen={setdiagOpen} type={typeToAdd} materialId={selectMaterialTab} shadeId={selectShadesTab} />}
            {diagDelOpen && <ModalDeleteMaterial open={diagDelOpen} setselectThickness={setselectThickness} selectThickness={selectThickness} setselectMaterialTab={setselectMaterialTab} setselectShadesTab={setselectShadesTab} setdiagOpen={setdiagDelOpen} type={typeToDel} settypeToDel={settypeToDel} materialId={selectMaterialTab} shadeId={selectShadesTab} thicknessId={selectThickness} />}
            {isModalPreviewImageOpen && <DialogImagePreview open={isModalPreviewImageOpen} fileName={fileNameForImagePreview} setOpen={setisModalPreviewImageOpen} />}
        </Paper>
    )
}

export default MaterialHandler
