import React, { useCallback, useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

function TextFieldValidation({ autoFocus, autoComplete, InputLabelProps, defaultValue, className, margin, id, label, type, fullWidth, helperText, value, onChange, yupValidation, disabled, onError, variant }) {

    const [error, seterror] = useState(false)

    useEffect(() => {
        if (value) {

            (async () => {
                try {

                    seterror({ value: false, message: '' })
                    await yupValidation.validate({ value })
                    if (onError) {
                        onError(false)
                    }
                } catch (err) {
                    seterror({ value: true, message: err.errors[0] })
                    if (onError) {
                        onError(true)
                    }

                }
            })()
        }

    }, []);

    const handelChange = useCallback((e) => {

        (async () => {
            onChange(e)
            seterror({ value: false, message: '' })
            try {
                await yupValidation.validate({ value: e.target.value })
                if (onError) {
                    onError(false)
                }
            } catch (err) {
                seterror({ value: true, message: err.errors[0] })
                if (onError) {
                    onError(true)
                }

            }

        })()

    }, [onChange, onError, yupValidation])

    return (
        <TextField
            disabled={disabled || false}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            className={className}
            margin={margin}
            id={id}
            label={label}
            type={type}
            fullWidth={fullWidth}
            helperText={error.message || helperText}
            value={value}
            onChange={handelChange}
            error={error.value}
            variant={variant}
            defaultValue={defaultValue || ''}
            InputLabelProps={InputLabelProps}
        />
    )
}

export default TextFieldValidation
