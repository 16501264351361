import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogActions, DialogContent, DialogContentText, Grid } from '@material-ui/core';
import TextFieldValidation from '../TextFieldValidation';
import { CatalogSvgDatasSchemas } from '../../modules/validation';

function DialogAddCatalogSvgFamily({ open, handleClose, familyToAdd, handleChangeFamilyToAdd, handleClickAddFamily }) {

    return (

        <Dialog maxWidth={'md'} open={open} onClose={handleClose}>
            <DialogTitle>Ajout d'une famille</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={10}>
                        <TextFieldValidation id="svg-add-family" label="Ajouter type" value={familyToAdd} onChange={handleChangeFamilyToAdd} fullWidth yupValidation={CatalogSvgDatasSchemas} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify='space-around'>


                    <Button onClick={handleClose}>Annuler</Button>
                    <Button disabled={!familyToAdd} onClick={handleClickAddFamily}>ajouter</Button>
                </Grid>
            </DialogActions>

        </Dialog>
    )
}

export default DialogAddCatalogSvgFamily
