import React, { useContext, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, Paper } from '@material-ui/core';
import HeightIcon from '@material-ui/icons/Height';
import PanoramaWideAngleIcon from '@material-ui/icons/PanoramaWideAngle';
import AddIcon from '@material-ui/icons/Add';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { displayFormatedPrice, calculPriceWithVat } from '../../modules/utils';
import { PropertiesContext } from '../../contexts/PropertiesContext';
import ConfiguratorMenuNumberPiece from '../EndUser/Configurator/ConfiguratorMenuNumberPiece';
import ConfiguratorMenu from '../EndUser/Configurator/ConfiguratorMenu';
import { ConfiguratorContext } from '../../contexts/ConfiguratorContext';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import useValidationForm from '../Hooks/useValidationForm';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '80vh',
        backgroundImage: `radial-gradient( circle 343px at 46.3% 47.5%, rgba(242,242,242,1) 0%, rgba(241,241,241,1) 72.9% )`
    },
    container: {
        height: '100%'
    },
    pricePaper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    viewerPaper: {
        padding: theme.spacing(2),
        height: '100%',
        position: 'relative'
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        gap: theme.spacing(1)
    },
    loader: {
        position: 'absolute',
        top: '50%',
        right: '50%',
    },
    svg: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        overflow: 'auto',
        '& svg': {
            maxWidth: '80%',
            maxHeight: '80%',
            width: '80%',
            height: '80%'
        }
    },
}));

export default function DialogShowDXFDropResult({ price, unitPrice, svg, open, handleClose, weight, maxHeight, maxWidth, handelAddToBasket, postBasketLoading, collision, handelClickNext }) {

    const classes = useStyles();
    const [property] = useContext(PropertiesContext);
    const [configurator] = useContext(ConfiguratorContext)
    const [loading, setLoading] = useState(false)

    const { validationOnErrorText } = useValidationForm()

    const isButtonDisabled = () => collision || loading || configurator.nbPiece <= 0 || !configurator.materialId || !configurator.shadeId || !configurator.thicknessId || configurator.validations?.hasError;

    return (
        <Dialog
            open={open}
            maxWidth={'lg'}
            fullWidth

        >
            <DialogContent className={classes.root}>
                <Grid container className={classes.container} spacing={2}>

                    <Grid item xs={8} className={classes.container}>
                        <Paper elevation={0} className={classes.viewerPaper}>
                            <div className={classes.svg} dangerouslySetInnerHTML={{ __html: svg }} />

                        </Paper>
                    </Grid>
                    <Grid item xs={4} className={classes.container}>
                        <Paper elevation={0} className={classes.pricePaper}>

                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container alignContent='center' style={{ height: '40px' }}>
                                        <HeightIcon color='primary' />
                                        <Typography style={{ fontWeight: 'bold', marginRight: '1rem', marginLeft: '0.5rem' }} variant="body2" gutterBottom display="inline" >Dimensions : </Typography>
                                        <Typography variant="body2" display="block" gutterBottom>{maxWidth && maxHeight ? `${maxHeight} x ${maxWidth} mm` : ` -- x -- mm`}</Typography>

                                    </Grid>
                                    <Grid container alignContent='center' style={{ height: '40px' }}>
                                        <PanoramaWideAngleIcon color='primary' />
                                        <Typography style={{ fontWeight: 'bold', marginRight: '1rem', marginLeft: '0.5rem' }} variant="body2" gutterBottom display="inline" >Poids : </Typography>
                                        <Typography variant="body2" display="block" gutterBottom>{`${weight || '--'} kg`}</Typography>

                                    </Grid>
                                    {
                                        !process.env.REACT_APP_IFRAME_MODE &&
                                        <>
                                            <Grid container alignItems="center" alignContent='center' style={{ height: '40px' }}>
                                                <Grid item>
                                                    <AddIcon color='primary' />
                                                </Grid>
                                                <Grid item>
                                                    <Typography style={{ fontWeight: 'bold', marginRight: '1rem', marginLeft: '0.5rem' }} variant="body2" gutterBottom display="inline" >Quantité : </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ConfiguratorMenuNumberPiece />
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    {!process.env.REACT_APP_IFRAME_MODE && <Grid container alignItems='center' style={{ marginTop: '1rem' }}>
                                        <Grid item xs={2} className={classes.priceContainer} >
                                            <Typography style={{ fontWeight: 'bold' }} variant="button" display="inline" gutterBottom>PU</Typography>
                                        </Grid>
                                        <Grid item xs={5} className={classes.priceContainer} >
                                            <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom display="inline" >{loading ? <CircularProgress size={20} /> : unitPrice ? displayFormatedPrice(unitPrice) : ' - - '}</Typography>
                                            <Typography variant="button" display="inline" gutterBottom> € HT</Typography>
                                        </Grid>
                                        <Grid item xs={5} className={classes.priceContainer}>
                                            <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom display="inline" >{loading ? <CircularProgress size={20} /> : unitPrice ? displayFormatedPrice(calculPriceWithVat(unitPrice, property.vatRate)) : ' - - '}</Typography>
                                            <Typography variant="button" display="inline" gutterBottom> € TTC</Typography>
                                        </Grid>

                                    </Grid>}

                                    <Grid container alignItems='center' style={{ marginTop: '1rem' }}>
                                        <Grid item xs={2} className={classes.priceContainer} >
                                            <Typography style={{ fontWeight: 'bold' }} variant="button" display="inline" gutterBottom>{process.env.REACT_APP_IFRAME_MODE ? 'PRIX' : 'TOTAL'}</Typography>
                                        </Grid>
                                        <Grid item xs={5} className={classes.priceContainer} >
                                            <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom display="inline" >{loading ? <CircularProgress size={20} /> : price ? displayFormatedPrice(price) : ' - - '}</Typography>
                                            <Typography variant="button" display="inline" gutterBottom> € HT</Typography>
                                        </Grid>
                                        <Grid item xs={5} className={classes.priceContainer}>
                                            <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom display="inline" >{loading ? <CircularProgress size={20} /> : price ? displayFormatedPrice(calculPriceWithVat(price, property.vatRate)) : ' - - '}</Typography>
                                            <Typography variant="button" display="inline" gutterBottom> € TTC</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper elevation={0} className={classes.pricePaper}>
                            <ConfiguratorMenu withoutNbPiece />
                        </Paper>
                        {
                            configurator.validations.hasError ? <Alert elevation={0} severity="error">
                                <AlertTitle>DXF non valide</AlertTitle>
                                {validationOnErrorText}
                            </Alert> : null
                        }
                        <>

                            <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
                                <Grid xs={8} item container justifyContent="flex-end" >
                                    <Button fullWidth disabled={isButtonDisabled()} onClick={handelAddToBasket} variant="contained" color='secondary' startIcon={<AddShoppingCartIcon />}>Ajouter au panier {postBasketLoading ? <CircularProgress color="primary" size={20} /> : null} </Button>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Grid xs={8} item container justifyContent="flex-end" >
                                    <Grid item xs={6}>
                                        <Button fullWidth onClick={handleClose} variant="outlined" color="primary">Fermer</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth variant="outlined" color="primary" onClick={handelClickNext}>Ignorer</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog>
    );
}
