import React, { useCallback, useRef } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogActions, DialogContent, DialogContentText, Grid, makeStyles } from '@material-ui/core';


const useStyle = makeStyles((theme) => ({
    title: {
        background: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center'
    }
}))

function DialogAddToCatalog({ open, handleClose, setfiles, handelclickManualAdd }) {

    const hiddenInputRef = useRef(null);
    const classes = useStyle()

    const handelSelectFile = useCallback(
        (e) => {
            setfiles(e.target.files);
            handleClose();
        },
        [handleClose, setfiles],
    )

    return (

        <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} className={classes.root}>
            <DialogTitle id="add-to-catalog-dialog" className={classes.title}>Ajout d'une forme</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Importer votre fichier DXF pour l'ajouter au catalogue
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container justify='flex-end' spacing={1}>
                    <Grid item>
                        <Button onClick={handleClose} variant="outlined" color="primary">
                            Fermer
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handelclickManualAdd} variant="contained" color="primary">
                            Ajouter une pièce
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
            <input ref={hiddenInputRef} onChange={handelSelectFile} id='fileid' type='file' multiple hidden />

        </Dialog>
    )
}

export default DialogAddToCatalog
