import React, { useEffect, useContext, useState, useRef } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, useMediaQuery, Grid } from '@material-ui/core';
import CatalogControlPannel from './CatalogControlPannel';
import { CatalogContext } from '../../../contexts/CatalogContext';
import CatalogDrawingPannel from './CatalogDrawingPannel';
import ConfiguratorDrawingPannel from '../Configurator/ConfiguratorDrawingPannel';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0

    },
    mainGrid: {
        display: 'flex',
        height: '100%'
    },
    drawer: {
        backgroundColor: '#f2f2f2',

        width: () => theme.breakpoints.down('sm') ? '100%' : '33.33%'
    },
    drawerFull: {
        width: '66.66%'
    },
    sideDrawlerFull: {
        width: '33.33%'
    },
    sideDrawler: {
        position: 'absolute',
        zIndex: 9,
        height: '100vh',
        transform: 'translateX(-100%)',
        transition: 'all 500ms'
    },
    drawlerButton: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        background: 'white',
        borderRadius: '25%',
        padding: '4px',
        height: '35px',
        width: '35px'
    },
    drawlerButtonContainer: {
        zIndex: 10,
        top: '10px',
        left: '10px',
        background: 'white',
        padding: '5px',
        borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    controlPannelContainer: {
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },

        height: `calc(100vh${process.env.REACT_APP_IFRAME_MODE ? '' : ' - 69px'})`,
        overflow: 'auto',
        position: 'relative',
        backgroundImage: "radial-gradient( circle 343px at 46.3% 47.5%, rgba(242,242,242,1) 0%, rgba(241,241,241,1) 72.9% )"
    }

}))

// eslint-disable-next-line react-hooks/exhaustive-deps
const fontsList = [
    {
        key: "BlackOpsOne",
        name: "Black Ops One (Pochoir)"
    },
    {
        key: "EmblemaOne-Regular",
        name: "EmblemaOne Regular (Pochoir)"
    },
    {
        key: "old_school_united_stencil",
        name: "Old school united stencil (Pochoir)"
    },
    {
        key: "StardosStencil-Bold",
        name: "StardosStencil Bold (Pochoir)"
    },
    {
        key: "Behind-the-Painter",
        name: "Behind the Painter (Attachée)"
    },
    {
        key: "Cathrina-Belisha",
        name: "Cathrina Belisha (Attachée)"
    },
    {
        key: "MontserratSubrayada-Bold",
        name: "MontserratSubrayada Bold (Attachée)"
    },
    {
        key: "arial-black",
        name: "Arial Black (Découpée)"
    },
    {
        key: "Forte",
        name: "Forte (Découpée)"
    },

    {
        key: "NewRocker-Regular",
        name: "NewRocker Regular (Découpée)"
    },
    {
        key: "arial",
        name: "Arial (Gravée)"
    },
    {
        key: "comic",
        name: "Comic (Gravée)"
    },
    {
        key: "times",
        name: "Times (Gravée)"
    }
];


function Catalog() {
    const classes = useStyles();
    const [{ step, selectedCatalog }, dispatch] = useContext(CatalogContext)
    const [configurator, dispatchConfigurator] = useContext(ConfiguratorContext)
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const [isMenuExpended, setisMenuExpended] = useState(true);
    const [textModels, settextModels] = useState([]);
    const refStage = useRef(null);

    useEffect(() => {
        if (!configurator.isFromCatalog) {

            dispatch({
                type: 'RESET_SOFT'
            })
            dispatchConfigurator({
                type: 'RESET'
            })
        } else if (!configurator.editId) {
            dispatchConfigurator({
                type: 'RESET_SOFT'
            })
        }

    }, []);


    useEffect(() => {
        if (isDownSm) {
            setisMenuExpended(!!!selectedCatalog)
        }

    }, [isDownSm, selectedCatalog])


    return (
        <main className={classes.root}>

            <Grid container className={classes.mainGrid}>
                <Grid xs={12} md={3} className={classes.controlPannelContainer}>
                    <CatalogControlPannel textModels={textModels} settextModels={settextModels} refStage={refStage} />
                </Grid>
                <Grid xs={12} md={9} style={{ display: 'flex' }} className={classes.drawer}>
                    {step === 1 && <CatalogDrawingPannel />}
                    {step === 2 && <ConfiguratorDrawingPannel textModels={textModels} settextModels={settextModels} fontsList={fontsList} refStage={refStage} />}
                </Grid>
            </Grid>

        </main >
    )
}

export default Catalog
