import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Grid, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useContext, useRef, useState, useEffect } from 'react'
import { Stage, Layer, Rect, Text, Circle, Line, Shape, RegularPolygon, Group } from 'react-konva';
import opentype from "opentype.js";
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
// eslint-disable-next-line import/no-webpack-loader-syntax
import uniqid from 'uniqid'
import ConfiguratorConfigCircle from './ConfiguratorConfigCircle';
import ConfiguratorConfigRectangle from './ConfiguratorConfigRectangle';
import makerjs from 'makerjs'
import ConfiguratorConfigOval from './ConfiguratorConfigOval';
import ConfiguratorMainForm from './ConfiguratorMainForm';
import { getPointsOfTriangle } from '../../../modules/geo'
import ConfiguratorConfigTriangle from './ConfiguratorConfigTriangle';
import ConfiguratorConfigFoldingLine from './ConfiguratorConfigFoldingLine';
import { useMakeCalcule } from '../../../modules/calcule';
import ConfiguratorConfigPolygone from './ConfiguratorConfigPolygone';
import ConfiguratorConfigText from './ConfiguratorConfigText';
import ConfiguratorDrawingPannelSideBar from './ConfiguratorDrawingPannelSideBar';
import ConfiguratorConfigCustom from './ConfiguratorConfigCustom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ConfiguratorIconsFormButton from './ConfiguratorIconsFormButton';


const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        background: '#f1f1f1',
        [theme.breakpoints.down("sm")]: {
            height: "auto",
        },
        [theme.breakpoints.up("sm")]: {
            height: `calc(100vh${process.env.REACT_APP_IFRAME_MODE ? '' : ' - 69px'})`,
        }
    },
    paper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        //position: 'relative',
        justifyContent: 'center',
        background: 'white',
        position: 'relative'
    },
    drawingWindo: {
        [theme.breakpoints.down("lg")]: {
            padding: "1rem 0.5rem",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "1rem 2rem",
        }
    },
    drawer: {
        [theme.breakpoints.down("lg")]: {
            height: '400px'
        },
        [theme.breakpoints.up("lg")]: {
            height: '100%'
        }
    },
    drawerConfig: {
        paddingLeft: '6px',
        backgroundImage: "radial-gradient( circle 343px at 46.3% 47.5%, rgba(242,242,242,1) 0%, rgba(241,241,241,1) 72.9% )"
    },
    buttontext: {
        ...theme.typography.button,
        padding: theme.spacing(1),
    },
    actionsNav: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        zIndex: 1000
    }
}))


function ConfiguratorDrawingPannel({ fontsList, textModels, settextModels, refStage }) {

    //const refStage = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('md'));
    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const paperRef = useRef(null);
    const [pvx, setpvx] = useState(0);
    const [pvy, setpvy] = useState(0);
    const [actualCursorPixelPos, setactualCursorPixelPos] = useState({ x: 0, y: 0 });
    const [actualCursorRealPos, setactualCursorRealPos] = useState({ x: 0, y: 0 });
    const [curentDraggingObjectPosition, setcurentDraggingObjectPosition] = useState({ x: 0, y: 0 });
    const [originXOnClick, setoriginXOnClick] = useState(0);
    const [originYOnClick, setoriginYOnClick] = useState(0);
    const [originMainFormX, setoriginMainFormX] = useState(0);
    const [originMainFormY, setoriginMainFormY] = useState(0);
    const [isCtrlDown, setisCtrlDown] = useState(false);
    const [isMouseDown, setisMouseDown] = useState(false);
    const [idTimeout, setidTimeout] = useState(null);
    const [mainFormX, setmainFormX] = useState(0);
    const [mainFormY, setmainFormY] = useState(0);
    const [isAccordionExpended, setisAccordionExpended] = useState(false);
    const [loadedFonts, setloadedFonts] = useState({});
    const [firstCalcul, setfirstCalcul] = useState(false);
    const [isLabelDisplayed, setisLabelDisplayed] = useState(true);
    const makeCalcule = useMakeCalcule()

    const handleZoom = useCallback((value) => {

        if ((pvx >= 50 || (pvx < 50 && Math.sign(value) === 1)) && configurator.ratio) {

            let zoomratio = 0
            if (value < 0) {
                zoomratio = pvy < pvx ? pvx / 10000 : pvy / 10000;
            } else {
                zoomratio = pvy < pvx ? (pvx / 10000) * -1 : (pvy / 10000) * -1;
            }
            dispatch({
                type: 'SET_VALUE',
                payload: {
                    key: 'ratio',
                    data: configurator.ratio + zoomratio
                }
            })
        }

    }, [configurator.ratio, dispatch, pvx])

    useEffect(() => {

        (async () => {
            for (const { key } of fontsList) {

                const font = await new Promise(
                    (resolve, reject) => opentype.load(
                        `/fonts/${key}.ttf`, (err, font) => err ? reject(err) : resolve(font)
                    )
                )
                setloadedFonts(l => ({ ...l, [key]: font }))
            }

        })()
    }, [])


    useEffect(() => {
        if (configurator.editId) {

            let ratio = (refStage.current?.clientWidth * 0.80) / configurator.mainForm.width
            dispatch({
                type: 'SET_VALUE',
                payload: {
                    key: 'ratio',
                    data: ratio
                }
            })
        }
    }, [configurator.editId])


    useEffect(() => {
        const fn = (e) => {
            if (e.key === 'Escape' && configurator.selectedForm) {
                dispatch({
                    type: 'DELETE_FORM',
                    payload: {
                        id: configurator.selectedForm
                    }
                })
            }
            if (e.key === 'Control') {
                setisCtrlDown(true)
            }
        }

        const handleKeyUp = (e) => {
            if (e.key === 'Control') {
                setisCtrlDown(false)
            }
        }

        const handleWeel = (e) => {
            if (isCtrlDown) {
                e.preventDefault();

                handleZoom(e.deltaY)

            }
        }

        const handleMouseDown = (e) => {
            if (isCtrlDown && e.which === 1) {

                const originX = e.offsetX;
                const originY = e.offsetY;
                setoriginXOnClick(originX)
                setoriginYOnClick(originY)
                setoriginMainFormX(mainFormX)
                setoriginMainFormY(mainFormY)
                setisMouseDown(true)
            }
        }

        const handleMouseUp = (e) => {
            if (e.which === 1) {

                setoriginMainFormX(0)
                setoriginMainFormY(0)
                setoriginXOnClick(0)
                setoriginYOnClick(0)
                setisMouseDown(false)
            }
        }

        document.addEventListener('keydown', fn)
        document.addEventListener('keyup', handleKeyUp)
        document.addEventListener('wheel', handleWeel, { passive: false })
        document.addEventListener('mousedown', handleMouseDown, { passive: false })
        document.addEventListener('mouseup', handleMouseUp, { passive: false })
        return () => {
            document.removeEventListener('keydown', fn)
            document.removeEventListener('keyup', handleKeyUp)
            document.removeEventListener('wheel', handleWeel, { passive: false })
            document.removeEventListener('mousedown', handleMouseDown, { passive: false })
            document.removeEventListener('mouseup', handleMouseUp, { passive: false })
        }
    }, [mainFormX, mainFormY, configurator.selectedForm, dispatch, handleZoom, isCtrlDown])


    const calculateLength = useCallback(
        () => {


            if (idTimeout) {
                clearTimeout(idTimeout)
            }
            setidTimeout(setTimeout(() => makeCalcule(textModels), 1000))
            return () => {
                clearTimeout(idTimeout);
            }

        },
        [idTimeout, makeCalcule, textModels],
    )


    const getRealRelativePosFromMainForm = useCallback(
        (x, y) => {

            return { x: Math.round((x - mainFormX) / configurator.ratio), y: Math.round((y - mainFormY) / configurator.ratio) }
        },
        [mainFormX, mainFormY, configurator.ratio],
    )

    const getInitialSizeOfCircle = useCallback(
        () => {
            return Math.round(configurator.mainForm.height * 0.10)
        },
        [configurator.mainForm.height]
    )

    const getInitialSizeOfRectangle = useCallback(
        () => {
            return { w: Math.round(configurator.mainForm.height * 0.10), h: Math.round(configurator.mainForm.height * 0.10) }
        },
        [configurator.mainForm.height],
    )

    const getInitialSizeOfTriangle = useCallback(
        () => {
            return { d: 90, l1: Math.round(configurator.mainForm.height * 0.10), l2: Math.round(configurator.mainForm.height * 0.10) }
        },
        [configurator.mainForm.height],
    )

    const getInitialSizeOfOval = useCallback(
        () => {
            return { w1: Math.round((configurator.mainForm.height * 0.10) * 2), r: Math.round(configurator.mainForm.height * 0.10) }
        },
        [configurator.mainForm.height],
    )

    const getInitialSizeOfPolygone = useCallback(
        () => {
            return { nbSides: 6, pr: configurator.mainForm.height * 0.10 }
        },
        [configurator.mainForm.height],
    )


    const resetCursorStyle = useCallback(
        () => {
            paperRef.current.style.cursor = 'auto'
        },
        [],
    )

    const getForm = useCallback(
        ({ x, y }) => {
            switch (configurator.formToAdd) {
                case 'CIRCLE':
                    return {
                        type: 'CIRCLE',
                        id: uniqid(),
                        radius: getInitialSizeOfCircle(),
                        realRadius: Math.ceil(getInitialSizeOfCircle()),
                        relatx: x || actualCursorPixelPos.x,
                        realx: x || actualCursorRealPos.x,
                        relaty: y || actualCursorPixelPos.y,
                        realy: y || actualCursorRealPos.y,
                        rotation: 0
                    }

                case 'RECTANGLE':
                    const { w, h } = getInitialSizeOfRectangle()
                    return {
                        type: 'RECTANGLE',
                        id: uniqid(),
                        width: w,
                        height: h,
                        relatx: x || actualCursorPixelPos.x,
                        realx: x || actualCursorRealPos.x,
                        relaty: y || actualCursorPixelPos.y,
                        realy: y || actualCursorRealPos.y,
                        rotation: 0
                    }
                case 'OVAL':
                    const { w1, r } = getInitialSizeOfOval()
                    return {
                        type: 'OVAL',
                        id: uniqid(),
                        width: w1,
                        radius: r,
                        relatx: x || actualCursorPixelPos.x,
                        realx: x || actualCursorRealPos.x,
                        relaty: y || actualCursorPixelPos.y,
                        realy: y || actualCursorRealPos.y,
                        rotation: 0

                    }
                case 'TRIANGLE':
                    const { d, l1, l2 } = getInitialSizeOfTriangle()
                    return {
                        type: 'TRIANGLE',
                        id: uniqid(),
                        l1,
                        l2,
                        d,
                        relatx: x || actualCursorPixelPos.x,
                        realx: x || actualCursorRealPos.x,
                        relaty: y || actualCursorPixelPos.y,
                        realy: y || actualCursorRealPos.y,
                        rotation: 0
                    }
                case 'POLYGONE':
                    const { nbSides, pr } = getInitialSizeOfPolygone()
                    return {
                        type: 'POLYGONE',
                        id: uniqid(),
                        radius: pr,
                        nbSides,
                        relatx: x || actualCursorPixelPos.x,
                        realx: x || actualCursorRealPos.x,
                        relaty: y || actualCursorPixelPos.y,
                        realy: y || actualCursorRealPos.y,
                        rotation: 0
                    };
                case 'FOLDINGLINE':

                    return {
                        type: 'FOLDINGLINE',
                        id: uniqid(),
                        a: { x: 0, y: 0 },
                        b: { x: 0, y: 0 },
                        relatx: x || actualCursorPixelPos.x,
                        realx: x || actualCursorRealPos.x,
                        relaty: y || actualCursorPixelPos.y,
                        realy: y || actualCursorRealPos.y,
                        rotation: 0,
                        foldingLineType: configurator.foldingLineType
                    }
                case 'TEXT':
                    return {
                        type: 'TEXT',
                        id: uniqid(),
                        relatx: x || actualCursorPixelPos.x,
                        realx: x || actualCursorRealPos.x,
                        relaty: y || actualCursorPixelPos.y,
                        realy: y || actualCursorRealPos.y,
                        text: "Texte",
                        rotation: 0,
                        fontSize: 15,
                        fontFamily: fontsList[0].key
                    }
                case 'CUSTOM':
                    return {
                        type: 'CUSTOM',
                        id: uniqid(),
                        relatx: actualCursorPixelPos.x,
                        realx: actualCursorRealPos.x,
                        relaty: actualCursorPixelPos.y,
                        realy: actualCursorRealPos.y,
                        paths: configurator.formToAddDataTemp.paths,
                        height: configurator.formToAddDataTemp.height,
                        width: configurator.formToAddDataTemp.width,
                        originalHeight: configurator.formToAddDataTemp.originalHeight,
                        originalWidth: configurator.formToAddDataTemp.originalWidth,
                        ratio: configurator.formToAddDataTemp.ratio,
                        model: configurator.formToAddDataTemp.model,
                        rotation: 0,
                    }
                default:
                    break;
            }
        },
        [actualCursorPixelPos.x, actualCursorPixelPos.y, actualCursorRealPos.x, actualCursorRealPos.y, configurator.foldingLineType, configurator.formToAdd, configurator.formToAddDataTemp.height, configurator.formToAddDataTemp.model, configurator.formToAddDataTemp.originalHeight, configurator.formToAddDataTemp.originalWidth, configurator.formToAddDataTemp.paths, configurator.formToAddDataTemp.ratio, configurator.formToAddDataTemp.width, fontsList, getInitialSizeOfCircle, getInitialSizeOfOval, getInitialSizeOfPolygone, getInitialSizeOfRectangle, getInitialSizeOfTriangle],
    )

    const hasCustomForm = () => false;

    useEffect(() => {
        const hasCustom = hasCustomForm();
        let isManualCalcule = !!hasCustom && firstCalcul;
        dispatch({
            type: 'SET_VALUE',
            payload: {
                key: 'isManualCalcule',
                data: isManualCalcule
            }
        })
        if (configurator.mainForm.configured && configurator.materialId && configurator.shadeId && configurator.thicknessId && configurator.step === 0 && !isManualCalcule) {
            calculateLength()
            if (!firstCalcul) {
                setfirstCalcul(true);
            }
        }


    }, [configurator.materialId, configurator.thicknessId, configurator.step, configurator.shadeId, configurator.mainForm.configured, configurator.form, configurator.forms, configurator.nbPiece, configurator.mainForm, textModels]);


    const handelClickAddForm = useCallback(
        (data) => {
            if (configurator.formToAdd) {
                //updateCursonPosition(data)
                if (configurator.formToAdd === 'CUSTOM') {
                    dispatch({
                        type: 'SET_VALUE',
                        payload: {
                            key: 'isManualCalcule',
                            data: true
                        }
                    })
                }
                if (configurator.formToAdd !== 'FOLDINGLINE') {
                    const form = getForm(data)
                    dispatch({
                        type: 'ADD_FORM',
                        payload: {
                            data: form
                        }
                    })

                    dispatch({
                        type: 'SET_VALUE',
                        payload: {
                            key: 'formToAdd',
                            data: null
                        }
                    })

                    dispatch({
                        type: 'SET_VALUE',
                        payload: {
                            key: 'selectedForm',
                            data: form.id
                        }
                    })
                    resetCursorStyle()
                } else {
                    if (configurator.step === 0) {
                        const form = getForm();

                        dispatch({
                            type: 'ADD_FORM',
                            payload: {
                                data: form
                            }
                        })
                        dispatch({
                            type: 'SET_VALUE',
                            payload: {
                                key: 'selectedForm',
                                data: form.id
                            }
                        })
                        dispatch({
                            type: 'SET_VALUE',
                            payload: {
                                key: 'step',
                                data: 1
                            }
                        })
                    } else {

                        dispatch({
                            type: 'SET_VALUE',
                            payload: {
                                key: 'formToAdd',
                                data: null
                            }
                        })
                        dispatch({
                            type: 'SET_VALUE',
                            payload: {
                                key: 'step',
                                data: 0
                            }
                        })
                        resetCursorStyle()
                    }
                }


            }


        },
        [configurator.formToAdd, configurator.forms, configurator.step, dispatch, getForm, resetCursorStyle],
    )

    useEffect(() => {
        if (paperRef) {
            const maxWidth = paperRef.current.clientWidth * 0.80
            const maxHeight = paperRef.current.clientHeight * 0.80

            const minWidth = paperRef.current.clientWidth / 2
            if (!configurator.ratio && configurator.mainForm.width && configurator.mainForm.height) {
                let ratio = 1;
                if (configurator.mainForm.width > configurator.mainForm.height) {
                    if (configurator.mainForm.width >= maxWidth) {
                        setpvx(maxWidth)
                        ratio = maxWidth / configurator.mainForm.width
                        dispatch({
                            type: 'SET_VALUE',
                            payload: {
                                key: 'ratio',
                                data: ratio
                            }
                        })
                        //reduce height by ratio
                        setpvy(configurator.mainForm.height * ratio)
                    } else {
                        setpvx(minWidth)
                        ratio = minWidth / configurator.mainForm.width
                        dispatch({
                            type: 'SET_VALUE',
                            payload: {
                                key: 'ratio',
                                data: ratio
                            }
                        })
                        //increasse height by ratio
                        setpvy(configurator.mainForm.height * ratio)
                    }
                } else {
                    if (configurator.mainForm.height >= maxHeight) {
                        setpvy(maxHeight)
                        ratio = maxHeight / configurator.mainForm.height
                        dispatch({
                            type: 'SET_VALUE',
                            payload: {
                                key: 'ratio',
                                data: ratio
                            }
                        })
                        //reduce height by ratio
                        setpvx(configurator.mainForm.width * ratio)
                    } else {
                        setpvy(maxHeight)
                        ratio = maxHeight / configurator.mainForm.height
                        dispatch({
                            type: 'SET_VALUE',
                            payload: {
                                key: 'ratio',
                                data: ratio
                            }
                        })
                        //reduce height by ratio
                        setpvx(configurator.mainForm.width * ratio)
                    }
                }
                setmainFormX(Math.ceil((paperRef.current.clientWidth / 2) - (configurator.mainForm.width * ratio / 2)))
                setmainFormY(Math.ceil((paperRef.current.clientHeight / 2) - configurator.mainForm.height * ratio / 2))

            } else {
                const oldPvx = pvx;
                const oldPvy = pvy;

                const newPvx = configurator.mainForm.width * configurator.ratio;
                const newPvy = configurator.mainForm.height * configurator.ratio;
                setpvx(newPvx)
                setpvy(newPvy)
                setmainFormX(x => {
                    //const newMainFormX = x - ((newPvx - oldPvx) * (actualCursorPixelPos.x / newPvx));
                    const newMainFormX = x - ((newPvx - oldPvx) * (actualCursorRealPos.x / configurator.mainForm.width));
                    return newMainFormX;
                })
                setmainFormY(y => {
                    //const newMainFormY = y - ((newPvy - oldPvy) * (actualCursorPixelPos.y / newPvy));
                    const newMainFormY = y - ((newPvy - oldPvy) * (actualCursorRealPos.y / configurator.mainForm.height));
                    return newMainFormY;
                })
            }
        }

    }, [configurator.mainForm.height, configurator.mainForm.width, configurator.ratio, pvx, pvy]);


    const updateCursonPosition = useCallback(
        (e) => {

            let offsetX = 0;
            let offsetY = 0;

            if (e.type === "touchstart") {
                let r = e.evt.touches[0].target.getBoundingClientRect();
                offsetX = e.evt.touches[0].clientX - r.left;
                offsetY = e.evt.touches[0].clientY - r.top;
            } else {
                offsetX = e.evt.offsetX;
                offsetY = e.evt.offsetY;
            }
            setactualCursorPixelPos({ x: offsetX - mainFormX, y: offsetY - mainFormY })
            const realx = (offsetX - mainFormX) / configurator.ratio
            const realy = (offsetY - mainFormY) / configurator.ratio
            setactualCursorRealPos({ x: Math.round(realx), y: Math.round(realy) })
            if (!isCtrlDown) {
                if (configurator.selectedForm && configurator.formToAdd === 'FOLDINGLINE' && configurator.step === 1) {
                    dispatch({
                        type: 'SET_PROPERTY_SELECT_FORM',
                        payload: {
                            property: 'b',
                            data: { x: offsetX, y: offsetY }
                        }
                    })
                    //settowPointForm({a:{x:0, y:0}, b:{x: e.evt.offsetX, y:e.evt.offsetY}})

                }
            }

        }, [isCtrlDown, mainFormX, mainFormY, configurator.ratio, configurator.selectedForm, configurator.formToAdd, configurator.step, dispatch])

    const handelDragingForm = useCallback(
        (e) => {
            clearTimeout(idTimeout)
            let { x, y } = e.target.getAbsolutePosition()
            setcurentDraggingObjectPosition(getRealRelativePosFromMainForm(x, y))
        },
        [getRealRelativePosFromMainForm, idTimeout],
    )


    /*     const handleTouchStart = useCallback((e) => {
            if (e.type === "touchstart") {
                const r = e.evt.touches[0].target.getBoundingClientRect();
                const offsetX = e.evt.touches[0].clientX - r.left;
                const offsetY = e.evt.touches[0].clientY - r.top;
                handelClickAddForm({ x: offsetX, y: offsetY })
            }
        }, [handelClickAddForm]) */

    const getConfiguratorSelectedForm = useCallback(
        () => {
            const selectedForm = configurator.forms.find(form => form.id === configurator.selectedForm);
            if (selectedForm && selectedForm.type) {

                switch (selectedForm.type) {
                    case 'CIRCLE':
                        return <ConfiguratorConfigCircle selectedForm={selectedForm} calculateLength={calculateLength} setcurentDraggingObjectPosition={setcurentDraggingObjectPosition} />
                    case 'RECTANGLE':
                        return <ConfiguratorConfigRectangle selectedForm={selectedForm} calculateLength={calculateLength} setcurentDraggingObjectPosition={setcurentDraggingObjectPosition} />
                    case 'OVAL':
                        return <ConfiguratorConfigOval selectedForm={selectedForm} calculateLength={calculateLength} setcurentDraggingObjectPosition={setcurentDraggingObjectPosition} />
                    case 'TRIANGLE':
                        return <ConfiguratorConfigTriangle selectedForm={selectedForm} calculateLength={calculateLength} setcurentDraggingObjectPosition={setcurentDraggingObjectPosition} />
                    case 'FOLDINGLINE':
                        return <ConfiguratorConfigFoldingLine selectedForm={selectedForm} calculateLength={calculateLength} setcurentDraggingObjectPosition={setcurentDraggingObjectPosition} />
                    case 'POLYGONE':
                        return <ConfiguratorConfigPolygone selectedForm={selectedForm} calculateLength={calculateLength} setcurentDraggingObjectPosition={setcurentDraggingObjectPosition} />
                    case 'TEXT':
                        return <ConfiguratorConfigText fontsList={fontsList} selectedForm={selectedForm} calculateLength={calculateLength} setcurentDraggingObjectPosition={setcurentDraggingObjectPosition} />
                    case 'CUSTOM':
                        return <ConfiguratorConfigCustom fontsList={fontsList} selectedForm={selectedForm} calculateLength={calculateLength} setcurentDraggingObjectPosition={setcurentDraggingObjectPosition} />
                    default:
                        break;
                }
            }
        },
        [calculateLength, configurator.forms, configurator.selectedForm, fontsList],
    )

    const getRectQuadraticCurve = useCallback((angle, radius, ctx, borderConfigurations, width = pvx, height = pvy,) => {

        const x = 0;
        const y = 0;
        if (borderConfigurations && borderConfigurations[angle] && borderConfigurations[angle].isRadiusEnabled) {
            if (angle === 'tl') {
                if (borderConfigurations[angle].isOutside) ctx.quadraticCurveTo(x, y, x + radius.tl, y);
                if (borderConfigurations[angle].isInner) ctx.quadraticCurveTo(x + radius.tl, y + radius.tl, x + radius.tl, y);
            }
            if (angle === 'tr') {
                if (borderConfigurations[angle].isOutside) ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
                if (borderConfigurations[angle].isInner) ctx.quadraticCurveTo(x + width - radius.tr, y + radius.tr, x + width, y + radius.tr);
            }
            if (angle === 'bl') {
                if (borderConfigurations[angle].isOutside) ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
                if (borderConfigurations[angle].isInner) ctx.quadraticCurveTo(x + radius.bl, y - radius.bl + height, x, y + height - radius.bl);
            }
            if (angle === 'br') {
                if (borderConfigurations[angle].isOutside) ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
                if (borderConfigurations[angle].isInner) ctx.quadraticCurveTo(x + width - radius.br, y - radius.br + height, x + width - radius.br, y + height);
            }
        }
        if (borderConfigurations && borderConfigurations[angle] && borderConfigurations[angle].isChanfreinEnabled) {
            if (angle === 'tl') {
                ctx.quadraticCurveTo(x + radius.tl.b, y, x + radius.tl.b, y);
            }
            if (angle === 'tr') {
                ctx.quadraticCurveTo(x + width - radius.tr.a, y, x + width, y + radius.tr.b)
            }
            if (angle === 'bl') {
                ctx.quadraticCurveTo(x + radius.bl.b, y + height, x, y + height - radius.bl.a);
            }
            if (angle === 'br') {
                ctx.quadraticCurveTo(x + width - radius.br.a, y + height, x + width - radius.br.a, y + height);
            }
        }


    }, [pvx, pvy])

    const getLQuadraticCurve = useCallback((a, b, c, d, angle, radius, ctx) => {

        const x = 0;
        const y = 0;

        if (configurator.mainForm.borderConfigurations[angle].isRadiusEnabled) {
            if (angle === 'tl') {
                if (configurator.mainForm.borderConfigurations[angle].isOutside) ctx.quadraticCurveTo(x, y, x, radius.tl);
                if (configurator.mainForm.borderConfigurations[angle].isInner) ctx.quadraticCurveTo(x + radius.tl, y + radius.tl, x, radius.tl);
            }
            if (angle === 'tr') {
                if (configurator.mainForm.borderConfigurations[angle].isOutside) ctx.quadraticCurveTo(a - c, y, a - c - radius.tr, y);
                if (configurator.mainForm.borderConfigurations[angle].isInner) ctx.quadraticCurveTo(a - c - radius.tr, y + radius.tr, a - c - radius.tr, y);
            }
            if (angle === 'ml') {
                if (configurator.mainForm.borderConfigurations[angle].isOutside) ctx.quadraticCurveTo(a - c + radius.ml, d - radius.ml, a - c, d - radius.ml);
                if (configurator.mainForm.borderConfigurations[angle].isInner) ctx.quadraticCurveTo(a - c, d, a - c, d - radius.ml);
            }
            if (angle === 'mr') {
                if (configurator.mainForm.borderConfigurations[angle].isOutside) ctx.quadraticCurveTo(a, d, a - radius.mr, d);
                if (configurator.mainForm.borderConfigurations[angle].isInner) ctx.quadraticCurveTo(a - radius.mr, d + radius.mr, a - radius.mr, d);
            }
            if (angle === 'bl') {
                if (configurator.mainForm.borderConfigurations[angle].isOutside) ctx.quadraticCurveTo(x, b, x + radius.bl, b);
                if (configurator.mainForm.borderConfigurations[angle].isInner) ctx.quadraticCurveTo(x + radius.bl, b - radius.bl, x + radius.bl, b);
            }
            if (angle === 'br') {
                if (configurator.mainForm.borderConfigurations[angle].isOutside) ctx.quadraticCurveTo(a, b, a, b - radius.br);
                if (configurator.mainForm.borderConfigurations[angle].isInner) ctx.quadraticCurveTo(a - radius.br, b - radius.br, a, b - radius.br);
            }
        }
        if (configurator.mainForm.borderConfigurations[angle].isChanfreinEnabled) {
            if (angle === 'tl') {
                ctx.quadraticCurveTo(x + radius.tl.b, y, x, radius.tl.a);
            }
            if (angle === 'tr') {
                ctx.quadraticCurveTo(a - c - radius.tr.a, y, a - c - radius.tr.a, y);
            }
            if (angle === 'mr') {
                ctx.quadraticCurveTo(a - radius.mr.a, d, a - radius.mr.a, d);
            }
            if (angle === 'ml') {
                ctx.quadraticCurveTo(a - c, d - radius.ml.a, a - c, d - radius.ml.a);
            }
            if (angle === 'bl') {
                ctx.quadraticCurveTo(x + radius.bl.b, b, x + radius.bl.b, b);
            }
            if (angle === 'br') {
                ctx.quadraticCurveTo(a - radius.br.a, b, a, b - radius.br.b);
            }
        }


    }, [configurator.mainForm.borderConfigurations])

    const getMainForm = useCallback(
        () => {
            switch (configurator.form) {
                case 0:
                    const x = 0;
                    const y = 0;
                    const radius = {
                        tl: configurator.mainForm.borderConfigurations.tl.isRadiusEnabled ? configurator.mainForm.borderConfigurations.tl.radius * configurator.ratio : configurator.mainForm.borderConfigurations.tl.isChanfreinEnabled ? { a: configurator.mainForm.borderConfigurations.tl.radius.a * configurator.ratio, b: configurator.mainForm.borderConfigurations.tl.radius.b * configurator.ratio } : 0,
                        tr: configurator.mainForm.borderConfigurations.tr.isRadiusEnabled ? configurator.mainForm.borderConfigurations.tr.radius * configurator.ratio : configurator.mainForm.borderConfigurations.tr.isChanfreinEnabled ? { a: configurator.mainForm.borderConfigurations.tr.radius.a * configurator.ratio, b: configurator.mainForm.borderConfigurations.tr.radius.b * configurator.ratio } : 0,
                        bl: configurator.mainForm.borderConfigurations.bl.isRadiusEnabled ? configurator.mainForm.borderConfigurations.bl.radius * configurator.ratio : configurator.mainForm.borderConfigurations.bl.isChanfreinEnabled ? { a: configurator.mainForm.borderConfigurations.bl.radius.a * configurator.ratio, b: configurator.mainForm.borderConfigurations.bl.radius.b * configurator.ratio } : 0,
                        br: configurator.mainForm.borderConfigurations.br.isRadiusEnabled ? configurator.mainForm.borderConfigurations.br.radius * configurator.ratio : configurator.mainForm.borderConfigurations.br.isChanfreinEnabled ? { a: configurator.mainForm.borderConfigurations.br.radius.a * configurator.ratio, b: configurator.mainForm.borderConfigurations.br.radius.b * configurator.ratio } : 0,
                    };
                    const width = pvx;
                    const height = pvy;

                    return <Shape
                        x={mainFormX}
                        y={mainFormY}
                        preventDefault={false}
                        sceneFunc={(ctx, shape) => {
                            ctx.beginPath();
                            ctx.moveTo(x + (radius.tl.b || radius.tl), y);
                            ctx.lineTo(x + width - (radius.tr.a || radius.tr), y);
                            /* ctx.quadraticCurveTo(getRectQuadraticCurve('x', 'tr'), getRectQuadraticCurve('y', 'tr'), x + width, y + radius.tr); */
                            getRectQuadraticCurve('tr', radius, ctx, configurator.mainForm.borderConfigurations);
                            ctx.lineTo(x + width, y + height - (radius.br.b || radius.br));
                            getRectQuadraticCurve('br', radius, ctx, configurator.mainForm.borderConfigurations);
                            ctx.lineTo(x + (radius.bl.b || radius.bl), y + height);
                            getRectQuadraticCurve('bl', radius, ctx, configurator.mainForm.borderConfigurations);
                            ctx.lineTo(x, y + (radius.tl.a || radius.tl));
                            getRectQuadraticCurve('tl', radius, ctx, configurator.mainForm.borderConfigurations);
                            ctx.closePath();

                            ctx.fillStrokeShape(shape);
                        }}
                        fill={configurator.shadeColor || "#7b7b7b"}
                    />
                case 1:
                    return <Circle
                        x={mainFormX + pvx / 2}
                        y={mainFormY + pvx / 2}
                        //offset={{ x: configurator.mainForm.radius * configurator.ratio, y: configurator.mainForm.radius * configurator.ratio }}
                        radius={configurator.mainForm.radius * configurator.ratio}
                        fill={configurator.shadeColor || "#7b7b7b"}
                    />
                case 2:
                    const coordinates = getPointsOfTriangle({ x: 0, y: 0 }, configurator.mainForm.l1 * configurator.ratio, configurator.mainForm.l2 * configurator.ratio, configurator.mainForm.radius)

                    return <Shape
                        x={mainFormX}
                        y={mainFormY}
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(coordinates.a.x, coordinates.a.y);
                            context.lineTo(coordinates.b.x, coordinates.b.y);
                            context.lineTo(coordinates.c.x, coordinates.c.y);
                            context.lineTo(coordinates.a.x, coordinates.a.y);

                            context.closePath();
                            context.fillStrokeShape(shape);
                        }}
                        fill={configurator.shadeColor || "#7b7b7b"}
                    />
                case 3:
                    const r = {
                        tl: configurator.mainForm.borderConfigurations.tl.isRadiusEnabled ? configurator.mainForm.borderConfigurations.tl.radius * configurator.ratio : configurator.mainForm.borderConfigurations.tl.isChanfreinEnabled ? { a: configurator.mainForm.borderConfigurations.tl.radius.a * configurator.ratio, b: configurator.mainForm.borderConfigurations.tl.radius.b * configurator.ratio } : 0,
                        tr: configurator.mainForm.borderConfigurations.tr.isRadiusEnabled ? configurator.mainForm.borderConfigurations.tr.radius * configurator.ratio : configurator.mainForm.borderConfigurations.tr.isChanfreinEnabled ? { a: configurator.mainForm.borderConfigurations.tr.radius.a * configurator.ratio, b: configurator.mainForm.borderConfigurations.tr.radius.b * configurator.ratio } : 0,
                        ml: configurator.mainForm.borderConfigurations.ml.isRadiusEnabled ? configurator.mainForm.borderConfigurations.ml.radius * configurator.ratio : configurator.mainForm.borderConfigurations.ml.isChanfreinEnabled ? { a: configurator.mainForm.borderConfigurations.ml.radius.a * configurator.ratio, b: configurator.mainForm.borderConfigurations.ml.radius.b * configurator.ratio } : 0,
                        mr: configurator.mainForm.borderConfigurations.mr.isRadiusEnabled ? configurator.mainForm.borderConfigurations.mr.radius * configurator.ratio : configurator.mainForm.borderConfigurations.mr.isChanfreinEnabled ? { a: configurator.mainForm.borderConfigurations.mr.radius.a * configurator.ratio, b: configurator.mainForm.borderConfigurations.mr.radius.b * configurator.ratio } : 0,
                        bl: configurator.mainForm.borderConfigurations.bl.isRadiusEnabled ? configurator.mainForm.borderConfigurations.bl.radius * configurator.ratio : configurator.mainForm.borderConfigurations.bl.isChanfreinEnabled ? { a: configurator.mainForm.borderConfigurations.bl.radius.a * configurator.ratio, b: configurator.mainForm.borderConfigurations.bl.radius.b * configurator.ratio } : 0,

                        br: configurator.mainForm.borderConfigurations.br.isRadiusEnabled ? configurator.mainForm.borderConfigurations.br.radius * configurator.ratio : configurator.mainForm.borderConfigurations.br.isChanfreinEnabled ? { a: configurator.mainForm.borderConfigurations.br.radius.a * configurator.ratio, b: configurator.mainForm.borderConfigurations.br.radius.b * configurator.ratio } : 0,
                    };
                    const a = configurator.mainForm.a * configurator.ratio;
                    const b = configurator.mainForm.b * configurator.ratio;
                    const c = configurator.mainForm.c * configurator.ratio;
                    const d = configurator.mainForm.d * configurator.ratio;
                    return <Shape
                        sceneFunc={(context, shape) => {
                            context.beginPath();

                            context.moveTo(0, (b - (r.bl.a || r.bl)));
                            getLQuadraticCurve(a, b, c, d, 'bl', r, context);
                            context.lineTo((a - (r.br.a || r.br)), b);
                            getLQuadraticCurve(a, b, c, d, 'br', r, context);
                            context.lineTo(a, (d + (r.mr.b || r.mr)));
                            getLQuadraticCurve(a, b, c, d, 'mr', r, context);
                            context.lineTo(a - c + (r.ml.b || r.ml), d);
                            getLQuadraticCurve(a, b, c, d, 'ml', r, context);
                            context.lineTo(a - c, 0 + (r.tr.b || r.tr));
                            getLQuadraticCurve(a, b, c, d, 'tr', r, context);
                            context.lineTo(0 + (r.tl.b || r.tl), 0);
                            getLQuadraticCurve(a, b, c, d, 'tl', r, context);
                            context.lineTo(0, 0 + (r.tl.a || r.tl));
                            context.closePath();
                            context.fillStrokeShape(shape);
                        }}
                        fill={configurator.shadeColor || "#7b7b7b"}
                        x={mainFormX}
                        y={mainFormY}
                    />
                case 4:
                    return <Group
                        x={mainFormX}
                        y={mainFormY}
                    >{configurator.mainForm.paths.map(path => <Shape
                        fill={configurator.shadeColor || "#7b7b7b"}
                        stroke="transparent"
                        sceneFunc={(ctx, shape) => {
                            const p = new Path2D(path);

                            ctx.fillStrokeShape(shape);
                            ctx._context.stroke(p);
                            ctx._context.fill(p, 'evenodd');
                        }}
                        scale={{
                            x: configurator.ratio * (configurator.mainForm.width / configurator.mainForm.originalWidth),
                            y: configurator.ratio * (configurator.mainForm.height / configurator.mainForm.originalHeight),
                        }}
                    />)}

                    </Group>
                case 5:
                    let font = loadedFonts[configurator.mainForm.fontFamily];
                    if (font) {

                        let textModel = textModels.main
                        let measure = null

                        if (!textModel || textModel.text !== configurator.mainForm.text || textModel.fontName !== configurator.mainForm.fontFamily || textModel.fontSize !== configurator.mainForm.fontSize) {
                            textModel = new makerjs.models.Text(font, configurator.mainForm.text, configurator.mainForm.fontSize);
                            measure = makerjs.measure.modelExtents(textModel);

                            let sizeInPx = configurator.mainForm.fontSize;
                            if (measure.width) {
                                sizeInPx = Math.pow(configurator.mainForm.fontSize, 2) / measure.width
                            }
                            textModel = new makerjs.models.Text(font, configurator.mainForm.text, sizeInPx);
                            settextModels(s => ({ ...s, main: { model: textModel, text: configurator.mainForm.text, fontName: configurator.mainForm.fontFamily, fontSize: configurator.mainForm.fontSize, sizeInPx, measure } }))
                            const finalMeasure = makerjs.measure.modelExtents(textModel);
                            dispatch({
                                type: 'SET_VALUE',
                                payload: {
                                    key: 'mainForm',
                                    data: {
                                        ...configurator.mainForm, height: finalMeasure.height, width: finalMeasure.width
                                    }
                                }
                            })
                        } else {
                            measure = textModel.measure
                        }

                        const path = makerjs.exporter.toSVGPathData(textModel.model || textModel)

                        return <Shape
                            x={mainFormX}
                            y={mainFormY}
                            fill={configurator.shadeColor || "#7b7b7b"}
                            stroke="transparent"

                            sceneFunc={(ctx, shape) => {
                                const p = new Path2D(path);

                                ctx.fillStrokeShape(shape);
                                ctx._context.stroke(p);
                                ctx._context.fill(p, 'evenodd');
                            }}
                            scale={{
                                x: configurator.ratio,
                                y: configurator.ratio,
                            }}
                        />
                    }
                    return null;
                default:
                    return null
            }
        },
        [configurator.form, configurator.mainForm, configurator.ratio, configurator.shadeColor, pvx, pvy, mainFormX, mainFormY, loadedFonts, getRectQuadraticCurve, getLQuadraticCurve, textModels.main, dispatch],
    )

    const handelClickForm = useCallback(
        (e, form) => {
            if (!configurator.formToAdd) {
                e.cancelBubble = true
                if (configurator.step === 0 && !form.notSelectable) {
                    dispatch({
                        type: 'SET_VALUE',
                        payload: {
                            key: 'selectedForm',
                            data: form.id
                        }
                    });
                    handelDragingForm(e);
                } else {
                    dispatch({
                        type: 'SET_VALUE',
                        payload: {
                            key: 'formToAdd',
                            data: null
                        }
                    })
                    dispatch({
                        type: 'SET_VALUE',
                        payload: {
                            key: 'step',
                            data: 0
                        }
                    })
                    dispatch({
                        type: 'SET_VALUE',
                        payload: {
                            key: 'selectedForm',
                            data: null
                        }
                    })
                    resetCursorStyle()
                }

            } else {
                if (configurator.step === 1) {
                    handelClickAddForm()
                }

            }
        },
        [configurator.formToAdd, configurator.step, dispatch, handelClickAddForm, handelDragingForm, resetCursorStyle],
    )


    const handelDragStart = useCallback(
        () => {
            if (configurator.selectedForm) {

                dispatch({
                    type: 'SET_VALUE',
                    payload: {
                        key: 'isDraging',
                        data: true
                    }
                })
            }
        },
        [configurator.selectedForm, dispatch],
    )



    const handelDragEnd = useCallback(
        () => {
            if (configurator.selectedForm) {
                dispatch({
                    type: 'SET_VALUE',
                    payload: {
                        key: 'isDraging',
                        data: false
                    }
                })
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            x: curentDraggingObjectPosition.x,
                            y: curentDraggingObjectPosition.y
                        }

                    }
                })
            }

        },
        [configurator, curentDraggingObjectPosition.x, curentDraggingObjectPosition.y, dispatch],
    )

    const displayForms = useCallback(() => {

        return configurator.forms.map(form => {

            if (form.type === 'CIRCLE') {
                return <Circle
                    key={form.id}
                    x={(form.realx * configurator.ratio) + mainFormX}
                    y={(form.realy * configurator.ratio) + mainFormY}
                    radius={Math.ceil(form.realRadius * configurator.ratio)}
                    fill={form.unionType === 'add' ? configurator.shadeColor || "#7b7b7b" : form.graved ? 'transparent' : 'white'}
                    stroke={configurator.selectedForm === form.id ? '#35919D' : form.graved ? 'green' : 'transparent'}
                    onClick={(e) => handelClickForm(e, form)}
                    onMouseDown={(e) => handelClickForm(e, form)}
                    draggable={!!configurator.selectedForm}
                    onTouchStart={(e) => handelClickForm(e, form)}
                    onDragStart={handelDragStart}
                    onDragEnd={handelDragEnd}
                    onDragMove={handelDragingForm}
                    rotation={form.rotation}
                    onMouseOver={updateCursonPosition}
                />
            }
            if (form.type === 'RECTANGLE') {
                const radius = {
                    tl: form.borderConfigurations?.tl?.isRadiusEnabled ? form.borderConfigurations.tl.radius * configurator.ratio : form.borderConfigurations?.tl?.isChanfreinEnabled ? { a: form.borderConfigurations.tl.radius.a * configurator.ratio, b: form.borderConfigurations.tl.radius.b * configurator.ratio } : 0,
                    tr: form.borderConfigurations?.tr?.isRadiusEnabled ? form.borderConfigurations.tr.radius * configurator.ratio : form.borderConfigurations?.tr?.isChanfreinEnabled ? { a: form.borderConfigurations.tr.radius.a * configurator.ratio, b: form.borderConfigurations.tr.radius.b * configurator.ratio } : 0,
                    bl: form.borderConfigurations?.bl?.isRadiusEnabled ? form.borderConfigurations.bl.radius * configurator.ratio : form.borderConfigurations?.bl?.isChanfreinEnabled ? { a: form.borderConfigurations.bl.radius.a * configurator.ratio, b: form.borderConfigurations.bl.radius.b * configurator.ratio } : 0,
                    br: form.borderConfigurations?.br?.isRadiusEnabled ? form.borderConfigurations.br.radius * configurator.ratio : form.borderConfigurations?.br?.isChanfreinEnabled ? { a: form.borderConfigurations.br.radius.a * configurator.ratio, b: form.borderConfigurations.br.radius.b * configurator.ratio } : 0,
                };
                const offsetX = (Number(Math.ceil(form.width)) / 2) * configurator.ratio;
                const offsetY = (Number(Math.ceil(form.height)) / 2) * configurator.ratio;
                const width = Math.ceil(form.width * configurator.ratio)
                const height = Math.ceil(form.height * configurator.ratio)
                const x = (form.realx * configurator.ratio) + mainFormX
                const y = (form.realy * configurator.ratio) + mainFormY
                return <Shape
                    key={form.id}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={form.unionType === 'add' ? configurator.shadeColor || "#7b7b7b" : form.graved ? 'transparent' : 'white'}
                    stroke={configurator.selectedForm === form.id ? '#35919D' : form.graved ? 'green' : 'transparent'}
                    onClick={(e) => handelClickForm(e, form)}
                    onMouseDown={(e) => handelClickForm(e, form)}
                    onTouchStart={(e) => handelClickForm(e, form)}
                    draggable={!form.notSelectable}
                    onDragStart={handelDragStart}
                    onDragEnd={handelDragEnd}
                    onDragMove={handelDragingForm}
                    rotation={form.rotation}
                    offset={{ x: offsetX, y: offsetY }}
                    onMouseOver={updateCursonPosition}

                    sceneFunc={(ctx, shape) => {
                        const x = 0
                        const y = 0
                        ctx.beginPath();
                        ctx.moveTo(x + (radius.tl.b || radius.tl), y);
                        ctx.lineTo(x + width - (radius.tr.a || radius.tr), y);
                        /* ctx.quadraticCurveTo(getRectQuadraticCurve('x', 'tr'), getRectQuadraticCurve('y', 'tr'), x + width, y + radius.tr); */
                        getRectQuadraticCurve('tr', radius, ctx, form.borderConfigurations, width, height);
                        ctx.lineTo(x + width, y + height - (radius.br.b || radius.br));
                        getRectQuadraticCurve('br', radius, ctx, form.borderConfigurations, width, height);
                        ctx.lineTo(x + (radius.bl.b || radius.bl), y + height);
                        getRectQuadraticCurve('bl', radius, ctx, form.borderConfigurations, width, height);
                        ctx.lineTo(x, y + (radius.tl.a || radius.tl));
                        getRectQuadraticCurve('tl', radius, ctx, form.borderConfigurations, width, height);
                        ctx.closePath();

                        ctx.fillStrokeShape(shape);
                    }}
                />
            }
            if (form.type === 'OVAL') {

                const w = (form.width * configurator.ratio)
                const radius = form.radius / 2 * configurator.ratio
                const offsetX = radius / 2;
                const offsetY = Number(Math.ceil(form.radius / 2)) * configurator.ratio;
                return <Group
                    key={form.id}
                    x={(form.realx * configurator.ratio) + mainFormX}
                    y={(form.realy * configurator.ratio) + mainFormY}
                    draggable
                    onClick={(e) => handelClickForm(e, form)}
                    onMouseDown={(e) => handelClickForm(e, form)}
                    onTouchStart={(e) => handelClickForm(e, form)}
                    onDragStart={handelDragStart}
                    onDragEnd={handelDragEnd}
                    onDragMove={handelDragingForm}
                    rotation={form.rotation}
                    offset={{ x: offsetX, y: offsetY }}
                    onMouseOver={updateCursonPosition}

                >


                    <Rect
                        x={-radius / 2}
                        y={0}
                        width={w - radius * 2}
                        height={radius * 2}
                        fill={form.unionType === 'add' ? configurator.shadeColor || "#7b7b7b" : form.graved ? 'transparent' : 'white'}
                        stroke={configurator.selectedForm === form.id ? '#35919D' : form.graved ? 'green' : 'transparent'}
                    />
                    <Circle
                        x={-radius / 2}
                        y={radius}
                        radius={radius}
                        fill={form.unionType === 'add' ? configurator.shadeColor || "#7b7b7b" : form.graved ? 'transparent' : 'white'}
                        stroke={configurator.selectedForm === form.id ? '#35919D' : form.graved ? 'green' : 'transparent'}
                    />
                    <Circle
                        x={w - radius - (radius) - radius / 2}
                        y={radius}
                        radius={radius}
                        fill={form.unionType === 'add' ? configurator.shadeColor || "#7b7b7b" : form.graved ? 'transparent' : 'white'}
                        stroke={configurator.selectedForm === form.id ? '#35919D' : form.graved ? 'green' : 'transparent'}
                    />

                    <Rect
                        x={-radius / 2}
                        y={0}
                        width={w - radius * 2}
                        height={(radius * 2) - 1}
                        fill={form.unionType === 'add' || form.graved ? configurator.shadeColor || "#7b7b7b" : 'white'}
                    />
                    <Circle
                        x={-radius / 2}
                        y={radius}
                        radius={radius - 1}
                        fill={form.unionType === 'add' || form.graved ? configurator.shadeColor || "#7b7b7b" : 'white'}
                    />
                    <Circle
                        x={w - radius - (radius) - radius / 2}
                        y={radius}
                        radius={radius - 1}
                        fill={form.unionType === 'add' || form.graved ? configurator.shadeColor || "#7b7b7b" : 'white'}
                    />
                </Group >
            }



            if (form.type === 'TRIANGLE') {
                const coordinates = getPointsOfTriangle({ x: 0, y: 0 }, form.l1 * configurator.ratio, form.l2 * configurator.ratio, form.d)
                return <Group
                    key={form.id}
                    x={(form.realx * configurator.ratio) + mainFormX}
                    y={(form.realy * configurator.ratio) + mainFormY}
                    onClick={(e) => handelClickForm(e, form)}
                    onMouseDown={(e) => handelClickForm(e, form)}
                    onTouchStart={(e) => handelClickForm(e, form)}
                    draggable
                    onDragStart={handelDragStart}
                    onDragEnd={handelDragEnd}
                    onDragMove={handelDragingForm}
                    rotation={form.rotation}
                    onMouseOver={updateCursonPosition}
                >
                    <Shape

                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(coordinates.a.x, coordinates.a.y);
                            context.lineTo(coordinates.b.x, coordinates.b.y);
                            context.lineTo(coordinates.c.x, coordinates.c.y);
                            context.lineTo(coordinates.a.x, coordinates.a.y);

                            context.closePath();
                            context.fillStrokeShape(shape);
                        }}
                        fill={form.unionType === 'add' ? configurator.shadeColor || "#7b7b7b" : form.graved ? 'transparent' : 'white'}
                        stroke={configurator.selectedForm === form.id ? '#35919D' : form.graved ? 'green' : 'transparent'}
                    />
                    <Text
                        x={-20}
                        y={(form.l1 * configurator.ratio) / 2}
                        fill="red"
                        text="L1"
                        fontStyle={"bold"}
                        fontSize={15}
                    />
                    <Text
                        x={(form.l2 * configurator.ratio) / 2}
                        y={-20}
                        fill="red"
                        text="L2"
                        fontStyle={"bold"}
                        fontSize={15}
                    />
                </Group>
            }
            if (form.type === 'FOLDINGLINE') {
                return <Line
                    key={form.id}
                    dashEnabled={form.foldingType === 'DOWN'}
                    dash={[10, 5]}
                    points={[
                        mainFormX + form.a.x * configurator.ratio,
                        mainFormY + form.a.y * configurator.ratio,
                        mainFormX + form.b.x * configurator.ratio,
                        mainFormY + form.b.y * configurator.ratio
                    ]}
                    stroke={'red'}
                    strokeWidth={1}
                    rotation={form.rotation}
                    onMouseOver={updateCursonPosition}
                />
            }
            if (form.type === 'POLYGONE') {
                return <RegularPolygon
                    key={form.id}
                    x={(form.realx * configurator.ratio) + mainFormX}
                    y={(form.realy * configurator.ratio) + mainFormY}
                    radius={Math.ceil(form.radius * configurator.ratio)}
                    sides={form.nbSides}
                    onTouchStart={(e) => handelClickForm(e, form)}
                    fill={form.unionType === 'add' ? configurator.shadeColor || "#7b7b7b" : form.graved ? 'transparent' : 'white'}
                    stroke={configurator.selectedForm === form.id ? '#35919D' : form.graved ? 'green' : 'transparent'}
                    onClick={(e) => handelClickForm(e, form)}
                    onMouseDown={(e) => handelClickForm(e, form)}
                    draggable
                    onDragStart={handelDragStart}
                    onDragEnd={handelDragEnd}
                    onDragMove={handelDragingForm}
                    rotation={form.rotation}
                    onMouseOver={updateCursonPosition}
                />
            }
            if (form.type === 'TEXT') {
                let font = loadedFonts[form.fontFamily];

                if (font) {
                    let textModel = textModels[form.id]
                    let measure = {};
                    let sizeInPx = 0;
                    if ((!textModel || textModel.fontName !== form.fontFamily || textModel.fontSize !== form.fontSize || textModel.text !== form.text) && form.text) {
                        textModel = new makerjs.models.Text(font, form.text, form.fontSize);
                        measure = makerjs.measure.modelExtents(textModel);
                        sizeInPx = form.fontSize;
                        if (measure.width) {
                            sizeInPx = Math.pow(form.fontSize, 2) / measure.width
                        }
                        textModel = new makerjs.models.Text(font, form.text, sizeInPx);
                        measure = makerjs.measure.modelExtents(textModel);
                        settextModels(s => ({ ...s, [form.id]: { model: textModel, text: form.text, fontName: form.fontFamily, fontSize: form.fontSize, measure } }))
                    } else {
                        measure = textModel.measure;
                    }

                    const path = makerjs.exporter.toSVGPathData(textModel.model || textModel)
                    return <Group
                        key={form.id}
                        x={(form.realx * configurator.ratio) + mainFormX}
                        y={(form.realy * configurator.ratio) + mainFormY}
                        onClick={(e) => handelClickForm(e, form)}
                        onTouchStart={(e) => handelClickForm(e, form)}
                        onMouseDown={(e) => handelClickForm(e, form)}
                        draggable
                        onDragStart={handelDragStart}
                        onDragEnd={handelDragEnd}
                        onDragMove={handelDragingForm}
                        rotation={form.rotation}
                        onMouseOver={updateCursonPosition}
                    >
                        <Rect
                            width={measure.width < 15 ? 15 : measure.width}
                            height={measure.height < 15 ? 15 : measure.height}
                        />

                        <Shape
                            strokeWidth={1}
                            fill={form.unionType === 'add' ? configurator.shadeColor || "#7b7b7b" : form.graved ? 'transparent' : 'white'}
                            stroke={configurator.selectedForm === form.id ? '#35919D' : form.graved ? 'green' : form.comment ? 'yellow' : 'transparent'}


                            sceneFunc={(ctx, shape) => {
                                const p = new Path2D(path);
                                ctx.beginPath();
                                ctx.closePath();
                                ctx.fillStrokeShape(shape);
                                ctx._context.stroke(p);
                                ctx._context.fill(p, 'evenodd');
                            }}
                            scale={{
                                x: configurator.ratio,
                                y: configurator.ratio,
                            }}
                        />
                    </Group>

                }
                return null
            }
            if (form.type === 'CUSTOM') {

                const offsetX = ((Number(Math.ceil(form.width)) / 2)) * configurator.ratio;
                const offsetY = ((Number(Math.ceil(form.height)) / 2)) * configurator.ratio;
                return <Group

                    x={(form.realx * configurator.ratio) + mainFormX}
                    y={(form.realy * configurator.ratio) + mainFormY}
                    draggable
                    onClick={(e) => handelClickForm(e, form)}
                    onMouseDown={(e) => handelClickForm(e, form)}

                    offset={{ x: offsetX, y: offsetY }}
                    onDragStart={handelDragStart}
                    onDragEnd={handelDragEnd}
                    onDragMove={handelDragingForm}
                    onTouchStart={(e) => handelClickForm(e, form)}
                    rotation={form.rotation}
                    onMouseOver={updateCursonPosition}
                >
                    {form.paths.map(path => <Shape
                        key={form.id}
                        fill={form.unionType === 'add' ? configurator.shadeColor || "#7b7b7b" : form.graved ? 'transparent' : 'white'}
                        stroke={configurator.selectedForm === form.id ? '#35919D' : form.graved ? 'green' : 'transparent'}


                        sceneFunc={(ctx, shape) => {
                            const p = new Path2D(path);
                            ctx.beginPath();
                            ctx.closePath();
                            ctx.fillStrokeShape(shape);
                            ctx._context.stroke(p);
                            ctx._context.fill(p, 'evenodd');
                            ctx._context.closePath(p);
                        }}
                        scale={{
                            x: configurator.ratio * (form.width / form.originalWidth),
                            y: configurator.ratio * (form.height / form.originalHeight),
                        }}
                    />)}
                    <Rect
                        width={form.width * configurator.ratio}
                        height={form.height * configurator.ratio}
                    />

                </Group>
            }
            if (form.type === 'LABEL' && isLabelDisplayed) {
                return <Text
                    key={form.id}
                    x={mainFormX + form.x * configurator.ratio}
                    y={mainFormY + form.y * configurator.ratio}
                    fill={'black'}
                    stroke={'black'}
                    text={form.text}
                    fontSize={form.fontSize * configurator.ratio}
                    rotation={form.rotation}
                    onMouseOver={updateCursonPosition}
                />
            }

            return null

        })
    },
        [configurator.forms, isLabelDisplayed, configurator.mainForm.width, textModels, loadedFonts, mainFormX, mainFormY, configurator.ratio, configurator.selectedForm, configurator.shadeColor, handelClickForm, handelDragEnd, handelDragStart, handelDragingForm, updateCursonPosition],
    )


    const displayPositionOnDraging = useCallback(
        () => {
            const selectedForm = configurator.forms.find(form => form.id === configurator.selectedForm);
            if (selectedForm) {

                let x = Number(selectedForm.realx);
                let y = Number(selectedForm.realy);

                if (configurator && configurator.isDraging) {

                    x = Number(curentDraggingObjectPosition.x);
                    y = Number(curentDraggingObjectPosition.y);

                }
                return <>
                    <Rect
                        x={(x * configurator.ratio) + mainFormX - 10}
                        y={mainFormY - 40}
                        width={x >= 1000 ? 80 : 65}
                        height={30}
                        cornerRadius={5}
                        fill="#3A7297">

                    </Rect>
                    <Line
                        points={[(x * configurator.ratio) + mainFormX, (y * configurator.ratio) + mainFormY, (x * configurator.ratio) + mainFormX, mainFormY]}
                        closed
                        stroke="#35919D"
                        dash={[5, 10]}
                    />
                    <Text fontStyle='bold' fill="white" x={(x * configurator.ratio) + mainFormX} y={mainFormY - 30} text={`X : ${x}`} fontSize={15} />
                    <Rect
                        x={mainFormX - 40}
                        y={(y * configurator.ratio) + mainFormY + 10}
                        rotation={270}
                        width={y >= 1000 ? 80 : 65}
                        height={30}
                        cornerRadius={5}
                        fill="#3A7297">

                    </Rect>
                    <Line
                        points={[(x * configurator.ratio) + mainFormX, (y * configurator.ratio) + mainFormY, mainFormX, (y * configurator.ratio) + mainFormY]}
                        closed
                        stroke="#35919D"
                        dash={[5, 10]}
                    />
                    <Text fontStyle='bold' fill="white" rotation={270} x={mainFormX - 30} y={(y * configurator.ratio) + mainFormY} text={`Y : ${y}`} fontSize={15} />
                    <Line
                        points={[
                            mainFormX - 6,
                            mainFormY - 5,
                            (x * configurator.ratio) + mainFormX,
                            mainFormY - 5
                        ]}
                        closed
                        stroke="#efd700"
                    />
                    <Line
                        points={[
                            mainFormX - 5,
                            mainFormY - 5,
                            mainFormX - 5,
                            (y * configurator.ratio) + mainFormY]}
                        closed
                        stroke="#efd700"

                    />
                </>
            }
        },
        [configurator, curentDraggingObjectPosition.x, curentDraggingObjectPosition.y, mainFormX, mainFormY],
    )



    const handleTouchStartOnStage = useCallback((e) => {
        if (configurator.formToAdd) {
            let offsetX = 0;
            let offsetY = 0;
            let r = e.evt.touches[0].target.getBoundingClientRect();
            offsetX = e.evt.touches[0].clientX - r.left;
            offsetY = e.evt.touches[0].clientY - r.top;
            const realx = Math.round((offsetX - mainFormX) / configurator.ratio)
            const realy = Math.round((offsetY - mainFormY) / configurator.ratio)
            handelClickAddForm({ x: realx, y: realy })
        } else {

        }


    }, [configurator.formToAdd, configurator.ratio, handelClickAddForm, mainFormX, mainFormY])

    const handleTouchEndOnStage = useCallback((e) => {
        /*  if (configurator.selectedForm && configurator.step === 0 && !configurator.formToAdd) {
             dispatch({
                 type: 'SET_VALUE',
                 payload: {
                     key: 'selectedForm',
                     data: null
                 }
             })
         } */
    }, [configurator.formToAdd, configurator.selectedForm, configurator.step, dispatch])


    const handelClickOnStage = useCallback(
        (e) => {

            handelClickAddForm(e)

            if (configurator.selectedForm && configurator.step === 0 && !configurator.formToAdd) {
                dispatch({
                    type: 'SET_VALUE',
                    payload: {
                        key: 'selectedForm',
                        data: null
                    }
                })
            }

        },
        [configurator.formToAdd, configurator.selectedForm, configurator.step, dispatch, handelClickAddForm],
    )

    const handleChangeAccordion = () => {
        setisAccordionExpended(!isAccordionExpended);
    };


    const handleMouveMainForm = useCallback((e) => {
        updateCursonPosition(e)
        if (isCtrlDown && isMouseDown) {
            const offsetMouveX = originXOnClick - e.evt.offsetX
            const offsetMouveY = originYOnClick - e.evt.offsetY
            setmainFormX(originMainFormX - offsetMouveX)
            setmainFormY(originMainFormY - offsetMouveY)

        }
    }, [isCtrlDown, isMouseDown, originMainFormX, originMainFormY, originXOnClick, originYOnClick, updateCursonPosition]);

    const handleKeyDown = (e) => {
        if (e.ctrlKey || e.metaKey) {
            switch (e.key) {
                case 'c':
                    dispatch({ type: 'COPY_FORM' });
                    break;
                case 'v':
                    dispatch({ type: 'PASTE_FORM', payload: { relatx: actualCursorPixelPos.x, relaty: actualCursorPixelPos.y, realx: actualCursorRealPos.x, realy: actualCursorRealPos.y } });
                    break;
                case 'x':
                    dispatch({ type: 'CUT_FORM' });
                    break;
                case 'z':
                    if (e.shiftKey) {
                        dispatch({ type: 'REDO' });
                    } else {
                        dispatch({ type: 'UNDO' });
                    }
                    break;
                case 'y':
                    dispatch({ type: 'REDO' });
                    break;
                default:
                    break;
            }
        }
    };

    useEffect(() => {

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [actualCursorPixelPos, actualCursorRealPos]);

    useEffect(() => {
        if (refStage.current?.clientWidth) {

            let ratio = (refStage.current.clientWidth * 0.80) / configurator.mainForm.width
            dispatch({
                type: 'SET_VALUE',
                payload: {
                    key: 'ratio',
                    data: ratio
                }
            })
        }
    }, [refStage.current, configurator.mainForm])

    return (
        <>
            <Grid container direction={isDownSm ? 'column' : 'column'} className={classes.container}>
                <Grid item style={{ height: isDownSm ? 'auto' : '90%' }}>
                    <Grid container justify='space-around' style={{ height: '100%', flexWrap: isDownSm ? 'wrap-revers' : 'wrap' }}>
                        <Grid container item xs={12} lg={10} className={`${!configurator.form && configurator.mainForm.configured ? classes.drawer : ''}`} style={{ overflow: 'hidden' }}>
                            {configurator.form !== null && !configurator.mainForm.configured && <Grid item xs={12} style={{ height: '100%' }}>
                                <ConfiguratorMainForm fontsList={fontsList} />
                            </Grid>}
                            <div onClick={(e) => e.stopPropagation()} onTouchStart={(e) => e.stopPropagation()} ref={paperRef} className={`${classes.paper} ${classes.drawer}`} >
                                {configurator.form !== null && configurator.mainForm.configured &&
                                    <>
                                        <Paper elevation={0} onClick={(e) => e.stopPropagation()} onTouchStart={(e) => e.stopPropagation()}>
                                            <Grid className={classes.actionsNav}>
                                                <ConfiguratorIconsFormButton calculateLength={calculateLength} setisLabelDisplayed={setisLabelDisplayed} isLabelDisplayed={isLabelDisplayed} />
                                            </Grid>
                                            <Stage ref={refStage} onMouseMove={handleMouveMainForm} onClick={handelClickOnStage} onTouchStart={handleTouchStartOnStage} onTouchEnd={handleTouchEndOnStage} style={{ flexGrow: 1 }} width={paperRef?.current?.clientWidth} height={paperRef?.current?.clientHeight}>
                                                <Layer>
                                                    {!isDownSm && <Text
                                                        x={10}
                                                        y={10}
                                                        text={'Zoom : CTRL + molette\nSe déplacer : CTRL + CLIC gauche maintenu'}
                                                        preventDefault={false}
                                                    />}


                                                    <Group>
                                                        <Group>

                                                            {getMainForm()}
                                                            <Group>

                                                                {displayForms()}
                                                            </Group>
                                                        </Group>
                                                    </Group>
                                                    {configurator.isDraging || configurator.selectedForm ? displayPositionOnDraging() : null}
                                                </Layer>
                                            </Stage>
                                        </Paper>
                                    </>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={2} style={{ overflow: 'auto', height: '100%' }} >

                            {configurator.mainForm.configured && configurator.step === 0 &&
                                <>
                                    {(!configurator.isFromCatalog || !process.env.REACT_APP_FOLDING_DISABLE_CUT) && <>
                                        {isDownSm ?

                                            <Accordion elevation={0} style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} expanded={isAccordionExpended} onChange={handleChangeAccordion}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                >
                                                    <Typography className={classes.heading}>Ajouter une découpe</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>

                                                    <ConfiguratorDrawingPannelSideBar paperRef={paperRef} setisAccordionExpended={setisAccordionExpended} />
                                                </AccordionDetails>
                                            </Accordion>
                                            :
                                            <ConfiguratorDrawingPannelSideBar paperRef={paperRef} />
                                        }
                                    </>}

                                </>
                            }
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container item style={{ height: isDownSm ? 'auto' : '10%' }} className={classes.drawerConfig} onTouchStart={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
                    {getConfiguratorSelectedForm()}
                </Grid>
            </Grid>
        </>

    )
}

export default ConfiguratorDrawingPannel
