import React, { useCallback } from 'react'
import { Checkbox, FormControlLabel, Grid, IconButton, TextField, Typography, useTheme, useMediaQuery, makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    buttons: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },

}))

function CatalogCustomzationLine({ entity, setentityInformations, entityInformations, withControl, linesLibele }) {

    const classes = useStyles()
    const theme = useTheme();
    const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));


    const handelChange = useCallback(
        (value, entityId, field) => {
            if ((field === 'min' || field === 'max') && value < 1) {
                return
            }
            if (field === 'name' && value.length > 10) {
                return
            }
            if (field === 'linesDep' || field === 'foldingType' || field === 'linesDepH') {
                value = value.value;
            }
            setentityInformations({ ...entityInformations, entities: [...entityInformations.entities.map(entity => entity._id === entityId ? { ...entity, [field]: value } : entity)] })
        },
        [entityInformations, setentityInformations],
    )



    const getValue = useCallback(
        (field) => {
            return entityInformations.entities?.find(en => en.name === entity.string)?.[field] || ''
        },
        [entity.string, entityInformations.entities],
    )

    const handelClickDelete = useCallback(
        () => {
            setentityInformations({ ...entityInformations, entities: [...entityInformations.entities.filter(en => en._id !== entity.id)] })
        },
        [entity.id, entityInformations, setentityInformations],
    )

    const isNotLength = useCallback(
        () => {
            return entity.type !== 'longer'
        },
        [entity.type],
    )

    const getEntityLibele = useCallback(
        () => {
            if (entity.type === 'longer') {
                return 'Longueur'
            } else if (entity.type === 'angle') {
                return 'Angle'
            } else {
                return 'Ligne'
            }
        },
        [entity.type],
    )


    return (
        <Grid container justify='space-around' alignItems='center'>
            <Grid item xs={12} sm={1}>

                {
                    !withControl ? <Typography variant="subtitle1" gutterBottom>Entité {entity.string}</Typography> : <div><Typography variant="subtitle1" gutterBottom>{getEntityLibele()}</Typography><TextField fullWidth onChange={(e) => handelChange(e.target.value, entity.id, 'name')} value={getValue('name')} /></div>
                }
            </Grid>
            <Grid item xs={12} sm={1}>
                {entity.type !== 'longer' && <FormControlLabel
                    value="start"
                    control={<Checkbox checked={getValue('horizontal')} onChange={(e) => handelChange(e.target.checked, entity.id, 'horizontal')} color="primary" />}
                    label="horizontal"
                    labelPlacement="start"
                />}
            </Grid>
            <Grid item xs={12} sm={3}>
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Grid item xs={12}>
                        <FormControlLabel
                            value="start"
                            control={<Checkbox checked={getValue('asMin')} onChange={(e) => handelChange(e.target.checked, entity.id, 'asMin')} color="primary" />}
                            label="Définir une valeur minimum"
                            labelPlacement="start"
                        />

                    </Grid>
                    {
                        getValue('asMin') && <Grid item>
                            <TextField onChange={(e) => handelChange(Number(e.target.value), entity.id, 'min')} value={getValue('min')} label={entity.type === 'angle' ? 'Valeur min en degré' : 'Longueur min en mm'} type="number" />
                        </Grid>
                    }
                </Grid>

            </Grid>
            <Grid item xs={12} sm={3}>
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Grid item xs={12}>
                        <FormControlLabel
                            value="start"
                            control={<Checkbox checked={getValue('asMax')} onChange={(e) => handelChange(e.target.checked, entity.id, 'asMax')} color="primary" />}
                            label="Définir une valeur maximum"
                            labelPlacement="start"
                        />

                    </Grid>
                    {
                        getValue('asMax') && <Grid item xs={12}>
                            <TextField onChange={(e) => handelChange(Number(e.target.value), entity.id, 'max')} value={getValue('max')} label={entity.type === 'angle' ? 'Valeur max en degré' : 'Longueur max en mm'} type="number" />
                        </Grid>
                    }

                </Grid>
            </Grid>

            <Grid item xs={12} sm={1}>
                {entity.type !== 'longer' && entityInformations.entities.some(({ type }) => type === 'line') && <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="demo-mutiple-name-label">Dépendance</InputLabel>
                    <Select

                        multiple
                        value={getValue('linesDep') || []}
                        onChange={(e) => handelChange(e.target, entity.id, 'linesDep')}

                    >
                        {linesLibele && linesLibele.filter(({ id, type, horizontal }) => id !== entity.id && type !== 'longer' && !horizontal).map((libele, index) => (
                            <MenuItem key={libele.id} value={libele.id} >
                                {libele.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
            </Grid>
            <Grid item xs={12} sm={1}>
                {entity.type !== 'longer' && getValue('horizontal') && entityInformations.entities.some(({ type }) => type === 'line') && <FormControl fullWidth className={classes.formControl}>
                    <InputLabel>Dépendance H</InputLabel>
                    <Select

                        multiple
                        value={getValue('linesDepH') || []}
                        onChange={(e) => handelChange(e.target, entity.id, 'linesDepH')}

                    >
                        {linesLibele && linesLibele.filter(({ id, horizontal, type }) => (id !== entity.id && horizontal) || type === 'longer').map((libele, index) => (
                            <MenuItem key={libele.id} value={libele.id} >
                                {libele.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
            </Grid>
            {entity.type === 'angle' ? <Grid item xs={12} sm={1}>
                <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="demo-mutiple-name-label">Type de plis</InputLabel>
                    <Select


                        value={getValue('foldingType') || 'UP'}
                        onChange={(e) => handelChange(e.target, entity.id, 'foldingType')}

                    >
                        <MenuItem value='UP' >
                            Montant
                        </MenuItem>
                        <MenuItem value='DOWN' >
                            Déscendant
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid> : <Grid item xs={12} sm={1}></Grid>}

            <Grid item xs={12} sm={1} className={classes.buttons}>
                {
                    withControl ? <IconButton aria-label="delete" disabled={!isNotLength()} size="medium"><DeleteIcon onClick={handelClickDelete} fontSize={isDownXs ? 'large' : 'medium'} /></IconButton> : null
                }
            </Grid>
        </Grid >
    )
}

export default CatalogCustomzationLine
