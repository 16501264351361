import React, { useEffect, useState } from 'react'
import { dxftoSVG } from '../../modules/dxf'
import { useGet } from '../../modules/request'
import { getFileExtension } from '../../modules/utils'

function CatalogImagePreview({ image }) {

    const get = useGet()
    const [extention, setextention] = useState(null)
    const [svg, setsvg] = useState(null)

    useEffect(() => {
        setextention(getFileExtension(image))

    }, [image])

    useEffect(() => {
        (async () => {
            if (!svg && extention === 'dxf') {
                const res = await get(`/download/image/${image}`)
                setsvg(dxftoSVG(res.data))
            }
        })()
    }, [extention, get, image, svg])

    return (
        <div>
            {
                extention === 'dxf' ?
                    <div dangerouslySetInnerHTML={{ __html: svg }} />

                    : <img
                        style={{ width: '100%' }}
                        src={`${process.env.REACT_APP_BASE_API}/download/image/${image}`}
                        alt=''
                    />
            }
        </div>
    )
}

export default CatalogImagePreview
