function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};


function expiresIn (endOfLifeToken) {
    let now = new Date().getTime()
    let nowInsecond = Math.trunc(now / 1000)
    return Number(endOfLifeToken - nowInsecond) / 60
}

export {parseJwt, expiresIn}