import React, { useCallback, useEffect, useContext, useState, useMemo } from 'react'
import { DataGrid, GridFilterToolbarButton, getGridStringOperators } from '@material-ui/data-grid';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useGet, usePost } from '../../modules/request';
import { OrderContext, useGetMyOrder, useGetOrders, useUpdateOrder } from '../../contexts/OrderContext';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArchiveIcon from '@material-ui/icons/Archive';
import DialogShowClientInformation from '../Dialog/DialogShowClientInformation'
import DialogShowFormsInOrder from '../Dialog/DialogShowFormsInOrder';
import OrderControlPaymentButton from './OrderControlPaymentButton';
import { useAuthUser } from 'react-auth-kit'
import { useHistory } from 'react-router';
import { displayFormatedPrice } from '../../modules/utils';
import ConfirmationModalWrapper from '../utils/ConfirmationModalWrapper';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useAddItemBasket } from '../../contexts/BasketContext';
import { SnackbarContext } from '../../contexts/SnackbarContext';


const useStyle = makeStyles((theme) => ({
    root: {

    }
}))

function convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
}




const stringColumnType = {
    extendType: 'string',
    filterOperators: getGridStringOperators()
        .filter((operator) => operator.value === 'contains')
        .map((operator) => {
            return {
                ...operator,

            };
        }),
};



function OrdersControl({
    mode = 'admin'
}) {
    const history = useHistory()
    const auth = useAuthUser()

    useEffect(() => {
        if (mode === 'admin' && auth()?.role !== 0) {
            history.push('/')
        }
    }, []);

    const [orders, dispatch] = useContext(OrderContext);
    const [clientToShow, setclientToShow] = useState(null)
    const [orderToShow, setorderToShow] = useState(null)
    const [showModalClientInfos, setshowModalClientInfos] = useState(false)
    const [showModalFormsInfos, setshowModalFormsInfos] = useState(false)

    const { addItemBasket } = useAddItemBasket()

    const classes = useStyle()
    const get = useGet()

    const post = usePost()

    const { updateOrder } = useUpdateOrder()
    const [snackbar, dispatchSnackbar] = useContext(SnackbarContext)


    const isUser = useMemo(() => mode === 'user', [mode])

    const hanclickShowInfoClient = useCallback(
        (client) => {
            setclientToShow(client)
            setshowModalClientInfos(true)
        },
        [],
    )
    const handleCloseInfoClient = useCallback(
        () => {
            setshowModalClientInfos(false)
        },
        [],
    )

    const handelClickShowInfoForms = useCallback(
        (order) => {
            setorderToShow(order)
            setshowModalFormsInfos(true)
        },
        [],
    )

    const handleCloseInfoForms = useCallback(
        () => {
            setshowModalFormsInfos(false)
        },
        [],
    )

    const handelClickDownloadZip = useCallback(
        (params) => {
            (async () => {
                try {

                    const response = await get('/download/zip/' + params.id, { responseType: 'blob' })

                    const url = window.URL.createObjectURL(new Blob([response.data]));

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', params.ref + '.zip'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                } catch (e) {

                }

            })()

        },
        [get],
    )


    const handleClickCloneOrder = async (orderId) => {
        const order = orders.find(o => o._id === orderId)
        for (const item of order.baskets) {
            const { _id, ...rest } = item

            await addItemBasket(rest)
        }
    }

    const handelClickArchive = (order) => {
        updateOrder(order.id, { isArchived: true })
    }

    const columnsdef = [
        { field: 'ref', headerName: 'REF', disableColumnMenu: true, width: 140 },
        {
            field: 'clone',
            headerName: 'Duppliquer',
            sortable: false,
            disableColumnMenu: true,
            width: 140,
            hide: !isUser,
            renderCell: (params) => {
                return (
                    <Grid container alignItems='center' justify='space-around'>

                        <Grid item style={{ cursor: "pointer" }}>
                            <Grid container alignItems='center'>
                                <FileCopyIcon color='primary' onClick={() => handleClickCloneOrder(params.row.id)} />
                            </Grid>

                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: "date",
            headerName: "Date",
            type: 'dateTime',
            sortable: true,
            width: 140,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    convertDate(params.row.date)
                );
            },
        },
        {
            field: "content",
            headerName: "Contenu",
            sortable: false,
            disableColumnMenu: true,
            width: 140,
            menu: false,
            disableClickEventBubbling: true,
            filterable: false,

            renderCell: (params) => {
                return (
                    <Grid container alignItems='center' justify='space-around'>
                        <Grid item>
                            {`${params.row.content.length} pièce${params.row.content.length > 1 ? 's' : ''}`}
                        </Grid>
                        <Grid item style={{ cursor: "pointer" }}>
                            <Grid container alignItems='center'>
                                <VisibilityIcon onClick={() => handelClickShowInfoForms(params.row)} color='primary' />
                            </Grid>

                        </Grid>
                    </Grid>
                );
            }
        },
        {
            field: "client",
            headerName: "Client",
            sortable: true,
            width: 250,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Grid container alignItems='center' justify='space-around'>
                        <Grid item>
                            {`${params.row.client.lastName} ${params.row.client.firstName}`}
                        </Grid>
                        <Grid item style={{ cursor: "pointer" }}>
                            <Grid container alignItems='center'>
                                <InfoIcon onClick={() => hanclickShowInfoClient(params.row.client)} color='primary' />
                            </Grid>

                        </Grid>
                    </Grid>
                );
            }
        },
        {
            field: "amount",
            headerName: "Prix HT commande",
            sortable: true,
            width: 150,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>{`${params.row.amount} €`}</div>
                );
            },

        },
        {
            field: "deliveryPrice",
            headerName: "Prix HT livraison",
            sortable: true,
            width: 150,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>{`${params.row.deliveryPrice} €`}</div>
                );
            },

        },
        {
            field: "totalPriceWithVat",
            headerName: "Prix total TTC",
            sortable: true,
            width: 150,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>{`${params.row.totalPriceWithVat} €`}</div>
                );
            },

        },
        {
            field: "export",
            headerName: "Exporter (dxf, csv)",
            hide: isUser,
            sortable: false,
            width: 180,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) => {

                return (
                    <Grid container alignItems='center' justify='space-around'>
                        <Grid item style={{ cursor: "pointer" }}>
                            <GetAppIcon onClick={() => handelClickDownloadZip(params.row)} color='primary' />
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: "validatePayment",
            headerName: "Valider le paiement",
            sortable: false,
            hide: isUser,
            width: 250,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) => {
                return (
                    <OrderControlPaymentButton order={params.row} />
                );
            },
        },
        {
            field: "deliveryMethode",
            headerName: "Livraison / retrait",
            sortable: false,
            width: 180,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Typography variant="body1" gutterBottom align='center'>
                        {params.row.deliveryMethode === 'pickup' ? 'retrait' : 'livraison'}
                    </Typography>
                );
            },
        },
        {
            field: "archive",
            headerName: "Archiver",
            hide: isUser,
            sortable: false,
            width: 180,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) => {

                return (
                    <Grid container alignItems='center' justify='space-around'>
                        <Grid item style={{ cursor: "pointer" }}>
                            <ConfirmationModalWrapper confirmationMessage="Voulez-vous archiver cette commande ?" onConfirm={() => handelClickArchive(params.row)}>
                                <ArchiveIcon color='primary' />
                            </ConfirmationModalWrapper>
                        </Grid>
                    </Grid>
                );
            },
        },
    ];

    const { getOrder } = useGetOrders()
    const { getMyOrder } = useGetMyOrder();
    
    const getRows = () => {
        return orders.filter(order => !order.isArchived).map(order =>
        ({
            id: order._id,
            ref: order.ref,
            date: order.orderDate,
            content: order.baskets,
            client: order.user,
            amount: displayFormatedPrice(order.amount),
            deliveryPrice: displayFormatedPrice(order.deliveryPrice) || null,
            paymentProcessed: order.paymentProcessed,
            totalPriceWithVat: displayFormatedPrice(order.total),
            pi: order.pi,
            deliveryMethode: order.deliveryMethode

        })
        )
    }

    useEffect(() => {
        if (mode === 'user') {
            getMyOrder({ field: '', value: '', page: 1 });
        } else {

            getOrder({ field: '', value: '', page: 1 })
        }

    }, []);

    const columns = () => {
        if (columnsdef.length > 0) {

            const mappedColumns = columnsdef.map((dataColumn) => {
                const mappedColumn = {
                    ...dataColumn,
                };

                if (mappedColumn.field === 'ref' || mappedColumn.field === 'client') {
                    mappedColumn.type = 'str';
                }
                return mappedColumn;
            });
            return mappedColumns;
        }
        return [];
    };

    return (
        <Grid container justify="center" className={classes.root}>
            <Grid item xs={12} sm={11}>
                <Paper elevation={0}>
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            pageSize={25}
                            pagination


                            rows={getRows()}
                            columns={columns()}
                            localeText={{
                                toolbarFilters: 'Filtrer',
                                filterOperatorContains: 'contient',
                                filterOperatorIs: 'égale',
                                filterOperatorNot: 'différent',
                                filterOperatorAfter: '>',
                                filterOperatorOnOrAfter: '>=',
                                filterOperatorBefore: '<',
                                filterOperatorOnOrBefore: '<='

                            }}
                            components={!isUser && {
                                Toolbar: GridFilterToolbarButton,
                            }}

                            columnTypes={{ str: stringColumnType }}
                        />
                    </div>

                </Paper>
            </Grid>
            <DialogShowClientInformation open={showModalClientInfos} handleClose={handleCloseInfoClient} client={clientToShow} />
            <DialogShowFormsInOrder open={showModalFormsInfos} handleClose={handleCloseInfoForms} baskets={orderToShow?.content || []} isUser={isUser} />
        </Grid>
    )
}

export default OrdersControl
