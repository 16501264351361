import React, { useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import {
    BrowserRouter as Router,
    Route,
  } from "react-router-dom";
import ParamHandler from './ParamHandler';
import { PropertiesContext } from '../contexts/PropertiesContext';
import {useAuthUser} from 'react-auth-kit';
import { useHistory } from 'react-router';

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column'
    },
    main: {
      flexGrow: 1
    },
    toolbar: {
      paddingRight: 24,
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
   
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));



function DashboardControl() {
    // eslint-disable-next-line no-unused-vars
    const [properties, dispatch] = useContext(PropertiesContext);
    const classes = useStyles();

    useEffect(() => {
      (async ()=> {
        try {
          const res = await axios.get(process.env.REACT_APP_BASE_API + '/propertie')
          dispatch({
            type: 'SET_PROPERTY',
            payload: res.data.message[0] || {}
          })
        } catch(e) {

        }
      })()
    }, []);

    const history = useHistory()
    const auth = useAuthUser()

    useEffect(() => {
        if(auth()?.role !== 0) {
            history.push('/')
        }
    }, []);

    return (
            <div  className={classes.root}>
                <main className={classes.main}>
                    <Route path="/control">
                        <ParamHandler />
                    </Route>
                </main>
            </div>
    )
}

export default DashboardControl
