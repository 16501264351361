import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogActions, DialogContent, Grid } from '@material-ui/core';
import TextFieldValidation from '../TextFieldValidation';
import { CatalogSvgDatasSchemas } from '../../modules/validation';


function DialogAddCatalogSvgType({ open, handleClose, typeToAdd, handleChangeTypeToAdd, handleClickAddType }) {

    return (

        <Dialog maxWidth={'md'} open={open} onClose={handleClose}>
            <DialogTitle id="add-to-catalog-dialog">Ajout d'un type</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={10}>
                        <TextFieldValidation id="svg-add-type" label="Type" value={typeToAdd} onChange={handleChangeTypeToAdd} fullWidth yupValidation={CatalogSvgDatasSchemas} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify='space-around'>


                    <Button onClick={handleClose}>Annuler</Button>
                    <Button disabled={!typeToAdd} onClick={handleClickAddType}>ajouter</Button>

                </Grid>
            </DialogActions>

        </Dialog>
    )
}

export default DialogAddCatalogSvgType
