import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router';
import axios from 'axios'
import { Button, CircularProgress, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { green, red } from '@material-ui/core/colors';;

const useStyles = makeStyles((theme) => ({
    paper: {
        height: 'max-content',
        padding: theme.spacing(5)
    },
    root: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

}));

function SignUpActivate() {

    let { token } = useParams();
    const history = useHistory()
    const [loading, setloading] = useState(false);
    const [success, setsuccess] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        (async () => {
            setloading(true)
            if (token) {
                try {
                    const res = await axios.get(`${process.env.REACT_APP_BASE_API}/user/activate/${token}`)
                    if (res.status === 200) {
                        setsuccess(true)
                    }
                } catch (e) {
                    setsuccess(false)
                }

            }
            setloading(false)
        })()

    }, []);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>

                {
                    loading ?
                        <>

                            <Typography variant="h4" gutterBottom>
                                Activation en cours
                            </Typography>
                            <Grid container justify='center'>
                                <CircularProgress />
                            </Grid>

                        </>
                        :
                        success === true ?
                            <>
                                <Typography variant="h4" gutterBottom>
                                    Activation réussie
                                </Typography>
                                <Grid container justify="center">
                                    <CheckBoxIcon style={{ color: green[500], fontSize: 80 }} />
                                </Grid>

                                <Typography variant="subtitle1" gutterBottom>
                                    Votre compte est désormais activé, vous pouvez vous authentifier.
                                </Typography>
                                <Grid container justify='flex-end'>
                                    <Button onClick={() => history.push('/login')} color='primary' variant="contained">Se connecter</Button>
                                </Grid>

                            </>
                            :

                            success === false ?
                                <>
                                    <Typography variant="h4" gutterBottom>
                                        Echec de l'activation
                                    </Typography>
                                    <Grid container justify="center">
                                        <CheckBoxIcon style={{ color: red[800], fontSize: 80 }} />
                                    </Grid>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Une erreure c'est produite ...
                                    </Typography>
                                    <Grid container justify='flex-end'>
                                        <Button onClick={() => history.push('/')} color='primary' variant="contained">Revenir</Button>
                                    </Grid>
                                </>
                                :
                                null
                }
                {


                }
            </Paper>
        </div>
    )
}

export default SignUpActivate
