import { FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';
import React, { useContext, useCallback } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import { MaterialsContext } from '../../../contexts/MaterialsContext';


const useStyles = makeStyles(() => ({
    materialName: {
        background: '#514f4f',
        padding: '10px',
        borderRadius: '5px',
    },
}))

function ConfiguratorMenuMaterialSector({ readOnly, materialShrink, overwriteValue = null, overwriteonChangeMaterial = null, stepThickness = null }) {
    const [materials, dispach] = useContext(MaterialsContext);

    const classes = useStyles()

    const configurator = useContext(ConfiguratorContext)[0]
    const dispachConfig = useContext(ConfiguratorContext)[1]


    const currentDatas = overwriteValue || configurator

    const getStyle = (imagename) => {
        return imagename ? { height: overwriteValue ? '25px' : '60px', fontWeight: 'bold', backgroundImage: `url('${process.env.REACT_APP_BASE_API}/download/image/${imagename}')`, backgroundSize: 'cover', color: 'white', marginBottom: overwriteValue ? '2px' : '0' } : { color: 'white', height: overwriteValue ? '25px' : 'auto', marginBottom: overwriteValue ? '2px' : '0' }
    }

    const formStyle = {
        marginBottom: overwriteValue ? '0' : '1rem'
    }

    const materialNameStyle = {
        background: '#514f4f',
        padding: overwriteValue ? '5px' : '10px',
        borderRadius: overwriteValue ? '0' : '5px',
        fontSize: overwriteValue ? '12px' : 'auto'
    };

    const getOptions = useCallback(
        () => {
            if (!stepThickness) {
                return materials.map(material => <MenuItem style={getStyle(material.image)} key={material._id} value={material._id}><span style={materialNameStyle}>{material.name}</span></MenuItem>)
            }
            return materials.filter(material => {
                return material.shades.some(shade => shade.thicknesses.some(thickness => thickness.value === stepThickness))
            }).map(material => <MenuItem style={getStyle(material.image)} key={material._id} value={material._id}><span style={materialNameStyle}>{material.name}</span></MenuItem>)
        },
        [materials, stepThickness],
    )
    const getMaterialImageNameFromId = useCallback((materialId) => materials.find(material => material._id === materialId)?.image, [materials])

    const onchange = (e) => {
        if (overwriteonChangeMaterial) {
            overwriteonChangeMaterial(e)
            return;
        }
        dispachConfig({
            type: 'SET_VALUES',
            payload: {
                data: {
                    materialId: e.target.value,
                    materialImageName: getMaterialImageNameFromId(e.target.value),
                    shadeId: null,
                    shadeImageName: null,
                    shadeColor: null,
                    thicknessId: null
                }
            }
        })
    }



    return (
        <FormControl fullWidth style={formStyle} >
            <InputLabel id="demo-simple-select-outlined-label" shrink={materialShrink}>Matière</InputLabel>
            <Select
                labelId="configurator-select-material-label"
                id="configurator-select-material"
                style={getStyle(getMaterialImageNameFromId(currentDatas.materialId))}
                value={currentDatas.materialId}
                onChange={onchange}
                label="Matériaux"
                fullWidth
                disabled={readOnly}
                inputProps={{ readOnly: readOnly || false }}

            >

                {getOptions()}
            </Select>
        </FormControl>
    )
}

export default ConfiguratorMenuMaterialSector
