import { Checkbox, FormControlLabel, Grid, makeStyles, Paper, TextField } from '@material-ui/core'
import React, { useCallback, useContext, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'

const useStyles = makeStyles((theme) => ({
    paper: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem'
    },
    line: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1),
            textAlign: 'center'
        }
    }
}))


function ConfiguratorConfigOval({ setcurentDraggingObjectPosition, selectedForm }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [x, setx] = useState(null);
    const [y, sety] = useState(null);
    const [l, setl] = useState(null);
    const [r, setr] = useState(null);
    const [rotation, setrotation] = useState(null);

    const classes = useStyles();

    const handelChangeL = (e) => setl(e.target.value);

    const handleBlurL = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(l);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_WIDTH_HEIGHT_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            width: evaluateValue
                        }
                    }

                })
                setl(evaluateValue)
            }
        } catch (e) {
            setl(null)
        }
    }, [configurator.selectedForm, dispatch, l])


    const getValueL = useCallback(
        () => {
            return l === null ? selectedForm.width : l
        },
        [l, selectedForm.width],
    )

    const getValueR = useCallback(
        () => {
            return r === null ? selectedForm.radius : r
        },
        [r, selectedForm.radius],
    )

    const handelChangeR = (e) => setr(e.target.value);

    const handleBlurR = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(r);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_RADIUS_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            radius: evaluateValue
                        }
                    }

                })
                setr(evaluateValue)
            }
        } catch (e) {
            setr(null)
        }
    }, [configurator.selectedForm, dispatch, r])

    const handelChangeX = (e) => setx(e.target.value);

    const handleBlurX = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(x);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            x: evaluateValue
                        }
                    }

                })
                setcurentDraggingObjectPosition((v) => ({ ...v, x: evaluateValue }))

                setx(evaluateValue)
            }
        } catch (e) {
            setx(null)
        }
    }, [configurator.selectedForm, dispatch, setcurentDraggingObjectPosition, x])


    const handelChangeY = (e) => sety(e.target.value);

    const handleBlurY = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(y);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            y: evaluateValue
                        }
                    }

                })
                setcurentDraggingObjectPosition((v) => ({ ...v, y: evaluateValue }))
            }
            sety(evaluateValue)
        } catch (e) {
            sety(null)
        }
    }, [configurator.selectedForm, dispatch, setcurentDraggingObjectPosition, y])

    const handelChangeRotation = (e) => setrotation(e.target.value);

    const handleBlurRotation = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(rotation);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_VALUE_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            rotation: evaluateValue
                        }
                    }

                })
            }
            setrotation(evaluateValue)
        } catch (e) {
            setrotation(null)
        }
    }, [configurator.selectedForm, dispatch, rotation])

    const handleChangeGraved = useCallback((e) => {
        dispatch({
            type: 'SET_VALUE_FORM',
            payload: {
                form: configurator.selectedForm,
                data: {
                    graved: e.target.checked,
                    unionType: e.target.checked ? 'del' : selectedForm.unionType

                }
            }
        })
    }, [configurator.selectedForm, dispatch, selectedForm.unionType])

    const getValueX = useCallback(
        () => {
            return x === null ? selectedForm.realx : x
        },
        [selectedForm.realx, x],
    )

    const getValueY = useCallback(
        () => {
            return y === null ? selectedForm.realy : y
        },
        [selectedForm.realy, y],
    )

    const getValueRotation = useCallback(
        () => {
            return rotation === null ? selectedForm.rotation : rotation
        },
        [rotation, selectedForm.rotation],
    )

    return (
        <Grid container justify="center" alignItems='center'>

            <Grid item xs={5} md={2} className={classes.line}>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField label="Largeur (mm)" value={getValueR()} onBlur={handleBlurR} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurR()} margin="dense" onChange={handelChangeR} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} className={classes.line}>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField label="Longueur (mm)" value={getValueL()} onBlur={handleBlurL} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurL()} margin="dense" onChange={handelChangeL} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField label="X (mm)" value={getValueX()} onBlur={handleBlurX} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurX()} margin="dense" onChange={handelChangeX} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField label="Y (mm)" value={getValueY()} onBlur={handleBlurY} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurY()} margin="dense" onChange={handelChangeY} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField label="Rotation (°)" value={getValueRotation()} onBlur={handleBlurR} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurRotation()} margin="dense" onChange={handelChangeRotation} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedForm.graved}
                                onChange={handleChangeGraved}
                                name="graved"
                                color="primary"
                            />
                        }
                        label="Graver"
                    />
                </Paper>
            </Grid>



        </Grid>


    )
}

export default ConfiguratorConfigOval
