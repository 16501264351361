import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, CircularProgress, DialogTitle, FormControlLabel, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import makerjs from 'makerjs';
import { setLayerToModel } from '../../modules/makerjs';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    dialog: {
        padding: 0,
    },
    svgDisplayBox: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(2)
    },
    svg: {
        height: '250px',
        display: 'flex',
        justifyContent: 'center',
        '& svg': {
            height: '100%',
            width: '100%'
        }
    },
    textLayerToDelete: {
        marginBottom: theme.spacing(2)
    }
}));

export default function DialogShowDXFLayers({ layers, open, handleClose, selectedLayers, setselectedLayers, gravedLayers, setgravedLayers, handleClickValidateDxf, loading, allowGraved = true, model }) {


    const classes = useStyles();

    const [editedModel, setEditedModel] = useState({ models: {}, paths: {} });
    const [svg, setsvg] = useState('');


    useEffect(() => {
        let svg = makerjs.exporter.toSVG(editedModel, { useSvgPathOnly: true });
        setsvg(svg);
    }, [editedModel]);


    useEffect(() => {
        setEditedModel({ ...model });
        let svg = makerjs.exporter.toSVG(model, { useSvgPathOnly: true });
        setsvg(svg);
    }, [model])


    useEffect(() => {

        let filtredModel = {
            ...model,
            paths: { ...model.paths },
            models: { ...model.models }
        };
        if (selectedLayers) {

            Object.keys(model.paths).forEach(key => {
                if (selectedLayers.includes(model.paths[key].layerName)) {
                    delete filtredModel.paths[key];
                }
            })

            Object.keys(model.models).forEach(key => {
                if (selectedLayers.includes(model.models[key].layerName)) {
                    delete filtredModel.models[key];
                }
            })

        }

        if (gravedLayers) {
            Object.keys(filtredModel.paths).forEach(key => {
                if (gravedLayers.includes(filtredModel.paths[key].layerName)) {
                    setLayerToModel(filtredModel.paths[key], 'green', filtredModel.paths[key].layerName)
                } else {
                    setLayerToModel(filtredModel.paths[key], 'black', filtredModel.paths[key].layerName)

                }
            })

            Object.keys(filtredModel.models).forEach(key => {
                if (gravedLayers.includes(filtredModel.models[key].layerName)) {
                    setLayerToModel(filtredModel.models[key], 'green', filtredModel.models[key].layerName)

                } else {
                    setLayerToModel(filtredModel.models[key], 'black', filtredModel.models[key].layerName)

                }
            })
        }
        setEditedModel({ ...filtredModel });

    }, [selectedLayers, gravedLayers])


    const handleClickRadio = useCallback((e, layer) => {
        if (e.target.checked) {
            setselectedLayers([...selectedLayers, layer])
            setgravedLayers(gravedLayers.filter(item => item !== layer))
        } else {
            setselectedLayers(selectedLayers.filter(item => item !== layer))
        }

    }, [gravedLayers, selectedLayers, setgravedLayers, setselectedLayers])

    const handleClickGraved = useCallback((e, layer) => {
        if (e.target.checked) {
            setgravedLayers([...gravedLayers, layer])
        } else {
            setgravedLayers(gravedLayers.filter(item => item !== layer))
        }

    }, [gravedLayers, setgravedLayers])


    const isButtonDisabled = () => selectedLayers.length >= layers.length || loading;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth={"lg"}
        >
            { loading && <LinearProgress /> }
            <DialogTitle>
                <Typography variant="h6" align='center' color='primary' gutterBottom>
                    Gestion des layers
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialog}>
                <Card raised={false} className={classes.root} elevation={0}>

                    <CardContent>
                        <Grid container justify='center'>
                            <Grid item xs={12} sm={8} className={classes.svgDisplayBox}>
                                <div className={classes.svg} dangerouslySetInnerHTML={{ __html: svg }} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Grid container justify='space-around'>
                                    <Grid item xs={12}>

                                        <TableContainer component={Paper} elevation={0}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nom du Layer</TableCell>
                                                        {allowGraved && <TableCell align="right">Gravure</TableCell>}
                                                        <TableCell align="right">Désactiver</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {layers.map((layer) => (
                                                        <TableRow key={layer}>
                                                            <TableCell style={{ padding: '0px, 1rem' }} component="th" scope="row">
                                                                {layer}
                                                            </TableCell>
                                                            {allowGraved && <TableCell style={{ padding: 0 }} align="right"><FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        disabled={selectedLayers.includes(layer)}
                                                                        checked={gravedLayers.includes(layer)}
                                                                        onChange={(e) => handleClickGraved(e, layer)}
                                                                        name="gravure"
                                                                        color="primary"
                                                                    />
                                                                }
                                                            /></TableCell>}
                                                            <TableCell style={{ padding: 0 }} align="right"><FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedLayers.includes(layer)}
                                                                        onChange={(e) => handleClickRadio(e, layer)}
                                                                        name="disable"
                                                                        color="primary"
                                                                    />
                                                                }
                                                            /></TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>

                    <CardActions className={classes.action}>
                        <Grid container justify='space-between'>
                            <Button variant="contained" color="primary" startIcon={loading && <CircularProgress color='white' size={15} />} onClick={() => handleClickValidateDxf(editedModel)} disabled={isButtonDisabled()}>Valider</Button>
                        </Grid>

                    </CardActions>
                </Card>
            </DialogContent>
        </Dialog>
    );
}
