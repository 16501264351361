import { Button, Grid, TextField } from '@material-ui/core'
import React, { useCallback, useState, useContext, useEffect } from 'react'
import configTriangle from '../../../assets/svg/configTriangle.svg'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'
import { PropertiesContext } from '../../../contexts/PropertiesContext'
import { getPointsOfTriangle } from '../../../modules/geo'

function ConfiguratorMainFormTriangle({ closeDialog }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [properties] = useContext(PropertiesContext);
    const [l1, setl1] = useState(configurator.mainForm.l1 || '');
    const [l2, setl2] = useState(configurator.mainForm.l2 || '');
    const [radius, setradius] = useState(configurator.mainForm.radius || '');

    const [error, seterror] = useState(false);
    const [errorText, seterrorText] = useState('');


    function canFit(A, B, maxA, maxB) {

        if ((A <= maxA && B <= maxB) || (A <= maxB && B <= maxA)) {
            return true;
        }

        if ((B <= maxA && A <= maxB) || (B <= maxB && A <= maxA)) {
            return true;
        }

        return false;
    }


    function canFitWithoutBeingTooSmall(A, B, minA, minB) {

        return (A >= minA && B >= minB) || (B >= minA && A >= minB);
    }

    useEffect(() => {
        if (l1 && l2 && radius) {

            const points = getPointsOfTriangle({ x: 0, y: 0 }, l1, l2, radius);
            const maxWidth = Math.max(Math.abs(points.a.x), Math.abs(points.b.x), Math.abs(points.c.x));
            const maxHeight = Math.max(Math.abs(points.a.y), Math.abs(points.b.y), Math.abs(points.c.y));

            const isTooLarge = !canFit(maxWidth, maxHeight, properties.machineMaxLength, properties.machineMaxWidth)
            const isTooSmall = !canFitWithoutBeingTooSmall(maxWidth, maxHeight, properties.machineMinLength, properties.machineMinWidth)
            if (isTooLarge) {
                seterror(true);
                seterrorText('Votre pièce est trop grande. Veuillez réduire les dimensions.');
            } else if (isTooSmall) {
                seterror(true);
                seterrorText('Votre pièce est trop petite. Veuillez augmenter les dimensions.');
            } else {
                // Si la pièce n'est ni trop grande ni trop petite, réinitialisez l'état d'erreur
                seterror(false);
                seterrorText('');
            }
        }
    }, [l1, l2, radius, properties.machineMaxLength, properties.machineMaxWidth, properties.machineMinWidth, properties.machineMinLength]);

    const handelChangel1 = (e) => {
        if (e.target.value.match(/^[0-9]*$/)) {
            seterror(false)
            seterrorText('')
            setl1(Number(e.target.value))
        }

    }

    const handelChangel2 = (e) => {

        if (e.target.value.match(/^[0-9]*$/)) {
            seterror(false)
            seterrorText('')
            setl2(Number(e.target.value))
        }

    }

    const handelChangeRadius = (e) => {
        if (e.target.value.match(/^[0-9]*$/)) {
            seterror(false)
            seterrorText('')
            setradius(Number(e.target.value))
        }

    }

    const handelClickvalidate = () => {

        const points = getPointsOfTriangle({ x: 0, y: 0 }, l1, l2, radius)
        const maxWidth = Math.max(Math.abs(points.a.x), Math.abs(points.b.x), Math.abs(points.c.x))
        const maxHeight = Math.max(Math.abs(points.a.y), Math.abs(points.b.y), Math.abs(points.c.y))

        dispatch({
            type: 'SET_VALUE',
            payload: {
                key: 'mainForm',
                data: {
                    ...configurator.mainForm, width: maxWidth, height: maxHeight, l1, l2, radius, configured: true
                }
            }
        })
        if (closeDialog) {
            closeDialog()
        }

    }

    return (
        <>
            <Grid container justify='flex-end'>
                <Grid item >
                    {closeDialog && <Button variant="outlined" color={'primary'} style={{ marginRight: '1rem' }} onClick={() => closeDialog()}>Fermer</Button>}

                    <Button disabled={!l1 || !l2 || !radius || error} color='primary' variant="contained" onClick={handelClickvalidate} >Valider</Button>
                </Grid>
            </Grid>
            <Grid container spacing={4} justify='center'>
                <Grid item>
                    <TextField label='L1' error={error} helperText={errorText || 'Longueur de L1 (mm)'} value={l1} onChange={handelChangel1} />
                </Grid>
                <Grid item>
                    <TextField label='L2' error={error} helperText={errorText || 'Longueur de L2 (mm)'} value={l2} onChange={handelChangel2} />
                </Grid>
                <Grid item>
                    <TextField label='Angle du sommet' error={error} helperText={errorText || "Angle (°)"} value={radius} onChange={handelChangeRadius} />
                </Grid>
            </Grid>
            <Grid container>
                <img alt='configuration rectangle' style={{ display: 'block', margin: '2rem auto', height: '300px', maxWidth: '300px' }} src={configTriangle} />
            </Grid>
        </>
    )
}

export default ConfiguratorMainFormTriangle
