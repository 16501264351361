import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ConfiguratorMenu from '../EndUser/Configurator/ConfiguratorMenu';
import { ConfiguratorContext } from '../../contexts/ConfiguratorContext';

function DialogConfigurationMenu({ open, setopenDialog, redirectToHome, isClosable, withoutThinckness }) {

    const [configurator] = useContext(ConfiguratorContext)

    const history = useHistory();

    const handleClose = () => {
        if (redirectToHome) {
            history.push('/');
            return;
        }
        setopenDialog(false)
    }

    const isButtonDisable = () => !configurator.materialId || !configurator.shadeId || (!configurator.thicknessId && !withoutThinckness)

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='sm'
            onClose={handleClose}
        >

            <DialogContent>
                <Typography align="center" color="primary" variant="h6">Choisissez votre matière</Typography>
                <ConfiguratorMenu withoutNbPiece withoutThinckness={withoutThinckness} />
            </DialogContent>
            <DialogActions>
                {(!process.env.REACT_APP_IFRAME_MODE || isClosable) && <Button disabled={isButtonDisable()} onClick={handleClose} variant="outlined" color="primary">
                    Fermer
                </Button>}

            </DialogActions>
        </Dialog>
    )
}

export default DialogConfigurationMenu
