import { Paper, TextField, Grid, Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useContext, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'

const useStyles = makeStyles((theme) => ({
    paper: {
        justifyContent: 'center',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem'
    },
    line: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1),
            textAlign: 'center'
        }
    }
}))

function ConfiguratorConfigText({ fontsList, setcurentDraggingObjectPosition, selectedForm }) {
    const [configurator, dispatch] = useContext(ConfiguratorContext);
    const [x, setx] = useState(null);
    const [y, sety] = useState(null);
    const [r, setr] = useState(null);
    const [text, settext] = useState(null);
    const [fontSize, setfontSize] = useState(null)

    const classes = useStyles();

    const handelChangeText = useCallback(
        (e) => {
            let value = null;

            value = e.target.value;

            settext(value);

            if (value !== '') {
                dispatch({
                    type: 'SET_TEXT_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            text: e.target.value
                        }
                    }

                })
            }
        },
        [configurator.selectedForm, dispatch],
    )

    const handelChangeFontSize = useCallback(
        (e) => {

            let value = null;
            if (e.target.value === '') {
                value = e.target.value;
            } else if (!isNaN(e.target.value)) {
                value = Number(e.target.value);
            }
            setfontSize(value);
            if ((value || value === 0) && !isNaN(value)) {
                dispatch({
                    type: 'SET_VALUE_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            fontSize: e.target.value
                        }
                    }

                })
            }


            ////////////////////

            /*             if (Number(e.target.value) >= 0) {
                            setfontSize(e.target.value);
                            //const selectedForm = configurator.forms.find(form => form.id === configurator.selectedForm)
                            dispatch({
                                type: 'SET_VALUE_FORM',
                                payload: {
                                    form: configurator.selectedForm,
                                    data: {
                                        fontSize: Math.ceil(Number(e.target.value) * configurator.ratio)
                                    }
                                }
            
                            })
            
                        } */

        },
        [configurator.selectedForm, dispatch],
    )

    const handelChangeFontFamily = useCallback(
        (e) => {
            dispatch({
                type: 'SET_VALUE_FORM',
                payload: {
                    form: configurator.selectedForm,
                    data: {
                        fontFamily: e.target.value
                    }
                }

            })
        },
        [configurator.selectedForm, dispatch],
    )

    const handelChangeX = useCallback(
        (e) => {
            let value = null;
            if (e.target.value === '') {
                value = e.target.value;
            } else if (!isNaN(e.target.value)) {
                value = Number(e.target.value);
            }
            setx(value);
            if ((value || value === 0) && !isNaN(value)) {
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            x: value
                        }
                    }

                })
                setcurentDraggingObjectPosition((v) => ({ ...v, x: e.target.value }))
            }


        },
        [configurator.selectedForm, dispatch, setcurentDraggingObjectPosition],
    )

    const handelChangeY = useCallback(
        (e) => {
            let value = null;
            if (e.target.value === '') {
                value = e.target.value;
            } else if (!isNaN(e.target.value)) {
                value = Number(e.target.value);
            }
            sety(value);
            if ((value || value === 0) && !isNaN(value)) {
                dispatch({
                    type: 'SET_POSITION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            y: value
                        }
                    }

                })
                setcurentDraggingObjectPosition((v) => ({ ...v, y: e.target.value }))
            }

        },
        [configurator.selectedForm, dispatch, setcurentDraggingObjectPosition],
    )

    const handelChangeRotation = (e) => setr(e.target.value)

    const handleBlurRotation = useCallback(() => {
        try {
            // eslint-disable-next-line no-eval
            const evaluateValue = eval(r);
            if (typeof evaluateValue === 'number') {
                dispatch({
                    type: 'SET_ROTATION_FORM',
                    payload: {
                        form: configurator.selectedForm,
                        data: {
                            rotation: evaluateValue
                        }
                    }

                })
            }
            setr(evaluateValue)
        } catch (e) {
            setr(null)
        }
    }, [configurator.selectedForm, dispatch, r])

    const handleChangeGraved = useCallback((e) => {
        dispatch({
            type: 'SET_VALUE_FORM',
            payload: {
                form: configurator.selectedForm,
                data: {
                    graved: e.target.checked,
                    comment: e.target.checked ? false : false,
                    unionType: e.target.checked ? 'del' : selectedForm.unionType

                }
            }
        })
    }, [configurator.selectedForm, dispatch, selectedForm.graved, selectedForm.unionType])

    const handleChangeComment = useCallback((e) => {
        dispatch({
            type: 'SET_VALUE_FORM',
            payload: {
                form: configurator.selectedForm,
                data: {
                    comment: e.target.checked,
                    graved: e.target.checked ? false : selectedForm.graved,
                    unionType: e.target.checked ? 'add' : selectedForm.unionType
                }
            }
        })
    }, [configurator.selectedForm, dispatch, selectedForm.graved, selectedForm.unionType])

    const getValueText = useCallback(
        () => {
            return text === '' ? text : selectedForm.text
        },
        [selectedForm.text, text],
    )

    const getValueFontSize = useCallback(() => {
        return fontSize === '' ? fontSize : selectedForm.fontSize
    }, [fontSize, selectedForm.fontSize])

    const getValueFontFamily = useCallback(
        () => {
            return selectedForm.fontFamily
        },
        [selectedForm.fontFamily],
    )

    const getValueX = useCallback(
        () => {
            return x === '' ? x : selectedForm.realx
        },
        [selectedForm.realx, x],
    )

    const getValueY = useCallback(
        () => {
            return y === '' ? y : selectedForm.realy
        },
        [selectedForm.realy, y],
    )

    const getValueRotation = useCallback(
        () => {
            return r === null ? selectedForm.rotation : r
        },
        [r, selectedForm.rotation],
    )

    return (

        <Grid container justify="center" alignItems='center'>

            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField multiline id="configurator-config-text-text" rows={1} label="Texte" value={getValueText()} margin="dense" onChange={handelChangeText} />
                </Paper>
            </Grid>

            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-text-fontSize" label="Taille (mm)" value={getValueFontSize()} margin="dense" onChange={handelChangeFontSize} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={2} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <FormControl fullWidth>

                        <InputLabel shrink id="configurator-config-text-fontFamilyLabel">
                            Police
                        </InputLabel>
                        <Select
                            id="configurator-config-text-fontFamily"
                            labelId='configurator-config-text-fontFamily'
                            value={getValueFontFamily()}
                            onChange={handelChangeFontFamily}
                            fullWidth
                        >
                            {fontsList.map(font => <MenuItem key={font.key} value={font.key}>{font.name}</MenuItem>)}

                        </Select>
                    </FormControl>
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-text-y" label="X (mm)" value={getValueX()} margin="dense" onChange={handelChangeX} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-text-x" label="Y (mm)" value={getValueY()} margin="dense" onChange={handelChangeY} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <TextField id="configurator-config-text-x" label="Rotation (°)" value={getValueRotation()} onKeyDown={(e) => (e.code === 'Enter' || e.code === 'NumpadEnter') && handleBlurRotation()} onBlur={handleBlurRotation} margin="dense" onChange={handelChangeRotation} />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedForm.graved}
                                onChange={handleChangeGraved}
                                name="graved"
                                color="primary"
                            />
                        }
                        label="Graver"
                        labelPlacement="top"
                    />
                </Paper>
            </Grid>
            <Grid item xs={5} md={2} lg={1} className={classes.line}>
                <Paper className={classes.paper} elevation={0}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedForm.comment}
                                onChange={handleChangeComment}
                                name="comment"
                                color="primary"
                            />
                        }
                        label="commentaire"
                        labelPlacement="top"
                    />
                </Paper>
            </Grid>
        </Grid>

    )
}

export default ConfiguratorConfigText
