import React, { useContext, useReducer } from 'react';
import { useGet, usePost, useDelete } from '../modules/request';

const CatalogSvgContext = React.createContext([[], () => { }]);

const initialState = {
    selectedCatalogSvg: null,
    catalogsSvg: [],
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_CATALOGS_SVG':
            return { ...state, catalogsSvg: action.payload }
        case 'ADD_CATALOG_SVG':
            return { ...state, catalogsSvg: [...state.catalogsSvg, { ...action.payload }] }
        case 'EDIT_CATALOG_SVG':
            return { ...state, catalogsSvg: [...state.catalogsSvg.map(catalog => catalog._id === action.payload.id ? action.payload.data : catalog)] }
        case 'DELETE_CATALOG_SVG':
            return { ...state, catalogsSvg: state.catalogsSvg.filter(catalog => catalog._id !== action.payload) }
        case 'RESET':
            return {
                selectedCatalogSvg: null,
                catalogsSvg: [],
            }
        default:
            throw new Error();
    }
};


const useGetCatalogSvg = () => {
    const [, dispatch] = useContext(CatalogSvgContext);
    const get = useGet()
    return {
        getCatalogSvg: async () => {

            const res = await get('/catalogSvg')
            dispatch({
                type: 'SET_CATALOGS_SVG',
                payload: res.data.message
            })
        },
    };
}

const usePostCatalogSvg = () => {
    const [, dispatch] = useContext(CatalogSvgContext);
    const post = usePost()
    return {
        postCatalogSvg: async (data, file = null, fileConfig = null) => {
            try {

                let formData = new FormData()
                formData.append('file', file)
                let uploadResponse = await post('/upload/image', formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                if (uploadResponse.status !== 201 || !uploadResponse.data.success) throw new Error('upload fail')
                let filename = uploadResponse.data.message
                data = { ...data, file: filename }

                const res = await post('/catalogSvg', data)

                dispatch({
                    type: 'ADD_CATALOG_SVG',
                    payload: res.data.message
                })
                if (res && res.status === 201) {
                    return res.data.message
                } else {
                    return false
                }
            } catch (e) {
                return false
            }
        },
    };
}


const useDeleteCatalogSvg = () => {
    const [, dispatch] = useContext(CatalogSvgContext);
    const del = useDelete()
    return {
        deleteCatalogSvg: async (id) => {
            try {
                const res = await del(`/catalogSvg/${id}`)
                if (res.status === 200) {
                    dispatch({
                        type: 'DELETE_CATALOG_SVG',
                        payload: id,
                    })
                    return true
                } else {
                    return false
                }

            } catch (e) {

            }
        },
    };
}


const CatalogSvgProvider = (props) => {
    const [catalogs, dispatch] = useReducer(reducer, initialState);



    return (
        <CatalogSvgContext.Provider value={[catalogs, dispatch]}>
            {props.children}
        </CatalogSvgContext.Provider>
    );
}

export { CatalogSvgContext, CatalogSvgProvider, useGetCatalogSvg, usePostCatalogSvg, useDeleteCatalogSvg };