import { useContext } from "react";
import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import { useGet } from "./request";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./../../src/modules/worker.js";
import paper from 'paper';
import { PropertiesContext } from "../contexts/PropertiesContext";
import { useMaterials } from "../contexts/MaterialsContext";

function useMakeCalcule() {
    const [configurator, dispatch] = useContext(ConfiguratorContext);
    const [properties] = useContext(PropertiesContext);
    const { getThickness } = useMaterials();
    const get = useGet()

    return async (textModels) => {

        dispatch({
            type: 'SET_VALUE',
            payload: {
                key: 'priceLoading',
                data: true
            }
        })

        const worker = new Worker()
        worker.postMessage({ type: 'CALCULATE', configurator, textModels });
        worker.onmessage = async (event) => {
            const { type } = event.data;
            if (type === 'GET_SVG_FROM_JSON') {
                paper.setup([640, 480]);
                paper.install(window);
                const { solutionJSON } = event.data;
                if (solutionJSON) {
                    const paperObject = paper.importJSON(solutionJSON);
                    const svg = paperObject.exportSVG({ asString: true });
                    const thickness = getThickness(configurator.materialId, configurator.shadeId, configurator.thicknessId)
                    worker.postMessage({ type: 'CALCULATE2', configurator, svg, textModels, formValidations: properties.formValidations, thickness: thickness?.value });
                }


            } else {
                const { nbChains, length, nbFolding, model, realWidth, realHeight, realSurface, validations } = event.data;
                dispatch({
                    type: 'SET_VALUES',
                    payload: {
                        data: { nbChains, length, nbFolding, model, realWidth, realHeight, realSurface, validations }
                    }
                })
                if (!validations.hasError) {
                    try {

                        let url = `/calculate?length=${length}&materialId=${configurator.materialId}&shadeId=${configurator.shadeId}&thicknessId=${configurator.thicknessId}&nbChains=${nbChains}&maxWidth=${realWidth}&maxHeight=${realHeight}&nbFolding=${configurator.nbFolding}&quantity=${configurator.nbPiece}&realSurface=${realSurface}&editId=${configurator.editId}`;
                        if (window.sessionId) {
                            url += '&sessionId=' + window.sessionId;
                        }
                        if (validations?.multipleChains?.nbChains) {
                            url += '&multipleChains=' + validations?.multipleChains?.nbChains;
                        }
                        const response = await get(url)


                        dispatch({
                            type: 'SET_VALUES',
                            payload: {
                                data: {
                                    price: response.data.message.price,
                                    unitPrice: response.data.message.unitPrice,
                                    weight: response.data.message.weight,
                                    realWeight: response.data.message.realWeight
                                }
                            }
                        })
                    } catch (e) {
                        worker.terminate()
                    }
                }
                dispatch({
                    type: 'SET_VALUE',
                    payload: {
                        key: 'priceLoading',
                        data: false
                    }
                })
                dispatch({
                    type: 'SET_VALUE',
                    payload: {
                        key: 'isManualCalcule',
                        data: false
                    }
                })
                worker.terminate()
            }


        }

    }

}

export { useMakeCalcule }