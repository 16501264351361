import React, { useCallback, useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import CatalogDxfProcessing from '../Control/CatalogDxfProcessing';
import { usePostCatalog } from '../../contexts/CatalogContext';

const useStyle = makeStyles((theme) => ({
    title: {
        background: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center'
    }
}))

function DialogProcessCatalogDxf({ open, handleClose, files }) {

    const { postCatalog } = usePostCatalog()
    const [nbFiles, setnbFiles] = useState(0);
    const [currentFile, setcurrentFile] = useState(0);
    const classes = useStyle()

    const [entityInformations, setentityInformations] = useState({});

    const handelClickNextFile = useCallback(
        () => {
            postCatalog({ ...entityInformations, entities: entityInformations.entities.map(({ _id, ...rest }) => rest) }, files[currentFile])
            //setentityInformations({})
            const nextFile = currentFile + 1
            if (nextFile >= nbFiles) {
                handleClose()
            } else {

                setcurrentFile(nextFile)
            }
        },
        [currentFile, entityInformations, files, handleClose, nbFiles, postCatalog],
    )

    useEffect(() => {
        setnbFiles(files.length)
    }, [files]);

    return (

        <Dialog fullScreen open={open} onClose={handleClose}>
            <DialogTitle id="add-to-catalog-dialog" class={classes.title}>Ajout d'une forme</DialogTitle>
            <DialogContent>
                <CatalogDxfProcessing entityInformations={entityInformations} setentityInformations={setentityInformations} files={files} currentFile={currentFile} nbFiles={nbFiles} handelClickNextFile={handelClickNextFile} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant='contained'>
                    Fermer
                </Button>
                <Button onClick={handelClickNextFile} color="primary" variant='contained'>
                    Valider
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default DialogProcessCatalogDxf
