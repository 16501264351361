import React, { useContext, useCallback } from 'react'
import { CatalogContext } from '../../../contexts/CatalogContext'
import CatalogImagePreview from '../../Control/CatalogImagePreview'

function CatalogdrawingPannelImage() {

    const [{ catalogs, selectedCatalog }] = useContext(CatalogContext)

    const getCatalog = useCallback(
        () => {
            const catalog = catalogs.find(catalog => catalog._id === selectedCatalog)
            if (catalog) return catalog.fileConfig
            return ''
        },
        [catalogs, selectedCatalog],
    )

    return (
        <div style={{ textAlign: 'center' }}>
            {getCatalog() ? <CatalogImagePreview image={getCatalog()} /> : null}
        </div>
    )
}

export default CatalogdrawingPannelImage
