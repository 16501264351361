import { Grid, LinearProgress, makeStyles, Paper } from '@material-ui/core'
import React, { useEffect, useState, useCallback, useContext } from 'react'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CartValidateChoiseDelivery from './CartValidateChoiseDelivery';
import { useGet } from '../modules/request';
import OrderData from './OrderData';
import OrderCheckOut from './OrderCheckOut';
import { BasketContext } from '../contexts/BasketContext';
import OrderResult from './OrderResult';
import { useHistory } from 'react-router';
import { usePostOrders } from '../contexts/OrderContext';
import { PropertiesContext } from '../contexts/PropertiesContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    marginBottom: theme.spacing(4)
  },
  stepContent: {

  },
  actionButtons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
}));

function getSteps() {
  return ['Méthode de livraison', 'Récapitulatif', 'Paiement de votre commande', 'Finalisation'];
}




function CartValidate() {
  const classes = useStyles()
  const get = useGet()
  const [property, dispatchProperty] = useContext(PropertiesContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [overSizeForTransport, setOverSizeForTransport] = useState(false);

  const [me, setme] = useState({});
  const [data, setdata] = useState({
    deliveryMethode: null,
    deliveryPrice: null,
    deliveryTime: null
  });
  const [paymentResult, setpaymentResult] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [baskets] = useContext(BasketContext);
  const { postOrder } = usePostOrders()
  // eslint-disable-next-line no-unused-vars
  const [, setmeLoading] = useState(false);
  const steps = getSteps();

  const getTotalPrice = useCallback(
    () => {
      let total = 0
      baskets.forEach(item => total += item.price)
      return Number(total.toFixed(2))
    },
    [baskets],
  )
  const vatRate = Number(property.vatRate);

  useEffect(() => {
    for (const basket of baskets) {
      if (+basket.maxHeight > property.transportMaxLength && +basket.maxHeight > property.transportMaxWidth) {
        setOverSizeForTransport(true);
      } else if (+basket.maxWidth > property.transportMaxWidth && +basket.maxWidth > property.transportMaxLength) {
        setOverSizeForTransport(true)
      }
    }
  }, [baskets])

  useEffect(
    () => {
      (async () => {
        if (data.deliveryMethode === 'delivery' && !overSizeForTransport) {
          setLoading(true)
          try {
            let baseUrl = '/calculate/shiping'
            if (data.deliveryTime) {
              baseUrl += '?deliveryTimeId=' + data.deliveryTime
            }
            const res = await get(baseUrl)

            setdata({ ...data, deliveryPrice: res.data.message })
            setLoading(false)
            return true
          } catch (e) {
            setLoading(false)
            return false
          }
        } else {
          setdata({ ...data, deliveryPrice: null })
        }

      })()
    },
    [data.deliveryMethode, data.deliveryTime, overSizeForTransport],
  )
  const history = useHistory()

  const getStepContent = useCallback(
    (stepIndex) => {
      switch (stepIndex) {
        case 0:
          return <CartValidateChoiseDelivery me={me} data={data} setdata={setdata} setme={setme} loading={loading} overSizeForTransport={overSizeForTransport} />
        case 1:
          return <OrderData me={me} data={data} getTotalPrice={getTotalPrice} vatRate={vatRate} />;
        case 2:
          return <OrderCheckOut getTotalPrice={getTotalPrice} setpaymentResult={setpaymentResult} setActiveStep={setActiveStep} paymentResult={paymentResult} me={me} data={data} />
        case 3:
          return <OrderResult paymentResult={paymentResult} />
        default:
          return 'Unknown stepIndex';
      }
    },
    [data, getTotalPrice, loading, me, overSizeForTransport, paymentResult, vatRate],
  )

  useEffect(() => {

    if (property?.transportEnabled && !overSizeForTransport) {
      const firstDeliveryTime = (property.deliveryTimes?.length && property.deliveryTimes[0]._id) || 'toConvent'
      setdata({ ...data, deliveryMethode: 'delivery', deliveryTime: firstDeliveryTime })
    } else if (property?.pickUpAllowed) {
      setdata({ ...data, deliveryMethode: 'pickup', deliveryTime: null })
    }
  }, [overSizeForTransport, property.deliveryTimes, property?.pickUpAllowed, property?.transportEnabled])

  useEffect(() => {
    (async () => {
      setmeLoading(true)
      try {
        const res = await get('/user/me')
        setme(res.data.message)
      } catch (e) {

      }
      setmeLoading(false)

    })()
  }, []);

  const handleNext = async () => {
    if (activeStep === 3) {
      history.push('/')
    } else if (activeStep === 2 && me.idExtern) {
      let clientDeliveryData = {}
      if (me.isPro) {
        clientDeliveryData = {
          deliveryHouseNumber: me.pro.deliveryHouseNumber,
          deliveryStreet: me.pro.deliveryStreet,
          deliveryCity: me.pro.deliveryCity,
          deliveryZipCode: me.pro.deliveryZipCode,
          deliveryMethode: data.deliveryMethode
        }
      } else {
        clientDeliveryData = {
          houseNumber: me.houseNumber,
          street: me.street,
          city: me.city,
          deliveryZipCode: me.deliveryZipCode,
          deliveryMethode: data.deliveryMethode

        }
      }
      try {
        const postOrderRes = await postOrder({ clientDeliveryData: { ...clientDeliveryData, lastName: me.lastName, firstName: me.firstName } })
        if (!postOrderRes) {
          throw new Error('Une erreur c\'est produite')
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (e) {
        console.log('err')
      }

    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const disabledButton = useCallback(
    () => {
      if (activeStep === 0) {
        return !data.deliveryMethode || data.error
      }

      if (activeStep === 1 && baskets.length <= 0) {
        return true;
      }
    },
    [activeStep, baskets.length, data.deliveryMethode, data.error],
  )

  return (
    <Grid container justify="center" className={classes.root} >

      <Grid item xs={12} sm={10}>

        <div className={classes.root}>
          <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>All steps completed</Typography>
                <Button onClick={handleReset}>Recommencer</Button>
              </div>
            ) : (
              <div>
                <div className={classes.stepContent}>{getStepContent(activeStep)}</div>
                <Paper elevation={0} className={classes.actionButtons}>
                  <Grid container justify='flex-end' >
                    {
                      activeStep < 3 ?
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          color='secondary'
                          className={classes.backButton}
                          variant='outlined'
                        >
                          Retour
                        </Button>
                        : null
                    }

                    {
                      activeStep !== 2 || me.idExtern ?
                        <Button variant="contained" color="primary" onClick={handleNext} disabled={disabledButton()}>
                          {activeStep === steps.length - 1 ? 'Terminer' : 'Suivant'}
                        </Button>
                        : null
                    }

                  </Grid>

                </Paper>
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default CartValidate
