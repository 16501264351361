import React, { useCallback, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ConfiguratorMenuMaterialSector from './ConfiguratorMenuMaterialSector';
import ConfigurationManuShadeSelector from './ConfigurationManuShadeSelector';
import ConfigurationMenuThicknessSelector from './ConfigurationMenuThicknessSelector';
import { useHistory } from 'react-router';
import { CloudUpload, Settings } from '@material-ui/icons';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';

import ConfiguratorMenuNumberPiece from './ConfiguratorMenuNumberPiece';
import DropDXF from '../../DropDXF';

import { PropertiesContext } from '../../../contexts/PropertiesContext';

import { CircularProgress, Grid } from '@material-ui/core';
import DropSTEP from '../../DropSTEP';

import dxfIcon from '../../../assets/svg/dxf-icon.svg'
import drawindIcon from '../../../assets/svg/drawing-icon.svg'
import foldIcon from '../../../assets/svg/fold-icon.svg'
import stepIcon from '../../../assets/svg/step-icon.svg'


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: '6px',
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    position: 'relative'

  },
  stepper: {
    borderRadius: '6px'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonGreen: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: '#00ad0a',
    color: 'white'
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  buttonConfigurator: {
    height: '125px',
    //background: theme.palette.primary.main,
    //color: 'white',
    width: '100%',
    fontSize: '1.2rem',
    '& .MuiButton-label': {
      display: 'block'
    }
  },
  buttonDropDXF: {
    height: '125px',
    //background: theme.palette.secondary.main,
    //color: 'white',
    width: '100%',
    fontSize: '1.2rem'
  },
  buttonContainer: {
    display: 'flex'
  }
}));


function ConfiguratorMenuStepper() {

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedMenu, setselectedMenu] = useState(null);
  const [startDXF, setstartDXF] = useState(false);
  const [startIMG, setstartIMG] = useState(false);
  const [startSTEP, setStartSTEP] = useState(false);
  const steps = getSteps();
  const history = useHistory()
  const [configurator, dispatch] = useContext(ConfiguratorContext)
  const [dxfLoading, setdxfLoading] = useState(false);
  const [properties] = useContext(PropertiesContext);
  const [stepLoading, setStepLoading] = useState(false)



  const handleClickValidate = useCallback(
    () => {
      if (selectedMenu === 'CONFIGURATOR') {
        history.push('/configurator')
      } else if (selectedMenu === 'FROMDXF') {
        setstartDXF(true)
      } else if (selectedMenu === 'FROMIMG') {
        setstartIMG(true)
      } else if (selectedMenu === 'FROMSTEP') {
        setStartSTEP(true)
      }
    },
    [history, selectedMenu],
  )

  const handleNext = (selectedMenu = null) => {
    setselectedMenu(selectedMenu)
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const disableButton = () => {
    if (dxfLoading || activeStep === 0) {
      return true;
    }

    if (activeStep === 1) {
      const hasMaterial = Boolean(configurator.materialId);
      const hasShade = Boolean(configurator.shadeId);
      const requiresThickness = selectedMenu !== 'FROMSTEP';
      const hasThickness = Boolean(configurator.thicknessId);
      const hasPiece = Boolean(configurator.nbPiece);

      return !(hasMaterial && hasShade && (!requiresThickness || hasThickness) && hasPiece);
    }

    return null;
  };

  function getSteps() {
    return ['Définir la forme de votre pièce', 'Propriétés de votre pièce'];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <>

          <Grid container spacing={2} alignItems="center" style={{ marginTop: '1rem' }}>
            <Grid item xs={12} sm={6}>
              <Button onClick={() => handleNext('CONFIGURATOR')} color='primary' className={classes.buttonConfigurator} variant="outlined" startIcon={<img src={drawindIcon} style={{ height: '50px' }} />}>Dessiner votre pièce</Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button onClick={() => handleNext('FROMDXF')} className={classes.buttonConfigurator} color="secondary" variant="outlined" startIcon={<img src={dxfIcon} style={{ height: '50px' }} />} >Utiliser vos DXF</Button>
            </Grid>
            {!process.env.REACT_APP_NO_STEP && <Grid item xs={12} sm={6}>
              <Button onClick={() => handleNext('FROMSTEP')} color='secondary' className={classes.buttonConfigurator} variant="outlined" startIcon={<img src={stepIcon} style={{ height: '50px' }} />}>Utiliser vos STEP</Button>
            </Grid>}
            {
              properties.foldingEnabled &&
              <Grid item xs={12} sm={6}>
                <Button onClick={() => history.push('/catalog')} color='primary' className={classes.buttonConfigurator} variant="outlined" startIcon={<img src={foldIcon} style={{ height: '50px' }} />}>Créer une pièce pliée</Button>
              </Grid>
            }

          </Grid>

        </>;
      case 1:
        return <>
          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <ConfiguratorMenuMaterialSector />
            <ConfigurationManuShadeSelector />
            {selectedMenu !== 'FROMSTEP' && <ConfigurationMenuThicknessSelector />}
          </div>
        </>;

      default:
        return 'Unknown step';
    }
  }

  return (
    <div className={classes.root}>

      <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>

              <div className={classes.actionsContainer}>
                <div>
                  {
                    activeStep !== 0 && <>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                        color="primary"
                        variant="outlined"
                        startIcon={<KeyboardArrowUpIcon />}
                      >
                        Retour
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickValidate}
                        className={classes.button}
                        endIcon={<KeyboardArrowRightIcon />}
                        disabled={disableButton()}
                      >
                        {dxfLoading && <CircularProgress size={20} disableShrink />}
                        {activeStep === steps.length - 1 ? 'Valider' : 'Terminer'}
                      </Button>
                    </>
                  }

                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>


      <DropDXF setdxfLoading={setdxfLoading} setActiveStep={setActiveStep} startDXF={startDXF} setstartDXF={setstartDXF} />
      <DropSTEP setStepLoading={setStepLoading} setActiveStep={setActiveStep} startSTEP={startSTEP} setStartSTEP={setStartSTEP} />
    </div>
  )
}

export default ConfiguratorMenuStepper
