import { Paper, TextField, Grid } from '@material-ui/core'
import React, { useCallback, useContext } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'

function ConfiguratorConfigFoldingLine({ setcurentDraggingObjectPosition }) {
    const [configurator, dispatch] = useContext(ConfiguratorContext)



    const handelChangeX = useCallback(
        (e) => {

            dispatch({
                type: 'SET_POSITION_FORM',
                payload: {
                    form: configurator.selectedForm,
                    data: {
                        x: e.target.value
                    }
                }

            })
            setcurentDraggingObjectPosition((v) => ({ ...v, x: e.target.value }))
            dispatch({
                type: 'SET_ABS_POSITION_FORM',
                payload: {
                    form: configurator.selectedForm,
                    data: {
                        x: (e.target.value * configurator.ratio) + configurator.mainFormX
                    }
                }

            })



        },
        [configurator.mainFormX, configurator.ratio, configurator.selectedForm, dispatch],
    )

    const handelChangeY = useCallback(
        (e) => {

            dispatch({
                type: 'SET_POSITION_FORM',
                payload: {
                    form: configurator.selectedForm,
                    data: {
                        y: e.target.value
                    }
                }

            })
            setcurentDraggingObjectPosition((v) => ({ ...v, y: e.target.value }))
            dispatch({
                type: 'SET_ABS_POSITION_FORM',
                payload: {
                    form: configurator.selectedForm,
                    data: {
                        y: (e.target.value * configurator.ratio) + configurator.mainFormY
                    }
                }

            })



        },
        [configurator.mainFormY, configurator.ratio, configurator.selectedForm, dispatch],
    )

    const handelChangeRotation = useCallback(
        (e) => {


            dispatch({
                type: 'SET_ROTATION_FORM',
                payload: {
                    form: configurator.selectedForm,
                    data: {
                        rotation: e.target.value
                    }
                }

            })
        },
        [configurator.selectedForm, dispatch],
    )


    const getValueX = useCallback(
        () => {
            return configurator.forms.find(form => form.id === configurator.selectedForm).realx
        },
        [configurator.forms, configurator.selectedForm],
    )

    const getValueY = useCallback(
        () => {
            return configurator.forms.find(form => form.id === configurator.selectedForm).realy
        },
        [configurator.forms, configurator.selectedForm],
    )

    const getValueRotation = useCallback(
        () => {
            return configurator.forms.find(form => form.id === configurator.selectedForm).rotation
        },
        [configurator.forms, configurator.selectedForm],
    )

    return (
        <Grid container spacing={2} alignItems='center' wrap='nowrap'>

            <Grid item>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField id="configurator-config-circle-y" type="number" label="X (mm)" value={getValueX()} margin="dense" onChange={handelChangeX} />
                </Paper>
            </Grid>

            <Grid item>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField id="configurator-config-circle-x" type="number" label="Y (mm)" value={getValueY()} margin="dense" onChange={handelChangeY} />
                </Paper>
            </Grid>
            <Grid item>
                <Paper style={{ padding: '0.5rem 1rem' }} elevation={0}>
                    <TextField id="configurator-config-circle-x" type="number" label="Rotation (Deg)" value={getValueRotation()} margin="dense" onChange={handelChangeRotation} />
                </Paper>
            </Grid>
        </Grid>


    )
}

export default ConfiguratorConfigFoldingLine
