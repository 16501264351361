import { Paper, makeStyles, Typography, Grid, Tooltip, Button, CircularProgress } from '@material-ui/core'
import { UserPasswordSchemas } from '../modules/validation'

import React, { useState } from 'react'
import TextFieldValidation from './TextFieldValidation';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { useResetPasswordUser } from '../contexts/UserContext';
import { useParams, useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(5),
        boxShadow: 'rgb(17 12 46 / 15%) 0px 48px 100px 0px',
        borderRadius: '6px',
        position: 'relative'
    }
}))


function ForgottenPasswordReset() {

    const classes = useStyles();

    const [password, setpassword] = useState('');
    const [password2, setpassword2] = useState('');
    const [different, setdifferent] = useState(false);
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)
    const history = useHistory()
    const { resetPasswordUser } = useResetPasswordUser()
    const { token } = useParams();


    const handleSubmit = async () => {
        if (password === password2) {
            setLoading(true)
            const success = await resetPasswordUser(token, password)
            setSuccess(!!success)

        } else {
            setdifferent(true)
        }
        setLoading(false)
    }

    const handleChange1 = (e) => {
        if (different) {
            setdifferent(false)
        }
        setpassword(e.target.value)
    }

    const handleChange2 = (e) => {
        if (different) {
            setdifferent(false)
        }
        setpassword2(e.target.value)
    }

    const passwordRules = <ul>
        <li>8 à 15 caractères</li>
        <li>au moins une lettre minuscule</li>
        <li>au moins une lettre majuscule</li>
        <li>au moins un chiffre</li>
        <li>au moins un de ces caractères spéciaux: $ @ % * + - _ !</li>
    </ul>

    return (
        <Grid container justify='center' className={classes.root}>
            <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} elevation={0}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography align='center' variant="h5" color='primary' gutterBottom>
                                Changer votre mot de passe
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align='center' variant="button" gutterBottom>
                                Entrez un nouveau mot de passe
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='center' spacing={4}>
                                <Grid item xs={12} sm={5}>
                                    <TextFieldValidation fullWidth type='password' label={<div style={{ display: 'flex', alignItems: 'center' }}>Nouveau mot de passe * <Tooltip placement="top" title={passwordRules}><LiveHelpIcon style={{ marginLeft: '5px', fontSize: 12 }} /></Tooltip></div>} yupValidation={UserPasswordSchemas} onChange={handleChange1} value={password} />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextFieldValidation fullWidth type='password' label={<div style={{ display: 'flex', alignItems: 'center' }}>Confirmation * <Tooltip placement="top" title={passwordRules}><LiveHelpIcon style={{ marginLeft: '5px', fontSize: 12 }} /></Tooltip></div>} yupValidation={UserPasswordSchemas} onChange={handleChange2} value={password2} />

                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container justify='flex-end' alignItems='center'>
                            {success === null &&
                                <>
                                    <Grid item xs={12} sm={4}>
                                        {different && <Typography color='error' variant="body2" gutterBottom>
                                            Les mots de passes ne correpondent pas.
                                        </Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button onClick={handleSubmit} color='primary' variant='contained' startIcon={loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : null}> Changer le mot de passe</Button>
                                    </Grid>
                                </>
                            }
                            {success === true &&
                                <>
                                    <Grid item xs={12} sm={8} >
                                        <Typography style={{ color: 'green' }} variant="body2" gutterBottom>
                                            Mot de passe modifié, vous pouvez maintenant vous connecter avec votre nouveau mot de passe
                                        </Typography></Grid>
                                    <Grid item xs={12} sm={2}><Button color='primary' variant='contained' onClick={() => history.push('/')}>retour</Button>
                                    </Grid>
                                </>
                            }
                            {success === false &&
                                <>
                                    <Grid item xs={12} sm={8}>
                                        <Typography color='error' variant="body2" gutterBottom>
                                            Une erreure c'est produite, merci de re-tenter ultérieurement.
                                        </Typography></Grid>
                                    <Grid item xs={12} sm={2}><Button color='primary' variant='contained' onClick={() => history.push('/')}>retour</Button>
                                    </Grid>
                                </>}
                        </Grid>
                    </Grid>



                </Paper>

            </Grid>
        </Grid>
    )
}

export default ForgottenPasswordReset