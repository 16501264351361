import { Grid, TableCell, CircularProgress } from '@material-ui/core'
import React, { useState, useCallback } from 'react'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldValidation from './TextFieldValidation'

function TableCellEditable({ icon, children, onCickEdited, value, onChange, yupValidation, align, justify, className, style }) {
    const [editing, setediting] = useState(false);
    const [loading, setloading] = useState(false)

    const handelClickEditing = useCallback(
        () => {
            setediting(!editing)
        },
        [editing],
    )

    const onEdit = useCallback(
        (e) => {
            (async () => {
                setloading(true)
                try {
                    await yupValidation.validate({ value })
                    await onCickEdited()
                } catch (e) {

                }
                setloading(false)
                setediting(false)
            })()

        },
        [onCickEdited, yupValidation, value],
    )

    return (
        <TableCell style={style} className={className} align={align || "center"} scope="row">
            <Grid container direction="row" style={{ flexWrap: 'nowrap' }} justify={justify || "center"} alignItems="center">
                {editing ? <TextFieldValidation label={!editing ? children : value} value={value} onChange={onChange} yupValidation={yupValidation}></TextFieldValidation> : children}
                {loading ? <CircularProgress size={20} /> : editing ? <><CheckIcon fontSize='small' onClick={onEdit} />  <CloseIcon fontSize='small' onClick={handelClickEditing} /></> : <div onClick={handelClickEditing}>{icon} </div>}
            </Grid>
        </TableCell>
    )
}

export default TableCellEditable
