import React, { useEffect } from 'react';



function ProcessUploadedRaw({ file, onValidate }) {


    useEffect(() => {
        onValidate({ file })
    }, [file, onValidate]);

    return (<></>)
}

export default ProcessUploadedRaw