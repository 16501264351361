import React, { useState, useContext, useEffect, useCallback } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, CircularProgress, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { ContactModelsContext, useEditContactModels, useCreateContactModels } from '../../contexts/ContactModelsContext';

function ContactModelEditor({ modelName }) {

    const [contactModelEdited, setcontactModelEdited] = useState();
    const [loading, setLoading] = useState(false);

    const [subjectError, setSubjectError] = useState(false)
    const { editContactModel } = useEditContactModels();
    const { createContactModel } = useCreateContactModels()
    const [contactModels] = useContext(ContactModelsContext);



    const handleSave = useCallback(() => {

        (async () => {
            if (!subjectError) {
                setLoading(true)
                if (!contactModelEdited._id) {
                    await createContactModel(contactModelEdited, { ...contactModelEdited, name: modelName })
                } else {
                    await editContactModel(contactModelEdited._id, { html: contactModelEdited.html, subject: contactModelEdited.subject })

                }
                setLoading(false)
            }
        })()
    }, [contactModelEdited, createContactModel, editContactModel, modelName, subjectError])

    const handleSubjectError = (isError) => {
        setSubjectError(isError)
    }

    const handelChangeSubject = (e) => {
        setcontactModelEdited({ ...contactModelEdited, subject: e.target.value })
    }

    const getTitle = () => {
        switch (modelName) {
            case 'OrderRegistrationCLient':
                return 'Modèle mail pour validation de commande';
            case 'OrderPaimentEngaged':
                return 'Modèle mail pour engagement de paiement';
            default:
                return ''
        }
    }

    useEffect(() => {
        if (contactModels && contactModels.length > 0) {
            const contactModel = contactModels.find(contactModel => contactModel.name === modelName)
            if (contactModel) {
                setcontactModelEdited({ ...contactModel })
            }
        }

    }, [contactModels, modelName]);
    return (
        <>
            <Grid container justify='center' style={{ margin: '2rem 0' }}>
                <Grid item xs={4}>
                    <Typography variant='h6' color='primary'>{getTitle()}</Typography>
                </Grid>
            </Grid>
            <Grid container justify='center'>
                <Grid item xs={3}>
                    <TextField type="text" onError={handleSubjectError} label="Sujet du mail" onChange={handelChangeSubject} value={contactModelEdited && contactModelEdited.subject} fullWidth />
                </Grid>
            </Grid>
            <Divider style={{ margin: '2rem 0' }} />
            <Grid container justify='center'>
                <Grid item xs={8}>
                    <CKEditor
                        editor={ClassicEditor}
                        data={contactModelEdited && contactModelEdited.html}
                        onChange={(event, editor) => {
                            if (contactModelEdited) {
                                const html = editor.getData();
                                setcontactModelEdited({ ...contactModelEdited, html })
                            }
                        }}
                    />

                </Grid>
            </Grid>
            <Grid container justify='flex-end' style={{ marginTop: '1rem' }}>
                <Grid item >
                    <Button onClick={handleSave} variant='contained' color='primary' startIcon={loading && <CircularProgress size={20} style={{ color: 'white' }} />}> Enregistrer</Button>
                </Grid>
                <Grid item xs={2} />
            </Grid>
        </>
    )
}

export default ContactModelEditor