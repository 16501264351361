import React from 'react'


function CatalogMenuForm({ catalogsToDisplay }) {

    return (
        <div>
            {catalogsToDisplay}
        </div>
    )
}

export default CatalogMenuForm
