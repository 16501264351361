import { Button, Divider, Grid, TextField } from '@material-ui/core'
import React, { useCallback, useState, useContext } from 'react'
import configL from '../../../assets/svg/configL.svg'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'
import { PropertiesContext } from '../../../contexts/PropertiesContext'
import ConfiguratorMainFormRecBorder from './ConfiguratorMainFormRecBorder'

function ConfiguratorMainFormL({ displayAsColumn, closeDialog }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [properties, dispatchProperties] = useContext(PropertiesContext);
    const [a, seta] = useState(configurator.mainForm.a);
    const [b, setb] = useState(configurator.mainForm.b);
    const [c, setc] = useState(configurator.mainForm.c);
    const [d, setd] = useState(configurator.mainForm.d);
    const [errorA, seterrorA] = useState(false);
    const [errorAText, seterrorAText] = useState('');
    const [errorB, seterrorB] = useState(false);
    const [errorBText, seterrorBText] = useState('');
    const [errorC, seterrorC] = useState(false);
    const [errorCText, seterrorCText] = useState('');
    const [errorD, seterrorD] = useState(false);
    const [errorDText, seterrorDText] = useState('');
    const [defaultValue, setdefaultValue] = useState(null);
    const [borderConfigurations, setborderConfigurations] = useState({
        tl: {
            isRadiusEnabled: configurator.mainForm?.borderConfigurations?.tl?.isRadiusEnabled || false,
            isChanfreinEnabled: configurator.mainForm?.borderConfigurations?.tl?.isChanfreinEnabled || false,
            isInner: configurator.mainForm?.borderConfigurations?.tl?.isInner || false,
            isOutside: configurator.mainForm?.borderConfigurations?.tl?.isInner ? false : configurator.mainForm?.borderConfigurations?.tl?.isOutside || true,
            radius: configurator.mainForm?.borderConfigurations?.tl?.radius || 0,
            error: false,
            errorText: ''
        },
        tr: {
            isRadiusEnabled: configurator.mainForm?.borderConfigurations?.tr?.isRadiusEnabled || false,
            isChanfreinEnabled: configurator.mainForm?.borderConfigurations?.tr?.isChanfreinEnabled || false,
            isInner: configurator.mainForm?.borderConfigurations?.tr?.isInner || false,
            isOutside: configurator.mainForm?.borderConfigurations?.tr?.isInner ? false : configurator.mainForm?.borderConfigurations?.tr?.isOutside || true,
            radius: configurator.mainForm?.borderConfigurations?.tr?.radius || 0,
            error: false,
            errorText: ''
        },
        mr: {
            isRadiusEnabled: configurator.mainForm?.borderConfigurations?.mr?.isRadiusEnabled || false,
            isChanfreinEnabled: configurator.mainForm?.borderConfigurations?.mr?.isChanfreinEnabled || false,
            isInner: configurator.mainForm?.borderConfigurations?.mr?.isInner || false,
            isOutside: configurator.mainForm?.borderConfigurations?.mr?.isInner ? false : configurator.mainForm?.borderConfigurations?.mr?.isOutside || true,
            radius: configurator.mainForm?.borderConfigurations?.mr?.radius || 0,
            error: false,
            errorText: ''
        },
        ml: {
            isRadiusEnabled: configurator.mainForm?.borderConfigurations?.ml?.isRadiusEnabled || false,
            isChanfreinEnabled: configurator.mainForm?.borderConfigurations?.ml?.isChanfreinEnabled || false,
            isInner: configurator.mainForm?.borderConfigurations?.ml?.isInner || false,
            isOutside: configurator.mainForm?.borderConfigurations?.ml?.isInner ? false : configurator.mainForm?.borderConfigurations?.ml?.isOutside || true,
            radius: configurator.mainForm?.borderConfigurations?.ml?.radius || 0,
            error: false,
            errorText: ''
        },
        bl: {
            isRadiusEnabled: configurator.mainForm?.borderConfigurations?.bl?.isRadiusEnabled || false,
            isChanfreinEnabled: configurator.mainForm?.borderConfigurations?.bl?.isChanfreinEnabled || false,
            isInner: configurator.mainForm?.borderConfigurations?.bl?.isInner || false,
            isOutside: configurator.mainForm?.borderConfigurations?.bl?.isInner ? false : configurator.mainForm?.borderConfigurations?.bl?.isOutside || true,
            radius: configurator.mainForm?.borderConfigurations?.bl?.radius || 0,
            error: false,
            errorText: ''
        },
        br: {
            isRadiusEnabled: configurator.mainForm?.borderConfigurations?.br?.isRadiusEnabled || false,
            isChanfreinEnabled: configurator.mainForm?.borderConfigurations?.br?.isChanfreinEnabled || false,
            isInner: configurator.mainForm?.borderConfigurations?.br?.isInner || false,
            isOutside: configurator.mainForm?.borderConfigurations?.br?.isInner ? false : configurator.mainForm?.borderConfigurations?.br?.isOutside || true,
            radius: configurator.mainForm?.borderConfigurations?.br?.radius || 0,
            error: false,
            errorText: ''
        }

    });

    const setborderConfiguration = useCallback((data, border) => {
        setborderConfigurations({ ...borderConfigurations, [border]: data })
    }, [borderConfigurations])

    const handelChangeA = useCallback(
        (e) => {
            if (e.target.value.match(/^[0-9]*$/)) {
                seterrorA(false)
                seterrorAText('')
                if (Number(e.target.value) < properties.machineMinWidth || Number(e.target.value) > properties.machineMaxWidth) {
                    seterrorAText(`valeur comprisent entre ${properties.machineMinWidth} et ${properties.machineMaxWidth}`)
                    seterrorA(true)
                }
                seta(Number(e.target.value));
            }

        },
        [properties.machineMaxWidth, properties.machineMinWidth],


    )

    const handelChangeB = useCallback(
        (e) => {
            if (e.target.value.match(/^[0-9]*$/)) {
                seterrorB(false)
                seterrorBText('')
                if (Number(e.target.value) < properties.machineMinLength || Number(e.target.value) > properties.machineMaxLength) {
                    seterrorBText(`valeur comprisent entre ${properties.machineMinLength} et ${properties.machineMaxLength}`)
                    seterrorB(true)
                }
                setb(Number(e.target.value));
            }

        },
        [properties.machineMaxLength, properties.machineMinLength],
    )


    const handelChangeC = useCallback(
        (e) => {
            if (e.target.value.match(/^[0-9]*$/)) {
                seterrorC(false)
                seterrorCText('')
                if (Number(e.target.value) < properties.machineMinWidth - 1 || Number(e.target.value) > properties.machineMaxWidth - 1) {
                    seterrorCText(`valeur comprisent entre ${properties.machineMinWidth - 1} et ${properties.machineMaxWidth - 1}`)
                    seterrorC(true)
                }
                setc(Number(e.target.value));
            }

        },
        [properties.machineMaxWidth, properties.machineMinWidth],
    )

    const handelChangeD = useCallback(
        (e) => {
            if (e.target.value.match(/^[0-9]*$/)) {
                seterrorD(false)
                seterrorDText('')
                if (Number(e.target.value) < properties.machineMinLength - 1 || Number(e.target.value) > properties.machineMaxLength - 1) {
                    seterrorDText(`valeur comprisent entre ${properties.machineMinLength - 1} et ${properties.machineMaxLength - 1}`)
                    seterrorD(true)
                }
                setd(Number(e.target.value));
            }


        },
        [properties.machineMaxLength, properties.machineMinLength],
    )

    const handelClickvalidate = useCallback(
        () => {
            dispatch({
                type: 'SET_VALUE',
                payload: {
                    key: 'mainForm',
                    data: {
                        ...configurator.mainForm,
                        configured: true,
                        width: a,
                        height: b,
                        a,
                        b,
                        c,
                        d,
                        borderConfigurations: {
                            tl: {
                                isRadiusEnabled: borderConfigurations.tl.isRadiusEnabled,
                                isChanfreinEnabled: borderConfigurations.tl.isChanfreinEnabled,
                                radius: borderConfigurations.tl.radius,
                                isInner: borderConfigurations.tl.isInner,
                                isOutside: borderConfigurations.tl.isOutside
                            },
                            tr: {
                                isRadiusEnabled: borderConfigurations.tr.isRadiusEnabled,
                                isChanfreinEnabled: borderConfigurations.tr.isChanfreinEnabled,
                                radius: borderConfigurations.tr.radius,
                                isInner: borderConfigurations.tr.isInner,
                                isOutside: borderConfigurations.tr.isOutside
                            },
                            mr: {
                                isRadiusEnabled: borderConfigurations.mr.isRadiusEnabled,
                                isChanfreinEnabled: borderConfigurations.mr.isChanfreinEnabled,
                                radius: borderConfigurations.mr.radius,
                                isInner: borderConfigurations.mr.isInner,
                                isOutside: borderConfigurations.mr.isOutside
                            },
                            ml: {
                                isRadiusEnabled: borderConfigurations.ml.isRadiusEnabled,
                                isChanfreinEnabled: borderConfigurations.ml.isChanfreinEnabled,
                                radius: borderConfigurations.ml.radius,
                                isInner: borderConfigurations.ml.isInner,
                                isOutside: borderConfigurations.ml.isOutside
                            },
                            bl: {
                                isRadiusEnabled: borderConfigurations.bl.isRadiusEnabled,
                                isChanfreinEnabled: borderConfigurations.bl.isChanfreinEnabled,
                                radius: borderConfigurations.bl.radius,
                                isInner: borderConfigurations.bl.isInner,
                                isOutside: borderConfigurations.bl.isOutside
                            },
                            br: {
                                isRadiusEnabled: borderConfigurations.br.isRadiusEnabled,
                                isChanfreinEnabled: borderConfigurations.br.isChanfreinEnabled,
                                radius: borderConfigurations.br.radius,
                                isInner: borderConfigurations.br.isInner,
                                isOutside: borderConfigurations.br.isOutside
                            }
                        }
                    }
                }
            })
            if (closeDialog) {
                closeDialog()
            }
        },
        [a, b, borderConfigurations.bl.isChanfreinEnabled, borderConfigurations.bl.isInner, borderConfigurations.bl.isOutside, borderConfigurations.bl.isRadiusEnabled, borderConfigurations.bl.radius, borderConfigurations.br.isChanfreinEnabled, borderConfigurations.br.isInner, borderConfigurations.br.isOutside, borderConfigurations.br.isRadiusEnabled, borderConfigurations.br.radius, borderConfigurations.ml.isChanfreinEnabled, borderConfigurations.ml.isInner, borderConfigurations.ml.isOutside, borderConfigurations.ml.isRadiusEnabled, borderConfigurations.ml.radius, borderConfigurations.mr.isChanfreinEnabled, borderConfigurations.mr.isInner, borderConfigurations.mr.isOutside, borderConfigurations.mr.isRadiusEnabled, borderConfigurations.mr.radius, borderConfigurations.tl.isChanfreinEnabled, borderConfigurations.tl.isInner, borderConfigurations.tl.isOutside, borderConfigurations.tl.isRadiusEnabled, borderConfigurations.tl.radius, borderConfigurations.tr.isChanfreinEnabled, borderConfigurations.tr.isInner, borderConfigurations.tr.isOutside, borderConfigurations.tr.isRadiusEnabled, borderConfigurations.tr.radius, c, configurator.mainForm, d, dispatch],
    )

    return (
        <>
            <Grid container justifyContent='flex-end'>
                <Grid item >
                    {closeDialog && <Button variant="outlined" color={'primary'} style={{ marginRight: '1rem' }} onClick={() => closeDialog()}>Fermer</Button>}
                    <Button disabled={errorA || errorB || errorC || errorD || !a || !b || !c || !d} color='primary' variant="contained" onClick={handelClickvalidate} >Valider</Button>
                </Grid>
            </Grid>
            <Grid container spacing={4} justify='center'>
                <Grid item>
                    <TextField label='A' error={errorA} helperText={errorAText || 'Longueur de A (mm)'} value={a} onChange={handelChangeA} />
                </Grid>
                <Grid item>
                    <TextField label='B' error={errorB} helperText={errorBText || 'Longueur de B (mm)'} value={b} onChange={handelChangeB} />
                </Grid>
            </Grid>
            <Grid container spacing={4} justify='center'>
                <Grid item>
                    <TextField label='C' error={errorC} helperText={errorCText || 'Longueur de C (mm)'} value={c} onChange={handelChangeC} />
                </Grid>
                <Grid item>
                    <TextField label='D' error={errorD} helperText={errorDText || 'Longueur de D (mm)'} value={d} onChange={handelChangeD} />
                </Grid>
            </Grid>
            {
                displayAsColumn ?
                    <>
                        <Grid container>
                            <img alt='configuration rectangle' style={{ height: '300px', display: 'block', margin: '2rem auto' }} src={configL} />
                        </Grid>
                        {Number(a) > 0 && Number(b) > 0 && Number(c) > 0 && Number(d) > 0 && <>

                            <ConfiguratorMainFormRecBorder label='1' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.tl} setborderConfiguration={(data) => setborderConfiguration(data, 'tl')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                            <Divider />
                            <ConfiguratorMainFormRecBorder label='2' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.tr} setborderConfiguration={(data) => setborderConfiguration(data, 'tr')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                            <Divider />
                            <ConfiguratorMainFormRecBorder label='3' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.ml} setborderConfiguration={(data) => setborderConfiguration(data, 'ml')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                            <Divider />
                            <ConfiguratorMainFormRecBorder label='4' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.mr} setborderConfiguration={(data) => setborderConfiguration(data, 'mr')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                            <Divider />
                            <ConfiguratorMainFormRecBorder label='5' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.br} setborderConfiguration={(data) => setborderConfiguration(data, 'br')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                            <Divider />
                            <ConfiguratorMainFormRecBorder label='6' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.bl} setborderConfiguration={(data) => setborderConfiguration(data, 'bl')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                        </>}
                    </>
                    :
                    <Grid container alignItems='center'>
                        <Grid item xs={12} sm={4} container direction='column' spacing={4}>
                            {Number(a) > 0 && Number(b) > 0 && Number(c) > 0 && Number(d) > 0 && <>
                                <Grid item>
                                    <ConfiguratorMainFormRecBorder label='1' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.tl} setborderConfiguration={(data) => setborderConfiguration(data, 'tl')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                                </Grid>
                                <Divider />
                                <Grid item>
                                    <ConfiguratorMainFormRecBorder label='3' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.ml} setborderConfiguration={(data) => setborderConfiguration(data, 'ml')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                                </Grid>
                                <Divider />
                                <Grid item>
                                    <ConfiguratorMainFormRecBorder label='6' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.bl} setborderConfiguration={(data) => setborderConfiguration(data, 'bl')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                                </Grid>
                            </>}
                        </Grid>
                        <Grid item xs={12} sm={4} container direction='column' spacing={4}>
                            <img alt='configuration rectangle' style={{ height: '300px', display: 'block', margin: '2rem auto' }} src={configL} />

                        </Grid>
                        <Grid item xs={12} sm={4} container direction='column' spacing={4}>
                            {Number(a) > 0 && Number(b) > 0 && Number(c) > 0 && Number(d) > 0 && <>
                                <Grid item>
                                    <ConfiguratorMainFormRecBorder label='2' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.tr} setborderConfiguration={(data) => setborderConfiguration(data, 'tr')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                                </Grid>
                                <Divider />
                                <Grid item>

                                    <ConfiguratorMainFormRecBorder label='4' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.mr} setborderConfiguration={(data) => setborderConfiguration(data, 'mr')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                                </Grid>
                                <Divider />
                                <Grid item>

                                    <ConfiguratorMainFormRecBorder label='5' labelChanfreins={['Départ chanfrein gauche', 'Départ chanfrein droite']} borderConfiguration={borderConfigurations.br} setborderConfiguration={(data) => setborderConfiguration(data, 'br')} defaultValue={defaultValue} setdefaultValue={setdefaultValue} />
                                </Grid>
                            </>}
                        </Grid>
                    </Grid>



            }
        </>
    )
}

export default ConfiguratorMainFormL
