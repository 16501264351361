import { Button, Grid, TableCell, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import React, { useCallback, useRef, useState } from 'react'
import { useUploadImage } from '../modules/request'

function TableCellFileSelect({ onCickEdited, className, align, children }) {

    const [errorMessage, seterrorMessage] = useState('')
    const theme = useTheme()
    const hiddenInputRef = useRef(null)

    const handleClick = useCallback(() => {
        hiddenInputRef.current.click()
    }, [])

    const uploadImage = useUploadImage()

    const handelSelectFile = useCallback((e) => {
        const file = e.target.files && e.target.files[0]
        if (file) {
            uploadImage(file).then(data => {
                if (data.success) {
                    onCickEdited(data.message)
                } else {
                    seterrorMessage(data.message)
                }
            })
        }
    }, [onCickEdited, uploadImage])

    return (
        <TableCell className={className} align={align || "center"} scope="row">
            <Grid container justify='center' alignItems='center'>
                <Button onClick={handleClick} variant='contained'>Choisir</Button>
                {children && <span style={{ marginLeft: theme.spacing(2) }}>{children}</span>}
            </Grid>
            {errorMessage && <Typography variant="caption" display="block" color='error' gutterBottom>
                {errorMessage}
            </Typography>}
            <input ref={hiddenInputRef} onChange={handelSelectFile} id='fileid' type='file' hidden />
        </TableCell>
    )
}

export default TableCellFileSelect