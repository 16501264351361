import React, { useContext, useCallback, useEffect, useState } from 'react';
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';

import { ButtonGroup, IconButton, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';

import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import DialogFormRectangle from '../../Dialog/DialogConfigFormRectangle';

function ConfiguratorIconsFormButton({ calculateLength, isLabelDisplayed, setisLabelDisplayed }) {
  const [configurator, dispatch] = useContext(ConfiguratorContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [openDialogConfigurationRectangle, setOpenDialogConfigurationRectangle] = useState(false);
  const selectedFormObject = configurator.forms.find(form => form.id === configurator.selectedForm);


  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleDelete = useCallback(
    () => {
      dispatch({
        type: 'DELETE_FORM',
        payload: {
          id: configurator.selectedForm,
        },
      });
      calculateLength();
    },
    [calculateLength, configurator.selectedForm, dispatch],
  );

  const handleCopy = useCallback(
    () => {
      dispatch({ type: 'DUPLICATE_FORM' });
      calculateLength();
    },
    [calculateLength, configurator.selectedForm, dispatch],
  );

  const handleReturn = useCallback(
    () => {
      dispatch({ type: 'UNDO' });
      calculateLength();
    },
    [calculateLength, configurator.selectedForm, dispatch],
  );

  const handleForward = useCallback(
    () => {
      dispatch({ type: 'REDO' });
      calculateLength();
    },
    [calculateLength, configurator.selectedForm, dispatch],
  );

  const handleOpenMainDailogConfigurationRectangle = () => {
    setOpenDialogConfigurationRectangle(true);
  };

  const handleCloseDialogConfigurationRectangle = () => {
    setOpenDialogConfigurationRectangle(false);
  }


  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: windowWidth <= 768 ? '0px' : '70px',
    backgroundColor: 'white',
    borderRadius: '5px'

  }

  return (

    <div className='Icons' style={containerStyle}>
      <ButtonGroup color="primary" size="small" >
        <Tooltip title="Annuler" placement="top">
          <IconButton
            disabled={!configurator.undoStack?.length}>
            <UndoIcon onClick={handleReturn}
              onMouseEnter={() => setHoveredIcon('arrowBack')}
              onMouseLeave={() => setHoveredIcon(null)}

            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Revenir" placement="top">
          <IconButton
            disabled={!configurator.redoStack?.length}>
            <RedoIcon onClick={handleForward}
              onMouseEnter={() => setHoveredIcon('arrowForward')}
              onMouseLeave={() => setHoveredIcon(null)}
            />
          </IconButton>

        </Tooltip>

        <Tooltip title="Supprimer" placement="top">
          <IconButton
            disabled={!configurator.selectedForm}>
            <DeleteOutlineIcon onClick={handleDelete}
              onMouseEnter={() => setHoveredIcon('delete')}
              onMouseLeave={() => setHoveredIcon(null)}
            />
          </IconButton>
        </Tooltip>

        <Tooltip title="Duppliquer" placement="top">
          <IconButton
            disabled={!configurator.selectedForm}>
            <FileCopyOutlinedIcon onClick={handleCopy}
              onMouseEnter={() => setHoveredIcon('copy')}
              onMouseLeave={() => setHoveredIcon(null)}
            />
          </IconButton>
        </Tooltip>

        {
          (selectedFormObject && selectedFormObject.type === 'RECTANGLE') ? (
            <Tooltip title="Arrondir les angles" placement="top">
              <IconButton onClick={handleOpenMainDailogConfigurationRectangle}>
                <FlipToBackIcon />
              </IconButton>
            </Tooltip>
          ) : null
        }


      </ButtonGroup>

      {
        openDialogConfigurationRectangle && <DialogFormRectangle
          open={openDialogConfigurationRectangle}
          setOpen={handleCloseDialogConfigurationRectangle}
          isSubForm={true}
        />
      }


    </div >
  );
}

export default ConfiguratorIconsFormButton;