import React, { useCallback, useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { usePost } from '../modules/request';
import { Button, CircularProgress, Grid, makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import poweredstripe from '../assets/images/poweredstripe.png'
import { grey } from '@material-ui/core/colors';
import { usePostOrders } from '../contexts/OrderContext';

const useStyle = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8)
    },
    paper: {
        padding: theme.spacing(2)
    },
    poweredstripe: {
        width: '200px',
    }
}))



function CheckOutForm({ amount, setpaymentResult, setActiveStep, me, datas }) {
    const post = usePost()
    const stripe = useStripe();
    const elements = useElements();
    const theme = useTheme();
    const { postOrder } = usePostOrders()
    const [loading, setloading] = useState(false);

    const classes = useStyle()

    const CARD_OPTIONS = {
        iconStyle: "solid",
        style: {
            base: {

                iconColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
                fontWeight: 500,
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: theme.typography.h5.fontSize,
                fontSmoothing: "antialiased",
                ":-webkit-autofill": {
                    color: theme.palette.primary.light
                },
                "::placeholder": {
                    color: theme.palette.primary.light
                },

            },
            invalid: {
                iconColor: "#ffc7ee",
                color: "#ffc7ee"
            }
        }
    };

    const handelPay = useCallback(
        (e) => {
            (async () => {
                e.preventDefault()
                setloading(true)
                try {

                    const cardElement = elements.getElement(CardElement)

                    const paymentMethodReq = await stripe.createPaymentMethod({
                        type: 'card',
                        card: cardElement,
                    });

                    if (paymentMethodReq.error) {
                        throw new Error(paymentMethodReq.error.message)
                    }
                    const { data } = await post('/buy', { deliveryMethod: datas.deliveryMethode })
                    const clientSecret = data.message.clientSecret
                    const confirmedCarPayment = await stripe.confirmCardPayment(clientSecret, {
                        payment_method: paymentMethodReq.paymentMethod.id
                    })
                    const pi = confirmedCarPayment.paymentIntent.id
                    let clientDeliveryData = {}
                    if (me.isPro) {
                        clientDeliveryData = {
                            deliveryHouseNumber: me.pro.deliveryHouseNumber,
                            deliveryStreet: me.pro.deliveryStreet,
                            deliveryCity: me.pro.deliveryCity,
                            deliveryZipCode: me.pro.deliveryZipCode,
                            deliveryMethode: datas.deliveryMethode
                        }
                    } else {
                        clientDeliveryData = {
                            houseNumber: me.houseNumber,
                            street: me.street,
                            city: me.city,
                            zipCode: me.zipCode,
                            deliveryMethode: datas.deliveryMethode
                        }
                    }

                    const postOrderRes = await postOrder({ pi, clientDeliveryData: { ...clientDeliveryData, lastName: me.lastName, firstName: me.firstName } })
                    if (!postOrderRes) {
                        throw new Error('Une erreur c\'est produite')
                    }

                    setpaymentResult({
                        success: true,
                        message: 'OK'
                    })
                    setActiveStep(3)
                } catch (e) {
                    setpaymentResult({
                        success: false,
                        message: e.message
                    })
                }


                setloading(false)
            })()
        },
        [amount, datas.deliveryMethode, elements, me, post, postOrder, setActiveStep, setpaymentResult, stripe],
    )


    return (
        <div>
            <Typography variant="h6" gutterBottom align="center" color='primary'>
                Entrez vos informations bancaire
            </Typography>
            <form onSubmit={handelPay} className={classes.form}>
                <Paper elevation={0} className={classes.paper}>
                    <CardElement options={CARD_OPTIONS} />
                </Paper>

                <Grid container justify='space-between' style={{ marginTop: theme.spacing(6) }}>
                    <img src={poweredstripe} alt='poweredstripe' className={classes.poweredstripe} />
                    <Button variant="contained" color="primary" type='submit' endIcon={loading ? <CircularProgress size={20} color={grey[50]} /> : null} >Valider</Button>
                </Grid>

            </form>
        </div>
    )
}

export default CheckOutForm
