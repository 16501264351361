import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Grid } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { PropertiesContext } from '../../../../../../contexts/PropertiesContext';
import axios from 'axios';
import { SnackbarContext } from '../../../../../../contexts/SnackbarContext';

function Cgv() {

    const [cgv, setCgv] = useState('');
    const [properties, dispatch] = useContext(PropertiesContext);
    const [, dispatchSnackbar] = useContext(SnackbarContext)

    const handelSave = () => {
        return (async () => {
            try {
                if (!properties || !properties._id) {
                    const data = {
                        cgv
                    }
                    const res = await axios.post(process.env.REACT_APP_BASE_API + '/propertie', data)
                    dispatch({
                        type: 'SET_PROPERTY',
                        payload: res.data.message
                    })

                } else {
                    const newData = { cgv }
                    const res = await axios.put(process.env.REACT_APP_BASE_API + '/propertie/' + properties._id, newData)
                    dispatch({
                        type: 'SET_PROPERTY',
                        payload: res.data.message
                    })
                }

                dispatchSnackbar({
                    type: 'SET_SNACKBAR',
                    payload: {
                        open: true,
                        content: "Conditions générales de vente enregistré avec succées",
                        type: 'success',
                        duration: 4000
                    }
                })

            } catch (e) {

            }

        })()
    }

    useEffect(() => {
        if (properties?.cgv?.length > 0) {
            setCgv(properties?.cgv)
        }

    }, [properties]);

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={8}>
                    <CKEditor
                        editor={ClassicEditor}
                        data={cgv}
                        onChange={(event, editor) => {

                            const html = editor.getData();
                            setCgv(html)

                        }}
                    />
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={handelSave} variant="outlined" color="primary">Enregistrer</Button>
                        </Grid>

                    </Grid >
                </Grid>

            </Grid>
        </>
    )
}

export default Cgv