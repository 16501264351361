import React, { useContext, useReducer } from 'react'
import uniqid from 'uniqid';

const ConfiguratorContext = React.createContext([[], () => { }])

const MAX_STACK_SIZE = 15;

const initialState = {
  materialId: null,
  shadeId: null,
  thicknessId: null,
  materialImageName: null,
  shadeImageName: null,
  form: 0,
  formToAdd: null,
  forms: [],
  ratio: 0,
  isDraging: false,
  mainFormX: 0,
  mainFormY: 0,
  mainForm: {
    configured: false,
    width: null,
    height: null,
    radius: null
  },
  price: 0,
  unitPrice: 0,
  nbPiece: 1,
  nbFolding: 0,
  priceLoading: false,
  length: 0,
  nbChains: 0,
  model: {},
  step: 0,
  selectedForm: '',
  formToAddDataTemp: {},
  realWidth: 0,
  realHeight: 0,
  weight: 0,
  isFromCatalog: false,
  shadeColor: null,
  realSurface: 0,
  copyForm: '',
  undoStack: [],
  redoStack: [],
  validations: {}
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'EDIT':
      return { ...initialState, ...action.payload.data, nbPiece: action.payload.quantity, editId: action.payload.id }
    case 'CLONE':
      return { ...initialState, ...action.payload.data }
    case 'SET_VALUE':
      return { ...state, [action.payload.key]: action.payload.data }
    case 'SET_VALUES':
      return { ...state, ...action.payload.data }
    case 'SET_PROPERTY_SELECT_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== state.selectedForm ? form : {
          ...form,
          [action.payload.property]: action.payload.data
        }
        )
      }
    case 'SET_POSITION_FORM':

      const selectedForm = state.forms.find(form => form.id === action.payload.form)
      if (selectedForm) {

        let x = Number(action.payload.data.x);
        let y = Number(action.payload.data.y);
        return {
          ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
            ...form,
            realx: (x || x === 0) && !isNaN(x) ? Math.ceil(x) : form.realx,
            realy: (y || y === 0) && !isNaN(y) ? Math.ceil(y) : form.realy,
          }
          ),
          undoStack: [
            ...state.undoStack.slice(state.undoStack.length >= MAX_STACK_SIZE ? 1 : 0),
            state.forms
          ],
          redoStack: []
        }
      }
      break;
    case 'SET_ABS_POSITION_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          absx: action.payload.data.x ? Math.ceil(action.payload.data.x) : form.absx,
          absy: action.payload.data.y ? Math.ceil(action.payload.data.y) : form.absy
        }
        )
      }
    case 'SET_WIDTH_HEIGHT_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          width: action.payload.data.width ? Math.ceil(action.payload.data.width) : form.width,
          height: action.payload.data.height ? Math.ceil(action.payload.data.height) : form.height
        }
        ),
        undoStack: [
          ...state.undoStack.slice(state.undoStack.length >= MAX_STACK_SIZE ? 1 : 0),
          state.forms
        ],
        redoStack: []
      }
    case 'SET_TEXT_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          text: action.payload.data.text || action.payload.data.text === '' ? action.payload.data.text : form.text,
        }
        ),
        undoStack: [
          ...state.undoStack.slice(state.undoStack.length >= MAX_STACK_SIZE ? 1 : 0),
          state.forms
        ],
        redoStack: []
      }
    case 'SET_RADIUS_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          radius: Math.ceil(Number(action.payload.data.radius))
        }
        ),
        undoStack: [
          ...state.undoStack.slice(state.undoStack.length >= MAX_STACK_SIZE ? 1 : 0),
          state.forms
        ],
        redoStack: []
      }

    case 'SET_ANGLE_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          d: Math.ceil(Number(action.payload.data.d))
        }
        ),
        undoStack: [
          ...state.undoStack.slice(state.undoStack.length >= MAX_STACK_SIZE ? 1 : 0),
          state.forms
        ],
        redoStack: []
      }
    case 'SET_VALUE_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          ...action.payload.data
        }
        )
      }
    case 'SET_ROTATION_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          rotation: Math.ceil(Number(action.payload.data.rotation))
        }
        ),
        undoStack: [
          ...state.undoStack.slice(state.undoStack.length >= MAX_STACK_SIZE ? 1 : 0),
          state.forms
        ],
        redoStack: []
      }
    case 'SET_MODEL_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          model: action.payload.data.model
        }
        )
      }

    case 'DELETE_FORM':
      return {
        ...state,
        forms: state.forms.filter(form => form.id !== action.payload.id),
        undoStack: [
          ...state.undoStack.slice(state.undoStack.length >= MAX_STACK_SIZE ? 1 : 0),
          state.forms
        ],
        redoStack: []
      }

    case 'SET_FORM_REC_BORDER_CONFIGURATION':
      return {
        ...state,
        forms: state.forms.map(form => form.id === state.selectedForm ? { ...form, borderConfigurations: action.payload.borderConfigurations } : form),
        undoStack: [...state.undoStack, state.forms],
        redoStack: []
      }

    case 'RESET_SOFT':
      return {
        ...state,
        formToAdd: null,
        forms: [],
        ratio: 0,
        isDraging: false,
        mainFormX: '',
        mainFormY: '',
        mainForm: { configured: false, width: null, height: null, radius: null },
        price: 0,
        unitPrice: 0,
        nbFolding: 0,
        priceLoading: false,
        length: 0,
        nbChains: 0,
        model: {},
        formToAddDataTemp: {},
        realSurface: 0,
        profil: null,
        profilH: null,
        validations: {},
        isValidationDesactived: false,

      }
    case 'RESET':
      return {
        ...state,
        materialId: null,
        shadeId: null,
        thicknessId: null,
        materialImageName: null,
        shadeImageName: null,
        form: 0,
        formToAdd: null,
        forms: [],
        ratio: 0,
        isDraging: false,
        mainFormX: 0,
        mainFormY: 0,
        mainForm: { configured: false, width: null, height: null, },
        price: null,
        unitPrice: 0,
        nbPiece: 1,
        nbFolding: 0,
        priceLoading: false,
        length: 0,
        nbChains: 0,
        model: {},
        formToAddDataTemp: {},
        realWidth: 0,
        realHeight: 0,
        weight: 0,
        isFromCatalog: false,
        shadeColor: null,
        realSurface: 0,
        profil: null,
        profilH: null,
        editId: null,
        undoStack: [],
        redoStack: [],
        validations: {},
        isValidationDesactived: false
      }

    case 'COPY_FORM':
      return { ...state, copyForm: state.selectedForm }

    case 'PASTE_FORM':
      const formToCopy = state.forms.find(form => form.id === state.copyForm);
      if (formToCopy) {
        const { realx, realy, relatx, relaty } = action.payload;
        const newForm = {
          ...formToCopy,
          id: uniqid(),
          realx,
          realy,
          relatx,
          relaty,
        };
        return {
          ...state,
          forms: [...state.forms, newForm],
          undoStack: [
            ...state.undoStack.slice(state.undoStack.length >= MAX_STACK_SIZE ? 1 : 0),
            state.forms
          ],
          redoStack: []
        };
      }
      return state;

    case 'CUT_FORM':
      const formToCut = state.forms.find(form => form.id === state.selectedForm);
      if (formToCut) {
        return {
          ...state,
          copyForm: state.selectedForm,
          forms: state.forms.filter(form => form.id !== state.selectedForm),
          undoStack: [
            ...state.undoStack.slice(state.undoStack.length >= MAX_STACK_SIZE ? 1 : 0),
            state.forms
          ],
          redoStack: []
        };
      }
      return state;
    case 'DUPLICATE_FORM':
      const formToDuplicate = state.forms.find(form => form.id === state.selectedForm);
      if (formToDuplicate) {
        const { realx, realy, relatx, relaty } = formToDuplicate;
        const newForm = {
          ...formToDuplicate,
          id: uniqid(),
          realx,
          realy,
          relatx,
          relaty,
        };
        return {
          ...state,
          forms: [...state.forms, newForm],
          undoStack: [
            ...state.undoStack.slice(state.undoStack.length >= MAX_STACK_SIZE ? 1 : 0),
            state.forms
          ],
          redoStack: []
        };
      }
      return state;

    case 'UNDO':
      const lastUndo = state.undoStack[state.undoStack.length - 1];
      if (lastUndo) {
        return {
          ...state,
          forms: lastUndo,
          redoStack: [state.forms, ...state.redoStack],
          undoStack: state.undoStack.slice(0, state.undoStack.length - 1),
        };
      }
      return state;
    case 'REDO':
      const lastRedo = state.redoStack[0];
      if (lastRedo) {
        return {
          ...state,
          forms: lastRedo,
          undoStack: [
            ...state.undoStack.slice(state.undoStack.length >= MAX_STACK_SIZE ? 1 : 0),
            state.forms
          ],
          redoStack: state.redoStack.slice(1),
        };
      }
      return state;

    case 'ADD_FORM':
      return {
        ...state,
        forms: [...state.forms, action.payload.data],
        undoStack: [
          ...state.undoStack.slice(state.undoStack.length >= MAX_STACK_SIZE ? 1 : 0),
          state.forms
        ],
        redoStack: []
      };

    default:
      throw new Error();

  }

};

const useConfigurator = () => {
  const [configurator] = useContext(ConfiguratorContext);

  const haveGravedForms = () => configurator.forms.some(form => form.graved);

  return {
    haveGravedForms,
  }
}

function ConfiguratorProvider(props) {
  const [configurator, dispatch] = useReducer(reducer, initialState);

  return (
    <ConfiguratorContext.Provider value={[configurator, dispatch]}>
      {props.children}
    </ConfiguratorContext.Provider>
  );
}

export { ConfiguratorContext, ConfiguratorProvider, useConfigurator }

